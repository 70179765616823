<template>
    <md-content
        v-if="showInfoWindow"
        :class="['info-tooltip md-elevation-2 md-layout map-panel', content.isOptimise ? 'info-tooltip-height' : '']"
        :style="styles"
    >
        <md-card
            :class="[
                'info--container',
                content.isStop ? 'top-margin' : '',
                content.isOptimise ? 'card-margin-top' : ''
            ]"
        >
            <div
                :class="
                    content.type === 'stop' || content.type === 'optimisedStop' ? 'close-button-large' : 'close-button'
                "
                @click="closeInfoWindow"
            >
                <md-icon>close</md-icon>
            </div>

            <md-content class="info-detail--container" v-if="content.type === 'driver'">
                <md-content class="info-detail" v-if="!pinnedUser">
                    <span class="info-heading">{{ content.fullName || content.groupName }}</span>
                </md-content>
                <div class="misc-details">
                    <md-content
                        class="info-detail"
                        v-if="content.battery && isLastLocationUpdateToday"
                        title="Battery level"
                    >
                        <md-icon class="battery-icon">battery_std</md-icon>
                        <span>{{ content.battery }}%</span>
                    </md-content>
                    <md-content class="info-detail" title="Speed">
                        <span class="time-update" v-if="isLastLocationUpdateToday">
                            {{ content.lastLocationUpdate | timeFormat }}
                        </span>
                        <span class="info-detail" v-else>
                            {{ content.lastLocationUpdate | dateFormat(DATE_TYPES.standardDateTime) }}
                        </span>
                        &nbsp;
                        <md-icon>speed</md-icon>
                        <span class="value">{{ Math.round(content.speed) }} {{ user.speedUnits || 'kph' }}</span>
                    </md-content>
                </div>
                <!--<md-content class="buttons-container">
                    <md-button class="text--button" :md-ripple="false" @click="showStreetView">STREET VIEW</md-button>
                </md-content>-->
            </md-content>
            <md-content class="info-detail--container" v-if="content.type === 'locationHistory'">
                <div class="misc-details">
                    <md-content class="info-detail" title="Speed">
                        <span class="time-update" v-if="isDateToday">
                            {{ content.lastLocationUpdate | timeFormat }}
                        </span>
                        &nbsp;
                        <md-icon>speed</md-icon>
                        <span class="value">{{ Math.round(content.speed) }} {{ user.speedUnits || 'kph' }}</span>
                    </md-content>
                </div>
                <md-content class="info-detail location-update" title="Location update" v-if="!isDateToday">
                    <span class="info-label">Last location update</span>
                    <span>{{ content.lastLocationUpdate | dateFormat(DATE_TYPES.standardDateTime) }}</span>
                </md-content>
                <md-content class="info-detail update-type" title="Type of update" v-if="isAssetGroup">
                    <md-icon>settings</md-icon>
                    <span class="value">{{ content.logType }}</span>
                </md-content>

                <!--<md-content class="buttons-container">
                    <md-button class="text--button" :md-ripple="false" @click="showStreetView">STREET VIEW</md-button>
                </md-content>-->
            </md-content>
            <div v-else-if="content.type === 'stop'">
                <info-window-stop-content
                    :content="content"
                    :show-details-page="showDetailsPage"
                    :get-capacity-info="getCapacityInfo"
                    :has-capacity-settings="hasCapacitySettings"
                    :get-skills-info="getSkillsInfo"
                    :has-skills-settings="hasSkillsSettings"
                    :get-service-info="getServiceInfo"
                    :has-service-settings="hasServiceSettings"
                    @handleReassignedStop="handleReassignedStop"
                    @handleDeleteStop="handleDeleteStop"
                />
            </div>
            <md-content class="info-detail--container" v-else-if="content.type === 'statusHistory'">
                <md-content class="info-detail stop-address">
                    <span class="info-heading">{{ content.address }}</span>
                </md-content>
                <md-content class="info-detail stop-ref" @click="stopRefClicked">
                    {{ content.stopRef }}
                </md-content>
                <md-content class="info-detail--container stop-status--detail stop-status--change">
                    <span :style="{ color: setColor(content.oldStatus) }">
                        {{ content.oldStatus }}
                    </span>
                    <md-icon class="arrow-icon">arrow_right_alt</md-icon>
                    <span :style="{ color: setColor(content.newStatus) }">
                        {{ content.newStatus }}
                    </span>
                </md-content>
                <md-content class="info-detail action-date" title="Action date">
                    <span class="info-label">Action date</span>
                    <span>{{ content.actionDate | dateFormat(DATE_TYPES.standardDateTime) }}</span>
                </md-content>
            </md-content>
            <div v-else-if="content.type === 'optimisedStop'">
                <info-window-optimised-stop-content
                    :content="content"
                    :show-details-page="showDetailsPage"
                    :get-capacity-info="getCapacityInfo"
                    :has-capacity-settings="hasCapacitySettings"
                    :get-skills-info="getSkillsInfo"
                    :has-skills-settings="hasSkillsSettings"
                    :get-service-info="getServiceInfo"
                    :has-service-settings="hasServiceSettings"
                />
            </div>
            <div v-else-if="content.type === 'geofence'">
                <md-content class="info-detail geo-fence-name">
                    <b>{{ content.title }}</b>
                    <p>{{ content.notes }}</p>
                    <div class="cta">
                        <md-button
                            class="md-button md-just-icon md-round md-primary"
                            @click="$emit('editGeofence', content.geofenceId)"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            class="md-button md-just-icon md-round md-primary"
                            @click="$emit('deleteGeofence', content.geofenceId)"
                        >
                            <md-icon>delete</md-icon>
                        </md-button>
                    </div>
                </md-content>
            </div>
        </md-card>
    </md-content>
</template>

<script>
import moment from 'moment';
import { GeneralMixin } from '@/mixins';
import InfoWindowStopContent from './components/InfoWindowStopContent';
import InfoWindowOptimisedStopContent from './components/InfoWindowOptimisedStopContent';

export default {
    name: 'InfoWindowComponent',
    components: {
        InfoWindowStopContent,
        InfoWindowOptimisedStopContent
    },
    mixins: [GeneralMixin],
    props: {
        isDateToday: {
            type: Boolean,
            default: true
        },
        content: {
            type: Object,
            default: () => {}
        },
        styles: {
            type: Object,
            default: () => {}
        },
        showInfoWindow: Boolean,
        loadedSaveModel: {
            type: Boolean,
            default: false
        },
        getCapacityInfo: {
            type: Function,
            default: () => {}
        },
        hasCapacitySettings: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        pinnedUser() {
            return this.$store.getters['map/pinnedUser'];
        },
        isAssetGroup() {
            return this.$store.getters['map/isAssetGroup'];
        },
        user() {
            return this.$store.getters['user/user'];
        },
        isLastLocationUpdateToday() {
            return (
                moment()
                    .startOf('day')
                    .diff(moment(this.content.lastLocationUpdate).startOf('day')) <= 0
            );
        }
    },
    methods: {
        // eslint-disable-next-line consistent-return
        setColor(status) {
            switch (status.toLowerCase()) {
                case 'pending':
                    return '#c2907f';
                case 'enroute':
                    return '#4ed2ff';
                case 'arrived':
                    return '#2b93ff';
                case 'departed':
                    return '#ffb42b';
                case 'cancelled':
                    return '#ff5245';
                case 'delayed':
                    return '#384553';
                case 'complete':
                    return '#0bda8e';
                default:
                // do nothing
            }
        },
        showStreetView() {
            this.$emit('handleShowStreetView', this.content.coordinates);
        },
        showDetailsPage() {
            const { stopId, shipmentId } = this.content;
            let location;
            if (stopId) {
                location = this.$router.resolve({ name: 'Stop Details', path: `/stops/details/${stopId}` });
            } else if (shipmentId) {
                location = this.$router.resolve({ name: 'Shipment Details', path: `/shipments/details/${shipmentId}` });
            }
            window.open(location.location.path, '_blank');
        },
        showTrackingLink() {
            window.open(`${this.content.trackingLinkUrl}?isAdmin=true&showPOD=true`, '_blank');
        },
        stopRefClicked() {
            this.$emit('centerToStop', { stopId: this.content.stopId });
        },
        handleDeleteStop(deleteTripStops) {
            this.$emit('handleDeleteStop', deleteTripStops);
        },
        handleReassignedStop(reassignedTripStop) {
            this.$emit('handleReassignedStop', reassignedTripStop);
        },
        closeInfoWindow() {
            this.$emit('closeInfoWindow');
        },
        getSkillsInfo() {
            const skills = this.content?.skills;
            return skills?.length ? skills.join(', ') : '-';
        },
        hasSkillsSettings() {
            const skillOptions = this.user?.skillOptions;
            return Array.isArray(skillOptions) && skillOptions.length > 0;
        },
        getServiceInfo() {
            const serviceLines = this.content?.serviceLines;
            const services = serviceLines?.map((x) => x.description) || [];
            return services.length ? services.join(', ') : '-';
        },
        hasServiceSettings() {
            return this.user.hasServiceSettings;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';
.close-button-large,
.close-button {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 4;
    .md-icon {
        max-width: 12px !important;
        max-height: 12px !important;
        font-size: 12px !important;
        color: black !important;
    }
}

.close-button-large {
    .md-icon {
        font-size: 20px !important;
    }
}

.md-content {
    font-weight: bold;
}
.left-margin {
    margin-left: 4px !important;
}
.top-margin {
    margin-top: 16px;
}
.content-phone {
    color: $grey-600 !important;
}
.info-tooltip-height {
    line-height: 1.5 !important;
    margin: 50px 0px 0px 5px;
}
.card-margin-top {
    margin-top: -1px;
}
.info-tooltip {
    position: absolute;
    line-height: 1.2;
    max-width: var(--infowindow-width);
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    color: black;
    z-index: 2;
    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: var(--tooltip-placement);
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }
    &:after {
        content: '';
        position: absolute;
        left: var(--tooltip-placement);
        top: 100%;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }
    .info--container {
        margin-top: 16px;
        margin-bottom: 16px;
        .info-detail--container,
        .contact-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0 8px;
            .misc-details {
                display: flex;
                margin: 5px auto;
            }
            .info-detail,
            .contact-details {
                text-align: center;
                cursor: help;
                margin: 5px;
                display: flex;
                justify-content: center;
                &.update-type {
                    margin-top: 10px;
                }
                &.stop-ref {
                    cursor: pointer;
                    color: $locate2u-blue-base;
                    align-self: baseline;
                    margin-left: 0;
                    font-size: 12px;
                }
                span {
                    font-size: 11px;
                    &.value {
                        margin-top: 5px;
                    }
                }
                .info-heading {
                    font-size: 14px;
                    font-weight: 500;
                    max-width: var(--text-max-width);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .time-update {
                    font-size: 12px;
                }
                .md-icon {
                    margin: 2px 2px 0 0;
                    width: 14px;
                    height: 14px;
                    font-size: 22px !important;
                    color: $locate2u-blue-base;
                    &.arrow-icon {
                        color: black;
                        height: 0;
                        margin-left: 8px;
                    }
                    &.battery-icon {
                        transform: rotate(90deg);
                    }
                }
                &.location-update,
                &.action-date,
                &.stop-address,
                &.route-optimization {
                    margin: 0 auto;
                    flex-direction: column;
                    align-items: baseline;
                    span {
                        &:first-child {
                            margin-bottom: 1px;
                        }
                        &:last-child {
                            margin-top: 1px;
                            font-size: 12px;
                        }
                        &.info-label {
                            font-size: 10px;
                            color: $grey;
                        }
                    }
                    &.route-optimization {
                        text-align: left;
                        margin: 5px 10px;
                    }
                }
                &.stop-address {
                    margin-top: 15px;
                    text-align: left;
                }
                &.action-date {
                    align-self: baseline;
                    margin: 0;
                }
            }
            &.stop-status--change {
                display: block;
                margin: 0;
                align-self: baseline;
                padding: 0;
                font-size: 15px;
            }
        }
        .buttons-container {
            width: 100%;
            margin: 8px auto;
            display: flex;
            justify-content: center;
            .info--buttons {
                min-width: 28px !important;
                width: 28px !important;
                height: 28px !important;
            }
            .text--button {
                width: 80%;
                margin: 0 auto;
                display: block;
                border-radius: 8px;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 1px;
                font-weight: 500;
                background: $white !important;
                color: $locate2u-blue-base !important;
                &:hover {
                    border: 1px solid $locate2u-blue-base !important;
                }
            }
        }
    }
}
.geo-fence-name {
    padding: 0 10px;
    b {
        font-size: 1.1rem;
    }
    p {
        font-weight: 100;
    }
    .cta {
        display: flex;
        justify-content: center;
    }
    .md-button {
        transform: scale(0.75);
    }
}
</style>
