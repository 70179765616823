<template>
    <div>
        <div class="street-view" ref="streetView" :style="{ zIndex: isStreetView ? '3' : '-1' }"></div>
        <div v-if="isStreetView" class="close-button">
            <md-button class="md-white md-icon-button" :md-ripple="false" @click="$_map_closeStreetView(false)">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-warning">
                <div class="card-icon">
                    <md-icon>map</md-icon>
                </div>
                <div class="map-router-link" v-if="tripDetails.assignedTo.publicUserId">
                    <router-link
                        :to="{
                            name: 'Maps Overview Active User Panel',
                            params: {
                                date: selectedDate,
                                userId: tripDetails.assignedTo.publicUserId
                            },
                            query: { from: 'trip' }
                        }"
                        target="_blank"
                    >
                        View Route Playback
                    </router-link>
                </div>
            </md-card-header>

            <md-card-content>
                <div ref="map" class="map"></div>
                <md-content
                    v-if="showInfoWindow"
                    class="info-tooltip md-elevation-2 md-layout"
                    :style="infoWindowStyles"
                    v-click-outside="closeInfoWindow"
                >
                    <md-content class="button--actions">
                        <!--<md-button
                            title="Show street view"
                            class="md-just-icon md-round md-info"
                            @click="showStreetView(content.latitude, content.longitude)"
                        >
                            <md-icon>location_searching</md-icon>
                        </md-button>-->
                    </md-content>
                    <md-content v-if="content.isStopStatus" class="info-detail--container stop-status--detail">
                        <md-icon>contacts</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Changed By</span>
                            <span>{{ content.changedBy }}</span>
                        </md-content>
                    </md-content>
                    <md-content v-if="content.isStopStatus" class="info-detail--container stop-status--detail">
                        <md-icon>date_range</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Location Change</span>
                            <span>{{ content.actionDate | dateTimeFormat }}</span>
                        </md-content>
                    </md-content>
                    <md-content
                        v-if="content.isStopStatus"
                        class="info-detail--container stop-status--detail stop-status--change"
                    >
                        <span :style="{ color: setColor(content.oldStatus) }">
                            {{ content.oldStatus }}
                        </span>
                        <md-icon class="arrow-icon">arrow_right_alt</md-icon>
                        <span :style="{ color: setColor(content.newStatus) }">
                            {{ content.newStatus }}
                        </span>
                    </md-content>
                    <md-content v-if="content.isStop" class="info-detail--container">
                        <md-icon>location_on</md-icon>
                        <md-content class="info-detail stop-detail">
                            <span class="label">Address</span>
                            <span>{{ content.address }}</span>
                        </md-content>
                    </md-content>
                    <md-content
                        v-if="content.isTripActualStartLocation"
                        class="info-detail--container stop-status--detail"
                    >
                        <md-icon>location_on</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Actual start location</span>

                            <span class="label">Changed By</span>
                            <span>{{ content.changedBy }}</span>
                        </md-content>
                    </md-content>
                    <md-content
                        v-if="content.isTripActualCompleteLocation"
                        class="info-detail--container stop-status--detail"
                    >
                        <md-icon>location_on</md-icon>
                        <md-content class="info-detail">
                            <span class="label">Actual Complete location</span>

                            <span class="label">Changed By</span>
                            <span>{{ content.changedBy }}</span>
                        </md-content>
                    </md-content>
                </md-content>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import { setColour, getStopStatusColour } from '@/helpers';
import { MarkerPins } from '@/utils/MarkerPins';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'MapOverlay',
    mixins: [GeneralMixin, MapOverviewMixin],
    props: {
        tripDetails: {
            type: Object,
            default: () => {}
        }
    },
    async mounted() {
        await this.$_map_loadGoogleMap();
        this.initializeMap();
    },
    data() {
        return {
            map: null,
            bounds: null,
            markers: [],
            panorama: null,
            isStreetView: false,
            showInfoWindow: false,
            infoWindowStyles: {},
            content: {},
            dimensions: {
                height: 125,
                width: 117.5
            },
            currentlySelectedMarker: null,
            selectedDate: null
        };
    },
    methods: {
        async initializeMap() {
            const { locationDetails, routeDetails, stopList, routePolyline, tripDate } = this.tripDetails;

            this.selectedDate = moment(tripDate).format(DATE_TYPES.internationalDate);

            const lat = locationDetails.startLocation.latitude;
            const lng = locationDetails.startLocation.longitude;

            const element = this.$refs.map;

            const options = {
                zoomControl: false,
                mapTypeControl: false,
                gestureHandling: 'cooperative'
            };

            this.map = this.$_map_createMap(element, lat, lng, options);
            this.bounds = this.$_map_createBounds();
            const pins = MarkerPins[0];
            if (routeDetails && routeDetails.coordinates) {
                const {
                    coordinates: { latitude, longitude },
                    route
                } = routeDetails;
                this.showDriverMarker({ latitude, longitude }, route, pins);
                if (route) {
                    this.showRoute(route, pins);
                }
            }

            if (routeDetails == null && routePolyline != null) {
                this.showRoute(routePolyline, pins);
            }

            this.showStopMarker(pins);
            if (stopList.length >= 1) 
                this.$_map_callMapFunction(this.map, 'fitBounds', [this.bounds]);
            else 
                this.$_map_callMapFunction(this.map, 'setZoom', [14]);

            // const panoramaContainer = this.$refs.streetView;

            // this.panorama = this.$_map_createPanorama(this.map, panoramaContainer);
            // const panoramaOptions = {
            //    scrollwheel: false,
            //    disableDefaultUI: true,
            //    clickToGo: false,
            //    enableCloseButton: true
            // };

            // const pov = { heading: 265, pitch: 0 };
            // this.$_map_setPanoramaOptions(this.panorama, panoramaOptions, pov);
            // this.panorama.setVisible(false);

            const started = this.tripDetails.statusHistory.find((x) => x.newStatus.toLowerCase() === 'started');
            const complete = this.tripDetails.statusHistory.find((x) => x.newStatus.toLowerCase() === 'complete');
            this.showTripMarkersOnMap(started, 'started', true, false);
            this.showTripMarkersOnMap(complete, 'started', false, true);
        },
        showStopMarker(pins) {
            if (this.tripDetails.stopList.length > 0) {
                let labelIndex = 0;

                const startEndLocationMarkers = {
                    startLocation: '/img/map/optimise-stop/start.png',
                    endLocation: '/img/map/optimise-stop/end.png'
                };

                let index = 0;
                this.tripDetails.stopList.forEach((stopDetails, i) => {
                    const isCompletedOrCancelledStop =
                        ['complete', 'cancelled', 'failed', 'on hold'].indexOf(stopDetails.status.toLowerCase()) !== -1;
                    const { location, isStartStop, isEndStop } = stopDetails;

                    let stopMarker = null;
                    if (!isStartStop && !isEndStop) {
                        stopMarker = this.$_map_setMarkers(location.latitude, location.longitude, 'stop', pins);
                        if (this.tripDetails.isFixedStopOrder) {
                            stopMarker.setLabel({
                                fontWeight: 'bold',
                                text: (index + 1).toString(),
                                color: '#fff'
                            });
                        } else {
                            // eslint-disable-next-line no-lonely-if
                            if (!isCompletedOrCancelledStop) {
                                labelIndex += 1;
                                stopMarker.setLabel({
                                    fontWeight: 'bold',
                                    text: labelIndex.toString(),
                                    color: '#fff'
                                });
                            }
                        }

                        index += 1;
                    } else {
                        // eslint-disable-next-line no-lonely-if
                        if (location) {
                            let isSameLocation = false;
                            if (isEndStop) {
                                const startLocation = this.tripDetails.stopList.find((x) => x.isStartStop).location;
                                isSameLocation =
                                    startLocation.latitude === location.latitude &&
                                    startLocation.longitude === location.longitude;
                            }

                            if (location && ((isEndStop && !isSameLocation) || isStartStop)) {
                                stopMarker = this.$_map_setMarkers(
                                    location.latitude,
                                    location.longitude,
                                    'optimiseStop',
                                    {
                                        optimiseStop: isStartStop
                                            ? startEndLocationMarkers.startLocation
                                            : startEndLocationMarkers.endLocation
                                    }
                                );
                            }
                        }
                    }

                    if (stopMarker != null) {
                        const { icon } = stopMarker;
                        const iconColor = setColour(stopDetails.status.toLowerCase());
                        if (iconColor) {
                            const obj = {
                                fillColor: setColour(stopDetails.status.toLowerCase()),
                                strokeColor: setColour(stopDetails.status.toLowerCase())
                            };
                            stopMarker.setIcon(this.changeIcon(icon, obj));
                        }
                        const { latitude, longitude } = location;
                        stopMarker.addListener('click', () => {
                            const obj = {
                                isStop: true,
                                address: stopDetails.address,
                                latitude,
                                longitude
                            };

                            this.content = obj;

                            // for setting the location of the tooltip (1/2 the width of tooltip + 1/2 width of marker icon)
                            this.infoWindowStyles = this.$_map_calculateMarkerCoordinates(
                                stopMarker,
                                this.map,
                                this.dimensions
                            );

                            if (!this.showInfoWindow) 
                                this.showInfoWindow = true;
                        });
                        this.markers.push(stopMarker);
                        this.$_map_callMapFunction(this.bounds, 'extend', [stopMarker.getPosition()]);
                    }
                });
            }
        },
        changeIcon(originalIcon, toChange) {
            return Object.assign({}, originalIcon, toChange);
        },
        showStreetView(lat, lng) {
            // this.isStreetView = true;
            // this.$_map_handlePanorama(this.panorama, lat, lng, true);
        },
        showTripMarkersOnMap(statusData, type, isTripStartLocation, isTripCompleteLocation) {
            const startEndLocationMarkers = {
                startLocation: '/img/map/optimise-stop/tripStart.png',
                endLocation: '/img/map/optimise-stop/tripEnd.png'
            };

            let tripMaker = null;
            if (statusData && statusData.location != null) {
                tripMaker = this.$_map_setMarkers(
                    statusData.location.latitude,
                    statusData.location.longitude,
                    'optimiseStop',
                    {
                        optimiseStop: isTripStartLocation
                            ? startEndLocationMarkers.startLocation
                            : startEndLocationMarkers.endLocation
                    }
                );
            }

            if (tripMaker != null) {
                const { icon } = tripMaker;
                const iconColor = setColour(type);

                if (iconColor) {
                    const obj = {
                        fillColor: setColour(type),
                        strokeColor: setColour(type)
                    };

                    tripMaker.setIcon(this.changeIcon(icon, obj));

                    if (statusData.location != null) {
                        const { latitude, longitude } = statusData.location;

                        tripMaker.addListener('click', () => {
                            const obj = {
                                isTripActualStartLocation: isTripStartLocation,
                                isTripActualCompleteLocation: isTripCompleteLocation,
                                changedBy: statusData.changedByUserFullName,
                                latitude,
                                longitude
                            };

                            this.content = obj;

                            this.infoWindowStyles = this.$_map_calculateMarkerCoordinates(
                                tripMaker,
                                this.map,
                                this.dimensions
                            );

                            if (!this.showInfoWindow) 
                                this.showInfoWindow = true;
                        });
                    }

                    this.markers.push(tripMaker);
                    this.$_map_callMapFunction(this.bounds, 'extend', [tripMaker.getPosition()]);
                }
            }
        },
        showDriverMarker({ latitude, longitude }, routePath, pins) {
            if (!latitude || !longitude) 
                return;
            // driver marker
            const driverMarker = this.$_map_setMarkers(latitude, longitude, 'driver', pins);
            // driverMarker.addListener('click', () => {
            //    this.isStreetView = true;
            //    this.$_map_handlePanorama(this.panorama, latitude, longitude, true);
            // });
            this.markers.push(driverMarker);

            this.$_map_callMapFunction(this.bounds, 'extend', [driverMarker.getPosition()]);

            // driver route
            if (routePath) {
                this.showRoute(routePath, pins);
            }
        },
        showRoute(routePath, pins) {
            const routeOptions = {
                strokeColor: pins.color,
                strokeOpacity: 1,
                zIndex: 3
            };

            const path = this.$_map_createPath(routePath);

            const route = this.$_map_createRouteLine(path, pins.color, routeOptions, this.map);
            this.$_map_addMapElement(route, this.map);
        },
        setColor(status) {
            return getStopStatusColour(status).fillColor;
        },
        closeInfoWindow() {
            if (this.isStreetView) 
                return;
            this.showInfoWindow = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_colors.scss';

::v-deep {
    .md-card-content {
        position: relative;
        .map {
            max-height: 480px;
        }
    }
}

.street-view {
    position: fixed !important;
    top: 0;
    left: 0;
    background-color: transparent !important;
    width: 100vw;
    height: 100vh;
}
.close-button {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 4;

    .md-button {
        .md-icon {
            color: black !important;
        }
    }
}

.info-tooltip {
    position: absolute;
    line-height: 1.2;
    max-width: 230px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    border-radius: 8px;
    color: black;
    z-index: 2;

    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 130px;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    &:after {
        content: '';
        position: absolute;
        left: 130px;
        top: 100%;
        border-top: 8px solid white;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    .button--actions {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 3px;

        .md-just-icon {
            min-width: 20px;
            width: 20px;
            height: 20px;

            .md-icon {
                font-size: 12px !important;
            }
        }
    }

    .info-detail--container {
        display: flex;
        margin: auto;
        margin-top: 8px;

        .md-icon {
            margin-left: 0px;
            margin-right: 8px;
            width: 28px;
            height: 28px;
            font-size: 28px !important;
            color: $locate2u-blue-base;
            &.arrow-icon {
                color: black;
                height: 0;
                margin-left: 8px;
            }
        }

        .info-detail {
            display: flex;
            flex-direction: column;

            &.stop-detail {
                padding-right: 24px;
            }

            span {
                font-size: 14px;
            }

            .label {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: normal;
                color: $grey-400 !important;
            }
        }

        &.stop-status--change {
            margin: auto !important;
            display: block;
            margin-top: 8px !important;
        }
    }
}
.stop-status--detail {
    margin-left: 0 !important;
}

.md-content {
    font-weight: bold;
}

::v-deep .md-card .md-card-header .card-icon i {
    color: #fff;
}

.map-router-link {
    display: inline-block;
    position: absolute;
    right: 23px;
    top: 15px;
    a {
        color: #2b93ff !important;
        font-weight: 600;
    }
}
</style>
