<template>
    <div class="area-fence map-panel" v-if="!loadShapesOnly">
        <div class="area-wrapper" :class="[{ 'drawing-area-fence': true }, { 'showing-area-list': showAreaList }]">
            <div class="area-main-wrapper">
                <div class="nav-buttons" :class="listLevel == 2 ? 'nav-buttons-three' : ''">
                    <div v-if="listLevel == 2" @click="isPanelCollapsed ? expandPanel() : collapsePanel()">
                        <md-icon>{{ isPanelCollapsed ? 'arrow_back' : 'arrow_forward' }}</md-icon>
                        <span v-if="!isPanelCollapsed">{{ isPanelCollapsed ? 'Expand' : 'Collapse' }} panel</span>
                    </div>
                    <div @click="selectDrawing('polygon')" :class="[{ 'selected-tab': selectedTab == 'draw' }]">
                        <md-icon>add</md-icon>
                        <span v-if="!isPanelCollapsed">Draw {{ title }}</span>
                    </div>
                    <div @click="searchList" :class="[{ 'selected-tab': selectedTab == 'search' }]">
                        <md-icon>search</md-icon>
                        <span v-if="!isPanelCollapsed">Select from list</span>
                    </div>
                </div>
            </div>

            <!-- section :  area wrapper lists -->
            <div v-if="selectedTab == 'draw'">
                <transition name="fade">
                    <div v-if="showAreaList" class="area-list-wrapper">
                        <md-card-content class="area-table-body" v-if="areaList.length">
                            <div class="list-title">
                                List of {{ title }}:
                                <span class="preview-btn" @click="turnOffMainListPreview">
                                    <md-icon>{{ toggleMainPreviewList ? 'visibility' : 'visibility_off' }}</md-icon>
                                    <md-tooltip>Turn On/Off preview on the map screen</md-tooltip>
                                </span>
                            </div>
                            <ul>
                                <li
                                    v-for="fence in areaList"
                                    :key="fence[idName]"
                                    :class="fence[idName] === [idName] ? 'highlight-item' : ''"
                                >
                                    <div class="name" @click="zoomToFence(fence[idName])">
                                        <span v-if="fence.suburbLocalities.length == 0">
                                            <md-icon>polyline</md-icon>
                                            <md-tooltip>This {{ title }} was drawn using the polyline</md-tooltip>
                                        </span>
                                        <span v-else>
                                            <md-icon>playlist_add</md-icon>
                                            <md-tooltip>
                                                This {{ title }} was created from the list of suburbs
                                            </md-tooltip>
                                        </span>
                                        {{ fence.name }}
                                    </div>
                                    <span class="pushed-right cta-list" v-if="!loadShapesOnly">
                                        <span @click="editArea(fence[idName])" title="Edit">
                                            <md-icon>edit</md-icon>
                                        </span>
                                        <span @click="handleMainListPreview(fence[idName])" title="Toggle preview">
                                            <md-icon>visibility</md-icon>
                                        </span>
                                        <span @click="deleteArea(fence[idName])" title="Delete">
                                            <md-icon>delete</md-icon>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </md-card-content>
                        <div class="small" v-else>
                            Draw an area on the map by clicking points to mark out the area boundary, and click the
                            start point again or double click on the map twice to complete the area boundary.
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="newArea" class="area-list-wrapper">
                        <div>
                            <form-wrapper :validator="$v" class="form-wrapper">
                                <form-group name="areaName" label="Name">
                                    <md-input v-model="areaName" aria-placeholder="Name" v-focus />
                                </form-group>
                            </form-wrapper>
                            <div class="form-button">
                                <md-button class="md-primary dialog-button md-theme-default" @click="addNewArea(false)">
                                    Save
                                </md-button>
                                <md-button
                                    class="md-default dialog-button md-theme-default"
                                    @click="cancelNewArea(false)"
                                >
                                    Cancel
                                </md-button>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div
                v-if="selectedTab == 'search'"
                :class="isPanelCollapsed ? 'full-container-size' : 'half-container-size'"
            >
                <div class="loader" v-if="isPanelLoading">
                    <fade-loader :loading="isPanelLoading" color="#333333" />
                    <span>LOADING</span>
                </div>
                <div v-else>
                    <div :class="listLevel == 1 ? 'list-container-state' : 'list-container'">
                        <div v-if="listLevel == 1">
                            <div v-if="listOfStates.length">
                                <div
                                    v-for="state in listOfStates"
                                    :key="state.stateId"
                                    class="state-item"
                                    @click="handleClickState(state.stateId)"
                                >
                                    {{ state.province }}
                                </div>
                            </div>
                            <div v-else class="no-result-container">
                                We currently don't support your country please contact support or email us at
                                <a href="mailto:support@locate2u.com">support@locate2u.com</a>
                            </div>
                        </div>
                        <div v-if="listLevel == 2 && !isPanelCollapsed">
                            <div @click="backToStates" class="back-btn">
                                <md-icon>arrow_back</md-icon>
                                Back to list of states
                            </div>
                            <div>
                                <span class="small small-note">
                                    You can either do a search and add the suburbs individually or you can paste a list
                                    of suburb **For multiple suburb support please separate them using comma.
                                </span>
                                <textarea class="c-textarea" v-model="suburbNames" />
                                <md-button class="validate-btn md-primary" @click="handleMultipleSuburbSearch">
                                    Validate
                                </md-button>
                            </div>
                            <div class="search-section">
                                <!-- <search-component
                                    :handle-search-on-type="true"
                                    @onSearch="handleFilterOrSearch"
                                    :search-icon="false"
                                    placeholder="Search Suburbs"
                                /> -->
                                <div class="preview-title">
                                    Result/s: ({{ listOfSuburbSearchResult.length }})
                                    <span
                                        class="preview-btn"
                                        v-if="listOfSuburbSearchResult.length > 0"
                                        @click="toggleSearchResultPreview"
                                    >
                                        <md-icon>
                                            {{ toggleSearchResultList ? 'visibility' : 'visibility_off' }}
                                        </md-icon>
                                        <md-tooltip>Turn On/Off preview on the map screen</md-tooltip>
                                    </span>
                                    <span
                                        class="preview-btn"
                                        v-if="listOfSuburbSearchResult.length > 0"
                                        @click="addSearchResultToPreviewList"
                                    >
                                        <md-icon>add</md-icon>
                                        <md-tooltip>Add search result to preview list</md-tooltip>
                                    </span>
                                </div>

                                <div class="search-container">
                                    <div class="loader" v-if="isLoading">
                                        <fade-loader :loading="isLoading" color="#333333" />
                                        <span>LOADING</span>
                                    </div>
                                    <div v-else>
                                        <div class="item-list" v-if="listOfSuburbSearchResult.length">
                                            <div
                                                v-for="suburb in listOfSuburbSearchResult"
                                                :key="suburb.suburbLocalityId"
                                                class="state-item"
                                            >
                                                <div>
                                                    <span @click="addToPreviewList(suburb)">
                                                        <md-icon class="blue-icon">add</md-icon>
                                                        <md-tooltip>Add to list</md-tooltip>
                                                    </span>
                                                    <span @click="handleSuburbPreview(suburb, true)">
                                                        <md-icon class="info-icon">visibility</md-icon>
                                                        <md-tooltip>Show map preview</md-tooltip>
                                                    </span>
                                                </div>
                                                <div>{{ suburb.namePostCode }}</div>
                                            </div>
                                        </div>
                                        <div
                                            class="no-result-container"
                                            v-else-if="listOfSuburbSearchResult.length == 0 && currentSearchTextValue"
                                        >
                                            <span>No results matching your search could be found.</span>
                                        </div>
                                        <div class="no-result-container" v-else>
                                            There's no data to show.
                                            <br />
                                            Start typing to show results here.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="listLevel == 2" class="list-container-search">
                        <div class="selected-preview">
                            <form-wrapper :validator="$v" class="form-wrapper">
                                <form-group name="areaName" label="Name">
                                    <md-input v-model="areaName" aria-placeholder="Name" v-focus />
                                </form-group>
                            </form-wrapper>
                            <div></div>
                            <div class="preview-title">
                                Your selection/s: ({{ previewSuburbList.length }})
                                <span
                                    class="preview-btn"
                                    v-if="previewSuburbList.length > 0"
                                    @click="turnOffAllSelectedPreview"
                                >
                                    <md-icon>{{ togglePreviewList ? 'visibility' : 'visibility_off' }}</md-icon>
                                    <md-tooltip>Turn On/Off preview on the map screen</md-tooltip>
                                </span>
                            </div>
                            <div class="preview-box" v-if="previewSuburbList.length > 0">
                                <div
                                    class="preview-item"
                                    v-for="previewSuburb in previewSuburbList"
                                    :key="previewSuburb.suburbLocalityId"
                                >
                                    <div>
                                        <span @click="removeFromList(previewSuburb)">
                                            <md-icon class="red-icon">close</md-icon>
                                            <md-tooltip>Remove item from list</md-tooltip>
                                        </span>
                                        <span @click="handleSuburbPreview(previewSuburb)">
                                            <md-icon class="info-icon">
                                                visibility
                                            </md-icon>
                                            <md-tooltip>Show preview in the map screen</md-tooltip>
                                        </span>
                                    </div>
                                    <div>{{ previewSuburb.namePostCode }}</div>
                                </div>
                            </div>
                            <div class="preview-box" v-else>
                                <div class="no-result-container">
                                    There's no data to show.
                                    <br />
                                    Start adding your suburbs now.
                                </div>
                            </div>
                        </div>
                        <div class="list-btn" v-if="listLevel == 2">
                            <md-button class="md-primary" @click="addNewSuburbArea()">
                                {{ isUpdateMultipleArea ? 'Update' : 'Create' }}
                            </md-button>
                            <md-button @click="clearSelectedSuburb()">
                                Clear
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { MapOverviewMixin, GeneralMixin } from '@/mixins';
import { required } from 'vuelidate/lib/validators';
import { MapBoxMixin } from '@/mixins/MapBoxMixin';
// import { SearchComponent } from '@/components';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { MarkerColorCode } from '@/utils/MarkerColorCode';

export default {
    components: {
        // SearchComponent,
        FadeLoader
    },
    props: {
        map: {
            type: Object,
            default: () => {}
        },
        drawingManager: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        idName: {
            type: String,
            default: ''
        },
        endPointName: {
            type: String,
            default: ''
        },
        loadShapesOnly: {
            type: Boolean,
            default: false
        },
        useMapbox: {
            type: Boolean,
            default: false
        },
        serviceAreaListOptions: {
            type: Array,
            default: () => []
        }
    },
    mixins: [MapOverviewMixin, GeneralMixin, MapBoxMixin],
    data() {
        return {
            areaList: [],
            areaDrawings: [],
            drawings: [],
            areaAction: [],
            newArea: false,
            areaName: null,
            currentShape: {},
            areaId: null,
            showAreaList: true,
            selectedTab: 'draw',
            listOfStates: [],
            listOfSuburbSearchResult: [],
            currentSearchTextValue: null,
            selectedStates: [],
            selectedSuburbs: [],
            listLevel: 1,
            isLoading: false,
            currentStateId: null,
            previewSuburbList: [],
            showCreateNewAreaModal: false,
            suburbNames: null,
            temporaryPreviewList: [],
            isPanelLoading: false,
            isPanelCollapsed: false,
            togglePreviewList: false,
            toggleMainPreviewList: false,
            isUpdateMultipleArea: false,
            colorBeforeEdit: null,
            toggleSearchResultList: false
        };
    },
    validations: {
        areaName: {
            required
        }
    },
    async mounted() {
        await this.getAreaData();
        if (!this.useMapbox) {
            this.initDrawing();
        }
        this.getDefaultStates();
    },
    beforeDestroy() {
        if (this.useMapbox) {
            this.clearAreaMapbox();
        } else {
            this.clearArea();
        }
    },
    computed: {
        // suburbs() {
        //     return this.listOfSuburbs;
        // }
    },
    watch: {
        serviceAreaListOptions() {
            this.areaList = this.serviceAreaListOptions;
            if (this.useMapbox) {
                this.clearAreaMapbox();
                this.displayAreaFenceMapbox();
            } else {
                this.clearArea();
                this.displayAreaFence();
            }
        }
    },
    methods: {
        zoomToFence(areaId) {
            const clickedFence = this.areaList.find((fence) => areaId === fence[this.idName]);
            const bounds = new google.maps.LatLngBounds();
            if (clickedFence.fullShape) {
                clickedFence.fullShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
            } else {
                clickedFence.paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
            }

            this.map.fitBounds(bounds);
        },
        initDrawing() {
            const onPolygonComplete = (polygon) => {
                // For polygon, loop through vertices and push the lat and lng as object
                this.currentShape = {
                    boundary: {
                        vertices: []
                    }
                };
                polygon.getPath().forEach((vertex) => {
                    this.currentShape.boundary.vertices.push({
                        lat: vertex.lat(),
                        lng: vertex.lng()
                    });
                });

                this.drawings.push(polygon);
                this.drawingManager.setDrawingMode(null);
                this.newArea = true;
                this.showAreaList = false;
            };

            this.$_map_handleListener(this.drawingManager, 'polygoncomplete', onPolygonComplete);
            this.$_map_handleListener(this.drawingManager, 'drawingmode_changed', () => {
                this.drawingManager.clearDrawing = false;
                if (this.drawingManager.getDrawingMode() !== null) {
                    this.clearDrawing();
                }
            });
        },
        selectDrawing(mode) {
            this.$emit('selectFromList', false);
            this.selectedTab = 'draw';
            this.drawingManager.setDrawingMode(mode);
            this.listLevel = 1;
            this.isPanelCollapsed = false;
            this.newArea = false;
            this.showAreaList = true;
            this.clearFields();
            this.clearSelectedSuburb();
            this.clearSuburbSearchResult();
        },
        clearDrawing() {
            this.drawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.drawings = [];
        },
        async addNewArea(isArea) {
            this.$_handleLoaderState(true, 'SAVING...');

            if (!isArea) {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    await this.saveArea(isArea);
                    this.$v.$reset();
                }
            } else {
                await this.saveArea(isArea);
            }
            this.$_handleLoaderState(false);
        },
        async saveArea(isArea) {
            const area = this.areaList.find((fence) => this.areaId === fence[this.idName]);
            if (this.areaId) {
                const { paintedShape } = area;
                Object.assign(this.currentShape, {
                    boundary: {
                        vertices: []
                    }
                });
                paintedShape.getPath().forEach((vertex) => {
                    this.currentShape.boundary.vertices.push({
                        lat: vertex.lat(),
                        lng: vertex.lng()
                    });
                });
                this.currentShape[this.idName] = this.areaId;
                this.currentShape.name = area.name;
                this.newArea = false;
            }
            if (!isArea) {
                this.currentShape.name = this.areaName;
                this.newArea = false;
            }

            const data = this.currentShape;
            const method = data[this.idName] ? 'PUT' : 'POST';
            const endPoint = data[this.idName] ? `${this.endPointName}/${data[this.idName]}` : `${this.endPointName}`;
            await handleRequests(endPoint, {
                method,
                data
            });

            this.$notify({
                message: this.areaId ? `${this.title} updated` : `${this.title} created`,
                type: 'success'
            });

            this.showAreaList = true;
            this.clearDrawing();
            this.clearFields();
            await this.getAreaData();
        },
        clearFields(byArea, action) {
            if (this.areaId) {
                const areaData = this.areaList.find((fence) => fence[this.idName] === this.areaId);
                if (areaData.paintedShape === undefined) {
                    return;
                }
                const { areaId, boundary, paintedShape } = areaData;
                paintedShape.setEditable(false);
                paintedShape.setDraggable(false);
                if (byArea) {
                    action.remove();
                    action.isDisplay = false;
                    const polygon = new google.maps.Polygon({
                        paths: boundary.vertices
                    });
                    paintedShape.setPath(polygon.getPath());
                    const bounds = new google.maps.LatLngBounds();
                    paintedShape.getPath().forEach((element) => {
                        bounds.extend(element);
                    });
                    const serviceAction = this.areaActions(
                        paintedShape,
                        areaData,
                        bounds.getCenter().lat(),
                        bounds.getCenter().lng()
                    );
                    const areaAction = this.areaAction.find((x) => x[this.idName] === areaId);
                    areaAction.action = serviceAction;
                    paintedShape.setMap(this.map);
                }
            }
            this.areaId = null;
            this.areaName = null;
            this.currentShape = {};
        },
        cancelNewArea(byArea, action) {
            this.clearDrawing();
            this.currentShape = {};
            this.drawings = [];
            this.newArea = false;
            this.showAreaList = true;
            this.drawingManager.setDrawingMode(null);
            this.clearDrawing();
            this.clearFields(byArea, action);
            this.$v.$reset();
        },
        editArea(areaId) {
            const fence = this.areaList.find((fence) => fence[this.idName] === areaId);
            if (fence.suburbLocalities.length) {
                this.colorBeforeEdit = fence.color;
                const { stateId } = fence.suburbLocalities[0];
                this.currentStateId = stateId;
                this.isUpdateMultipleArea = true;
                this.zoomToFence(areaId);
                this.selectedTab = 'search';
                this.handleClickState(stateId);
                this.areaId = areaId;
                this.areaName = fence.name;
                this.previewSuburbList = [...fence.suburbLocalities];
                this.drawMultipleSuburb();
            } else {
                this.colorBeforeEdit = fence.color;
                this.zoomToFence(areaId);
                this.clearFields();
                this.areaId = areaId;
                this.areaName = fence.name;
                this.newArea = true;
                this.showAreaList = false;

                fence.paintedShape.setEditable(true);
                fence.paintedShape.setDraggable(true);
            }
        },
        deleteArea(areaId) {
            this.zoomToFence(areaId);
            this.$messageBox
                .show({
                    title: `Delete ${this.title.toLowerCase()}`,
                    body: `Are you sure you want to delete this ${this.title.toLowerCase()}?`,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(areaId);
                });
        },
        async handleConfirmDelete(areaId) {
            this.$_handleLoaderState(true, 'DELETING...');
            try {
                const endPoint = `${this.endPointName}/${areaId}`;
                const payload = {
                    method: 'delete'
                };
                await handleRequests(endPoint, payload);
                await this.getAreaData();

                this.$notify({
                    message: `${this.title} removed`,
                    type: 'success'
                });
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = `Could not delete ${this.title.toLowerCase()}.`;
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        areaActions(paintedShape, shape, lat, lng, zoneName = null) {
            let shapeName = shape.name;
            let isMultipleSuburb = false;
            // if zone name is not null it means this area is using a suburb boundary.
            if (zoneName) {
                shapeName = zoneName;
                isMultipleSuburb = true;
            }
            const action = this.$_map_createAreaActions({
                latlng: this.$_map_createLatLngPoint(lat, lng),
                map: this.map,
                html:
                    this.loadShapesOnly || isMultipleSuburb
                        ? `<div style="background-color: #fff;padding: 5px;border-radius: 8px; text-align: center;">
                            <div style="color: #000; font-size: 14px; font-weight: 500;">${shapeName}
                            </div>
                      </div>`
                        : `<div style="background-color: #fff;padding: 5px;border-radius: 8px; text-align: center;">
                            <div style="color: #000; font-size: 14px; font-weight: 500;">${shapeName}
                            </div>
                            <div class="area-id" style="display: none; margin-top: 8px;">
                                <span class="edit-area" title="Edit">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">edit</i>
                                </span>
                                <span class="delete-area" title="Delete">
                                    <i style="width: 14px; height: 14px; font-size: 14px !important;" class="md-icon md-icon-font md-theme-default">delete</i>
                                </span>
                            </div>
                      </div>`
            });

            if (!this.loadShapesOnly) {
                if (isMultipleSuburb) {
                    action.isDisplay = true;
                    return action;
                }

                const onEdit = () => {
                    this.editArea(shape[this.idName]);
                };
                const onDelete = () => {
                    this.deleteArea(shape[this.idName]);
                };

                google.maps.event.addListener(paintedShape, 'mouseover', (evt) => {
                    if (action.div) {
                        const dom = action.div.getElementsByClassName('area-id')[0].style;
                        dom.display = 'block';

                        const editDom = action.div.getElementsByClassName('edit-area')[0];
                        if (editDom.getAttribute('editlistener') !== 'true') {
                            editDom.setAttribute('editlistener', 'true');
                            editDom.addEventListener('click', () => {
                                onEdit();
                            });
                        }

                        const deleteDom = action.div.getElementsByClassName('delete-area')[0];
                        if (deleteDom.getAttribute('deletelistener') !== 'true') {
                            deleteDom.setAttribute('deletelistener', 'true');
                            deleteDom.addEventListener('click', () => {
                                onDelete();
                            });
                        }
                    }
                });
                google.maps.event.addListener(paintedShape, 'mouseout', (evt) => {
                    if (action.div) {
                        action.div.getElementsByClassName('area-id')[0].style.display = 'none';
                    }
                });
                const onRadiusChanged = () => {
                    const bounds = new google.maps.LatLngBounds();
                    shape.paintedShape.getPath().forEach((element) => {
                        bounds.extend(element);
                    });
                    action.setPosition(bounds.getCenter());
                };

                const polygonPath = paintedShape.getPath();
                this.$_map_handleListener(polygonPath, 'insert_at', onRadiusChanged);
                this.$_map_handleListener(polygonPath, 'set_at', onRadiusChanged);

                action.isDisplay = true;
            }

            return action;
        },
        paintFence(shape, areaId, color) {
            shape.color = color;
            const paintedShape = new google.maps.Polygon({
                paths: shape.boundary.vertices,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                draggable: false,
                editable: false,
                fillOpacity: 0.35
            });
            const bounds = new google.maps.LatLngBounds();
            paintedShape.getPath().forEach((element) => {
                bounds.extend(element);
            });
            const action = this.areaActions(paintedShape, shape, bounds.getCenter().lat(), bounds.getCenter().lng());
            paintedShape.setMap(this.map);
            this.areaDrawings.push(paintedShape);

            const index = this.areaList.findIndex((x) => x[this.idName] === areaId);
            if (index === -1) {
                shape[this.idName] = areaId;
                this.areaList.push(Object.assign({ paintedShape }, { ...shape }, { areaId }));
            } else {
                this.areaList[index].paintedShape = paintedShape;
                Object.assign(this.areaList[index], shape);
            }

            this.areaAction.push({ areaId, action });
        },
        paintMultipleFence(fence, areaId, zoneName, color) {
            const fullBounds = [];
            const { suburbLocalities } = fence;
            fence.color = color;
            suburbLocalities.forEach((suburb) => {
                suburb.boundary.vertices.forEach((vertex) => {
                    fullBounds.push({
                        lat: vertex.lat,
                        lng: vertex.lng
                    });
                });
                const paintedShape = new google.maps.Polygon({
                    paths: suburb.boundary.vertices,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    draggable: false,
                    editable: false,
                    fillOpacity: 0.35
                });
                const bounds = new google.maps.LatLngBounds();
                paintedShape.getPath().forEach((element) => {
                    bounds.extend(element);
                });
                const action = this.areaActions(
                    paintedShape,
                    suburb,
                    bounds.getCenter().lat(),
                    bounds.getCenter().lng(),
                    zoneName
                );
                paintedShape.setMap(this.map);
                this.areaDrawings.push(paintedShape);
                suburb.paintedShape = paintedShape;

                this.areaAction.push({ areaId, action, suburbLocalityId: suburb.suburbLocalityId });
            });
            // fence.name = zoneName;

            // this helps with the zooming of per zone area if using suburb localities
            const fullShape = new google.maps.Polygon({
                paths: fullBounds,
                visible: false
            });

            fence.fullShape = fullShape;
        },
        displayAreaFence(value) {
            this.areaAction.forEach((area) => {
                area.action.remove();
                area.action.isDisplay = false;
            });
            this.areaList.forEach((fence, i) => {
                if (!fence.paintedShape) {
                    if (fence.suburbLocalities.length) {
                        const { color } = MarkerColorCode[i % MarkerColorCode.length];
                        this.paintMultipleFence(fence, fence[this.idName], fence.name, color);
                    } else {
                        const { color } = MarkerColorCode[i % MarkerColorCode.length];
                        this.paintFence(fence, fence[this.idName], color);
                    }
                } else {
                    fence.paintedShape.setVisible(value);
                }
                const areaAction = this.areaAction.find((x) => x[this.idName] === fence[this.idName]);
                if (value) {
                    areaAction.action.isDisplay = true;
                    areaAction.action.draw();
                }
            });
        },
        displayAreaFenceMapbox(value) {
            this.markerServiceAreajson = {
                type: 'FeatureCollection',
                features: []
            };

            this.areaList.forEach((fence) => {
                if (fence.suburbLocalities.length > 0) {
                    fence.suburbLocalities.forEach((suburb) => {
                        const feat = {
                            type: 'Feature',
                            geometry: {
                                type: 'Polygon',
                                coordinates: [suburb.boundary.vertices.map((v) => [v.lng, v.lat])]
                            }
                        };
                        this.markerServiceAreajson.features.push(feat);
                    });
                } else {
                    const feat = {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [fence.boundary.vertices.map((v) => [v.lng, v.lat])]
                        }
                    };
                    this.markerServiceAreajson.features.push(feat);
                }
            });

            this.$_mapBox_setData(this.map, 'service-area-polygons', this.markerServiceAreajson);
        },
        clearArea() {
            this.clearDrawing();
            this.areaDrawings.forEach((draw) => {
                draw.setMap(null);
            });
            this.areaDrawings = [];

            this.areaAction.forEach((area) => {
                area.action.remove();
                area.action.isDisplay = false;
            });
            this.areaAction = [];
        },
        clearAreaMapbox() {
            this.markerServiceAreajson = {
                type: 'FeatureCollection',
                features: []
            };

            this.$_mapBox_setData(this.map, 'service-area-polygons', this.markerServiceAreajson);
        },
        async getAreaData() {
            this.clearArea();
            if (this.serviceAreaListOptions && this.serviceAreaListOptions.length > 0) {
                this.areaList = this.serviceAreaListOptions;
            } else {
                const endPoint = `${this.endPointName}`;
                const response = await handleRequests(endPoint);
                this.areaList = response.data.sort((x, y) => (x.name > y.name ? 1 : -1));
            }

            if (this.showAreaList) {
                if (this.useMapbox) {
                    this.displayAreaFenceMapbox();
                } else {
                    this.displayAreaFence();
                }
            }
        },
        async searchList() {
            this.selectedTab = 'search';
            this.suburbNames = null;
            await this.getDefaultStates();
        },
        async getDefaultStates() {
            if (this.listOfStates != null && this.listOfStates.length > 0) {
                return;
            }
            const qry = new URLSearchParams();
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }
            const api = `/api/teams/states?${qry}`;
            const { data } = await handleRequests(api);
            this.listOfStates = data;
        },
        // eslint-disable-next-line func-names
        handleFilterOrSearch: _.debounce(function(obj) {
            this.currentSearchTextValue = obj.searchText;
            switch (this.listLevel) {
                case 1:
                    this.getDefaultStates();
                    break;
                case 2:
                    this.handleClickState(this.currentStateId);
                    this.handleSearchSuburb();
                    break;
                default:
                    break;
            }
        }, 800),
        async handleClickState(stateId) {
            this.currentStateId = stateId;
            this.listLevel = 2;
            this.$emit('selectFromList', true);
            this.clearSuburbSearchResult();
        },
        async handleSearchSuburb() {
            if (!this.currentSearchTextValue) {
                this.listOfSuburbSearchResult = [];
                return;
            }
            this.isLoading = true;
            const qry = new URLSearchParams();
            if (this.currentSearchTextValue) {
                qry.append('searchText', this.currentSearchTextValue);
            }
            const api = `/api/teams/states/${this.currentStateId}/suburbs?${qry}`;
            const { data } = await handleRequests(api);
            this.listOfSuburbSearchResult = data;
            this.isLoading = false;
        },
        backToStates() {
            this.listLevel = 1;
            this.$emit('selectFromList', false);
            this.clearSelectedSuburb();
            this.clearSuburbSearchResult();

            if (this.isUpdateMultipleArea) {
                const area = this.areaList.find((fence) => this.areaId === fence[this.idName]);

                area.suburbLocalities.forEach((x) => {
                    if (x.paintedShape) {
                        x.paintedShape.setOptions({
                            strokeColor: area.color,
                            fillColor: area.color
                        });
                        x.paintedShape.setVisible(true);
                    } else {
                        this.drawPreview(x, false, area.color);
                        x.paintedShape.setVisible(true);
                        const areaActions = this.areaAction.filter((x) => x.areaId === this.areaId);
                        areaActions.forEach((element) => {
                            element.action.isDisplay = true;
                            element.action.draw();
                        });
                    }
                });
                this.isUpdateMultipleArea = false;
            }
        },
        handleSuburbPreview(suburbData, forPreviewOnly = false) {
            let isPreviewOnly = forPreviewOnly;

            const suburb = this.previewSuburbList.find((x) => x.suburbLocalityId === suburbData.suburbLocalityId);

            if (suburb) {
                isPreviewOnly = false;
            }
            if (suburbData.paintedShape) {
                suburbData.paintedShape.setVisible(!suburbData.paintedShape.getVisible());
            } else {
                this.drawPreview(suburbData, isPreviewOnly);
            }
        },
        addToPreviewList(suburbData) {
            const suburbIndex = this.previewSuburbList.findIndex(
                (x) => x.suburbLocalityId === suburbData.suburbLocalityId
            );

            if (suburbIndex > -1) {
                this.$notify({
                    message: `${suburbData.name} has already been added to the list.`,
                    type: 'success'
                });

                return;
            }

            this.previewSuburbList.push(suburbData);

            if (suburbData.paintedShape) {
                suburbData.paintedShape.setOptions({
                    strokeColor: '#FF0000',
                    fillColor: '#FF0000'
                });
            } else {
                this.drawPreview(suburbData);
            }
        },
        removePreview(suburbData) {
            if (suburbData.paintedShape) {
                suburbData.paintedShape.setMap(null);
                suburbData.paintedShape = null;
            }

            const suburbAction = this.areaAction.find((x) => x.suburbLocalityId === suburbData.suburbLocalityId);
            if (suburbAction) {
                suburbAction.action.isDisplay = false;
                suburbAction.action.remove();
            }
        },
        removeFromList(suburbData) {
            const suburbIndex = this.previewSuburbList.findIndex(
                (x) => x.suburbLocalityId === suburbData.suburbLocalityId
            );
            this.removePreview(suburbData);
            this.previewSuburbList.splice(suburbIndex, 1);
        },
        turnOffAllSelectedPreview() {
            this.previewSuburbList.forEach((suburbData) => {
                suburbData.paintedShape.setVisible(this.togglePreviewList);
            });
            this.togglePreviewList = !this.togglePreviewList;
        },
        toggleSearchResultPreview() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                if (suburbData.paintedShape) {
                    suburbData.paintedShape.setVisible(!this.toggleSearchResultList);
                } else {
                    this.drawPreview(suburbData, true);
                }
            });
            this.toggleSearchResultList = !this.toggleSearchResultList;
        },
        drawPreview(suburbData, forPreviewOnly = false, color = null) {
            let colorCode = forPreviewOnly ? '#7F00FF' : '#FF0000';

            if (color != null) {
                colorCode = color;
            }

            const paintedShape = new google.maps.Polygon({
                paths: suburbData.boundary.vertices,
                strokeColor: colorCode,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: colorCode,
                draggable: false,
                editable: false,
                fillOpacity: 0.35
            });
            const bounds = new google.maps.LatLngBounds();
            paintedShape.getPath().forEach((element) => {
                bounds.extend(element);
            });

            paintedShape.setMap(this.map);

            suburbData.paintedShape = paintedShape;
        },
        async addNewSuburbArea() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$notify({
                    message: 'Service Area name is required',
                    type: 'danger'
                });
                return;
            }

            if (this.previewSuburbList.length === 0) {
                this.$notify({
                    message: 'Please add suburbs',
                    type: 'danger'
                });
                return;
            }

            this.$_handleLoaderState(true, 'SAVING...');

            this.isPanelLoading = true;
            const suburbListIds = this.previewSuburbList.map((x) => x.suburbLocalityId);

            const data = {
                name: this.areaName,
                suburbIds: suburbListIds
            };
            const method = this.isUpdateMultipleArea ? 'PUT' : 'POST';
            const endPoint = this.isUpdateMultipleArea
                ? `${this.endPointName}/${this.areaId}/multiple`
                : `${this.endPointName}/multiple`;
            await handleRequests(endPoint, {
                method,
                data
            });

            this.$notify({
                message: this.areaId ? `${this.title} updated` : `${this.title} created`,
                type: 'success'
            });

            this.$v.$reset();

            this.$_handleLoaderState(false);
            this.isPanelLoading = false;
            this.isUpdateMultipleArea = false;
            this.showAreaList = true;
            this.clearSelectedSuburb();
            this.clearDrawing();
            this.areaId = null;
            this.areaName = null;
            this.$emit('selectFromList', false);
            this.selectedTab = 'draw';
            this.listLevel = 1;
            this.isPanelCollapsed = false;
            await this.getAreaData();
            this.initDrawing();
        },
        async createNewArea() {
            const shapeList = {
                boundary: {
                    vertices: []
                }
            };

            this.previewSuburbList.forEach((suburb) => {
                suburb.boundary.vertices.forEach((vertex) => {
                    shapeList.boundary.vertices.push({
                        lat: vertex.lat,
                        lng: vertex.lng
                    });
                });
            });

            shapeList.name = this.areaName;

            const data = shapeList;
            const method = data[this.idName] ? 'PUT' : 'POST';
            const endPoint = data[this.idName] ? `${this.endPointName}/${data[this.idName]}` : `${this.endPointName}`;
            await handleRequests(endPoint, {
                method,
                data
            });

            this.$notify({
                message: this.areaId ? `${this.title} updated` : `${this.title} created`,
                type: 'success'
            });

            this.showAreaList = true;
            this.showCreateNewAreaModal = false;
            this.clearDrawing();
            this.clearFields();
            await this.getAreaData();
        },
        async handleMultipleSuburbSearch() {
            if (!this.suburbNames) {
                this.$notify({
                    message: "There's no data to validate.",
                    type: 'warning'
                });
                return;
            }

            this.isLoading = true;

            const listOfSuburbNames = this.suburbNames.trim().split(',');

            const data = {
                suburbNames: listOfSuburbNames
            };

            const api = `/api/teams/states/${this.currentStateId}/suburbs/validate`;
            const result = await handleRequests(api, { method: 'POST', data });
            this.clearSuburbSearchResult();
            this.listOfSuburbSearchResult = result.data;
            this.isLoading = false;
        },
        drawMultipleSuburb() {
            if (this.previewSuburbList.length === 0) 
                return;

            this.previewSuburbList.forEach((element) => {
                this.removePreview(element);
                this.drawPreview(element);
            });
        },
        collapsePanel() {
            this.isPanelCollapsed = true;
            this.$emit('selectFromList', false);
        },
        expandPanel() {
            this.isPanelCollapsed = false;
            this.$emit('selectFromList', true);
        },
        clearSelectedSuburb() {
            this.previewSuburbList.forEach((suburbData) => {
                this.removePreview(suburbData);
            });

            this.previewSuburbList = [];
        },
        turnOffMainListPreview() {
            this.areaList.forEach((suburbData) => {
                if (suburbData.suburbLocalities.length) {
                    suburbData.suburbLocalities.forEach((element) => {
                        this.handleActionToggleVisibility(
                            element.paintedShape,
                            suburbData[this.idName],
                            this.toggleMainPreviewList
                        );
                    });
                } else {
                    this.handleActionToggleVisibility(
                        suburbData.paintedShape,
                        suburbData[this.idName],
                        this.toggleMainPreviewList
                    );
                }
            });

            this.toggleMainPreviewList = !this.toggleMainPreviewList;
        },
        handleMainListPreview(areaId) {
            const fence = this.areaList.find((fence) => fence[this.idName] === areaId);
            if (fence.suburbLocalities.length) {
                fence.suburbLocalities.forEach((element) => {
                    this.handleActionToggleVisibility(element.paintedShape, areaId);
                });
            } else {
                this.handleActionToggleVisibility(fence.paintedShape, areaId);
            }
        },
        clearSuburbSearchResult() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                const suburbIndex = this.previewSuburbList.findIndex(
                    (x) => x.suburbLocalityId === suburbData.suburbLocalityId
                );
                if (suburbIndex < 0) {
                    this.removePreview(suburbData);
                }
            });
            this.listOfSuburbSearchResult = [];
        },
        handleActionToggleVisibility(paintedShape, areaId, visibilityValue = null) {
            let isVisible = !paintedShape.getVisible();

            if (visibilityValue != null) {
                isVisible = visibilityValue;
            }

            paintedShape.setVisible(isVisible);

            const areaActions = this.areaAction.filter((x) => x.areaId === areaId);
            areaActions.forEach((element) => {
                if (!isVisible) {
                    element.action.isDisplay = false;
                    element.action.remove();
                } else {
                    element.action.isDisplay = true;
                    element.action.draw();
                }
            });
        },
        addSearchResultToPreviewList() {
            this.listOfSuburbSearchResult.forEach((suburbData) => {
                this.addToPreviewList(suburbData);
            });
        }
    }
};
// collapese the sidebar and show
</script>

<style lang="scss" scoped>
span.area-icons {
    transform: scale(0.7);
}

.collapsed {
    transition: opacity 1s;
}

.area-panel {
    display: flex;

    .title {
        flex-grow: 1;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        margin-top: 0px;
    }

    .area-icons {
        padding-left: 2px;
        margin-top: -2px;
    }

    .md-icon {
        transition: 0.3s linear;
    }
}

.area-wrapper {
    top: 60px;
    z-index: 2;
    background: #fff;
    padding: 12px 8px 3px 8px;
    left: 15px;
    width: 100%;

    &.showing-area-list {
        width: 100%;
    }

    &.drawing-area-fence {
        width: 100%;
    }

    .area-main-wrapper {
        padding: 5px;
        border-bottom: 1px solid #e4e4e4;
    }

    a,
    a:hover {
        color: #222;
        font-size: 0.9rem;
        cursor: pointer;
        display: flex;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0px;
        font-size: 14px;

        li {
            cursor: pointer;
            font-size: 14px;
            display: flex;
            padding: 1px 0px;

            &:hover {
                font-weight: bold;

                .cta-list {
                    display: unset;
                }
            }

            a.name {
                color: #2b93ff;
                max-width: 80%;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .md-icon {
                width: 14px;
                height: 14px;
                font-size: 14px !important;
                margin-right: 0px;
                margin-left: 0px;

                &.user-pin {
                    transform: rotate(90deg);
                }
            }
        }

        .pushed-right {
            margin-left: auto;
        }

        .highlight-item {
            font-weight: bold;

            ::v-deep .cta-list {
                display: unset;
            }
        }
    }

    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .add-new {
        cursor: pointer;
        background: #2b93ff;
        padding: 5px;
        border-radius: 3px;
        color: #fff;
        font-weight: bold;
    }

    .md-checkbox {
        margin: 0;
        vertical-align: sub;
    }

    .md-checkbox-container {
        width: 15px;
        height: 15px;
        min-width: 15px;
    }
}

.drawing-tool {
    margin-left: 0px;
    margin-top: -10px;

    .small {
        line-height: 1.2;
        margin-bottom: 5px;
    }

    .area-print {
        display: flex;
        justify-content: center;
    }

    .area-print-child {
        float: left;
        line-height: 0;

        &.active button {
            border: 1px solid #2b93ff;
            width: 32px;
            height: 32px;
            min-width: 32px;
        }
    }

    button {
        background: none padding-box transparent;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 4px;
        text-transform: none;
        appearance: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        direction: ltr;
        overflow: hidden;
        text-align: left;
        font-family: Roboto, Arial, sans-serif;
        font-size: 11px;
        border: 1px solid #e8e8e8;

        span {
            display: inline-block;
        }

        div {
            width: 16px;
            height: 14px;
            overflow: hidden;
            position: relative;
        }

        img {
            position: absolute;
            left: 0px;
            user-select: none;
            border: 0px;
            padding: 0px;
            margin: 0px;
            max-width: none;
            width: 16px;
            height: 192px;
        }
    }

    .button-pressed {
        background: #e5e5e5;
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
        -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    }

    .drawing-button {
        color: rgb(0, 0, 0);
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        font-weight: 500;

        img {
            top: -144px;
        }
    }

    .polygon-button {
        color: rgb(86, 86, 86);
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;

        img {
            top: -65px;
        }
    }
}

.md-field.md-has-textarea:not(.md-autogrow) .md-textarea {
    padding: 15px 0;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
    margin-top: 0;
}

.showing-area-list .toggle-list .md-icon {
    color: #2b93ff;
}

.toggle-add {
    transition: 0.3s linear;
}

.fade-enter-active {
    transition: all 0.3s ease;
}

.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    display: none;
    overflow: hidden;
}

.area-list-wrapper {
    background: #fff;
    padding: 3px 5px;
    font-size: 0.8rem;
    max-height: 300px;
    overflow: auto;
}

.form-wrapper {
    margin-top: 25px;
}

.form-button {
    text-align: center;
}

::v-deep .md-card-content {
    padding: 0;
}

::v-deep .md-table-head {
    padding: 0;
}

::v-deep .md-table-head-label {
    font-size: 0.83rem;
    font-weight: 800;
}

map-overlay {
    ::v-deep .area-fence-name {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.nav-buttons {
    > div {
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align: top;
        cursor: pointer;
        padding: 5px;

        span {
            display: block;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    > div:hover {
        background-color: #eee;
        color: #222;
        i {
            color: #222;
        }
    }

    .selected-tab {
        background-color: #2b93ff;
        color: #fff;
        i {
            color: #fff;
        }
    }
}

.nav-buttons-three {
    > div {
        width: 35%;
    }
    > div:first-child {
        width: 30%;
    }
}

.state-item:hover {
    background-color: #eee;
    cursor: pointer;
}

.item-list {
    overflow: auto;
    max-height: 33vh;

    .state-item {
        margin-bottom: 5px;
        display: table;
        font-size: 14px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 3px;
        width: 100%;
        > div {
            display: table-cell;
            vertical-align: middle;

            .md-icon:hover {
                color: #2b93ff;
            }
        }

        > div:first-child {
            width: 55px;
        }
    }
}

.list-container {
    width: 50%;
    position: relative;
    height: 88vh;
    display: inline-block;
    vertical-align: top;
    padding-right: 8px;

    .back-btn {
        margin: 10px 0;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        i {
            font-size: 18px !important;
        }
    }

    .back-btn:hover {
        color: #2b93ff;
        i {
            color: #2b93ff;
        }
    }

    .loader {
        position: absolute;
        top: 40%;
        left: 50%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }

    ::v-deep .search-button--container {
        margin-right: 0;
    }

    .list-btn {
        display: inline-block;
    }
}

.c-textarea {
    width: 100%;
    min-height: 150px;
    resize: none;
    overflow: auto;
}

.small-note {
    color: #aaa;
    line-height: 22px;
    font-size: 0.8rem;
}

.map-panel {
    position: relative;
}

.selected-preview-outer {
    position: absolute;
    bottom: 40px;
    left: -201px;
    background-color: #fff;
    min-width: 200px;
    padding: 10px;
    height: 250px;
}

.list-container-search {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 8px;
    border-left: 1px solid #eee;
    min-height: 88vh;
    position: relative;

    .selected-preview {
        margin-bottom: 10px;
        > .preview-item:hover {
            cursor: pointer;
            background-color: #eee;
        }
        > .preview-box {
            border: 1px solid #eee;
            min-height: 67vh;
            overflow: auto;
        }
        .preview-item {
            display: table;
            font-size: 14px;
            border-bottom: 1px solid #ddd;
            width: 100%;
            > div {
                display: table-cell;
                vertical-align: middle;
            }
            > div:first-child {
                width: 55px;
            }
        }
        > .preview-title {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
        }

        .form-wrapper {
            margin-top: 0;
        }
    }

    .list-btn {
        width: 100%;

        button {
            margin: 0;
            display: inline-block;
            width: calc((100% - 10px) / 2);
        }

        button:first-child {
            margin-right: 10px;
        }
    }
}

.no-result-container {
    margin-top: 20%;
    font-size: 14px;
    color: #aaa;
    text-align: center;
    padding: 0 20px;
    a {
        display: initial;
        color: #2b93ff;
    }
    a:hover {
        display: initial;
    }
}

.search-container {
    position: relative;
    height: 25vh;
}

.validate-btn {
    margin: 0;
    width: 100%;
}

.search-section {
    margin-top: 10px;
    > .preview-title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
    }
}

.preview-btn {
    margin-left: 10px;
}

.preview-btn:hover {
    color: #2b93ff;
    cursor: pointer;
}

.full-container-size {
    position: relative;
    min-height: 50vh;
    .list-container-search {
        width: 100%;
    }

    .list-container {
        display: none;
    }
    .loader {
        position: absolute;
        top: 40%;
        left: 50%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }
}

.half-container-size {
    position: relative;
    min-height: 50vh;
    .list-container,
    .list-container-search {
        width: 50%;
    }

    .loader {
        position: absolute;
        top: 40%;
        left: 50%;

        span {
            position: absolute;
            margin-top: 50px;
            width: 110px;
            left: calc((100% - 100px) / 2);
            text-align: center;
            font-weight: 600;
        }
    }
}

.list-container-state {
    width: 100%;
}

.blue-icon {
    color: #2b93ff !important;
}

.red-icon {
    color: #f44336 !important;
}

.info-icon {
    color: #00bcd4 !important;
}

.list-title {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
}

@media screen and (min-width: 1920px) {
    .item-list {
        max-height: 50vh;
    }
}
</style>
