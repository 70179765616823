var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "information-box" }, [
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Team Member Invoice Prefix",
                name: "invoiceAffixSettings.teamMemberInvoiceNumberPrefix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.invoiceAffixSettings.teamMemberInvoiceNumberPrefix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.invoiceAffixSettings,
                      "teamMemberInvoiceNumberPrefix",
                      $$v
                    )
                  },
                  expression:
                    "invoiceAffixSettings.teamMemberInvoiceNumberPrefix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Team Member Invoice Suffix",
                name: "invoiceAffixSettings.teamMemberInvoiceNumberSuffix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.invoiceAffixSettings.teamMemberInvoiceNumberSuffix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.invoiceAffixSettings,
                      "teamMemberInvoiceNumberSuffix",
                      $$v
                    )
                  },
                  expression:
                    "invoiceAffixSettings.teamMemberInvoiceNumberSuffix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              staticClass: "sample-invoice",
              attrs: { label: "Team Member Invoice Example" },
            },
            [
              _c("md-input", {
                attrs: {
                  disabled: "",
                  value: _vm.exampleTeamMemberInvoiceNumber,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Customer Invoice Prefix",
                name: "invoiceAffixSettings.customerInvoiceNumberPrefix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.invoiceAffixSettings.customerInvoiceNumberPrefix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.invoiceAffixSettings,
                      "customerInvoiceNumberPrefix",
                      $$v
                    )
                  },
                  expression:
                    "invoiceAffixSettings.customerInvoiceNumberPrefix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              attrs: {
                label: "Customer Invoice Suffix",
                name: "invoiceAffixSettings.customerInvoiceNumberSuffix",
              },
            },
            [
              _c("md-input", {
                model: {
                  value: _vm.invoiceAffixSettings.customerInvoiceNumberSuffix,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.invoiceAffixSettings,
                      "customerInvoiceNumberSuffix",
                      $$v
                    )
                  },
                  expression:
                    "invoiceAffixSettings.customerInvoiceNumberSuffix",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-30 md-small-size-100" },
        [
          _c(
            "form-group",
            {
              staticClass: "sample-invoice",
              attrs: { label: "Customer Invoice Example" },
            },
            [
              _c("md-input", {
                attrs: {
                  disabled: "",
                  value: _vm.exampleCustomerInvoiceNumber,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }