<template>
    <md-button
        title="Delete shipment"
        class="md-danger md-just-icon md-round"
        @click.stop="handleDeleteShipment(shipmentId)"
    >
        <md-icon>delete</md-icon>
    </md-button>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests } from '@/helpers';

export default {
    name: 'DeleteShipmentButton',
    props: {
        shipmentId: {
            type: [Number, String],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        }
    },
    mixins: [GeneralMixin],
    methods: {
        handleDeleteShipment(shipmentId) {
            const message = 'Are you sure you want to delete this shipment? This cannot be undone.';
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Shipment',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.handleConfirmDelete(shipmentId);
                });
        },
        async handleConfirmDelete(shipmentId) {
            this.$_handleLoaderState(true);
            const payload = {
                method: 'delete'
            };
            const api = `/api/shipments/${shipmentId}`;
            try {
                this.$_handleLoaderState(false);
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Shipment was deleted!',
                    type: 'success'
                });
                this.$emit('shipmentDeleted', true);
            } catch (e) {
                this.$_handleLoaderState(false);
                let message = 'Cannot delete shipment.';
                if (e) 
                    [{ message }] = e.data;
                this.$notify({
                    message,
                    type: 'danger'
                });
                this.$emit('shipmentDeleted', false);
            }
        }
    }
};
</script>
