var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFetchingUserDetails
    ? _c(
        "div",
        { staticClass: "loader-content-container" },
        [_c("LoginRedirect")],
        1
      )
    : _c(
        "div",
        {
          staticClass: "wrapper",
          class: [{ "nav-open": _vm.$sidebar.showSidebar }],
        },
        [
          _c("notifications"),
          _c(
            "side-bar",
            {
              attrs: {
                "active-color": _vm.sidebarBackground,
                "background-image": _vm.sidebarBackgroundImage,
                "background-color": _vm.sidebarBackgroundColor,
                logo:
                  _vm.isReadOnlyUser && _vm.getTeamGeneralSettings.companyLogo
                    ? _vm.getTeamGeneralSettings.companyLogo
                    : null,
                "is-customer-type": _vm.isReadOnlyUser,
              },
            },
            [
              _c("user-menu"),
              _c(
                "template",
                { slot: "links" },
                [
                  _vm.$root.isDesktop && !_vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.dashboard"),
                            icon: "dashboard",
                            path: "/dashboard",
                          },
                        },
                      })
                    : _vm._e(),
                  _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: _vm.$t("menus.map"),
                        icon: "map",
                        path: "/map",
                      },
                    },
                  }),
                  !_vm.isIndividualUser &&
                  _vm.hasCustomerListAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.customers"),
                            icon: "contact_page",
                            path: "/customers/list",
                          },
                        },
                      })
                    : _vm._e(),
                  _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: _vm.$t("menus.trips"),
                        icon: "commute",
                        path: "/trips/list",
                      },
                    },
                  }),
                  _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: _vm.$t("menus.stops"),
                        icon: "place",
                        path: "/stops/list",
                      },
                    },
                  }),
                  _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: _vm.$t("menus.shipments"),
                        icon: "directions_car",
                        path: "/shipments/list",
                      },
                    },
                  }),
                  _vm.hasOptimiseAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.useNewOptimiseStops
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.optimizeStops"),
                            icon: "route",
                            path: "/optimise",
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.hasOptimiseAccess &&
                  _vm.$root.isDesktop &&
                  _vm.useNewOptimiseStops
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.optimizeStops"),
                            icon: "route",
                            path: "/optimise-stops",
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.isIndividualUser &&
                  _vm.hasInventoryListAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Items",
                            icon: "inventory",
                            path: "/inventory/list",
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.$root.isDesktop && !_vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.calendarTimeline"),
                            icon: "calendar_today",
                            path: "/stops/calendar",
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.isIndividualUser &&
                  _vm.hasAssetAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.isReadOnlyUser
                    ? _c("asset-management-menu")
                    : _vm._e(),
                  _c("report-menu"),
                  !_vm.isIndividualUser &&
                  _vm.hasInvoicesAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.isReadOnlyUser
                    ? _c("invoices-menu")
                    : _vm._e(),
                  _vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: "Invoices",
                            icon: "paid",
                            path: "/customer-invoices/list",
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.isIndividualUser &&
                  _vm.hasTeamAccess &&
                  _vm.$root.isDesktop &&
                  !_vm.isReadOnlyUser
                    ? _c("team-menu")
                    : _vm.isIndividualUser &&
                      _vm.$root.isDesktop &&
                      !_vm.isReadOnlyUser
                    ? _c("sidebar-item", {
                        attrs: {
                          link: {
                            name: _vm.$t("menus.team.team"),
                            icon: "group",
                            path: "/team/team-members",
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.isReadOnlyUser ? _c("support-menu") : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "main-panel custom-lp",
              class: [
                _vm.$root.isMobileOnly ? "main-panel-whitebg" : "",
                _vm.isSupportUser ? "with-header" : "no-header",
                _vm.isReadOnlyUser || !_vm.$route.meta.hideFooter
                  ? "show-footer"
                  : "no-footer",
              ],
            },
            [
              _c("support-user-nav-bar"),
              !_vm.isFetchingUserDetails && _vm.showTrialBanner
                ? _c(
                    "div",
                    { staticClass: "trial-alert-container" },
                    [_c("TrialBanner")],
                    1
                  )
                : _vm._e(),
              _c("top-navbar", {
                staticClass: "mobile-navigation",
                class: _vm.$root.isMobileOnly ? "mobileonly-navigation" : "",
              }),
              _c(
                "div",
                {
                  class: {
                    content: !_vm.$route.meta.hideContent,
                    "map-overview": _vm.$route.meta.isMap,
                    "optimise-stops-overview": _vm.$route.meta.isOptimise,
                  },
                  on: { click: _vm.toggleSidebar },
                },
                [
                  _c(
                    "zoom-center-transition",
                    { attrs: { duration: 200, mode: "out-in" } },
                    [
                      _vm.getTeamGeneralSettings.isTwoFAEnabled &&
                      !_vm.user.hasTwoFAEnabled
                        ? _c("div", { staticClass: "layout-parent" }, [
                            _c("div", { staticClass: "two-fa-container" }, [
                              _c("h5", { staticClass: "two-fa-title" }, [
                                _vm._v("Two Factor Authentication Setup"),
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                            Your team has enabled two factor authentication. In order to use the app properly you\n                            must logout and sign in again to setup two factor authentication on your account.\n                        "
                                ),
                              ]),
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "logout-link",
                                    attrs: { href: "#" },
                                    on: { click: _vm.logout },
                                  },
                                  [_vm._v("Click here to logout")]
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "div",
                            { staticClass: "layout-parent" },
                            [
                              _c("router-view", { key: _vm.$router.path }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isLoading,
                                      expression: "isLoading",
                                    },
                                  ],
                                  staticClass: "loading",
                                },
                                [
                                  _c("fade-loader", {
                                    attrs: {
                                      loading: _vm.isLoading,
                                      color: "#333333",
                                    },
                                  }),
                                  _c("span", [_vm._v(_vm._s(_vm.loadingText))]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _vm.isReadOnlyUser || !_vm.$route.meta.hideFooter
                ? _c("content-footer")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }