<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>account_box</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p
                v-if="!stopDetails.contact.name && !stopDetails.contact.phone && !stopDetails.contact.email"
                class="grayedout-text"
            >
                No contact details provided.
            </p>
            <div v-else>
                <div class="details-box" v-if="stopDetails.contact.name">
                    <label>Contact Name</label>
                    <span>{{ stopDetails.contact.name }}</span>
                </div>
                <div class="details-box" v-if="stopDetails.contact.phone">
                    <label>Phone</label>
                    <a :href="`tel:${stopDetails.contact.phone}`">
                        {{ stopDetails.contact.phone }}
                    </a>
                </div>
                <div class="details-box" v-if="stopDetails.contact.email">
                    <label>Email</label>
                    <span>{{ stopDetails.contact.email }}</span>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';

export default {
    name: 'DriverDetails',
    mixins: [GeneralMixin],
    props: {
        stopDetails: {
            type: Object,
            default: () => {}
        }
    }
};
</script>
