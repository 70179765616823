<template>
    <div class="stop-item">
        <md-button
            v-if="!isCustomer"
            title="Edit stop details"
            :class="useIcon ? 'md-warning md-just-icon md-round' : `${className}`"
            :disabled="isFailed || isCarrierAssigned"
            @click.stop="handleUpdateStop"
        >
            <md-icon v-if="useIcon">edit</md-icon>
            <span v-else>Edit</span>
        </md-button>

        <md-tooltip v-if="isCarrierAssigned" class="line-tooltip" md-direction="top">
            Shipment is assigned to a carrier. Edit is not allowed.
        </md-tooltip>
        <md-tooltip v-else-if="isFailed" class="line-tooltip" md-direction="top">
            Not allowed to edit a stop when it is already marked as Failed.
        </md-tooltip>
    </div>
</template>

<script>
import { UpdateStopModal } from '@/pages/Stops/components';
import { mapGetters } from 'vuex';

export default {
    name: 'UpdateStopButton',
    props: {
        stop: {
            type: [Number, String, Object],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        },
        className: {
            type: String,
            default: () => ''
        },
        useIcon: {
            type: Boolean,
            default: () => true
        },
        isFailed: {
            type: Boolean,
            default: () => false
        },
        carrierTeamId: {
            type: [Number],
            default: () => null
        }
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomer: 'user/isCustomer',
        }),
        isCarrierAssigned() {
            return this.carrierTeamId != null || (this.stop && this.stop.carrierTeamId != null);
        }
    },
    methods: {
        handleUpdateStop() {
            this.$modal
                .show(UpdateStopModal, {
                    members: this.teamMembers,
                    stop: this.stop
                })
                .then((response) => {
                    this.$emit('stopUpdated', response);
                    this.$modal.hide();
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.stop-item {
    display: inline;
}
</style>
