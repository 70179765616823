<template>
    <div class="customer-autocomplete">
        <md-field class="md-chips custom-md-chips">
            <md-chip
                class="md-primary"
                v-for="(customer, index) in customers"
                :key="index"
                @md-delete="deleteChip(customer, index)"
                md-deletable
                md-clickable
            >
                {{ customer.name }}
            </md-chip>
            <md-input
                ref="customerAutocomplete"
                type="text"
                v-model="autocompleteText"
                v-click-outside="handleClickOutside"
                @focus="onFocus($event)"
                @blur="blur"
                @change="onChange"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter="onEnter"
            />
            <div class="autocomplete">
                <ul
                    id="autocomplete-results"
                    v-show="isOpen && autocompleteList.length"
                    class="autocomplete-results"
                    ref="autocompleteContainer"
                >
                    <li class="loading" v-if="isAutoCompleteLoading">
                        Loading results...
                    </li>
                    <li
                        ref="autocompleteOptions"
                        v-else
                        v-for="(result, i) in autocompleteList"
                        :key="result.customerId"
                        @click="setAutocompleteResult(result)"
                        class="autocomplete-result"
                        :class="{ 'is-active': i === arrowCounter }"
                    >
                        {{ result.name }}
                    </li>
                </ul>
            </div>
        </md-field>
    </div>
</template>
<script>
import _ from 'lodash';
import { handleRequests } from '@/helpers';

export default {
    name: 'CustomerChipsAutocomplete',
    props: {
        customers: {
            type: Array,
            default: () => []
        },
        teamRegionId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            autocompleteText: null,
            autocompleteList: [],
            isOpen: false,
            isAutoCompleteLoading: false,
            arrowCounter: 0,
            selectedAutocomplete: {
                customerId: null,
                name: null
            }
        };
    },
    watch: {
        // eslint-disable-next-line func-names
        autocompleteText: _.debounce(function() {
            if (this.autocompleteText !== this.selectedAutocomplete.name) {
                this.getCustomerList();
            }
        }, 500)
    },
    methods: {
        onFocus(e) {
            // for selecting the whole input field
            setTimeout(() => e.target.select(), 0);
            this.$emit('focus');
        },
        onChange() {
            this.$emit('change', this.autocompleteText);
        },
        focus() {
            this.$refs.customerAutocomplete.$el.focus();
        },
        blur() {
            this.$refs.customerAutocomplete.$el.blur();
            setTimeout(() => {
                this.isOpen = false;
            }, 300);
        },
        setAutocompleteResult(data) {
            if (data === undefined || data === null) {
                return;
            }

            const { customerId, name } = data;
            const selected = { customerId, name };
            this.$emit('select', selected);
            this.autocompleteText = null;
            this.selectedAutocomplete = selected;
            this.isOpen = false;
        },
        onArrowDown() {
            if (this.arrowCounter < this.autocompleteList.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
                this.fixScrolling();
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
                this.fixScrolling();
            }
        },
        onEnter() {
            if (this.arrowCounter === -1) {
                return;
            }
            this.setAutocompleteResult(this.autocompleteList[this.arrowCounter]);
            this.arrowCounter = -1;
        },
        fixScrolling() {
            if (!this.isAutoCompleteLoading) {
                const optionHeight = this.$refs.autocompleteOptions[this.arrowCounter].clientHeight;
                this.$refs.autocompleteContainer.scrollTop = optionHeight * this.arrowCounter;
            }
        },
        // eslint-disable-next-line func-names
        async getCustomerList() {
            this.isAutoCompleteLoading = true;
            const endpoint = `/api/customers`;
            const {
                data: { customers }
            } = await handleRequests(endpoint, {
                params: {
                    pageNumber: 1,
                    itemsPerPage: 50,
                    searchText: this.autocompleteText,
                    teamRegionId: this.teamRegionId
                }
            });

            if (this.autocompleteText !== null && this.autocompleteText !== '') 
                this.isOpen = true;

            this.autocompleteList = customers.filter((x) => {
                return !this.customers.some((y) => {
                    return y.customerId === x.customerId;
                });
            });
            this.isAutoCompleteLoading = false;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        async deleteChip(customer, index) {
            await this.$emit('delete', customer, index);
        }
    }
};
</script>

<style lang="scss" scoped>
.customer-autocomplete {
    position: relative;
    width: 100%;
    display: inline-block;
}

.autocomplete {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    top: 40px;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    width: 100%;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
}
.custom-md-chips {
    padding-bottom: 0;

    .md-chip {
        background-color: #2b93ff !important;
    }
}
::v-deep .md-chip .md-ripple {
    padding-right: 21px !important;
}
::v-deep .md-chip .md-input-action .md-button-content i svg {
    opacity: 1 !important;
}
</style>
