// eslint-disable-next-line import/no-cycle
import { getShortLocaleStandardDate } from '@/helpers';

export const FILTERS_CONSTANTS = [
    { name: 'None', value: '' },
    { name: 'Status', value: 'status' },
    { name: 'Team Member', value: 'teamMember' },
    { name: 'Trip Date', value: 'tripDate' }
];

export const STATUS_CONSTANTS = [
    'Pending',
    'Enroute',
    'Arrived',
    'Cancelled',
    'Delayed',
    'Complete',
    'On Hold',
    'Failed'
];
export const FUTURE_STATUS_CONSTANTS = ['Pending', 'On Hold', 'Cancelled'];
export const PAST_STATUS_CONSTANTS = ['Pending', 'Cancelled', 'On Hold', 'Complete', 'Failed'];
export const UNASSIGNED_STATUS_CONSTANTS = ['Pending', 'On Hold', 'Cancelled'];
// Status for shipment when viewing form the Stop page.
export const STATUS_STOP_SHIPMENT_CONSTANTS = ['Pending', 'Enroute', 'Arrived', 'Delayed', 'Complete', 'On Hold'];
export const FUTURE_STATUS_STOP_SHIPMENT_CONSTANTS = ['Pending', 'Cancelled', 'On Hold'];
export const PAST_STATUS_STOP_SHIPMENT_CONSTANTS = ['Pending', 'Complete', 'Failed'];
export const UNASSIGNED_STATUS_STOP_SHIPMENT_CONSTANTS = ['Pending', 'On Hold'];
export const STATUS_SHIPMENT_CONSTANTS = [
    'Pending',
    'Enroute to Pickup',
    'Arrived at Pickup',
    'Picked Up',
    'Enroute to Drop',
    'Arrived at Drop',
    'Complete',
    'Cancelled',
    'Delayed',
    'On Hold'
];
export const UNASSIGNED_STATUS_SHIPMENT_CONSTANTS = ['Pending', 'On Hold', 'Cancelled'];
export const FUTURE_STATUS_SHIPMENT_CONSTANTS = ['Pending', 'Cancelled', 'On Hold'];
export const PAST_STATUS_SHIPMENT_CONSTANTS = ['Pending', 'Cancelled', 'On Hold', 'Complete'];
export const TRIP_STATUS_CONSTANTS = ['Pending', 'Started', 'Complete', 'Cancelled', 'On Hold'];
export const FUTURE_TRIP_STATUS_CONSTANTS = ['Pending', 'Cancelled', 'On Hold'];
export const PAST_TRIP_STATUS_CONSTANTS = ['Pending', 'Started', 'Complete', 'Cancelled', 'On Hold'];
export const CUSTOMER_ADMIN_ROLE_STATUS_CONSTANTS = ['Pending', 'Cancelled'];

export const SMS_STOP_TYPES = ['All', 'No Stop Type', 'Pick Up', 'Drop'];

export const TIME_WINDOW_CONSTANTS = [
    { name: 'Any Time', timeWindowStart: '00:00', timeWindowEnd: '23:59' },
    { name: 'Morning', timeWindowStart: '09:00', timeWindowEnd: '12:00' },
    { name: 'Afternoon', timeWindowStart: '12:00', timeWindowEnd: '17:00' },
    { name: 'Evening', timeWindowStart: '17:00', timeWindowEnd: '19:00' },
    { name: 'Custom time window', timeWindowStart: '00:00', timeWindowEnd: '23:59' }
];

// contains centralized date types. can add more as needed.
export const DATE_TYPES = {
    internationalDate: 'YYYY-MM-DD',
    militaryTime: 'HH:mm',
    // based on user's locale
    longDate: 'LL',
    shortDate: 'L',
    standardDate: getShortLocaleStandardDate(), // Sat, 30 Jan 2021
    standardTime: 'LT',
    standardDateTime: 'LLL',
    day: 'MMM. D'
};

export const SPEED_MULTIPLIER = {
    kph: 3.6,
    mph: 2.23694
};

export const CUSTOM_FIELD_TYPES_CONSTANTS = ['Text', 'Date', 'MultiLineText', 'CheckBox'];

// There is a corresponding list in the back-end found in the QuestionTypes class.
export const QUESTION_TYPES_CONSTANTS = ['Text', 'Date', 'MultiLineText', 'CheckBox', 'Dropdown', 'Photo', 'Barcode'];

// export const GROUP_OPTIONS_CONSTANTS = ['Proof of Delivery', 'Failed Delivery', 'Trip Start', 'Trip End'];
export const GROUP_OPTIONS_CONSTANTS = [
    { text: 'Booking', teamType: 'Carrier' },
    { text: 'Proof of Delivery', teamType: 'All' },
    { text: 'Failed Delivery', teamType: 'All' },
    { text: 'Trip Start', teamType: 'All' },
    { text: 'Trip End', teamType: 'All' },
    { text: 'Enroute to Stop', teamType: 'All' },
    { text: 'Arrived at Stop', teamType: 'All' }
];

export const QUESTION_KIND_OPTIONS = ['Standard', 'Custom'];
export const QUESTION_STANDARD_BOOKING_OPTIONS = [
    // must match with 'CarrierStandardQuestionFields' constants
    {
        kind: 'Standard',
        name: 'IsPickupCommercial', // the primary key
        label: 'Is pickup a commercial entity?',
        type: 'CheckBox',
        isRequired: false,
        options: [],
        description: 'Whether or not the pickup address is a commercial entity.'
    },
    {
        kind: 'Standard',
        name: 'PickupCompanyName', // the primary key
        label: 'Pickup company name',
        type: 'Text',
        isRequired: false,
        options: [],
        description: 'Required when pickup is a commercial entity.'
    },
    {
        kind: 'Standard',
        name: 'IsDropoffCommercial', // the primary key
        label: 'Is dropoff a commercial entity?',
        type: 'CheckBox',
        isRequired: false,
        options: [],
        description: 'Whether or not the dropoff address is a commercial entity.'
    },
    {
        kind: 'Standard',
        name: 'DropoffCompanyName', // the primary key
        label: 'Dropoff company name',
        type: 'Text',
        isRequired: false,
        options: [],
        description: 'Required when dropoff is a commercial entity.'
    },
    {
        kind: 'Standard',
        name: 'NotifyContact', // the primary key
        label: 'Notify contact',
        type: 'CheckBox',
        isRequired: false,
        options: [],
        description: 'Whether or not to notify contact of delivery updates.'
    },
    {
        kind: 'Standard',
        name: 'ParcelInsuranceValue', // the primary key
        label: 'Parcel insurance value',
        type: 'Text',
        isRequired: false,
        options: [],
        description: 'Parcel insurance value'
    },
    {
        kind: 'Standard',
        name: 'Tip', // the primary key
        label: 'Tip',
        type: 'Text',
        isRequired: false,
        options: [],
        description: 'The tip amount.'
    }
];

export const ASSET_TYPE_CONSTANTS = ['Tracker', 'Vehicle', 'Equipment', 'Trailer', 'Personnel'];

export const NOTE_TYPE_CONSTANTS = [
    { name: 'Stop Note', color: 'pending' },
    { name: 'Shipment Note', color: 'delayed' },
    { name: 'Lead', color: 'enroute' },
    { name: 'Incoming Call', color: 'arrived' },
    { name: 'Outgoing Call', color: 'complete' },
    { name: 'Incoming SMS', color: 'arrived' },
    { name: 'Outgoing SMS', color: 'complete' },
    { name: 'Outgoing Email', color: 'arrived' },
    { name: 'Feedback', color: 'cancelled' },
    { name: 'Enquiry', color: 'enroute' },
    { name: 'Photo', color: 'pending' },
    { name: 'Proof of Delivery', color: 'enroute' },
    { name: 'Other', color: 'cancelled' },
    { name: 'Attempt Failed', color: 'failed' },
    { name: 'Trip Started', color: 'enroute' },
    { name: 'Trip Complete', color: 'complete' }
];
export const STOP_NOTE_TYPE_CONSTANTS = [
    { name: 'Enroute to Stop', color: 'enroute' },
    { name: 'Arrived at Stop', color: 'arrived' }
];

export const SMS_EVENT_TRIGGER_CONSTANTS = [
    {
        key: 'STOP-STATUS-ENROUTE',
        eventTrigger: 'Status-Enroute'
    },
    {
        key: 'STOP-STATUS-PENDING',
        eventTrigger: 'Status-Pending'
    },
    {
        key: 'STOP-STATUS-ARRIVED',
        eventTrigger: 'Status-Arrived'
    },
    {
        key: 'STOP-STATUS-CANCELLED',
        eventTrigger: 'Status-Cancelled'
    },
    {
        key: 'STOP-STATUS-DELAYED',
        eventTrigger: 'Status-Delayed'
    },
    {
        key: 'STOP-STATUS-COMPLETE',
        eventTrigger: 'Status-Complete'
    },
    {
        key: 'STOP-STATUS-ON-HOLD',
        eventTrigger: 'Status-On-Hold'
    },
    {
        key: 'STOP-STATUS-FAILED',
        eventTrigger: 'Status-Failed'
    },
    {
        key: 'STOP-ASSIGNMENT',
        eventTrigger: 'StopAssigned'
    },
    {
        key: 'GENERAL-LINK-CREATED',
        eventTrigger: 'GeneralLinkCreated'
    },
    {
        key: 'STOP-LINK-CREATED',
        eventTrigger: 'StopLinkCreated'
    }
];

export const SMS_TRIP_EVENT_TRIGGER_CONSTANTS = [
    {
        key: 'TRIP-STATUS-STARTED',
        eventTrigger: 'Trip-Status-Started'
    },
    {
        key: 'TRIP-STATUS-COMPLETE',
        eventTrigger: 'Trip-Status-Complete'
    }
];

export const SMS_MANUAL_EVENT_TRIGGER = {
    key: 'MANUAL',
    eventTrigger: 'Manual'
};

export const GEOFENCE_GROUP_EVENT_TRIGGER_CONSTANTS = [
    {
        key: 'StartingTrip',
        triggerGroupEvent: 'Start a Trip'
    },
    {
        key: 'EndingTrip',
        triggerGroupEvent: 'End a Trip'
    },
    {
        key: 'EnrouteToStop',
        triggerGroupEvent: 'Change stop status to Enroute'
    },
    {
        key: 'ArrivingAtStop',
        triggerGroupEvent: 'Change stop status to Arrived'
    },
    {
        key: 'LeavingStop',
        triggerGroupEvent: 'Change stop status to Complete'
    }
];

export const GEOFENCE_EVENT_ACTIONS_CONSTANTS = [
    {
        key: 'StartingTrip',
        eventObj: {
            triggerGroupEvent: 'StartingTrip',
            level: 'Trip',
            triggerEvent: 'Distance-Trigger',
            travelDistanceInMeters: 100,
            geofenceActions: [
                {
                    geofenceEventId: 0,
                    action: 'Change-Trip-Status',
                    toValue: 'Started'
                }
            ]
        }
    },
    {
        key: 'EndingTrip',
        eventObj: {
            triggerGroupEvent: 'EndingTrip',
            triggerEvent: 'Distance-Trigger',
            level: 'Trip',
            travelDistanceInMeters: 100,
            geofenceActions: [
                {
                    geofenceEventId: 0,
                    action: 'Change-Trip-Status',
                    fromValue: 'Started',
                    tovalue: 'Complete'
                }
            ]
        }
    },
    {
        key: 'EnrouteToStop',
        eventObj: {
            triggerGroupEvent: 'EnrouteToStop',
            triggerEvent: 'Distance-Trigger',
            level: 'Stop',
            travelDistanceInMeters: 5000,
            geofenceActions: [
                {
                    geofenceEventId: 0,
                    action: 'Change-Stop-Status',
                    fromValue: 'Pending',
                    tovalue: 'Enroute'
                }
            ]
        }
    },
    {
        key: 'ArrivingAtStop',
        eventObj: {
            triggerGroupEvent: 'ArrivingAtStop',
            triggerEvent: 'Distance-Trigger',
            level: 'Stop',
            travelDistanceInMeters: 100,
            geofenceActions: [
                {
                    geofenceEventId: 0,
                    action: 'Change-Stop-Status',
                    fromValue: 'Enroute',
                    tovalue: 'Arrived'
                }
            ]
        }
    },
    {
        key: 'LeavingStop',
        eventObj: {
            triggerGroupEvent: 'LeavingStop',
            triggerEvent: 'Distance-Trigger',
            level: 'Stop',
            travelDistanceInMeters: 100,
            geofenceActions: [
                {
                    geofenceEventId: 0,
                    action: 'Change-Stop-Status',
                    fromValue: 'Arrived',
                    tovalue: 'Complete'
                }
            ]
        }
    }
];

export const GEOFENCE_TYPES = ['Logging'];

export const INSERT_MERGE_FIELDS_CONSTANTS = [
    { name: 'Brand', key: 'Brand' },
    { name: 'Customer', key: 'Customer' },
    { name: 'Contact First Name', key: 'Contact-FirstName' },
    { name: 'Contact Last Name', key: 'Contact-LastName' },
    { name: 'Contact Email', key: 'Contact-Email' },
    { name: 'Driver First Name', key: 'FirstName' },
    { name: 'Driver Last Name', key: 'LastName' },
    { name: 'Driver Phone', key: 'Phone' },
    { name: 'Driver Email', key: 'Email' },
    { name: 'Company', key: 'Company' },
    { name: 'Link', key: 'Link' },
    { name: 'Shipment Link', key: 'Shipment-Link' },
    { name: 'Trip Date', key: 'TripDate' },
    { name: 'Appointment Time', key: 'AppointmentTime' },
    { name: 'Time Window Start', key: 'TimeWindowStart' },
    { name: 'Time Window End', key: 'TimeWindowEnd' },
    { name: 'Address', key: 'Address' },
    { name: 'ETA', key: 'ETA' },
    { name: 'ETA Time Window', key: 'ETA-TimeWindow' },
    { name: 'Order Number', key: 'OrderNumber' },
    { name: 'One Time Pin (OTP)', key: 'OneTimePin' },
    { name: 'Source Reference', key: 'SourceReference' }
];

export const INSERT_SHIPMENT_MERGE_FIELDS_CONSTANTS = [
    { name: 'Shipment Contact First Name', key: 'Shipment.Contact-FirstName' },
    { name: 'Shipment Contact Last Name', key: 'Shipment.Contact-LastName' },
    { name: 'Shipment Contact Email', key: 'Shipment.Contact-Email' },
    { name: 'Shipment Pickup Address', key: 'Shipment.Pickup-Address' },
    { name: 'Shipment Drop Address', key: 'Shipment.Drop-Address' },
    { name: 'Shipment Pickup ETA', key: 'Shipment.Pickup-ETA' },
    { name: 'Shipment Drop ETA', key: 'Shipment.Drop-ETA' },
    { name: 'Shipment Pickup ETA Time Window', key: 'Shipment.Pickup-ETA-TimeWindow' },
    { name: 'Shipment Drop ETA Time Window', key: 'Shipment.Drop-ETA-TimeWindow' }
];

export const GPS_DATA_COLLECTION_RULE_CONSTANTS = [
    {
        name: 'Default',
        description:
            'Team member location data will only be sent when they have active stops for the day or active links. This provides a balance of privacy and phone battery life, collecting location data only while the team member is actively working.'
    },
    {
        name: 'Always',
        description:
            'Collect location data 24 hours a day. This may have privacy implications and affect phone battery life.'
    },
    {
        name: 'Never',
        description:
            'Never collect location data from the phone app. Use this when the team member is assigned to an asset group containing a vehicle tracker, or if live tracking of the team member is not required.'
    },
    {
        name: 'Business Hours',
        description: 'Collect location data between the start and end time defined for trips from Monday to Friday.'
    }
];

export const PAYMENT_METHOD_CONSTANTS = ['None', 'Bank Transfer'];

export const INDUSTRY_CONSTANTS = [
    'Appliance Repair',
    'Building',
    'Carpet Cleaning',
    'Cleaning',
    'Data & Security',
    'Delivery',
    'Electrical',
    'Flooring',
    'Handyman',
    'HVAC',
    'IT Services',
    'Painting',
    'Plumbing & Gas',
    'Pool & Spa Services',
    'Pressure Washing',
    'Repairs & Maintenance',
    'Snow Removal',
    'Transportation',
    'Tree Care',
    'Utilities',
    'Other'
];

export const CAPACITY_TYPES_CONSTANTS = [
    { name: 'Quantity', key: 'quantity' },
    { name: 'Volume', key: 'volume' },
    { name: 'Weight', key: 'weight' },
    { name: 'Length', key: 'length' },
    { name: 'Width', key: 'width' },
    { name: 'Height', key: 'height' }
];

const dimensionUnits = [
    {
        unit: 'Centimeters',
        symbol: 'cm',
        default: true
    },
    {
        unit: 'Meters',
        symbol: 'm',
        default: true
    },
    {
        unit: 'Kilometers',
        symbol: 'km',
        default: true
    },
    {
        unit: 'Inches',
        symbol: 'in'
    },
    {
        unit: 'Feet',
        symbol: 'ft',
        default: true
    },
    {
        unit: 'Miles',
        symbol: 'mi',
        default: true
    }
];

export const CAPACITY_TYPE_DEFAULTS_CONSTANTS = [
    {
        selected: false,
        type: 'quantity',
        label: 'Quantity',
        unit: 'Pieces',
        symbol: 'pc',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: [
            {
                unit: 'Pieces',
                symbol: 'pc',
                default: true
            }
        ],
        selectedUnit: 'Pieces'
    },
    {
        selected: false,
        type: 'volume',
        label: 'Volume',
        unit: 'Litres',
        symbol: 'L',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: [
            {
                unit: 'Litres',
                symbol: 'L',
                default: true
            },
            {
                unit: 'CubicMeters',
                symbol: 'm3'
            },
            {
                unit: 'CubicInches',
                symbol: 'in3'
            },
            {
                unit: 'CubicFeet',
                symbol: 'ft3'
            }
        ],
        selectedUnit: 'Litres'
    },
    {
        selected: false,
        type: 'weight',
        label: 'Weight',
        unit: 'Kilograms',
        symbol: 'kg',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: [
            {
                unit: 'Kilograms',
                symbol: 'kg',
                default: true
            },
            {
                unit: 'Grams',
                symbol: 'g'
            },
            {
                unit: 'Pounds',
                symbol: 'lb'
            },
            {
                unit: 'Ounce',
                symbol: 'oz'
            }
        ],
        selectedUnit: 'Kilograms'
    },
    {
        selected: false,
        type: 'length',
        label: 'Length',
        unit: 'Centimeters',
        symbol: 'cm',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: dimensionUnits,
        selectedUnit: 'Centimeters'
    },
    {
        selected: false,
        type: 'width',
        label: 'Width',
        unit: 'Centimeters',
        symbol: 'cm',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: dimensionUnits,
        selectedUnit: 'Centimeters'
    },
    {
        selected: false,
        type: 'height',
        label: 'Height',
        unit: 'Centimeters',
        symbol: 'cm',
        defaultLoad: null,
        defaultMaxCapacity: null,
        units: dimensionUnits,
        selectedUnit: 'Centimeters'
    }
];

export const MAP_ZOOM_LEVEL_BREAKPOINT = 15;

export const GPS_COORDINATE_CONSTANT = 'GPS Coordinates';

// pricing plans should be loaded from server/stripe in the near future
export const PRICING_PLANS = [
    {
        name: 'core-gps',
        prices: [{ key: 'au', price: 10 }, { key: 'us', price: 10 }, { key: 'eu', price: 8 }, { key: 'gb', price: 7 }]
    },
    {
        name: 'professional-gps',
        prices: [{ key: 'au', price: 19 }, { key: 'us', price: 19 }, { key: 'eu', price: 16 }, { key: 'gb', price: 12 }]
    },
    {
        name: 'premium3',
        prices: [{ key: 'au', price: 95 }, { key: 'us', price: 95 }, { key: 'eu', price: 78 }, { key: 'gb', price: 65 }]
    },
    {
        name: 'premium10',
        prices: [
            { key: 'au', price: 185 },
            { key: 'us', price: 185 },
            { key: 'eu', price: 150 },
            { key: 'gb', price: 125 }
        ]
    },
    {
        name: 'premium30',
        prices: [
            { key: 'au', price: 385 },
            { key: 'us', price: 385 },
            { key: 'eu', price: 315 },
            { key: 'gb', price: 280 }
        ]
    }
];

export const WEEK_DAYS_CONSTANTS = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
};
export const RUN_SCHEDULE_TYPE_CONSTANTS = ['Weekly', 'Fortnightly', 'On Demand'];

export const RUN_SCHEDULE_STATUS_CONSTANTS = ['Active', 'Disabled'];

// As they are stored on DB
export const ITEM_STATUS_CONSTANTS = ['Created', 'Delivered', 'Returned', 'Onboard', 'Damaged', 'Lost'];

// Values are the same as CustomActionEmailRecipientTypes in the backend
export const TEAM_CUSTOM_ACTION_EMAIL_RECIPIENT_OPTIONS = [
    {
        value: 'StopContact',
        text: 'Stop Contact'
    },
    {
        value: 'ShipmentContact',
        text: 'Shipment Contact'
    },
    {
        value: 'Customer',
        text: 'Customer - Email'
    },
    {
        value: 'CustomerContact',
        text: 'Customer - Contact Email'
    },
    {
        value: 'TeamMember',
        text: 'Assigned Team Member'
    },
    {
        value: 'TeamManager',
        text: 'Team Manager'
    },
    {
        value: 'Specified',
        text: 'Specific Email Addresses'
    }
];

// must be in-sync with backend
export const TEAM_CUSTOM_ACTION_TYPE_OPTIONS = [
    {
        value: 'Email',
        text: 'Send Email',
        group: 'Email',
        eventTriggers: [
            {
                order: 1,
                eventTrigger: 'STOP-ASSIGNED',
                displayText: 'Stop - Assigned'
            },
            {
                order: 2,
                eventTrigger: 'STOP-STATUS-CHANGED',
                displayText: 'Stop - Status Changed',
                filters: {
                    statuses: ['Trip Started', 'Enroute', 'Complete', 'Failed']
                },
                limitRecipientsByFilter: (filter) => {
                    if (!filter) {
                        return null;
                    }

                    if (filter === 'Trip Started') {
                        return ['StopContact', 'ShipmentContact', 'Customer', 'CustomerContact', 'Specified'];
                    }

                    return null;
                }
            },
            {
                order: 3,
                eventTrigger: 'STOP-CREATED',
                displayText: 'Stop - Created'
            },
            {
                order: 4,
                eventTrigger: 'SHIPMENT-CREATED',
                displayText: 'Shipment - Created'
            },
            {
                order: 7,
                eventTrigger: 'TRIP-STATUS-CHANGED',
                displayText: 'Trip - Status Changed',
                filters: {
                    statuses: ['Started', 'Complete']
                },
                limitRecipients: ['Customer', 'CustomerContact', 'TeamMember', 'TeamManager', 'Specified']
            },
            {
                order: 9,
                eventTrigger: 'GEOFENCE-MOVEMENT',
                displayText: 'Geofence Movement',
                filters: {
                    statuses: ['All', 'Enter', 'Leave']
                },
                limitRecipients: ['TeamMember', 'TeamManager', 'Specified']
            },
            {
                order: 10,
                eventTrigger: 'REVIEW-FEEDBACK',
                displayText: 'Review Feedback',
                filters: {
                    statuses: ['All', 'Low']
                },
                limitRecipients: ['Specified']
            },
            {
                order: 11,
                eventTrigger: 'CUSTOMER-BOOKING',
                displayText: 'Customer Booking'
            },
            {
                order: 12,
                eventTrigger: 'JOB-OFFER-FAILED',
                displayText: 'Job Offer To Team Members Failed',
                limitRecipients: ['TeamManager', 'Specified']
            },
            {
                order: 13,
                eventTrigger: 'TRACKER-OFFLINE-TIME-EXCEEDED',
                displayText: 'Tracker Offline time exceeded',
                // Commented out in case we need advanced filtering.
                // filters: {
                //    statuses: ['24 hours', '48 hours']
                // },
                limitRecipients: ['TeamManager', 'Specified']
            }
        ]
    },
    {
        value: 'GetQuotesFromCarriers',
        text: 'Get quotes from carriers',
        group: 'Others',
        eventTriggers: [
            {
                eventTrigger: 'SHIPMENT-CREATED',
                displayText: 'Shipment - Created'
            }
        ]
    },
    {
        value: 'PlaceCarrierBooking',
        text: 'Place Booking with Carrier',
        group: 'Others',
        eventTriggers: [
            {
                eventTrigger: 'SHIPMENT-QUOTES-GATHERED',
                displayText: 'Shipment Quotes Gathered'
            }
        ]
    },
    {
        value: 'OfferToTeamMembers',
        text: 'Offer to Team Members',
        group: 'Others',
        eventTriggers: [
            {
                eventTrigger: 'SHIPMENT-CREATED',
                displayText: 'Shipment - Created'
            },
            {
                order: 1,
                eventTrigger: 'STOP-CREATED',
                displayText: 'Stop - Created'
            }
        ]
    }
    /* , {
        value: 'Sms',
        text: 'Send Sms'
    },
    {
        value: 'Webhook',
        text: 'Trigger Webhook'
    } */
];

export const TEAM_CUSTOM_ACTION_STOP_MERGE_FIELDS = [
    { key: 'address', name: 'Address' },
    { key: 'stopRef', name: 'Stop Reference' },
    { key: 'sourceReference', name: 'Source Reference' },
    { key: 'type', name: 'Stop Type' },
    { key: 'stopDetailsUrl', name: 'Stop Details Url' },
    { key: 'driverFirstName', name: 'Driver First Name' },
    { key: 'driverLastName', name: 'Driver Last Name' },
    { key: 'driverEmail', name: 'Driver Email' },
    { key: 'driverPhone', name: 'Driver Phone' },
    { key: 'contactName', name: 'Contact Name' },
    { key: 'contactEmail', name: 'Contact Email' },
    { key: 'contactPhone', name: 'Contact Phone' },
    { key: 'customerName', name: 'Customer Name' },
    { key: 'customerFirstName', name: 'Customer First Name' },
    { key: 'customerLastName', name: 'Customer Last Name' },
    { key: 'customerEmail', name: 'Customer Email' },
    { key: 'customerPhone', name: 'Customer Phone' },
    { key: 'tripDate', name: 'Trip Date' },
    { key: 'appointmentTime', name: 'Appointment Time' },
    { key: 'timeWindowStart', name: 'Time Window Start' },
    { key: 'timeWindowEnd', name: 'Time Window End' },
    { key: 'orderNumber', name: 'Order Number' },
    { key: 'trackingLink', name: 'Tracking Url' },
    { key: 'podLink', name: 'Proof of Delivery Url' },
    { key: 'rateDriverLink', name: 'Rate Driver Url' }
];

export const PAYMENT_STATUS_CONSTANTS = ['Paid', 'Unpaid'];

export const CALENDAR_ACTIVE_STATUS_CONSTANTS = ['Enroute', 'Arrived', 'Delayed', 'On Hold'];

export const RATE_RULE_TAB_HEADERS = ['Levy', 'Surcharge', 'Hourly', 'Item', 'Weight', 'Distance', 'Volume'];

export const RATE_CHARGING_TYPES = [
    { key: 'Levy', type: 'levy-charger' },
    { key: 'Surcharge', type: 'surcharge-charger' },
    { key: 'Hourly', type: 'transport-hourly-charger' },
    { key: 'Item', type: 'transport-items-charger' },
    { key: 'Weight', type: 'transport-kilogram-charger' },
    { key: 'Distance', type: 'transport-kilometre-charger' },
    { key: 'Volume', type: 'transport-cubic-charger' }
];

export const RATE_CONDITION_TYPES = [
    { key: '==', type: 'Equal' },
    { key: '>=', type: 'GreaterThanEqualTo' },
    { key: '<=', type: 'LessThanEqualTo' },
    { key: 'Between', type: 'between' }
];

export const RATE_ZONE_CONDITION_TYPES = [{ key: 'In', type: 'In' }, { key: 'Not In', type: 'NotIn' }];

export const RATE_VARIABLES = [
    { key: 'Weight', type: 'Weight' },
    { key: 'Items', type: 'Items' },
    { key: 'Volume', type: 'Volume' },
    { key: 'Distance', type: 'Distance' },
    { key: 'Pickup Address', type: 'PickupAddress' },
    { key: 'Drop Address', type: 'DropAddress' }
];

export const SPEED_TO_DISTANCE_UNITS = {
    kph: 'km',
    mph: 'miles'
};

export const JOB_OFFER_SENDING_MODE_OPTIONS = [
    {
        value: 'Broadcast',
        text: 'Broadcast'
    },
    {
        value: 'DripFeed',
        text: 'Drip Feed'
    }
];

export const JOB_OFFER_ALLOCATION_METHOD_OPTIONS = [
    // Only 'Manual' for the initial release
    // {
    //     value: 'Auto',
    //     text: 'Algorithmic Assignment'
    // },
    {
        value: 'Manual',
        text: 'Manual Assignment'
    }
];

export const DISTANCE_UNIT_OPTIONS = [
    {
        value: 'km',
        text: 'Kilometers'
    },
    {
        value: 'mi',
        text: 'Miles'
    }
];

export const OFFER_METHODS_OPTIONS = [
    {
        methodType: 'Manual',
        quoteTypeOptions: [],
        jobAssignmentOptions: []
    },
    {
        methodType: 'Instant',
        quoteTypeOptions: ['Manual', 'Auto'],
        jobAssignmentOptions: ['Manual', 'Auto']
    }
];

export const OFFER_QUOTING_STATUSES = [
    {
        value: 'GatheringQuotes',
        text: 'Gathering Quotes',
        color: 'pending'
    },
    {
        value: 'QuotesGathered',
        text: 'Quotes Gathered',
        color: 'enroute'
    },
    {
        value: 'Booking',
        text: 'Booking',
        color: 'departed'
    },
    {
        value: 'BookingFailed',
        text: 'Booking Failed',
        color: 'cancelled'
    },
    {
        value: 'JobAssigned',
        text: 'Job Assigned',
        color: 'arrived'
    },
    {
        value: 'OfferingJob',
        text: 'Offering Job',
        color: 'delayed'
    },
    {
        value: 'JobAccepted',
        text: 'Job Accepted',
        color: 'complete'
    },
    {
        value: 'QuotingFailed',
        text: 'Quoting Failed',
        color: 'cancelled'
    }
];

export const TEAM_CARRIER_STATUS_CONSTANTS = [
    { value: 'Active', text: 'Active' },
    { value: 'Inactive', text: 'Inactive' },
    { value: 'PendingCarrierApproval', text: 'Pending Carrier Approval' },
    { value: 'CarrierApproved', text: 'Carrier Approved' },
    { value: 'Onboarding', text: 'Onboarding' }
];

export const TEAM_SETTING_OPTIONS_CONSTANTS = [
    {
        name: 'Accounts',
        class: 'accounts',
        icon: 'manage_accounts'
    },
    {
        name: 'Apps',
        class: 'apps',
        icon: 'apps'
    },
    {
        name: 'Brands',
        class: 'brands',
        icon: 'face'
    },
    {
        name: 'Capacity Settings',
        class: 'capacitySettings',
        icon: 'production_quantity_limits'
    },
    {
        name: 'Custom Actions',
        class: 'customActions',
        icon: 'offline_bolt'
    },
    {
        name: 'Custom Fields',
        class: 'customFields',
        icon: 'list_alt'
    },
    {
        name: 'Driver Instructions',
        class: 'driverInstructions',
        icon: 'checklist'
    },
    {
        name: 'General Settings',
        class: 'generalSettings',
        icon: 'settings_applications'
    },
    {
        name: 'Geofence Events Settings',
        class: 'geofenceEventsSettings',
        icon: 'settings_input_antenna'
    },
    {
        name: 'Import Mappings',
        class: 'importMappings',
        icon: 'publish'
    },
    {
        name: 'Proof Of Delivery Configuration',
        class: 'proofOfDeliveryConfiguration',
        icon: 'fact_check'
    },
    {
        name: 'Questions',
        class: 'questions',
        icon: 'question_answer'
    },
    {
        name: 'Rate Zones',
        class: 'rateZones',
        icon: 'share_location'
    },
    {
        name: 'Services',
        class: 'services',
        icon: 'store'
    },
    {
        name: 'Shipment Contents',
        class: 'shipmentContents',
        icon: 'medication_liquid'
    },
    {
        name: 'Skills',
        class: 'skills',
        icon: 'psychology'
    },
    {
        name: 'SMS Settings',
        class: 'smsSettings',
        icon: 'sms'
    },
    {
        name: 'Team Regions',
        class: 'teamRegions',
        icon: 'crop'
    },
    {
        name: 'App Permission Settings',
        class: 'appPermissionSettings',
        icon: 'settings_accessibility'
    },
    {
        name: 'Service Areas',
        class: 'serviceAreas',
        icon: 'my_location'
    },
    {
        // temporarily hide on carrier first release
        name: 'Job Offer Settings',
        class: 'jobOffers',
        icon: 'work_history'
    },
    {
        name: 'Failed Delivery Configuration',
        class: 'failedDeliveryConfiguration',
        isCustomIcon: true,
        icon: 'failed-delivery-configuration-icon.svg'
    }
];

export const DEFAULT_SHIPMENT_CONTENTS_CONSTANTS = [
    {
        label: 'Food',
        values: [{ name: 'Food' }, { name: 'Baked' }, { name: 'Cake' }]
    },
    {
        label: 'Meat',
        values: [{ name: 'Meat' }, { name: 'Beef' }, { name: 'Pork' }]
    },
    {
        label: 'Liquid',
        values: [{ name: 'Alcohol' }, { name: 'Liquid' }]
    },
    {
        label: 'Medications',
        values: [{ name: 'Prescription medications' }]
    },
    {
        label: 'Drugs',
        values: [{ name: 'Tobacco' }, { name: 'Dangerous goods' }]
    },
    {
        label: 'Fragile Things',
        values: [{ name: 'Fragile' }, { name: 'Glass' }]
    },
    {
        label: 'Floral',
        values: [{ name: 'Flowers' }]
    },
    {
        label: 'Other',
        values: [{ name: 'Not rotatable' }, { name: 'Must be upright' }]
    }
];
export const OPERATOR_CONSTANTS = [
    {
        name: 'equals',
        value: '='
    },
    {
        name: 'not equals',
        value: '!='
    },
    {
        name: 'greater than',
        value: '>'
    },
    {
        name: 'less than',
        value: '<'
    },
    {
        name: 'greater than equals',
        value: '>='
    },
    {
        name: 'less than equals',
        value: '<='
    }
];
export const CONDITION_CONSTANTS = [
    {
        name: 'None',
        value: 'none'
    },
    {
        name: 'Team Regions',
        value: 'teamRegions'
    },
    {
        name: 'Shipment Contents',
        value: 'shipmentContents'
    },
    {
        name: 'Custom Field Value',
        value: 'customFields'
    }
];

export const CUSTOMER_ROLES_CONSTANTS = ['Customer', 'Customer Admin'];

export const ITEM_MOVEMENT_LOCATION_CONSTANTS = [
    { key: 'NoLocation', name: 'No Location' },
    { key: 'WithSupplier', name: 'With Supplier' },
    { key: 'AtWarehouse', name: 'At Warehouse' },
    { key: 'InTransit', name: 'In Transit' },
    { key: 'Delivered', name: 'Delivered' },
    { key: 'ReturnToCustomer', name: 'Return To Customer' },
    { key: 'ReturnToSupplier', name: 'Return To Supplier' }
];

export const GEOCODING_CONFIDENCE_SCORE_CONSTANTS = [
    { key: 1, name: '< 100% Confidence', selectedLabel: '< 100%' },
    { key: 0.9, name: '< 90% Confidence', selectedLabel: '< 90%' },
    { key: 0.8, name: '< 80% Confidence', selectedLabel: '< 80%' },
    { key: 0.7, name: '< 70% Confidence', selectedLabel: '< 70%' },
    { key: 0.6, name: '< 60% Confidence', selectedLabel: '< 60%' },
    { key: 0.5, name: '< 50% Confidence', selectedLabel: '< 50%' },
    { key: 0.4, name: '< 40% Confidence', selectedLabel: '< 40%' },
    { key: 0.3, name: '< 30% Confidence', selectedLabel: '< 30%' },
    { key: 0.2, name: '< 20% Confidence', selectedLabel: '< 20%' },
    { key: 0.1, name: '< 10% Confidence', selectedLabel: '< 10%' }
];

export const DRIVER_RATING_SCORE_CONSTANTS = [
    { key: -1, name: 'No Rating' },
    { key: 5, name: '<= 5 star rating' },
    { key: 4, name: '<= 4 star rating' },
    { key: 3, name: '<= 3 star rating' },
    { key: 2, name: '<= 2 star rating' },
    { key: 1, name: '<= 1 star rating' }
];

export const STOP_HEADER_DROPDOWN_CONSTANTS = [
    {
        label: 'Stop List',
        key: 'stop',
        icon: 'manage_accounts'
    },
    {
        label: 'Fix Addresses',
        key: 'fixAddress',
        icon: 'manage_accounts'
    }
];

export const GEOCODE_ADDRESS_TYPE = [
    {
        name: 'Street Number',
        key: 'street_number',
        color: 'delayed',
        selectedLabel: 'Street Number'
    },
    {
        name: 'Street',
        key: 'street',
        color: 'complete',
        selectedLabel: 'Street'
    },
    {
        name: 'Others',
        key: 'others',
        color: 'cancelled',
        selectedLabel: 'Others'
    }
];

export const PROCESS_TOTALS_CONSTANTS = [
    { key: 0, name: 'All', selectedLabel: 'All' },
    { key: 1, name: '< $0', selectedLabel: '< 0' }
];

export const REFERENCE_SOURCE_KEY_OPTIONS = [
    {
        name: 'Reference Number',
        key: 'reference-number'
    },
    {
        name: 'Source Reference',
        key: 'source-reference',
        tooltip: 'If the source reference is unavailable, the reference number will be displayed'
    }
];

export const STOP_TYPES = [{ key: 'Pick Up ', type: 'Pick Up' }, { key: 'Drop', type: 'Drop' }];

export const INVOICE_ADJUSTMENT_HEADER_DROPDOWN_CONSTANTS = [
    {
        label: 'Driver Invoice Adjustment',
        key: 'driver'
    }
];

export const INVOICE_ADJUSTMENT_RULE_TYPES = [
    {
        type: 'Daily Adjustment',
        value: 1,
        information:
            'Daily Adjustment is an addition or deduction which will be applied to driver invoices based on the unique days the driver has worked.'
    }
];

export const RATES_DASHBOARD_HEADER_DROPDOWN_CONSTANTS = [
    {
        label: 'Customer',
        key: 'customer',
        icon: 'price_change'
    },
    {
        label: 'Team Member',
        key: 'teamMember',
        icon: 'price_change'
    }
];

export const RATES_DAYS_OF_THE_WEEK = [
    {
        name: 'Monday',
        label: 'Mon',
        value: 1
    },
    {
        name: 'Tuesday',
        label: 'Tue',
        value: 2
    },
    {
        name: 'Wednesday',
        label: 'Wed',
        value: 4
    },
    {
        name: 'Thursday',
        label: 'Thu',
        value: 8
    },
    {
        name: 'Friday',
        label: 'Fri',
        value: 16
    },
    {
        name: 'Saturday',
        label: 'Sat',
        value: 32
    },
    {
        name: 'Sunday',
        label: 'Sun',
        value: 64
    }
];

export const SEND_TO_ACCOUNTING_CONSTANTS = {
    enableRetryAfterMinutes: 15
};

export const SHIPMENT_HEADER_DROPDOWN_CONSTANTS = [
    {
        label: 'Shipment View',
        key: 'shipment',
        icon: 'manage_accounts'
    },
    {
        label: 'Quotes View',
        key: 'quotes',
        icon: 'manage_accounts'
    }
];

// set the Incompatible line type of the key of an exisiting type.
// a Incompatible type means that this type of invoice line can't be used with another type to create invoice lines.
// these are enums of InvoiceLineBuilderType
export const INVOICE_LINE_TYPES = [
    { key: 1, name: 'Roll up', inCompatible: [2] },
    { key: 2, name: 'No roll up', inCompatible: [1] },
    { key: 4, name: 'Include service lines', inCompatible: [] }
];

export const SHIPMENT_CONTENTS_OPTIONS = [
    {
        key: 'isFood',
        text: 'Has Food',
        order: 1
    },
    {
        key: 'isBaked',
        text: 'Is Baked',
        order: 2
    },
    {
        key: 'isCake',
        text: 'Has Cake',
        order: 3
    },
    {
        key: 'isMeat',
        text: 'Has Meat',
        order: 4
    },
    {
        key: 'isBeef',
        text: 'Has Beef',
        order: 5
    },
    {
        key: 'isPork',
        text: 'Has Pork',
        order: 6
    },
    {
        key: 'isLiquid',
        text: 'Has Liquid',
        order: 7
    },
    {
        key: 'isAlcohol',
        text: 'Has Alcohol',
        order: 8
    },
    {
        key: 'isPrescriptionMedication',
        text: 'Has Prescription Medication',
        order: 9
    },
    {
        key: 'isTobacco',
        text: 'Has Tobacco',
        order: 10
    },
    {
        key: 'isDangerousGoods',
        text: 'Has Dangerous Goods',
        order: 11
    },
    {
        key: 'isFragile',
        text: 'Is Fragile',
        order: 12
    },
    {
        key: 'isGlass',
        text: 'Has Glass',
        order: 13
    },
    {
        key: 'isFlowers',
        text: 'Has Flowers',
        order: 14
    },
    {
        key: 'isNotRotatable',
        text: 'Is Not Rotatable',
        order: 15
    },
    {
        key: 'mustBeUpright',
        text: 'Must Be Upright',
        order: 16
    }
];

export const DRIVER_INSTRUCTIONS_OPTIONS = [
    {
        key: 'hasAuthorityToLeave',
        text: 'Has Authority to Leave?',
        order: 1
    },
    {
        key: 'mustCheckRecipientId',
        text: 'Must Check Recipient Id?',
        order: 2
    },
    {
        key: 'mustWearHighVisibilityVest',
        text: 'Must Wear High Visibility Vest?',
        order: 3
    },
    {
        key: 'isContactlessDelivery',
        text: 'Is Contactless Delivery?',
        order: 4
    },
    {
        key: 'instructions',
        text: 'Instructions',
        order: 5
    }
];
