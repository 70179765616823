<template>
    <!-- isLoading is needed here to prevent errors appearing in the console if stopId was passed since this will be rendered with stopDetails having an empty object -->
    <div class="modal-container" v-if="!isLoading">
        <div class="modal-header">
            <h4 class="modal-title">Update Stop</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <div v-if="!stopDetails.shipmentId">
            <md-button class="md-success chargeButton-toggle" @click="openCharges()">
                Charges ({{ chargeList.length }})
            </md-button>
            <charge-list-modal
                :rates-list="chargeList"
                :rates-is-loading="ratesIsLoading"
                :rate-list-data="rateListData"
                :currency="stopDetails.currency"
            />
        </div>
        <br />
        <div class="modal-body stop-form-modal">
            <form-wrapper :validator="$v.stopDetails" class="form-wrapper">
                <div class="stop-form-container">
                    <charging-type-options
                        v-if="!stopDetails.shipmentId"
                        :selected-charge-type-id="stopDetails.rateGroupId"
                        @selectedOption="handleRateTypeChanged"
                    />
                    <team-region-member-controls
                        v-if="!stopDetails.shipmentId"
                        :team-region-id="stopDetails.teamRegionId"
                        :public-user-id="stopDetails.assignedTo.publicUserId"
                        :carrier-team-id="stopDetails.carrierTeamId"
                        :show-label-if-member-not-in-team-region="true"
                        @onChanged="handleTeamRegionMemberChanged"
                        :show-carriers="false"
                        :strict-team-region-filter="false"
                        mode="update"
                    />
                    <div v-if="!stopDetails.shipmentId">
                        <form-group name="type" label="Type">
                            <md-input v-model="stopDetails.type" type="hidden" />
                            <vue-select
                                :options="stopTypes"
                                :reduce="(x) => x.type"
                                label="key"
                                v-model="stopDetails.type"
                            ></vue-select>
                        </form-group>
                    </div>
                    <div>
                        <form-group name="sourceReference" label="Source Reference">
                            <md-input
                                v-model="stopDetails.sourceReference"
                                :readonly="stopDetails.shipmentId !== null"
                            />
                        </form-group>
                    </div>
                    <div>
                        <brand-filter v-model="stopDetails" />
                    </div>
                    <div class="contact-details">
                        <span class="cd-title">Customer Details</span>
                        <customer-autocomplete
                            :label="stopDetails.customerId == null ? 'Name' : 'Customer'"
                            id="autocustomer"
                            placeholder=""
                            :should-focus="false"
                            v-model="stopDetails"
                            :auto-fill-address="true"
                            @change="handleCustomerFieldChange"
                            @changeCustomerRateGroup="handleCustomerChangeRateGroup"
                        />
                        <div>
                            <google-autocomplete
                                label="Destination"
                                ref="address"
                                id="autoaddress"
                                classname="form-control autocomplete-input"
                                placeholder=""
                                :should-focus="true"
                                v-model="stopDetails"
                                :disable-gps-coordinates="false"
                                @handleCoordinates="handleCoordinates"
                                @change="handleStopDetailsChanged"
                            ></google-autocomplete>
                        </div>
                        <form-group name="name" label="Contact Name" v-if="stopDetails.customerId != null">
                            <md-input v-model="stopDetails.contact.name" />
                        </form-group>
                        <form-group name="phone" label="Phone">
                            <md-input v-model="stopDetails.contact.phone" />
                        </form-group>
                        <form-group name="email" label="Email">
                            <md-input v-model="stopDetails.contact.email" />
                        </form-group>
                        <div v-if="loadFieldList !== null">
                            <load-inputs
                                v-for="(load, index) in loadFieldList"
                                :key="index"
                                :load-definition="load"
                                :initial-value="loadValues"
                                @changed="handleLoadChanged"
                            />
                        </div>
                        <form-group name="skills" label="Skills" v-if="!stopDetails.type && skillOptions.length">
                            <multiselect
                                v-model="stopDetails.skills"
                                :options="skillOptions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Pick skills"
                            ></multiselect>
                        </form-group>
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="stopDetails.notes" />
                        </form-group>
                        <div v-if="stopCustomFieldList !== null">
                            <custom-field-inputs
                                v-for="(customField, index) in stopCustomFieldList"
                                :key="index"
                                :custom-field-definition="customField"
                                :initial-value="customFieldValues[customField.name]"
                                @changed="handleCustomFieldChanged"
                            />
                        </div>
                        <div>
                            <form-group name="runNumber" label="Run (Optional)">
                                <md-input
                                    v-model="stopDetails.runNumber"
                                    @input="($value) => (stopDetails.runNumber = parseFloat($value) || null)"
                                    type="number"
                                    min="1"
                                />
                            </form-group>
                        </div>
                    </div>
                    <!--<div>
        <form-group name="runnumber" label="Run">
            <md-input type="number" placeholder="Optional" v-model.number="stopDetails.runNumber" min="1" />
        </form-group>
    </div>-->
                    <div class="tripdate">
                        <md-checkbox v-model="isSetTripDate" :disabled="tripDateRequired">
                            Set trip date for this stop?
                        </md-checkbox>
                    </div>
                    <div
                        class="stop-datepicker"
                        v-if="isSetTripDate"
                        :class="!stopDetails.assignedTo.publicUserId ? '' : 'hide-clear-button'"
                    >
                        <div>
                            <md-datepicker
                                ref="mdDatePicker"
                                v-model="tripDate"
                                md-immediately
                                :md-disabled-dates="disabledDates"
                                :md-debounce="10"
                            >
                                <label>Trip Date</label>
                            </md-datepicker>
                            <span v-if="isSetTripDate && tripDateRequired && tripDate == null" class="error">
                                Trip date is required.
                            </span>
                        </div>
                        <div>
                            <form-group name="time" label="Time" class="time-picker">
                                <time-picker
                                    :time="time"
                                    :additional-time-options="additionalTimeOption"
                                    @selectedTime="getTime"
                                />
                            </form-group>
                        </div>
                        <div>
                            <form-group name="durationMinutes" label="Duration (mins)">
                                <md-input type="number" v-model="stopDetails.durationMinutes" />
                                <!-- <span class="md-suffix">minutes</span> -->
                            </form-group>
                        </div>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" :disabled="ratesIsLoading" @click="updateStop">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import {
    handleRequests,
    showErrorMessage,
    isAValidCoordinate,
    showTeamRegionControl,
    filterMembersByTeamRegionId
} from '@/helpers';
import { required, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators';
import {
    TimePicker,
    GoogleAutocomplete,
    CustomFieldInputs,
    CustomerAutocomplete,
    LoadInputs,
    TeamRegionMemberControls,
    ChargeListModal,
    ChargingTypeOptions,
    BrandFilter
} from '@/components';
import moment from 'moment';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { DATE_TYPES, TIME_WINDOW_CONSTANTS, STOP_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'UpdateStopModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        CustomFieldInputs,
        CustomerAutocomplete,
        LoadInputs,
        TeamRegionMemberControls,
        ChargeListModal,
        ChargingTypeOptions,
        BrandFilter
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        stop: {
            type: [Number, Object, String],
            default: () => null
        },
        members: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isSingleTeamMemberUser: 'user/isSingleTeamMember',
            hasRateRules: 'user/hasRateRules'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.stopDetails.teamRegionId
            );
        }
    },
    created() {
        if (this.members.length > 1) {
            const tempMembers = this.members.map((member) => ({
                publicUserId: member.publicUserId,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                email: member.email,
                teamRegionId: member.teamRegionId
            }));

            this.teamMemberOptions = [...tempMembers];
        }
    },
    async mounted() {
        let stopDetails = {};
        if (typeof this.stop === 'number' || typeof this.stop === 'string') {
            const data = await this.getStopDetails(this.stop);
            data.tripDate =
                data.tripDate != null
                    ? this.$options.filters.timeFormat(data.tripDate, DATE_TYPES.internationalDate)
                    : null;
            stopDetails = Object.assign({}, data);
        } else {
            stopDetails = Object.assign({}, this.stop);
        }

        if (stopDetails.teamRegionId === null) 
            stopDetails.teamRegionId = -1;

        this.stopDetails = { ...stopDetails };

        this.isLoading = false;

        if (this.stopDetails.assignedTo.publicUserId != null) {
            // this is to support disabled members that is not included in the members list. we need to append them at the bottom of the list
            // so that when updating a stop that belongs to them, their name will be shown instead of their publicUserId
            const user = this.members.find((x) => x.publicUserId === this.stopDetails.assignedTo.publicUserId);
            if (!user) {
                this.teamMemberOptions.push({
                    publicUserId: this.stopDetails.assignedTo.publicUserId,
                    fullName: this.stopDetails.assignedTo.fullName,
                    email: this.stopDetails.assignedTo.email,
                    teamRegionId: this.stopDetails.teamRegionId
                });
            }
        }
        this.filterTeamMembersOptions(this.stopDetails.teamRegionId);
        this.isSetTripDate =
            this.stopDetails.tripDate !== null ||
            this.stopDetails.appointmentTime !== null ||
            this.stopDetails.timeWindowStart !== null ||
            this.stopDetails.timeWindowEnd !== null ||
            this.isSingleTeamMemberUser;

        this.tripDateRequired = this.stopDetails.assignedTo.publicUserId !== null || this.isSingleTeamMemberUser;
        this.durationMinutes = this.stopDetails.durationMinutes;
        let stopTime = null;
        this.stopCustomFieldList = this.user.stopCustomFieldDefinitions;
        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];

        if (
            this.stopCustomFieldList != null &&
            this.stopCustomFieldList.length &&
            this.stopDetails.customFields != null
        ) {
            this.customFieldValues = this.stopDetails.customFields;
        }

        if (this.loadFieldList != null && this.loadFieldList.length && this.stopDetails.load != null) {
            this.loadValues = this.stopDetails.load;
        }

        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }
        if (this.stopDetails.timeWindowStart != null && this.stopDetails.timeWindowEnd != null) {
            const timeWindowStart = moment(this.stopDetails.timeWindowStart).format(DATE_TYPES.militaryTime);
            const timeWindowEnd = moment(this.stopDetails.timeWindowEnd).format(DATE_TYPES.militaryTime);

            const timeWindowStartStandard = moment(this.stopDetails.timeWindowStart).format(DATE_TYPES.standardTime);
            const timeWindowEndStandard = moment(this.stopDetails.timeWindowEnd).format(DATE_TYPES.standardTime);

            let hasMatch = false;

            TIME_WINDOW_CONSTANTS.some((x) => {
                if (timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd) {
                    stopTime = x.name;
                    hasMatch = true;
                }
                return timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd;
            });

            if (!hasMatch) {
                stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                this.additionalTimeOption.push({
                    name: stopTime,
                    timeWindowStart,
                    timeWindowEnd
                });
            }
        } else if (this.stopDetails.appointmentTime != null) {
            stopTime = moment(this.stopDetails.appointmentTime).format(DATE_TYPES.standardTime);
        } else {
            stopTime = 'None';
        }
        this.time = stopTime;

        this.chargeList = this.stopDetails.chargeLines;
        this.costList = this.stopDetails.costLines;
    },
    watch: {
        tripDate(newValue, oldValue) {
            if (newValue != null) {
                this.stopDetails.tripDate = moment(newValue).format(DATE_TYPES.internationalDate);
            } else {
                this.stopDetails.tripDate = null;
            }
        },
        isSetTripDate(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue) {
                if (!this.$root.isDesktop) {
                    this.$nextTick(() => {
                        if (this.$refs.mdDatePicker)
                            this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
                    });
                }
                if (this.stopDetails.tripDate) {
                    this.tripDate = moment(this.stopDetails.tripDate).format(DATE_TYPES.internationalDate);
                } else {
                    this.tripDate = moment().format(DATE_TYPES.internationalDate);
                }
            } else {
                this.tripDate = null;
                this.time = 'None';
                this.stopDetails.tripDate = null;
            }
        },
        'stopDetails.assignedTo.publicUserId': function(newValue, oldValue) {
            const hasTripDate =
                this.stopDetails.tripDate !== null ||
                this.stopDetails.appointmentTime !== null ||
                this.stopDetails.timeWindowStart !== null ||
                this.stopDetails.timeWindowEnd !== null;

            if (newValue !== oldValue && oldValue) {
                this.setDurationMinutes(newValue);
            }

            // eslint-disable-next-line eqeqeq
            if (newValue == null || newValue == 0) {
                this.isSetTripDate = hasTripDate;
                this.tripDateRequired = false;
            } else {
                this.isSetTripDate = true;
                this.tripDateRequired = true;

                if (this.stopDetails.tripDate == null) {
                    this.tripDate = moment().format(DATE_TYPES.internationalDate);
                }
            }
        },
        'stopDetails.tripDate': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== oldValue) {
                if (this.stopDetails.tripDate !== newValue) 
                    this.validateCanGenerateRates();
            }
        }
    },
    methods: {
        async getStopDetails(stopId) {
            this.$_handleLoaderState(true);
            const api = `/api/stops/${stopId}?includeStatusHistory=true`;
            const {
                data: { data }
            } = await handleRequests(api);

            this.$_handleLoaderState(false);
            return data;
        },
        async updateStop() {
            if ((this.isSetTripDate && this.tripDateRequired && this.tripDate == null) || this.isTimeValid === false)
                return;

            const {
                assignedTo: { publicUserId }
            } = this.stopDetails;
            this.$v.stopDetails.$touch();

            if (!this.$v.stopDetails.$invalid) {
                this.$_handleLoaderState(true, 'UPDATING...');
                if (!publicUserId) 
                    this.stopDetails.assignedTo.publicUserId = null;
                // eslint-disable-next-line radix
                this.stopDetails.durationMinutes = parseInt(this.stopDetails.durationMinutes);
                this.stopDetails.customFields = JSON.stringify(this.customFieldValues);
                this.stopDetails.load = this.loadValues;
                this.stopDetails.skills =
                    this.stopDetails.skills && this.stopDetails.skills.length > 0 ? this.stopDetails.skills : null;

                // eslint-disable-next-line no-unused-expressions
                this.chargeList?.forEach((rate, index) => {
                    rate.stopId = this.stopDetails.stopId;
                });

                // eslint-disable-next-line no-unused-expressions
                this.costList?.forEach((cost, index) => {
                    cost.stopId = this.stopDetails.stopId;
                });

                if (!this.stopDetails.shipmentId) {
                    this.stopDetails.updatedChargeLines = this.updatedCharges;
                    this.stopDetails.updatedCostLines = this.updatedCosts;
                }

                const api = `/api/stops/${this.stopDetails.stopId}`;
                const payload = {
                    method: 'put',
                    data: this.stopDetails
                };

                try {
                    const responseData = await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully updated a stop.',
                        type: 'success'
                    });

                    const feedbackMessage = responseData?.data;
                    if (feedbackMessage && typeof feedbackMessage === 'string' && feedbackMessage.trim().length !== 0) {
                        this.$notifyWarning(feedbackMessage, 7000);
                    }

                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    const message = 'Could not update stop.';
                    showErrorMessage(this, message, e);
                    this.resolve('error');
                }
            }
        },
        getTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.stopDetails.appointmentTime = appointmentTime;
                this.stopDetails.timeWindowStart = timeWindowStart;
                this.stopDetails.timeWindowEnd = timeWindowEnd;
            } else {
                this.stopDetails.appointmentTime = null;
                this.stopDetails.timeWindowStart = null;
                this.stopDetails.timeWindowEnd = null;
            }
            this.isTimeValid = isTimeValid;
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleLoadChanged({ name, value }) {
            let canGetRules = false;
            if (this.loadValues[name] !== parseFloat(value)) {
                canGetRules = true;
            }

            this.loadValues[name] = parseFloat(value);

            if (canGetRules) {
                this.ruleGenerationLoadDetails();
            }
        },
        handleTeamRegionMemberChanged(val) {
            if (this.stopDetails.assignedTo.publicUserId !== val.publicUserId) {
                this.validateCanGenerateRates();
            }

            if (val != null && val.teamMember != null && val.teamMember.rateGroupId != null && this.stopDetails.rateGroupId === null) {
                this.stopDetails.rateGroupId = val.teamMember.rateGroupId;
            }

            this.stopDetails.teamRegionId = val && val.teamRegionId ? val.teamRegionId : null;
            this.stopDetails.assignedTo.publicUserId = val && val.publicUserId ? val.publicUserId : null;
            this.stopDetails.carrierTeamId = val && val.carrierTeamId ? val.carrierTeamId : null;
        },
        setDurationMinutes(publicUserId) {
            const teamMember = this.members.find((x) => x.publicUserId === publicUserId);
            const defaultStopDurationMinutes =
                teamMember?.defaultStopDurationMinutes ?? this.user.defaultStopDurationMinutes;
            // User user or team default stop duration, if null use value from Stop details
            this.stopDetails.durationMinutes = defaultStopDurationMinutes ?? this.durationMinutes;
        },
        filterTeamMembersOptions(teamRegionId) {
            this.filteredTeamMemberOptions = filterMembersByTeamRegionId(
                this.teamRegions,
                this.teamMemberOptions,
                this.defaultTeamMemberOption,
                teamRegionId
            );
        },
        handleCoordinates(value) {
            this.useCoordinates = value;
            this.$v.$reset();
        },
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        handleRateTypeChanged(rateRule) {
            this.stopDetails.rateGroupId = rateRule?.rateGroupId ?? null;

            if (this.stopDetails.rateGroupId === null) {
                this.chargeList = [];
                this.costList = [];

                this.stopDetails.chargeLines = this.chargeList;
                this.stopDetails.costLines = this.costList;

                this.updatedCharges = [];
                this.updatedCosts = [];
            }

            this.ruleGenerationDebounce();
        },
        validateCanGenerateRates(addressChanged, rateGroupChanged) {
            if (this.stopDetails.address !== null && this.ratesIsLoading === false) {
                this.generateRates(addressChanged, rateGroupChanged);
            }
        },
        async generateRates(addressChanged, rateGroupChanged) {
            if (!this.hasRateRules) 
                return;

            if (this.stopDetails.shipmentId) {
                return;
            }

            if (!this.stopDetails.rateGroupId) {
                // && !this.stopDetails.assignedTo?.rateGroupId)  {
                this.rateListData = null;
                this.chargeList = [];
                this.costList = [];
                this.updatedCharges = [];
                this.updatedCosts = [];

                return;
            }

            this.ratesIsLoading = true;

            let response = {
                data: {
                    rateList: null
                }
            };

            // eslint-disable-next-line no-unused-expressions
            this.chargeList?.forEach((rate, index) => {
                rate.stopId = this.stopDetails.stopId;
                rate.isChargingRule = true;
                rate.customerId = this.stopDetails.customerId;
            });

            // eslint-disable-next-line no-unused-expressions
            this.costList?.forEach((cost, index) => {
                cost.stopId = this.stopDetails.stopId;
                cost.isCostingRule = true;
                cost.customerId = this.stopDetails.customerId;
            });

            const hasNoCustomerInvoice = this.stopDetails.customerInvoiceId === null;
            const hasNoTeamMemberInvoice = this.stopDetails.teamMemberInvoiceId === null;

            try {
                const data = this.$_getStopData(
                    this.stopDetails,
                    this.user,
                    this.loadValues,
                    this.chargeList,
                    this.costList,
                    hasNoCustomerInvoice,
                    hasNoTeamMemberInvoice,
                    false,
                    false,
                    rateGroupChanged,
                    addressChanged
                );

                const payload = {
                    method: 'post',
                    data
                };

                // TODO
                // response = await handleRequests(`/api/rates/stop/${this.stopDetails.stopId}/generate-rates/${hasNoTeamMemberInvoice}/${hasNoCustomerInvoice}/false/false`, payload);

                response = await handleRequests('/api/rates/generate-rates', payload);

                if (response.data) {
                    this.rateListData = response.data;
                    this.chargeList = response.data.charge ?? [];
                    this.costList = response.data.cost ?? [];

                    this.updatedCharges = response.data.charge ?? [];
                    this.updatedCosts = response.data.cost ?? [];
                }
                this.ratesIsLoading = false;
            } catch (error) {
                const message = `Error generating rates, if issue persists please contact support`;
                showErrorMessage(this, message, null);
                this.ratesIsLoading = false;
            }
        },
        // eslint-disable-next-line func-names
        ruleGenerationDebounce: _.debounce(function() {
            this.validateCanGenerateRates(false, true);
        }, 500),
        // eslint-disable-next-line func-names
        ruleGenerationLoadDetails: _.debounce(function() {
            this.validateCanGenerateRates(false, false);
        }, 1000),
        // eslint-disable-next-line func-names
        handleStopDetailsChanged: _.debounce(function() {
            this.validateCanGenerateRates(true, false);
        }, 500),
        handleCustomerFieldChange() {
            this.ruleGenerationLoadDetails();
        },
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.stopDetails.rateGroupId === null || this.stopDetails.rateGroupId === undefined) {
                this.stopDetails.rateGroupId = customerRateGroupId;
            }
        }
    },
    data() {
        return {
            tripDate: null,
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return !moment(x).isSameOrAfter(now, 'day');
            },
            isSetTripDate: false,
            tripDateRequired: false,
            time: null,
            isTimeValid: true,
            stopCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            stopDetails: {},
            isLoading: true,
            additionalTimeOption: [],
            teamMemberOptions: [],
            useCoordinates: false,
            filteredTeamMemberOptions: [],
            durationMinutes: null,
            defaultTeamMemberOption: {
                publicUserId: 0,
                fullName: 'Unassigned',
                email: null,
                teamRegionId: null,
                teamId: null
            },
            rateListData: {
                totalCharge: 0,
                totalCost: 0
            },
            chargeList: [],
            ratesIsLoading: false,
            costList: [],
            updatedCharges: [],
            updatedCosts: [],
            stopTypes: STOP_TYPES
        };
    },
    validations: {
        stopDetails: {
            address: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return !this.useCoordinates;
                    }),
                coordinatesValid(data) {
                    if (this.useCoordinates) {
                        return isAValidCoordinate(data);
                    }
                    return true;
                }
            },
            durationMinutes: { required, numeric },
            sourceReference: {
                maxLength: maxLength(200)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
::v-deep .time-picker {
    .vs__search:focus {
        min-width: 135px !important;
    }
}

.charge-wrapper {
    padding-left: 25px;
}

.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}

.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}

.chargeButton-toggle {
    float: right;
    border: none;
    background-color: #4caf50;
    position: relative;
    place-items: center;
    display: grid;
    color: white !important;
}

.chargeButton-toggle:hover {
    background: #409443;
}
</style>
