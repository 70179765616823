<template>
    <div :style="{ display: display }" :id="id" class="filter-inline">
        <md-field v-if="teamRegions && teamRegions.length > 0">
            <vue-select
                label="name"
                :options="filterTeamRegionOptions"
                placeholder="Team Region"
                v-model="teamRegionFilterValue"
                :clearable="clearable"
                append-to-body
                :calculate-position="withPopper"
                :id="`sel-team-region-${id}`"
            >
                <template #selected-option="option">
                    <span style="margin-left: 2px">
                        {{ option.name }}
                    </span>
                </template>
                <template v-slot:option="option">
                    <span style="margin-left: 2px" :class="isSubRegion ? `option-level-${option.level}` : ''">
                        {{ option.name }}
                    </span>
                </template>
            </vue-select>
        </md-field>
        <md-field
            v-if="
                (showTeamMember || showCarriers) &&
                    filteredTeamMemberOptions.length > 0 &&
                    !isSingleUser &&
                    !isSingleTeamMember
            "
            class="filter-member-name"
        >
            <vue-select
                label="displayText"
                :options="filteredTeamMemberOptions"
                :placeholder="assigneePlaceHolder"
                v-model="teamMemberFilterValue"
                :searchable="$root.isDesktop"
                :id="`sel-member-${id}`"
            >
                <template v-slot:option="option">
                    <span v-if="option.type !== 'Separator'">{{ option.displayText }}</span>
                    <hr v-else />
                </template>
            </vue-select>
        </md-field>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { filterMembersByTeamRegionId } from '@/helpers/index';
import { createPopper } from '@popperjs/core';

export default {
    name: 'TeamRegionMemberFilterOptions',
    props: {
        teamRegionId: {
            type: Number,
            default: null
        },
        showTeamMember: {
            type: Boolean,
            default: true
        },
        showCarriers: {
            type: Boolean,
            default: false
        },
        strictTeamRegionFilter: {
            type: Boolean,
            default: true
        },
        showUnassigned: {
            type: Boolean,
            default: true
        },
        teamMemberPublicUserid: {
            type: String,
            default: null
        },
        includeSubTeamRegions: { type: Boolean, default: true },
        clearable: { type: Boolean, default: true },
        useInMap: { type: Boolean, default: false },
        display: { type: String, default: 'inline' },
        id: { type: String, default: 'team-region-member-filter-options' },
        useElevatedFilterView: { type: Boolean, default: false } // special handling for Team Member Invoice page, where Managers and Staffs can Filter
    },
    data() {
        return {
            teamRegionFilterValue: null,
            selectedTeamRegionId: null,
            teamMemberFilterValue: null,
            defaults: [
                {
                    name: 'All Team Regions',
                    teamRegionId: null
                },
                {
                    name: 'None',
                    teamRegionId: -1
                }
            ],
            teamMembers: [],
            defaultTeamMemberOption: {
                publicUserId: 'unassigned',
                fullName: 'Unassigned'
            }
        };
    },
    computed: {
        ...mapGetters({
            teamRegions: 'team/teamRegions',
            user: 'user/user',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            hasElevatedTeamRegionFilter: 'user/hasElevatedTeamRegionFilter',
            hasMarketplaceAccess: 'user/hasMarketplaceAccess',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            allTeamMembers: 'team/teamMembers',
            allTeamCarriers: 'team/teamCarriers'
        }),
        filterTeamRegionOptions() {
            if (this.teamRegions) {
                if (this.includeSubTeamRegions) {
                    return [...this.defaults, ...this.teamRegions];
                }

                const filtered = this.teamRegions.filter((x) => !x.level || x.level <= 1); // parent-most team regions only
                return [...this.defaults, ...filtered];
            }

            return [];
        },
        filteredTeamMemberOptions() {
            const defaults = this.showUnassigned ? this.defaultTeamMemberOption : null;

            let mapped = [];

            if (this.showTeamMember) {
                const memberOptions = filterMembersByTeamRegionId(
                    this.teamRegions,
                    this.allTeamMembers,
                    defaults,
                    this.selectedTeamRegionId,
                    this.strictTeamRegionFilter
                );

                mapped = memberOptions.map((x) => {
                    return {
                        type: 'Member',
                        valueId: x.publicUserId,
                        displayText: x.fullName
                    };
                });
            }

            if (this.showCarriers && this.allTeamCarriers && this.allTeamCarriers.length) {
                if (this.showTeamMember) {
                    mapped.push({
                        type: 'Separator'
                    });
                }

                for (let ndx = 0; ndx < this.allTeamCarriers.length; ndx++) {
                    mapped.push({
                        type: 'Carrier',
                        valueId: this.allTeamCarriers[ndx].carrierTeamId,
                        displayText: this.allTeamCarriers[ndx].carrierTeam?.company
                    });
                }
            }

            return mapped;
        },
        assigneePlaceHolder() {
            if (this.hasMarketplaceAccess && this.showCarriers) {
                if (this.showTeamMember) 
                    return 'Team Member / Carrier';
                return ' Carrier';
            }

            return 'Team Member';
        },
        isSubRegion() {
            const subRegion = this.filterTeamRegionOptions.find((x) => x.level >= 2);
            return !!subRegion;
        }
    },
    watch: {
        teamRegionId: {
            immediate: true,
            handler(newValue, oldValue) {
                if (oldValue !== null || oldValue !== newValue) {
                    this.updateSelectedRegion();
                }
            }
        },
        teamRegions: {
            immediate: true,
            deep: true,
            handler() {
                this.updateSelectedRegion();
            }
        },
        teamRegionFilterValue(newValue, oldValue) {
            const newTeamRegionId = newValue ? newValue.teamRegionId : null;
            const oldTeamRegionId = oldValue ? oldValue.teamRegionId : null;
            if (newTeamRegionId !== oldTeamRegionId) {
                this.selectedTeamRegionId = newTeamRegionId;

                if (this.filteredTeamMemberOptions.length !== 1) {
                    this.teamMemberFilterValue = null;
                } else {
                    // auto-select member if only 1
                    // eslint-disable-next-line prefer-destructuring
                    this.teamMemberFilterValue = this.filteredTeamMemberOptions[0];
                }
                this.emitChange();
            }
        },
        teamMemberFilterValue(newValue, oldValue) {
            const newValueId = newValue?.valueId || null;
            const oldValueId = oldValue?.valueId || null;
            if (newValueId !== oldValueId) {
                this.emitChange();
            }
        },
        filteredTeamMemberOptions(newValue) {
            const members = this.filteredTeamMemberOptions;
            const member = members.find((x) => x.valueId === this.teamMemberPublicUserid);

            if (member) {
                // eslint-disable-next-line prefer-destructuring
                this.teamMemberFilterValue = member;
            } else {
                this.teamMemberFilterValue = null;
            }
        }
    },
    methods: {
        updateSelectedRegion() {
            let userTeamRegion = null;
            if (this.teamRegionId && this.teamRegionId > 0) {
                userTeamRegion = this.teamRegions.find((x) => x.teamRegionId === this.teamRegionId);
            }

            if (userTeamRegion) {
                this.teamRegionFilterValue = userTeamRegion;
            } else if (this.teamRegionId === -1) {
                this.teamRegionFilterValue = { ...this.defaults[1] }; // None
            } else {
                this.teamRegionFilterValue = { ...this.defaults[0] }; // All
            }
        },
        emitChange() {
            let teamMember = null;
            if (this.allTeamMembers && this.teamMemberFilterValue && this.teamMemberFilterValue.type === 'Member') {
                teamMember = this.allTeamMembers.find((x) => x.publicUserId === this.teamMemberFilterValue.valueId);
            }

            let teamCarrier = null;
            if (this.allTeamCarriers && this.teamMemberFilterValue && this.teamMemberFilterValue.type === 'Carrier') {
                teamCarrier = this.allTeamCarriers.find((x) => x.carrierTeamId === this.teamMemberFilterValue.valueId);
            }

            const eventArgs = {
                teamRegionId: this.teamRegionFilterValue ? this.teamRegionFilterValue.teamRegionId : null,
                teamRegion: this.teamRegionFilterValue || null,
                type: this.teamMemberFilterValue?.type,
                publicUserId:
                    this.teamMemberFilterValue && this.teamMemberFilterValue.type === 'Member'
                        ? this.teamMemberFilterValue.valueId
                        : null,
                teamMember: teamMember || null,
                carrierTeamId:
                    this.teamMemberFilterValue && this.teamMemberFilterValue.type === 'Carrier'
                        ? this.teamMemberFilterValue.valueId
                        : null,
                teamCarrier: teamCarrier || null
            };

            this.$emit('changed', eventArgs);
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'bottom', // default
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top');
                        }
                    }
                ]
            });

            /**
             * To prevent memory leaks Popper needs to be destroyed.
             * If you return function, it will be called just before dropdown is removed from DOM.
             */
            return () => popper.destroy();
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep .v-select.drop-up.vs--open .vs__dropdown-toggle {
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    border-bottom-color: rgba(60, 60, 60, 0.26);
}

::v-deep [data-popper-placement='top'] {
    border-radius: 4px 4px 0 0;
    border-top-style: solid;
    border-bottom-style: none;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.filter-inline {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        margin-right: 20px;
    }
}
.option-level-1 {
    font-weight: 500;
}
.option-level-2 {
    margin-left: 20px !important;
}
.option-level-3 {
    margin-left: 40px !important;
}

::v-deep .filter-member-name .vs__selected {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    display: block;
}
</style>
