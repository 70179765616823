<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Stop</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>

        <div>
            <md-button class="md-success chargeButton-toggle" @click="openCharges()">
                Charges ({{ chargeList.length }})
            </md-button>
            <charge-list-modal
                :rates-list="chargeList"
                :rates-is-loading="ratesIsLoading"
                :rate-list-data="rateListData"
                :currency="currency"
            />
        </div>
        <br />

        <div class="modal-body stop-form-modal">
            <form-wrapper :validator="$v.stopDetails" class="form-wrapper">
                <div class="stop-form-container">
                    <!-- <div v-if="!isSingleUser && !isSingleTeamMember">
                    <form-group v-if="showTeamRegion" name="teamRegion" label="Team Region">
                        <team-region-options
                            :selected="stopDetails.teamRegionId"
                            @selectedOption="handleTeamRegionChanged"
                        />
                    </form-group>

                        <form-group name="member" label="Team Member">
                            <md-input v-model="stopDetails.assignToPublicUserId" type="hidden" />
                            <vue-select
                                :reduce="(fullName) => fullName.publicUserId"
                                label="fullName"
                                :options="filteredTeamMemberOptions"
                                v-model="stopDetails.assignToPublicUserId"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </form-group>
                    </div> -->

                    <charging-type-options
                        :selected-charge-type-id="stopDetails.rateGroupId"
                        @selectedOption="handleRateTypeChanged"
                    />

                    <team-region-member-controls
                        :team-region-id="stopDetails.teamRegionId"
                        :public-user-id="stopDetails.assignToPublicUserId"
                        :carrier-team-id="stopDetails.carrierTeamId"
                        @onChanged="handleTeamRegionMemberChanged"
                        :strict-team-region-filter="false"
                        :show-carriers="false"
                    />
                    <div>
                        <form-group name="type" label="Type">
                            <md-input v-model="stopDetails.type" type="hidden" />
                            <vue-select
                                :options="stopTypes"
                                :reduce="(x) => x.type"
                                label="key"
                                v-model="stopDetails.type"
                            ></vue-select>
                        </form-group>
                    </div>
                    <div>
                        <form-group name="sourceReference" label="Source Reference">
                            <md-input v-model="stopDetails.sourceReference" />
                        </form-group>
                    </div>
                    <div>
                        <brand-filter v-model="stopDetails" />
                    </div>
                    <div class="contact-details">
                        <span class="cd-title">Customer Details</span>

                        <customer-autocomplete
                            :label="stopDetails.customerId == null ? 'Name' : 'Customer'"
                            id="autocustomer"
                            placeholder=""
                            :should-focus="false"
                            v-model="stopDetails"
                            :auto-fill-address="true"
                            @change="handleCustomerFieldChange"
                            @changeCustomerRateGroup="handleCustomerChangeRateGroup"
                        />

                        <div>
                            <google-autocomplete
                                label="Destination"
                                ref="address"
                                id="autoaddress"
                                classname="form-control autocomplete-input"
                                placeholder=""
                                :should-focus="false"
                                v-model="stopDetails"
                                :disable-gps-coordinates="false"
                                @handleCoordinates="handleCoordinates"
                                @change="handleStopDetailsChanged"
                            ></google-autocomplete>
                        </div>
                        <form-group name="name" label="Contact Name" v-if="stopDetails.customerId != null">
                            <md-input v-model="stopDetails.contact.name" />
                        </form-group>
                        <form-group name="phone" label="Phone">
                            <md-input v-model="stopDetails.contact.phone" />
                        </form-group>
                        <form-group name="email" label="Email">
                            <md-input v-model="stopDetails.contact.email" />
                        </form-group>
                        <div v-if="loadFieldList !== null">
                            <load-inputs
                                v-for="(load, index) in loadFieldList"
                                :key="index"
                                :load-definition="load"
                                :initial-value="loadValues"
                                @changed="handleLoadChanged"
                            />
                        </div>
                        <form-group v-if="skillOptions.length" name="skills" label="Skills">
                            <multiselect
                                v-model="stopDetails.skills"
                                :options="skillOptions"
                                :multiple="true"
                                :close-on-select="true"
                                placeholder="Pick required skills"
                            ></multiselect>
                        </form-group>
                        <form-group name="notes" label="Notes">
                            <md-textarea v-model="stopDetails.notes" />
                        </form-group>
                        <div v-if="stopCustomFieldList !== null">
                            <custom-field-inputs
                                v-for="(customField, index) in stopCustomFieldList"
                                :key="index"
                                :custom-field-definition="customField"
                                @changed="handleCustomFieldChanged"
                            />
                        </div>
                        <div v-if="!isCustomerAdmin">
                            <form-group name="runNumber" label="Run (Optional)">
                                <md-input
                                    v-model="stopDetails.runNumber"
                                    @input="($value) => (stopDetails.runNumber = parseFloat($value) || null)"
                                    type="number"
                                    min="1"
                                />
                            </form-group>
                        </div>
                    </div>
                    <!--<div>
                    <form-group name="runnumber" label="Run">
                        <md-input type="number" placeholder="Optional" v-model.number="stopDetails.runNumber" min="1" />
                    </form-group>
                </div>-->
                    <div class="tripdate">
                        <md-checkbox v-model="isSetTripDate" :disabled="tripDateRequired">
                            Set trip date for this stop?
                        </md-checkbox>
                    </div>
                    <div
                        class="stop-datepicker"
                        v-if="isSetTripDate"
                        :class="!stopDetails.assignToPublicUserId ? '' : 'hide-clear-button'"
                    >
                        <div>
                            <md-datepicker
                                ref="mdDatePicker"
                                v-model="stopDetails.tripDate"
                                md-immediately
                                :md-disabled-dates="disabledDates"
                                :md-debounce="10"
                            >
                                <label>Trip Date</label>
                            </md-datepicker>
                            <span
                                v-if="isSetTripDate && tripDateRequired && stopDetails.tripDate == null"
                                class="error"
                            >
                                Trip date is required.
                            </span>
                        </div>
                        <div>
                            <form-group name="time" label="Time" class="time-picker">
                                <time-picker @selectedTime="getTime" />
                            </form-group>
                        </div>
                        <div>
                            <form-group name="durationMinutes" label="Duration (mins)">
                                <md-input type="number" v-model="stopDetails.durationMinutes" />
                                <!-- <span class="md-suffix">minutes</span> -->
                            </form-group>
                        </div>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" :disabled="ratesIsLoading" @click="createStop">
                Create
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import {
    handleRequests,
    showErrorMessage,
    isAValidCoordinate,
    showTeamRegionControl
    // filterMembersByTeamRegionId
} from '@/helpers';
import { STOP_DETAILS_DEFAULTS } from '@/utils/defaults';
import { required, numeric, requiredIf, maxLength } from 'vuelidate/lib/validators';
import {
    TimePicker,
    GoogleAutocomplete,
    CustomFieldInputs,
    LoadInputs,
    CustomerAutocomplete,
    // TeamRegionOptions,
    TeamRegionMemberControls,
    ChargeListModal,
    ChargingTypeOptions,
    BrandFilter
} from '@/components';
import moment from 'moment';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { DATE_TYPES, STOP_TYPES } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'CreateStopModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        CustomFieldInputs,
        LoadInputs,
        CustomerAutocomplete,
        // TeamRegionOptions,
        TeamRegionMemberControls,
        ChargeListModal,
        ChargingTypeOptions,
        BrandFilter
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        stopDetails: {
            type: Object,
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            hasAssetAccess: 'user/hasAssetAccess',
            teamRegions: 'team/teamRegions',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isSingleTeamMemberUser: 'user/isSingleTeamMember',
            hasRateRules: 'user/hasRateRules'
        }),
        // filteredTeamMemberOptions() {
        //     const filtered = filterMembersByTeamRegionId(
        //         this.teamRegions,
        //         this.teamMemberOptions,
        //         this.defaultTeamMemberOption,
        //         this.selectedTeamRegionId
        //     );

        //     return filtered;
        // },
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.stopDetails.teamRegionId
            );
        }
    },
    created() {
        const tempMembers = this.members.map((member) => ({
            publicUserId: member.publicUserId,
            fullName: `${member.firstName || ''} ${member.lastName || ''}`,
            email: member.email,
            teamRegionId: member.teamRegionId,
            teamId: member.teamId
        }));

        this.teamMemberOptions = [...tempMembers];
    },
    async mounted() {
        this.stopDetails.teamRegionId = this.user.teamRegionId;
        // this.selectedTeamRegionId = this.user.teamRegionId;

        if (
            // eslint-disable-next-line eqeqeq
            (this.stopDetails.assignToPublicUserId !== null && this.stopDetails.assignToPublicUserId != 0) ||
            this.isSingleTeamMemberUser
        ) {
            this.isSetTripDate = true;
            this.tripDateRequired = true;
        }

        this.stopCustomFieldList = this.user.stopCustomFieldDefinitions;
        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }

        if ((this.isSingleUser || this.isSingleTeamMember || this.hideTeamRegion) && !this.isCustomerAdmin) {
            this.stopDetails.assignToPublicUserId = this.user.publicUserId;
        }
        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];
    },
    watch: {
        isSetTripDate(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue) {
                if (!this.$root.isDesktop) {
                    this.$nextTick(() => {
                        if (this.$refs.mdDatePicker)
                            this.$refs.mdDatePicker.$refs.input.$el.setAttribute('readonly', true);
                    });
                }
                this.stopDetails.tripDate = moment().format(DATE_TYPES.internationalDate);
            } else {
                this.stopDetails.appointmentTime = null;
                this.stopDetails.tripDate = null;
            }
        },
        'stopDetails.assignToPublicUserId': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== null && newValue != 0) {
                this.isSetTripDate = true;
                this.tripDateRequired = true;
                this.setDurationMinutes(newValue);
            } else {
                this.tripDateRequired = false;
            }
        },
        'stopDetails.tripDate': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== oldValue) {
                this.validateCanGenerateRates();
            }
        }
        // filteredTeamMemberOptions(newValue) {
        //     if (this.stopDetails.assignToPublicUserId) {
        //         if (Array.isArray(newValue)) {
        //             const currentSelectedInOptions = newValue.find(
        //                 (x) => x.publicUserId === this.stopDetails.assignToPublicUserId
        //             );
        //             if (!currentSelectedInOptions) {
        //                 this.stopDetails.assignToPublicUserId = 0;
        //             }
        //         } else {
        //             this.stopDetails.assignToPublicUserId = 0;
        //         }
        //     }
        // }
    },
    methods: {
        async createStop() {
            if (
                (this.isSetTripDate && this.tripDateRequired && this.stopDetails.tripDate == null) ||
                this.isTimeValid === false
            )
                return;

            const { assignToPublicUserId } = this.stopDetails;
            this.$v.stopDetails.$touch();

            if (!this.$v.stopDetails.$invalid) {
                this.$_handleLoaderState(true, 'SAVING...');
                // eslint-disable-next-line eqeqeq
                if (assignToPublicUserId == null || assignToPublicUserId == 0)
                    this.stopDetails.assignToPublicUserId = null;
                // eslint-disable-next-line radix
                this.stopDetails.durationMinutes = parseInt(this.stopDetails.durationMinutes);
                this.stopDetails.customFields = JSON.stringify(this.customFieldValues);
                this.stopDetails.load = this.loadValues;
                this.stopDetails.skills =
                    this.stopDetails.skills === 'null' || this.stopDetails.skills === []
                        ? null
                        : this.stopDetails.skills;

                this.stopDetails.chargeLines = this.chargeList;
                this.stopDetails.costLines = this.costList;

                const api = '/api/stops';
                const payload = {
                    method: 'post',
                    data: this.stopDetails
                };

                try {
                    const responseData = await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully created a stop.',
                        type: 'success'
                    });

                    const feedbackMessage = responseData?.data?.feedbackMessage;
                    if (feedbackMessage && typeof feedbackMessage === 'string' && feedbackMessage.trim().length !== 0) {
                        this.$notifyWarning(feedbackMessage, 7000);
                    }

                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    const message = 'Could not create a new stop.';
                    showErrorMessage(this, message, e);
                    this.$_handleLoaderState(false);
                }
            }
        },
        getTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.stopDetails.appointmentTime = appointmentTime;
                this.stopDetails.timeWindowStart = timeWindowStart;
                this.stopDetails.timeWindowEnd = timeWindowEnd;
            } else {
                this.stopDetails.appointmentTime = null;
                this.stopDetails.timeWindowStart = null;
                this.stopDetails.timeWindowEnd = null;
            }
            this.isTimeValid = isTimeValid;
        },
        resetStopState() {
            Object.assign(this.stopDetails, STOP_DETAILS_DEFAULTS());
            this.$v.$reset();
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleTeamRegionChanged(value) {
            this.stopDetails.teamRegionId = value;
            this.selectedTeamRegionId = value;
        },
        handleTeamRegionMemberChanged(value) {
            if (value != null && value.teamMember != null && value.teamMember.rateGroupId != null && this.stopDetails.rateGroupId === null) {
                this.stopDetails.rateGroupId = value.teamMember.rateGroupId;
            }

            this.stopDetails.teamRegionId = value != null ? value.teamRegionId : null;
            this.stopDetails.assignToPublicUserId = value && value.publicUserId ? value.publicUserId : null;
            this.stopDetails.assignToCarrierTeamId = value && value.carrierTeamId ? value.carrierTeamId : null;
            this.validateCanGenerateRates();
        },
        setDurationMinutes(publicUserId) {
            const teamMember = this.members.find((x) => x.publicUserId === publicUserId);
            const defaultStopDurationMinutes =
                teamMember?.defaultStopDurationMinutes ?? this.user.defaultStopDurationMinutes;

            // User user or team default stop duration, if null use value from STOP_DETAILS_DEFAULTS
            this.stopDetails.durationMinutes = defaultStopDurationMinutes ?? STOP_DETAILS_DEFAULTS().durationMinutes;
        },
        handleLoadChanged({ name, value }) {
            this.loadValues[name] = value;
            this.ruleGenerationLoadDetails();
        },
        handleCoordinates(value) {
            this.useCoordinates = value;
            this.$v.$reset();
        },
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        handleRateTypeChanged(rateRule) {
            this.stopDetails.rateGroupId = rateRule?.rateGroupId ?? null;
            this.ruleGenerationDebounce();
        },
        validateCanGenerateRates(addressChanged, rateGroupChanged) {
            if (this.stopDetails.address !== null && this.ratesIsLoading === false) {
                this.generateRates(addressChanged, rateGroupChanged);
            }
        },
        // eslint-disable-next-line func-names
        ruleGenerationDebounce: _.debounce(function() {
            this.validateCanGenerateRates(false, true);
        }, 500),
        // eslint-disable-next-line func-names
        ruleGenerationLoadDetails: _.debounce(function() {
            this.validateCanGenerateRates(false, false);
        }, 1000),
        handleCustomerFieldChange() {
            this.ruleGenerationLoadDetails();
        },
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.stopDetails.rateGroupId === null || this.stopDetails.rateGroupId === undefined) {
                this.stopDetails.rateGroupId = customerRateGroupId;
            }
        },
        // eslint-disable-next-line func-names
        handleStopDetailsChanged: _.debounce(function() {
            this.validateCanGenerateRates(true, false);
        }, 500),
        async generateRates(addressChanged, rateGroupChanged) {
            if (!this.hasRateRules) 
                return;

            if (!this.stopDetails.rateGroupId) {
                this.chargeList = [];
                this.costList = [];

                this.stopDetails.chargeLines = this.chargeList;
                this.stopDetails.costLines = this.costList;
                return;
            }

            this.ratesIsLoading = true;

            let response = {
                data: {
                    rateList: null
                }
            };

            try {
                const data = this.$_getStopData(
                    this.stopDetails,
                    this.user,
                    this.loadValues,
                    null, // chargeList
                    null, // costList
                    true, // isCharging
                    this.stopDetails.assignToPublicUserId !== null && this.stopDetails.assignToPublicUserId !== 0, // isCosting
                    false, // reverCharges
                    false, // revertCosts
                    rateGroupChanged,
                    addressChanged
                );

                const payload = {
                    method: 'post',
                    data
                };

                response = await handleRequests('/api/rates/generate-rates', payload);
                if (response.data) {
                    this.rateListData = response.data;
                    this.chargeList = response.data.charge ?? [];
                    this.costList = response.data.cost ?? [];
                    this.currency = this.rateListData?.currency;
                }
                this.ratesIsLoading = false;
            } catch (error) {
                const message = `Error generating rates, if issue persists please contact support`;
                showErrorMessage(this, message, null);
                this.ratesIsLoading = false;
            }
        }
    },
    data() {
        return {
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return !moment(x).isSameOrAfter(now, 'day');
            },
            isSetTripDate: false,
            tripDateRequired: false,
            isTimeValid: true,
            stopCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            teamMemberOptions: [],
            useCoordinates: false,
            // selectedTeamRegionId: null,
            defaultTeamMemberOption: {
                publicUserId: 0,
                fullName: 'Unassigned',
                email: null,
                teamRegionId: null,
                teamId: null
            },
            rateListData: null,
            currency: '',
            chargeList: [],
            ratesIsLoading: false,
            costList: [],
            stopTypes: STOP_TYPES
        };
    },
    validations: {
        stopDetails: {
            // eslint-disable-next-line func-names
            address: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return !this.useCoordinates;
                    }),
                coordinatesValid(data) {
                    if (this.useCoordinates) {
                        return isAValidCoordinate(data);
                    }
                    return true;
                }
            },
            durationMinutes: { required, numeric },
            sourceReference: {
                maxLength: maxLength(200)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}
::v-deep .time-picker {
    .vs__search:focus {
        min-width: 135px !important;
    }
}

.chargeButton-toggle {
    float: right;
    border: none;
    background-color: #4caf50;
    position: relative;
    place-items: center;
    display: grid;
    color: white !important;
}

.chargeButton-toggle:hover {
    background: #409443;
}

.charge-wrapper {
    padding-left: 25px;
}

.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}

.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}
</style>
