var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rootJob
    ? _c(
        "div",
        {
          staticClass: "row",
          class: {
            "active-row": _vm.hasExpandedRow,
            "inactive-row": !_vm.hasExpandedRow,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleRowClick($event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-row" },
            [
              _c("md-checkbox", {
                staticClass: "checkbox",
                attrs: { value: _vm.rootJob },
                on: { change: _vm.onCheckboxClick },
                model: {
                  value: _vm.selectedJobs,
                  callback: function ($$v) {
                    _vm.selectedJobs = $$v
                  },
                  expression: "selectedJobs",
                },
              }),
              _c(
                "span",
                { staticClass: "refNo" },
                [
                  _vm.showWarning(_vm.rootJob)
                    ? _c(
                        "md-icon",
                        [
                          _vm._v("\n                warning\n                "),
                          _c("CustomTooltip", [
                            _vm._v(
                              "\n                    Not Geocoded\n                    "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                    Confirm address to fix.\n                "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.rootJob.stopId
                    ? _c(
                        "span",
                        [
                          _vm.rootJob.stopId
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Stop Details",
                                      params: { stopId: _vm.rootJob.stopId },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.referenceHeaderValue.key ===
                                          "reference-number" ||
                                          _vm.rootJob.sourceReference ===
                                            null ||
                                          _vm.rootJob.sourceReference === ""
                                          ? _vm.rootJob.ref
                                          : _vm.rootJob.sourceReference
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.rootJob.shipmentId
                    ? _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Shipment Details",
                                  params: {
                                    shipmentId: _vm.rootJob.shipmentId,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.referenceHeaderValue.key ===
                                      "reference-number" ||
                                      _vm.rootJob.sourceReference === null ||
                                      _vm.rootJob.sourceReference === ""
                                      ? _vm.rootJob.ref
                                      : _vm.rootJob.sourceReference
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.rootJob.tripId
                    ? _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Trip Details",
                                  params: { tripId: _vm.rootJob.tripId },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.referenceHeaderValue.key ===
                                      "reference-number" ||
                                      _vm.rootJob.sourceReference === null ||
                                      _vm.rootJob.sourceReference === ""
                                      ? _vm.rootJob.ref
                                      : _vm.rootJob.sourceReference
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("span", { staticClass: "trip-date" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.rootJob.tripDate,
                      _vm.DATE_TYPES.standardDate
                    )
                  )
                ),
              ]),
              _c("span", { staticClass: "customer-name" }, [
                _vm._v(_vm._s(_vm.rootJob.customerName)),
              ]),
              _c(
                "div",
                {
                  staticClass: "time",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openUpdateTimeModal($event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.getTimeValue()) +
                          "\n                "
                      ),
                      _vm.rootJob.tripId
                        ? _c("md-icon", { staticClass: "edit-icon" }, [
                            _vm._v(
                              "\n                    edit\n                "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "CustomTooltip",
                        _vm._l(
                          _vm.getTimeTooltipText(),
                          function (time, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "time-tooltip-content",
                              },
                              [
                                _c("b", [_vm._v(_vm._s(time.label))]),
                                _c("span", [_vm._v(_vm._s(time.value))]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "status" },
                [
                  _c("JobStatus", {
                    attrs: {
                      variant: _vm.getStatusNormalized(_vm.rootJob.status),
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "driver-name" }, [
                _vm.rootJob.assignedTo.publicUserId
                  ? _c(
                      "span",
                      { staticClass: "custom-ellipsis", attrs: { href: "#" } },
                      [
                        _vm.rootJob.assignedTo.fullName
                          ? _c("img", {
                              staticClass: "profile-image",
                              attrs: {
                                src:
                                  _vm.rootJob.assignedTo.photoUrl !== null
                                    ? _vm.rootJob.assignedTo.photoUrl
                                    : _vm.$root.defaultPhotoUrl,
                                alt: "avatar",
                              },
                              on: { error: _vm.$_setDefaultBrokenImage },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.rootJob.assignedTo.fullName) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "span",
                { staticClass: "charge-container" },
                [
                  _vm.rootJob.totalCharge < 0
                    ? _c("span", { staticClass: "charge-span" }, [
                        _vm._v(
                          "\n                (" +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.getTotalCharge,
                                _vm.rootJob.currency
                              )
                            ) +
                            ")\n            "
                        ),
                      ])
                    : _c("span", { staticClass: "charge-span" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.getTotalCharge,
                                _vm.rootJob.currency
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                  _vm.rootJob.customerInvoiceId
                    ? _c("md-icon", { staticClass: "charge-icon" }, [
                        _vm._v("receipt_long"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "cost-container" },
                [
                  _vm.rootJob.totalCost < 0
                    ? _c("span", { staticClass: "cost-span" }, [
                        _vm._v(
                          "\n                (" +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.getTotalCost,
                                _vm.rootJob.currency
                              )
                            ) +
                            ")\n            "
                        ),
                      ])
                    : _c("span", { staticClass: "cost-span" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.getTotalCost,
                                _vm.rootJob.currency
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                  _vm.rootJob.teamMemberInvoiceId
                    ? _c("md-icon", { staticClass: "cost-icon" }, [
                        _vm._v("receipt_long"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("span", { staticClass: "margin" }, [
                _vm.rootJob.margin < 0
                  ? _c("span", [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getMargin,
                              _vm.rootJob.currency
                            )
                          ) +
                          ")"
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getMargin,
                              _vm.rootJob.currency
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "actions" }, [
                !_vm.rootJob.isRecalculating
                  ? _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-success md-just-icon md-round recalculate-icon",
                            attrs: {
                              title: "Update Rates",
                              disabled: !_vm.rootJob.rateGroupId,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.updateRates($event)
                              },
                            },
                          },
                          [_c("md-icon", [_vm._v("currency_exchange")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "sync-loader" },
                      [_c("straight-line-loader")],
                      1
                    ),
              ]),
              _vm.hasExpandedRow
                ? [
                    _c("div", { staticClass: "more-info" }, [
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v("Address")]),
                        _c(
                          "span",
                          [
                            _vm._l(_vm.addresses, function (address, index) {
                              return _c("span", { key: index }, [
                                _c("span", [
                                  _c("b", [
                                    _vm.rootJob.pickupStopId &&
                                    _vm.rootJob.dropStopId
                                      ? _c(
                                          "span",
                                          [
                                            _vm.rootJob.pickupStopId &&
                                            address.label === "Pickup Address"
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Stop Details",
                                                        params: {
                                                          stopId:
                                                            _vm.rootJob
                                                              .pickupStopId,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(address.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.rootJob.dropStopId &&
                                            address.label === "Drop Address"
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to: {
                                                        name: "Stop Details",
                                                        params: {
                                                          stopId:
                                                            _vm.rootJob
                                                              .dropStopId,
                                                        },
                                                      },
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(address.label) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(_vm._s(address.label)),
                                        ]),
                                  ]),
                                  _vm._v(
                                    "\n                                : " +
                                      _vm._s(address.address) +
                                      "\n                            "
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }),
                            !_vm.rootJob.stopId
                              ? _c(
                                  "CustomTooltip",
                                  _vm._l(
                                    _vm.addresses,
                                    function (address, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "address-tooltip-content",
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(_vm._s(address.label)),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(address.address)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v(_vm._s(_vm.getTimeLabel()))]),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.getTimeValue()) +
                                "\n                        "
                            ),
                            _c(
                              "CustomTooltip",
                              _vm._l(
                                _vm.getTimeTooltipText(),
                                function (time, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "time-tooltip-content",
                                    },
                                    [
                                      _c("b", [_vm._v(_vm._s(time.label))]),
                                      _c("span", [_vm._v(_vm._s(time.value))]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v("Service Package")]),
                        _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.rootJob.rateGroupName) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                      _vm.hasHourlyRate
                        ? _c("div", { staticClass: "section-item" }, [
                            _c("span", [_vm._v("Total Chargeable Hours")]),
                            _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.rootJob.chargeableHours) +
                                  "\n                    "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.rootJob.loadDetails
                        ? _c("div", { staticClass: "load-form" }, [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "section-item" },
                                [
                                  _c("md-icon", [
                                    _c("img", {
                                      staticClass: "capacity-images",
                                      attrs: {
                                        src: "/img/icons/capacity-items-gray.png",
                                      },
                                    }),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCapacityInfo(
                                          _vm.rootJob.loadDetails.quantity,
                                          "items",
                                          "quantity"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "section-item" },
                                [
                                  _c("md-icon", [
                                    _c("img", {
                                      staticClass: "capacity-images",
                                      attrs: {
                                        src: "/img/icons/capacity-volume-gray.png",
                                      },
                                    }),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCapacityInfo(
                                          _vm.rootJob.loadDetails.volume,
                                          "m3",
                                          "volume"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "section-item" },
                                [
                                  _c("md-icon", [
                                    _c("img", {
                                      staticClass: "capacity-images",
                                      attrs: {
                                        src: "/img/icons/capacity-weight-gray.png",
                                      },
                                    }),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCapacityInfo(
                                          _vm.rootJob.loadDetails.weight,
                                          "kg",
                                          "weight"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.rootJob.serviceLines.length > 0
                      ? _c("div", { staticClass: "service-form" }, [
                          _c("div", [
                            _vm._m(0),
                            _c(
                              "div",
                              { staticClass: "section-item" },
                              [
                                _c(
                                  "md-tooltip",
                                  { attrs: { "md-direction": "top" } },
                                  [
                                    _c("b", [_vm._v("Services:")]),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.getServiceNames) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getServiceNames)),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "total-form" }, [
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v("Total Charge:")]),
                        _vm.rootJob.totalCharge < 0
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.getTotalCharge,
                                      _vm.rootJob.currency
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getTotalCharge,
                                    _vm.rootJob.currency
                                  )
                                )
                              ),
                            ]),
                      ]),
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v("Total Cost:")]),
                        _vm.rootJob.totalCost < 0
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.getTotalCost,
                                      _vm.rootJob.currency
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getTotalCost,
                                    _vm.rootJob.currency
                                  )
                                )
                              ),
                            ]),
                      ]),
                      _c("div", { staticClass: "section-item" }, [
                        _c("span", [_vm._v("Margin")]),
                        _vm.rootJob.margin < 0
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.getMargin,
                                      _vm.rootJob.currency
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getMargin,
                                    _vm.rootJob.currency
                                  )
                                )
                              ),
                            ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.hasExpandedRow
            ? _c(
                "div",
                { staticClass: "nested-table-container" },
                [_vm._t("nested-table")],
                2
              )
            : _vm._e(),
          _c("div", {
            staticStyle: { height: "1px", width: "100%", background: "#eee" },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("span", [_vm._v("Services:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }