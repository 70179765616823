<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
                <md-icon>description</md-icon>
            </div>
        </md-card-header>

        <md-card-content>
            <md-table class="custom-paginated-table">
                <div class="alert-section" v-if="showAlert">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <div>
                            <div>
                                <strong>ATTENTION!</strong>
                                Please click the save button in order to save the changes made.
                            </div>
                        </div>
                        <div>
                            <md-button
                                title="Save new order of stops"
                                class="md-primary md-just-icon md-round"
                                @click.stop="saveRunStopOrder()"
                            >
                                <md-icon>save</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
                <draggable
                    v-model="runDetails.stops"
                    draggable=".drag-item"
                    class="custom-draggable show-draggable"
                    @change="stopOrderChanged"
                >
                    <md-table-row slot="header">
                        <md-table-head class="drag-icon"></md-table-head>
                        <md-table-head class="stop-address">Address</md-table-head>
                        <md-table-head class="stop-contact">Contact</md-table-head>
                        <md-table-head class="stop-actions">Actions</md-table-head>
                    </md-table-row>

                    <md-table-row
                        v-for="(stopItem, stopIndex) in runDetails.stops"
                        :key="stopIndex"
                        class="drag-item"
                        :class="stopItem.isStartStop || stopItem.isEndStop ? 'startend-table-row' : ''"
                    >
                        <md-table-cell class="drag-icon">
                            <md-icon>drag_indicator</md-icon>
                        </md-table-cell>
                        <md-table-cell class="stop-address">
                            <span class="custom-ellipsis">{{ $_displayAddressName(stopItem) }}</span>
                        </md-table-cell>
                        <md-table-cell class="stop-contact">
                            <span class="custom-ellipsis">
                                {{ stopItem.contact != null ? stopItem.contact.name : '' }}
                            </span>
                        </md-table-cell>
                        <md-table-cell class="action-buttons">
                            <md-button
                                title="Edit stop details"
                                class="md-button md-primary md-just-icon md-round btn-size-27"
                                @click.stop="updateRunStop(stopItem)"
                            >
                                <md-icon>edit</md-icon>
                            </md-button>
                            <md-button
                                title="Delete stop"
                                class="md-danger md-just-icon md-round btn-size-27"
                                @click.stop="handleDeleteRunStop(stopItem)"
                            >
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </draggable>
            </md-table>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import CreateRunStopModal from './CreateRunStopModal';

export default {
    name: 'StopListTable',
    mixins: [GeneralMixin],
    props: {
        runDetails: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showAlert: false
        };
    },
    methods: {
        updateRunStop(runStopDetail) {
            this.$modal
                .show(CreateRunStopModal, {
                    runStopDetails: runStopDetail,
                    runScheduleCustomerId: this.runDetails.customerId,
                    isUpdate: true
                })
                .then((response) => {
                    this.$emit('runStopUpdated', response);
                    this.$modal.hide();
                });
        },
        async handleDeleteRunStop(runStopDetail) {
            const message = 'Are you sure you want to delete this stop?';
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Stop',
                    body: message,
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        const onSuccess = this.handleConfirmDelete.bind(
                            this,
                            runStopDetail.runScheduleId,
                            runStopDetail.runScheduleStopId
                        );
                        await this.$emit(
                            'verifyPendingTripExistsOnRunSchedule',
                            runStopDetail.runScheduleId,
                            onSuccess
                        );
                    }
                });
        },
        async handleConfirmDelete(runScheduleId, runScheduleStopId) {
            this.$_handleLoaderState(true, 'DELETING...');
            const payload = {
                method: 'delete'
            };
            const api = `/api/run-schedules/${runScheduleId}/run-stops/${runScheduleStopId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Stop was deleted!',
                    type: 'success'
                });
                this.$emit('deleteRunStop', true);
            } catch (e) {
                const message = 'Error in deleting a stop.';
                showErrorMessage(this, message, e);
                this.$emit('deleteRunStop', false);
            }
        },
        stopOrderChanged() {
            this.showAlert = true;
            this.$forceUpdate();
        },
        async saveRunStopOrder() {
            const obj = [];

            this.runDetails.stops.forEach((x, i) => {
                x.order = i + 1;

                obj.push({
                    runScheduleStopId: x.runScheduleStopId,
                    order: i + 1
                });
            });

            this.$_handleLoaderState(true, 'SAVING...');

            try {
                const endpoint = `/api/run-schedules/${this.runDetails.runScheduleId}/stop/reorder`;
                const payload = {
                    method: 'post',
                    data: {
                        RunStops: obj
                    }
                };
                await handleRequests(endpoint, payload);

                this.showAlert = false;
                this.$notify({
                    message: 'Successfully reordered stops.',
                    type: 'success'
                });
            } catch (error) {
                const message = 'Error in reordering of stops.';
                showErrorMessage(this, message, error);
            }

            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.stop-address,
.stop-contact,
.stop-notes {
    max-width: 200px;
}

.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
}

.custom-paginated-table {
    > ::v-deep div {
        overflow: auto;
        max-height: 400px;
    }
    ::v-deep th {
        position: sticky;
        top: 0;
    }
}

.startend-table-row {
    background-color: #fafafa;
}

@media (min-width: 1904px) {
    .custom-paginated-table {
        > ::v-deep div {
            overflow: auto;
            max-height: 600px;
        }
    }
}

.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.drag-item {
    cursor: grab;
}

.show-draggable {
    tr > .drag-icon {
        width: 2%;
    }
}

.stop-actions {
    width: 200px;
}

.alert-section {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
    > div {
        padding: 8px 20px;
        > div {
            display: inline-block;
            vertical-align: middle;
            button {
                height: 30px;
                min-width: 30px;
                width: 30px;
            }
        }
    }
}
</style>
