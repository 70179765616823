var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-card-content",
        [
          _c("md-table", {
            staticClass: "table-hover",
            attrs: { value: _vm.driveData },
            scopedSlots: _vm._u([
              {
                key: "md-table-row",
                fn: function (ref) {
                  var item = ref.item
                  var index = ref.index
                  return _c(
                    "md-table-row",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.viewAddress(item)
                        },
                      },
                    },
                    [
                      _c("md-table-cell", { attrs: { "md-label": "ID" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(index + 1) +
                            "\n                "
                        ),
                      ]),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Date / time", "md-sort": "" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("timeFormat")(
                                  _vm.displayDateTime(item),
                                  _vm.DATE_TYPES.standardTime
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Location", "md-sort": "" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "address",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.viewAddress(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.displayAddressOrCoord(item)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        {
                          attrs: { "md-label": "Stop duration", "md-sort": "" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.toTime(item.stopTimeMinutes)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _c(
                        "md-table-cell",
                        { attrs: { "md-label": "Device IMEI" } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.deviceIMIE) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("div", { staticClass: "label-total" }, [
            _c("span", [
              _vm._v("Total Stops: " + _vm._s(_vm.driveData.length)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }