import axios from 'axios';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store/store';

const { config } = require(`../config/config.${process.env.NODE_ENV}`); // eslint-disable-line import/no-dynamic-require

export class Timer {
    constructor(fn, t) {
        let timerObj = setInterval(fn, t);
        this.stop = () => {
            if (timerObj) {
                clearInterval(timerObj);
                timerObj = null;
            }
            return this;
        };
        this.start = () => {
            if (!timerObj) {
                this.stop();
                timerObj = setInterval(fn, t);
            }
            return this;
        };
    }
}

// https://stackoverflow.com/questions/2283566/how-can-i-round-a-number-in-javascript-tofixed-returns-a-string
export function toFixedNumber(num, digits, base = 10) {
    // eslint-disable-next-line no-restricted-properties
    const pow = Math.pow(base, digits);
    return Math.round(num * pow) / pow;
}

export function getSpeedMarkerColour(speed) {
    switch (true) {
        case speed <= 5:
            return { fillColor: '#DCE4EE ', strokeColor: '#303842' };
        case speed > 5 && speed <= 60:
            return { fillColor: '#0cdb8f', strokeColor: '#303842' };
        case speed > 60 && speed <= 90:
            return { fillColor: '#ff8629', strokeColor: '#303842' };
        case speed > 90 && speed <= 110:
            return { fillColor: '#fb1908', strokeColor: '#303842' };
        case speed > 110:
            return { fillColor: '#700069', strokeColor: '#303842' };
        default:
            return { fillColor: '#000', strokeColor: '#303842' };
    }
}

export function setColour(status) {
    let color;
    switch (status.toLowerCase()) {
        case 'pending':
            color = '#c2907f';
            break;
        case 'enroute':
            color = '#4ed2ff';
            break;
        case 'arrived':
            color = '#2b93ff';
            break;
        case 'departed':
            color = '#ffb42b';
            break;
        case 'cancelled':
            color = '#ff5245';
            break;
        case 'failed':
            color = '#f44336';
            break;
        case 'delayed':
        case 'on hold':
            color = '#384553';
            break;
        case 'complete':
            color = '#0bda8e';
            break;
        case 'started':
            color = '#4ED2FF';
            break;
        default:
        // do nothing
    }
    return color;
}

export function getStopStatusColour(status) {
    switch (status.toLowerCase()) {
        case 'pending':
            return {
                fillColor: '#c2907f',
                strokeColor: '#c2907f'
            };
        case 'enroute':
            return {
                fillColor: '#4ed2ff',
                strokeColor: '#4ed2ff'
            };
        case 'arrived':
            return {
                fillColor: '#2b93ff',
                strokeColor: '#2b93ff'
            };
        case 'departed':
            return {
                fillColor: '#ffb42b',
                strokeColor: '#ffb42b'
            };
        case 'complete':
            return {
                fillColor: '#0bda8e',
                strokeColor: '#0bda8e'
            };
        case 'delayed':
            return {
                fillColor: '#384553',
                strokeColor: '#384553'
            };
        case 'cancelled':
            return {
                fillColor: '#ff5245',
                strokeColor: '#ff5245'
            };
        default: {
            return {};
        }
    }
}

export function getShipmentStatusColour(status) {
    switch (status.toLowerCase()) {
        case 'pending':
            return {
                fillColor: '#c2907f',
                strokeColor: '#c2907f'
            };
        case 'enroute to pickup':
            return {
                fillColor: '#4ed2ff',
                strokeColor: '#4ed2ff'
            };
        case 'arrived at pickup':
            return {
                fillColor: '#2b93ff',
                strokeColor: '#2b93ff'
            };
        case 'enroute to drop':
            return {
                fillColor: '#4ed2ff',
                strokeColor: '#4ed2ff'
            };
        case 'arrived at drop':
            return {
                fillColor: '#2b93ff',
                strokeColor: '#2b93ff'
            };
        case 'departed':
            return {
                fillColor: '#ffb42b',
                strokeColor: '#ffb42b'
            };
        case 'picked up':
            return {
                fillColor: '#0bda8e',
                strokeColor: '#0bda8e'
            };
        case 'complete':
            return {
                fillColor: '#0bda8e',
                strokeColor: '#0bda8e'
            };
        case 'delayed':
            return {
                fillColor: '#384553',
                strokeColor: '#384553'
            };
        case 'cancelled':
            return {
                fillColor: '#ff5245',
                strokeColor: '#ff5245'
            };
        default: {
            return {};
        }
    }
}

const unlicensedTeamAlertMessage = {
    class: 'sm-modal-container',
    title: 'Manage Billing Online - Unavailable',
    body: `If you are paying by invoice or have an enterprise agreement, please contact our <a style="color:#2b93ff;" href="mailto:sales@locate2u.com">Sales Team</a> or your account manager.
    <br />
    <br />
    
    If you have a mobile app store subscription, you will need to manage your billing there.
    <br />
    <br />
    
    Contact our <a style = "color:#2b93ff;" href = "mailto:support@locate2u.com">Support Team</a> if neither of the above apply to you.
    `,
    buttons: ['OK']
};

const unlicensedStandaloneAlertMessage = {
    class: 'sm-modal-container',
    title: 'Upgrade Request',
    body: `Your upgrade request has been received. One of our team will reach out to you.`,
    buttons: ['OK']
};

export async function handleRequests(endpoint, payload = { method: 'get' }, isLoggedIn = true) {
    // If entry for Backend server is found,
    // the dev server will connect to cloud server for api hits
    const url =
        process.env.VUE_APP_BACKEND_SERVER && endpoint.startsWith('/api/')
            ? process.env.VUE_APP_BACKEND_SERVER + endpoint
            : endpoint;

    const request = Object.assign({}, payload, {
        url
    });

    if ((payload.method !== 'put' && isLoggedIn) || (payload.method === 'put' && payload.headers === undefined)) {
        const user = await window.auth.getUser();
        const accessToken = user.access_token;
        request.headers = Object.assign({}, payload.headers || {}, {
            Authorization: `Bearer ${accessToken}`
        });
    }

    return new Promise((resolve, reject) => {
        axios(request).then(
            (r) => {
                const { data, status } = r;
                resolve({ data, status });
            },
            (e) => {
                if (!e.response) 
                    return;

                const { data, status } = e.response;

                if (data && Array.isArray(data) && data.length) {
                    const unlicensed = data.find((x) => x.errorCode === 'MUST_HAVE_LICENSE');
                    if (unlicensed) {
                        // hide the loader (just in case it's loaded)
                        const loader = {
                            isLoading: false,
                            loadingText: null
                        };
                        store.commit('CHANGE_LOADING_STATE', loader, { root: true });

                        let alertMessage = unlicensedTeamAlertMessage;
                        if (!store.getters['user/hasTeam']) {
                            alertMessage = unlicensedStandaloneAlertMessage;
                        }

                        window.messageBox
                            .show(alertMessage)
                            // eslint-disable-next-line consistent-return
                            .then((response) => {
                                if (response && response === 'OK') {
                                    // eslint-disable-next-line no-use-before-define
                                    return redirectToBillingPortal(config.billing_redirect_url, alertMessage);
                                }
                            });
                    }
                }
                /* eslint-disable prefer-promise-reject-errors */
                reject({ data, status });
            }
        );
    });
}

export async function redirectToBillingPortal(redirectUrl, alertMessage) {
    const api = `/api/teams/billing-portal-url/?redirectUrl=${redirectUrl}`;
    const payload = {
        method: 'get'
    };

    try {
        const { data } = await handleRequests(api, payload);
        window.location.href = data;
    } catch (error) {
        const showAlertMessage = alertMessage ?? unlicensedTeamAlertMessage;

        window.messageBox
            .show(showAlertMessage)
            // eslint-disable-next-line consistent-return
            .then((response) => {
                if (response && response === 'OK') {
                    return true;
                }
            });
    }
}

export async function downloadFile(data, reportName) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', reportName);
    document.body.appendChild(link);
    link.click();
}

export async function openFile(data, reportName, target = '_blank', type = 'application/pdf') {
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', target);
    document.body.appendChild(link);
    link.click();
}

export function getRegionCoordinates(regionCode) {
    // these are currently the only regions based from the regions code. will add more as we expand our coverage.
    const regions = {
        AU: {
            latitude: -25.2743988,
            longitude: 133.7751312
        },
        TR: {
            latitude: -25.2743988,
            longitude: 133.7751312
        },

        OCA: {
            latitude: -6.3149929,
            longitude: 143.9555511
        },
        IN: {
            latitude: 20.5936832,
            longitude: 78.962883
        },
        PH: {
            latitude: 12.8797207,
            longitude: 121.7740173
        },
        ZA: {
            latitude: -30.559482,
            longitude: 22.937506
        }
    };

    return Object.prototype.hasOwnProperty.call(regions, regionCode) ? regions[regionCode] : regions[TR];
}

export function imageCacheBuster(image) {
    let x = image;
    if (x != null) {
        x = `${x}?${new Date().getTime()}`;
    }
    return x;
}

export function isPhoneValid(phone) {
    if (!phone) {
        return true;
    }

    return /^[0-9*#+() -]+$/.test(phone);
}

export function minStopDuration(duration) {
    if (!duration) 
        return true;

    if (duration < 0) 
        return false;

    return true;
}

export function maxStopDuration(duration) {
    if (!duration) 
        return true;

    if (duration > 1440) 
        return false;

    return true;
}

export function isStopDurationNull(duration) {
    if (!duration) 
        return true;

    const regExp = /[a-zA-Z]/g;

    return !regExp.test(duration);
}

export function isValidEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function showErrorMessage(el, message, error) {
    if (error && error.data && error.data.length > 0) {
        error.data.forEach((e) => {
            el.$notify({
                message: e.message,
                type: 'danger'
            });
        });
    } else {
        el.$notify({
            message,
            type: 'danger'
        });
    }
}

export function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

export function isAValidCoordinate(coordinateString) {
    if (!coordinateString) {
        return false;
    }

    const expression = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
    return expression.test(coordinateString);
}

export function getShortLocaleStandardDate() {
    // get user locale based on browser's userLanguage
    // usetLanguage is for IE browsers, languages is for all other modern browsers
    const userLocale = window.navigator.userLanguage || window.navigator.language;

    // get long date without time format (moment.js has no local implementation)
    const localeData = moment.localeData(userLocale);
    const llll = localeData.longDateFormat('llll');
    const lll = localeData.longDateFormat('lll');
    const ll = localeData.longDateFormat('ll');

    return llll.replace(lll.replace(ll, ''), '');
}

export function convertUtcTimeStringToLocalDate(timeStamp, utcOffset) {
    const timeStampWithUtcRemoved = timeStamp.replace(/Z|z/, '');
    const convertedDate = new Date(timeStampWithUtcRemoved);

    // getTime in milliseconds so we have to convert utcOffset to milliseconds
    return new Date(convertedDate.getTime() + utcOffset * 60000);
}

export function camelCaseToSentenceCase(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

/* from harvestine formula
 * https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 */
export function getDistanceBetweenMarkers(mk1, mk2) {
    const R = 6371.071; // Radius of the Earth in km
    const rlat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

    const d =
        2 *
        R *
        Math.asin(
            Math.sqrt(
                Math.sin(difflat / 2) * Math.sin(difflat / 2) +
                    Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
            )
        );
    return d; // in km;
}
export function getTimeInMinutes(currentTime, offset) {
    // Note that in daylight saving switchover scenarios, this won't be the number of minutes since midnight
    const momentTime = moment(currentTime).utcOffset(offset);
    return momentTime.hour() * 60 + momentTime.minute();
}
export function getTimeInMs(timestamp) {
    const time = moment
        .utc(timestamp)
        .local()
        .format('HH:mm:ss')
        .split(':');
    // hours minutes seconds
    return (Number(time[0]) * 3600 + Number(time[1]) * 60 + Number(time[2])) * 1000;
}

export function showTeamRegionControl(currentStore, moduleTeamRegionId) {
    return currentStore.hasAssetAccess && currentStore.teamRegions && currentStore.teamRegions.length > 0;
    // return (
    //     !(currentStore.teamRegions && currentStore.teamRegions.length > 0) ||
    //     !!currentStore.user.teamRegionId ||
    //     !!moduleTeamRegionId
    // );
}

export function filterMembersByTeamRegionId(
    allTeamRegions,
    allRawMembers,
    defaults,
    teamRegionId,
    strictTeamRegionFilter
) {
    // no team members
    if (!Array.isArray(allRawMembers) || allRawMembers.length === 0) 
        return [];

    // update empty fullname to
    const allMembers = allRawMembers.map((x) => {
        if (!x.fullName || x.fullName === ' ') 
            return { ...x, fullName: x.email };
        return x;
    });

    // no team region setup
    if (!Array.isArray(allTeamRegions) || allTeamRegions.length === 0) {
        if (defaults) 
            return [defaults, ...allMembers];
        return [...allMembers];
    }

    // all members
    if (!teamRegionId || teamRegionId === 0) {
        if (defaults) 
            return [defaults, ...allMembers];
        return [...allMembers];
    }

    let filtered = [];
    if (teamRegionId === -1) {
        // only members without team region
        filtered = allMembers.filter((x) => {
            // null SHOULD suffice
            return x.teamRegionId === undefined || x.teamRegionId === null || x.teamRegionId === -1;
        });
    } else if (strictTeamRegionFilter) {
        // specific
        filtered = allMembers.filter((x) => {
            return x.teamRegionId === teamRegionId;
        });
    } else {
        // include members without TR set
        filtered = allMembers.filter((x) => {
            return !x.teamRegionId || x.teamRegionId === teamRegionId;
        });
    }

    // return at least the default
    if (defaults) 
        return [defaults, ...filtered];
    return [...filtered];
}

export function filterMemberOrCarrierssByTeamRegionId({
    allTeamRegions,
    allRawMembers,
    defaults,
    teamRegionId,
    strictTeamRegionFilter,
    allRawTeamCarriers,
    showChildTeamRegion,
    listOfTeamRegion
}) {
    let allMembers = [];
    let allTeamCarriers = [];

    // team members
    if (Array.isArray(allRawMembers) && allRawMembers.length > 0) {
        // update empty fullname to
        allMembers = allRawMembers.map((x) => {
            const fullName = !x.fullName || x.fullName === ' ' ? x.email : x.fullName;
            return {
                publicUserId: x.publicUserId,
                fullName,
                email: x.email,
                teamRegionId: x.teamRegionId,
                type: 'member',
                selectable: true,
                memberOrCarrierId: x.publicUserId
            };
        });
    }

    // team carriers
    if (Array.isArray(allRawTeamCarriers) && allRawTeamCarriers.length > 0) {
        allTeamCarriers = allRawTeamCarriers.map((x) => {
            return {
                fullName: x.carrierTeam.company,
                teamRegionId: x.teamRegionId,
                carrierTeamId: x.carrierTeamId,
                type: 'carrier',
                selectable: true,
                memberOrCarrierId: x.carrierTeamId
            };
        });
    }

    let allList = [];
    if (allMembers.length && allTeamCarriers.length) {
        allList = [...allMembers, { selectable: false }, ...allTeamCarriers];
    } else {
        allList = [...allMembers, ...allTeamCarriers];
    }

    // no team region setup
    if (!Array.isArray(allTeamRegions) || allTeamRegions.length === 0) {
        if (defaults) 
            return [defaults, ...allList];
        return [...allList];
    }

    // all members
    if (!teamRegionId || teamRegionId === 0) {
        if (defaults) 
            return [defaults, ...allList];
        return [...allList];
    }

    let filtered = [];
    if (teamRegionId === -1) {
        // only those without team region
        filtered = allList.filter((x) => {
            // null SHOULD suffice
            return !x.selectable || x.teamRegionId === undefined || x.teamRegionId === null || x.teamRegionId === -1;
        });
    } else if (strictTeamRegionFilter) {
        // specific
        filtered = allList.filter((x) => {
            return (
                !x.selectable ||
                x.teamRegionId === teamRegionId ||
                (showChildTeamRegion &&
                    allTeamRegions.some((y) => {
                        return y.teamRegionId === x.teamRegionId && y.parentTeamRegionId === teamRegionId;
                    }))
            );
        });
    } else {
        // include members and carriers without TR set
        filtered = allList.filter((x) => {
            return (
                !x.selectable ||
                !x.teamRegionId ||
                x.teamRegionId === teamRegionId ||
                listOfTeamRegion.includes(x.teamRegionId)
            );
        });
    }

    // return at least the default
    if (defaults) 
        return [defaults, ...filtered];
    return [...filtered];
}

export function getTeamRegionName(teamRegionId, teamRegions) {
    if (teamRegionId && teamRegions) {
        const tr = teamRegions.find((x) => x.teamRegionId === teamRegionId);
        return tr?.name;
    }
    return null;
}

export class CarrierPreBookingQuestionsMapper {
    mapAsync = async (carrierTeamId) => {
        try {
            const api = `/api/teams/carriers/?carrierTeamId=${carrierTeamId}`;
            const { data } = await handleRequests(api);

            if (
                data.carrierTeam &&
                data.carrierTeam.questions &&
                data.carrierTeam.questions.booking &&
                data.carrierTeam.questions.booking.length
            ) {
                const result = this.map({
                    carrierBookingQuestions: data.carrierTeam.questions.booking,
                    customerMappings: data.questionCustomFieldsMapping
                });

                return {
                    ...result,
                    success: true
                };
            }

            return {
                fixedMappingList: [],
                newAnswersList: [],
                combinedList: [],
                success: true
            };
        } catch (error) {
            return {
                success: false,
                error
            };
        }
    };

    map = ({ carrierBookingQuestions, customerMappings }) => {
        const mappings = customerMappings || [];

        // list of questions that are mapped to custom fields
        // or has default and not overridable
        const fixedMappingList = mappings.filter(
            (x) => x.mapping === 'CustomField' || (x.mapping === 'SetDefault' && !x.overridable)
        );

        // find carrier questions that can be overridden or no mapping at all
        const overridableQuestions = carrierBookingQuestions.filter((q) => {
            return fixedMappingList.findIndex((m) => m.name === q.name) === -1;
        });

        const newAnswersList = [];
        for (let ndx = 0; ndx < overridableQuestions.length; ndx++) {
            const defaultAnswer = mappings.find((q) => q.name === overridableQuestions[ndx].name);
            if (defaultAnswer) {
                if (defaultAnswer.mapping && defaultAnswer.mapping !== 'Ignore') {
                    // populate with default answer
                    newAnswersList.push({
                        ...defaultAnswer,
                        defaultValue: this.parseValue(overridableQuestions[ndx].type, defaultAnswer.defaultValue),
                        ...overridableQuestions[ndx]
                    });
                }
            } else {
                // no mapping but we'll show if it's required
                // eslint-disable-next-line no-lonely-if
                if (overridableQuestions[ndx].isRequired) {
                    newAnswersList.push({
                        ...overridableQuestions[ndx],
                        defaultValue: null,
                        mapping: 'SetDefault' // there's no mapping; we'll set it 'SetDefault' in order to assign answer
                    });
                }
            }
        }

        // include questions that are mapped to custom fields
        const combinedList = this.combine(fixedMappingList, newAnswersList);
        return {
            fixedMappingList,
            newAnswersList,
            combinedList
        };
    };

    combine = (fixedMappingList, newAnswersList) => {
        const combined = [...fixedMappingList, ...newAnswersList];
        return combined.map((x) => {
            return {
                name: x.name,
                type: x.type,
                mapping: x.mapping,
                defaultValue: this.setValue(x),
                customFieldName: x.customFieldName
            };
        });
    };

    parseValue = (type, value) => {
        switch (type) {
            case 'CheckBox':
                return value === 'true';
            default:
                return value;
        }
    };

    setValue = (answer) => {
        if (answer.mapping === 'SetDefault') {
            if (answer.type === 'CheckBox') {
                return (answer.defaultValue || false).toString();
            }
            return answer.defaultValue ? answer.defaultValue.toString() : null;
        }

        if (answer.mapping === 'AskWhenBooking') {
            return answer.defaultValue ? answer.defaultValue.toString() : null;
        }

        return null;
    };
}

export class SearchGuard {
    constructor(filters = {}) {
        this.filters = filters;
    }

    // Aims to avoid calling the Api for the same parameters by
    // comparing the previous filters and new filters.
    // returns true if different, and false if the same
    shouldSearch = (newFilters) => {
        let shouldSearch = false;
        Object.keys(newFilters).forEach((q) => {
            shouldSearch = shouldSearch || (newFilters[q] || null) !== (this.filters[q] || null);
        });

        if (shouldSearch) {
            this.filters = Object.assign(this.filters, newFilters);
        }
        return shouldSearch;
    };
}

// sum of digits of a number
function sumDigit(value) {
    let number = value;
    let result = 0;
    while (number > 0) {
        result += number % 10;
        number = parseInt(number / 10, 10);
    }
    return result;
}
export function isValidIMEI(value) {
    let number = value;
    if (!number) {
        return false;
    }
    // Converting the number into
    // String for finding length
    const imeiString = number.toString();
    const len = imeiString.length;

    if (len !== 15) {
        return false;
    }

    let sum = 0;
    for (let i = len; i >= 1; i--) {
        let digit = number % 10;

        // Doubling every alternate digit
        if (i % 2 === 0) {
            digit *= 2;
        }

        // Finding sum of the digits
        sum += sumDigit(digit);
        number = parseInt(number / 10, 10);
    }

    const result = sum % 10 === 0;
    return result;
}

export function formatCondition(conditionRow, rateZones) {
    let tempConditionRow = conditionRow;
    const numberPattern = /\d+/g;

    if (tempConditionRow.includes('In') || tempConditionRow.includes('NotIn')) {
        const match = tempConditionRow.match(numberPattern);

        match.forEach((zoneId, index) => {
            const zone = rateZones.find((x) => x.rateZoneId === Number(zoneId));
            tempConditionRow = tempConditionRow.replaceAll(zoneId, zone.name);
        });
    }

    tempConditionRow = tempConditionRow.replaceAll('r.', '');
    tempConditionRow = tempConditionRow.replace('&&', '');
    tempConditionRow = tempConditionRow.replace('||', '');
    tempConditionRow = tempConditionRow.replaceAll('.', ' ');
    tempConditionRow = tempConditionRow.replace('==', '=');
    return tempConditionRow;
}

export function formatOperator(data) {
    if (data.includes('&&')) {
        return 'And';
    }

    if (data.includes('||')) {
        return 'Or';
    }

    return null;
}

export function checkForOperator(data) {
    if (data.includes('&&') || data.includes('||')) {
        return true;
    }

    return false;
}

export function isValidInvoiceNumberAffix(value) {
    if (value === null || value === '') {
        return true;
    }

    const regExp = /^[A-Za-z0-9-]+$/;

    return regExp.test(value);
}

export function titleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(' ');
}
