<template>
    <div>
        <div class="detail-container">
            <div class="close-button">
                <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <div v-if="!loading">
                <div class="detail-section text-center">
                    <span class="title blue-ref" v-if="shipmentDetails.shipmentRef != null">
                        <router-link
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: shipmentDetails.shipmentId }
                            }"
                            target="_blank"
                        >
                            {{ shipmentDetails.shipmentRef }}
                        </router-link>
                    </span>
                    <span class="title" v-else>
                        <router-link
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: shipmentDetails.shipmentId }
                            }"
                        >
                            {{ shipmentDetails.id }}
                        </router-link>
                    </span>
                    <div class="detail-group text-center action-detail-group">
                        <img
                            v-if="shipmentDetails.assignedTo.fullName"
                            class="profile-image"
                            :src="
                                shipmentDetails.assignedTo.photoUrl !== null
                                    ? shipmentDetails.assignedTo.photoUrl
                                    : $root.defaultPhotoUrl
                            "
                            alt="avatar"
                        />
                        <img
                            v-if="shipmentDetails.carrierTeam != null"
                            class="profile-image"
                            :src="
                                shipmentDetails.carrierTeam.logoUrl !== null
                                    ? shipmentDetails.carrierTeam.logoUrl
                                    : $root.defaultLogoUrl
                            "
                            alt="avatar"
                        />
                        <router-link
                            title="View shipment details"
                            class="md-button md-success md-just-icon md-round"
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: shipmentDetails.shipmentId }
                            }"
                            target="_blank"
                        >
                            <div class="md-ripple">
                                <div class="md-button-content">
                                    <md-icon>visibility</md-icon>
                                </div>
                            </div>
                        </router-link>
                        <template v-if="isCarrierAssigned">
                            <a
                                v-if="carrierTrackingUrl"
                                class="md-button md-primary md-just-icon md-round"
                                :href="carrierTrackingUrl"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>place</md-icon>
                                    </div>
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <a
                                v-if="shipmentDetails.trackingLinkUrl"
                                class="md-button md-primary md-just-icon md-round"
                                :href="setTrackingLinkUrl(shipmentDetails.trackingLinkUrl)"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>place</md-icon>
                                    </div>
                                </div>
                            </a>
                            <a
                                v-else-if="shipmentDetails.trackingLinkUrl == null && !isReadOnlyUser"
                                class="md-button md-default md-just-icon md-round"
                                @click="createTrackingLink(shipmentDetails)"
                                target="_blank"
                            >
                                <div class="md-ripple">
                                    <div class="md-button-content">
                                        <md-icon>add_location_alt</md-icon>
                                    </div>
                                </div>
                            </a>
                        </template>
                        <QuotesButton
                            v-if="shipmentDetails.carrierTeam != null"
                            @assignedUser="assignShipment"
                            class="quotes-button"
                            :shipment="shipmentDetails"
                            type="icon"
                        />
                        <update-shipment-button
                            v-if="!isReadOnlyUser"
                            :shipment="shipmentDetails.shipmentId"
                            :team-members="teamMembers"
                            :carrier-team-id="shipmentDetails.carrierTeamId"
                            @shipmentUpdated="updateShipment"
                        />
                        <reassign-shipment-button
                            v-if="!isSingleUser && !isSingleTeamMember && !isReadOnlyUser"
                            type="icon"
                            class-name="small-icon"
                            :item="shipmentDetails"
                            :team-members="teamMembers"
                            @assignedUser="assignShipment"
                        />
                        <delete-shipment-button
                            v-if="!isReadOnlyUser"
                            :shipment-id="shipmentDetails.shipmentId"
                            :team-members="teamMembers"
                            @shipmentDeleted="deleteShipment"
                            :has-attached-items="shipmentDetails.pickupStop.stopItems.length > 0"
                        />
                        <CarrierDownloadLabelButton
                            v-if="isCarrierAssigned && carrierLabels.length"
                            :use-icon="true"
                            :labels="carrierLabels"
                        />
                        <generate-label-button
                            v-if="shipmentDetails.pickupStop.stopItems.length > 0"
                            :id="shipmentDetails.shipmentId"
                            :type="'Shipment'"
                        />
                        <stop-pdf-download-button
                            v-if="!shipmentDetails.carrierTeamId"
                            :status="shipmentDetails.status.toLowerCase()"
                            :stop-id="shipmentDetails.dropStop.stopId"
                            :stop-ref="shipmentDetails.dropStop.stopRef"
                        />
                    </div>
                </div>
                <div class="line-separator"></div>
                <div
                    class="detail-section"
                    v-if="shipmentDetails.quotingStatus != null && shipmentDetails.quotingStatus != 'JobAssigned'"
                >
                    <div class="detail-group quoting-container">
                        <label class="label">Quoting Status:</label>
                        <div class="value">
                            <span
                                class="custom-badge quoting-badge"
                                :class="`status-${$_getQuotingStatusBadgeColor(shipmentDetails.quotingStatus)}`"
                            >
                                {{ $_getQuotingStatusText(shipmentDetails.quotingStatus) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="shipmentDetails.quotingStatus != null && shipmentDetails.quotingStatus != 'JobAssigned'"
                ></div>
                <div
                    class="detail-section"
                    v-if="shipmentDetails.carrierTeam != null && shipmentDetails.assignedCarrierBooking != null"
                >
                    <span class="title">Carrier Details</span>
                    <div class="detail-group">
                        <label class="label">Carrier:</label>
                        <span class="value">
                            {{ shipmentDetails.carrierTeam.company }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Reference #:</label>
                        <span class="value">
                            {{ shipmentDetails.assignedCarrierBooking.supportReference }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Service Type:</label>
                        <span class="value">
                            <span
                                class="custom-badge"
                                :class="
                                    `status-${$_getServiceLevelBadgeColor(
                                        shipmentDetails.assignedCarrierBooking.timeWindowEnd
                                    )}`
                                "
                            >
                                {{ $t(shipmentDetails.assignedCarrierBooking.serviceLevel) }}
                            </span>
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Price:</label>
                        <span class="value">
                            {{
                                shipmentDetails.assignedCarrierBooking.feeAfterTax
                                    | currency(shipmentDetails.assignedCarrierBooking.currency)
                            }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Pickup ETA:</label>
                        <span class="value" v-if="shipmentDetails.assignedCarrierBooking.pickupEta">
                            {{
                                shipmentDetails.assignedCarrierBooking.pickupEta
                                    | dateTimeFormat(DATE_TYPES.standardDate)
                            }}
                        </span>
                        <span v-else class="not-available">Not available</span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Drop ETA:</label>
                        <span class="value" v-if="shipmentDetails.assignedCarrierBooking.dropOffEta">
                            {{
                                shipmentDetails.assignedCarrierBooking.dropOffEta
                                    | dateTimeFormat(DATE_TYPES.standardDate)
                            }}
                        </span>
                        <span v-else class="not-available">Not available</span>
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="shipmentDetails.carrierTeam != null && shipmentDetails.assignedCarrierBooking != null"
                ></div>
                <div class="detail-section">
                    <span class="title">Shipment Details</span>
                    <div class="detail-group">
                        <label class="label">Status:</label>
                        <span class="value">
                            <shipment-status-button :shipment="shipmentDetails" @statusUpdated="changeShipmentStatus" />
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">When:</label>
                        <div class="value">
                            <span
                                v-if="
                                    shipmentDetails.tripDate == null &&
                                        shipmentDetails.appointmentTime == null &&
                                        shipmentDetails.timeWindowStart == null &&
                                        shipmentDetails.timeWindowEnd == null
                                "
                            >
                                <i>Unscheduled</i>
                            </span>
                            <span
                                v-if="
                                    shipmentDetails.tripDate != null &&
                                        (shipmentDetails.dropStop.tripDate == null ||
                                            shipmentDetails.pickupStop.tripDate === shipmentDetails.dropStop.tripDate)
                                "
                            >
                                <router-link
                                    v-if="shipmentDetails.tripId && !isCarrierAssigned"
                                    :to="{
                                        name: 'Trip Details',
                                        params: {
                                            tripId: shipmentDetails.tripId
                                        }
                                    }"
                                    target="_blank"
                                >
                                    {{ shipmentDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </router-link>
                                <span v-else>
                                    {{ shipmentDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </span>
                            <span v-else>
                                <router-link
                                    v-if="shipmentDetails.tripId && !isCarrierAssigned"
                                    :to="{
                                        name: 'Trip Details',
                                        params: {
                                            tripId: shipmentDetails.tripId
                                        }
                                    }"
                                    target="_blank"
                                >
                                    {{ shipmentDetails.pickupStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                    <strong>-></strong>
                                    {{ shipmentDetails.dropStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </router-link>
                                <span v-else>
                                    {{ shipmentDetails.pickupStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                    <strong>-></strong>
                                    {{ shipmentDetails.dropStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div v-if="teamRegionName" class="detail-group">
                        <label class="label">Team Region:</label>
                        <span class="value">
                            {{ teamRegionName }}
                        </span>
                    </div>

                    <div class="detail-group" v-if="shipmentDetails.runNumber">
                        <label class="label">Run:</label>
                        <span class="value">
                            {{ shipmentDetails.runNumber }}
                        </span>
                    </div>
                </div>
                <div class="detail-section contact-detail-section">
                    <span class="title">Contact Details</span>
                    <div class="detail-group">
                        <label class="label">Name:</label>
                        <span class="value">
                            {{ shipmentDetails.contact.name }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Phone:</label>
                        <a class="value link" :href="`tel:${shipmentDetails.contact.name}`">
                            {{ shipmentDetails.contact.phone }}
                        </a>
                    </div>
                    <div class="detail-group">
                        <label class="label">Email:</label>
                        <span class="value">
                            {{ shipmentDetails.contact.email }}
                        </span>
                    </div>
                </div>

                <div class="detail-section" v-if="shipmentDetails.brandId != null">
                    <span class="title">Brand Details</span>
                    <img
                        v-if="shipmentDetails.brandLogoUrl"
                        class="brand-logo"
                        :src="
                            shipmentDetails.brandLogoUrl !== null ? shipmentDetails.brandLogoUrl : $root.defaultPhotoUrl
                        "
                        @error="$_setDefaultBrokenImage"
                        :title="shipmentDetails.brandName"
                        :alt="shipmentDetails.brandName"
                    />
                    <span v-if="!shipmentDetails.brandLogoUrl">{{ shipmentDetails.brandName }}</span>
                </div>

                <div class="line-separator" v-if="shipmentDetails.skills.length > 0"></div>
                <div class="detail-section contact-detail-section" v-if="shipmentDetails.skills.length > 0">
                    <span class="title">Required Skills</span>
                    <div class="detail-group md-chips md-primary">
                        <div class="md-chip" v-for="(skill, index) in shipmentDetails.skills" :key="index">
                            {{ skill }}
                        </div>
                    </div>
                </div>
                <div class="line-separator" v-if="shipmentCustomFieldList.length"></div>
                <div class="detail-section contact-detail-section" v-if="shipmentCustomFieldList.length">
                    <span class="title">Custom fields</span>
                    <div class="detail-group" v-for="(customField, index) in shipmentCustomFieldList" :key="index">
                        <span class="label">{{ customField.label }}:</span>
                        <div
                            class="value"
                            v-if="customFieldValues !== null && customFieldValues[customField.name] !== null"
                        >
                            <span v-if="customField.type.toLowerCase() === 'date'">
                                {{ customFieldValues[customField.name] | dateTimeFormat(DATE_TYPES.standardDate) }}
                            </span>
                            <span v-else>{{ customFieldValues[customField.name] }}</span>
                        </div>
                    </div>
                </div>
                <div class="line-separator" v-if="loadCapacityList.length"></div>
                <div class="detail-section contact-detail-section" v-if="loadCapacityList.length">
                    <span class="title">Load</span>
                    <div class="detail-group" v-for="(loadCapacity, index) in loadCapacityList" :key="index">
                        <div v-if="loadCapacity.type !== 'dimensions'">
                            <span class="label">{{ loadCapacity.label }}:</span>
                            <div class="value" v-if="loadValues !== null && loadValues[loadCapacity.type] !== null">
                                {{ loadValues[loadCapacity.type] }} {{ loadCapacity.symbol }}
                            </div>
                        </div>
                        <div v-if="loadCapacity.type === 'dimensions'">
                            <span class="label">{{ loadCapacity.label }}</span>
                            <div class="value" v-if="loadValues !== null && loadValues[loadCapacity.type] !== null">
                                L: {{ loadValues['length'] }} {{ loadCapacity.symbol }} W: {{ loadValues['width'] }}
                                {{ loadCapacity.symbol }} H: {{ loadValues['height'] }} {{ loadCapacity.symbol }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Pickup Details</span>
                    <div class="detail-group">
                        <label class="label">Pickup Ref:</label>
                        <div class="value">
                            <router-link
                                :to="{
                                    name: 'Stop Details',
                                    params: { stopId: shipmentDetails.pickupStop.stopId }
                                }"
                                target="_blank"
                            >
                                {{ shipmentDetails.pickupStop.stopRef }}
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="detail-group"
                        v-if="
                            shipmentDetails.pickupStop.tripDate != null &&
                                shipmentDetails.pickupStop.tripDate !== shipmentDetails.dropStop.tripDate
                        "
                    >
                        <label class="label">When:</label>
                        <div class="value">
                            {{ shipmentDetails.pickupStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                        </div>
                    </div>
                    <div
                        class="detail-group"
                        v-if="
                            shipmentDetails.pickupStop.originalEta != null &&
                                shipmentDetails.pickupStop.status != 'Arrived' &&
                                shipmentDetails.pickupStop.status != 'Departed' &&
                                shipmentDetails.pickupStop.status != 'Complete'
                        "
                    >
                        <label class="label">ETA:</label>
                        <span class="value">
                            {{ shipmentDetails.pickupStop.originalEta | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Address:</label>
                        <div class="value">
                            <div
                                v-if="
                                    shipmentDetails.pickupStop.name &&
                                        !$_validateCoordinate(shipmentDetails.pickupStop.address)
                                "
                                class="address-name"
                            >
                                {{ shipmentDetails.pickupStop.name }}
                            </div>
                            <a class="address" @click.prevent="viewAddress(true)">
                                {{ $_displayAddressName(shipmentDetails.pickupStop) }}
                            </a>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">Duration:</label>
                        <span class="value">{{ shipmentDetails.pickupStop.durationMinutes }} minutes</span>
                    </div>
                    <div class="detail-group" v-if="shipmentDetails.arrivalDate">
                        <label class="label">Arrival:</label>
                        <span class="value">
                            {{ shipmentDetails.pickupStop.arrivalDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group" v-if="shipmentDetails.departureDate">
                        <label class="label">Departure:</label>
                        <span class="value">
                            {{ shipmentDetails.pickupStop.departureDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                </div>
                <div class="detail-section contact-detail-section">
                    <span class="title">Contact Details</span>
                    <div class="detail-group">
                        <label class="label">Name:</label>
                        <span class="value">
                            {{ shipmentDetails.pickupStop.contact.name }}
                            <span>
                                <a
                                    class="link contact-detail"
                                    v-if="shipmentDetails.pickupStop.contact.phone"
                                    :href="`tel:${shipmentDetails.pickupStop.contact.phone}`"
                                >
                                    <md-icon>
                                        phone
                                        <md-tooltip md-direction="right">
                                            {{ shipmentDetails.pickupStop.contact.phone }}
                                        </md-tooltip>
                                    </md-icon>
                                </a>
                                <a
                                    class="link contact-detail"
                                    v-if="shipmentDetails.pickupStop.contact.email"
                                    :href="`mailto:${shipmentDetails.pickupStop.contact.email}`"
                                >
                                    <md-icon>
                                        email
                                        <md-tooltip md-direction="right">
                                            {{ shipmentDetails.pickupStop.contact.email }}
                                        </md-tooltip>
                                    </md-icon>
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="detail-section" v-if="shipmentDetails.pickupStop.driverInstructions">
                    <span class="title">Driver's Instructions</span>
                    <DriverInstructions :driver-instructions="shipmentDetails.pickupStop.driverInstructions" />
                </div>
                <div class="line-separator"></div>
                <div class="detail-section">
                    <span class="title">Drop Details</span>
                    <div class="detail-group">
                        <label class="label">Drop off Ref:</label>
                        <div class="value">
                            <router-link
                                :to="{
                                    name: 'Stop Details',
                                    params: { stopId: shipmentDetails.dropStop.stopId }
                                }"
                                target="_blank"
                            >
                                {{ shipmentDetails.dropStop.stopRef }}
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="detail-group"
                        v-if="
                            shipmentDetails.dropStop.tripDate != null &&
                                shipmentDetails.pickupStop.tripDate !== shipmentDetails.dropStop.tripDate
                        "
                    >
                        <label class="label">When:</label>
                        <div class="value">
                            {{ shipmentDetails.dropStop.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                        </div>
                    </div>
                    <div
                        class="detail-group"
                        v-if="
                            shipmentDetails.dropStop.originalEta != null &&
                                shipmentDetails.dropStop.status != 'Arrived' &&
                                shipmentDetails.dropStop.status != 'Departed' &&
                                shipmentDetails.dropStop.status != 'Complete'
                        "
                    >
                        <label class="label">ETA:</label>
                        <span class="value">
                            {{ shipmentDetails.dropStop.originalEta | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group">
                        <label class="label">Address:</label>
                        <div class="value">
                            <div
                                v-if="
                                    shipmentDetails.dropStop.name &&
                                        !$_validateCoordinate(shipmentDetails.dropStop.address)
                                "
                                class="address-name"
                            >
                                {{ shipmentDetails.dropStop.name }}
                            </div>
                            <a class="address" @click.prevent="viewAddress(false)">
                                {{ $_displayAddressName(shipmentDetails.dropStop) }}
                            </a>
                        </div>
                    </div>
                    <div class="detail-group">
                        <label class="label">Duration:</label>
                        <span class="value">{{ shipmentDetails.dropStop.durationMinutes }} minutes</span>
                    </div>
                    <div class="detail-group" v-if="shipmentDetails.dropStop.arrivalDate">
                        <label class="label">Arrival:</label>
                        <span class="value">
                            {{ shipmentDetails.dropStop.arrivalDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                    <div class="detail-group" v-if="shipmentDetails.dropStop.departureDate">
                        <label class="label">Departure:</label>
                        <span class="value">
                            {{ shipmentDetails.dropStop.departureDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </span>
                    </div>
                </div>
                <div class="detail-section contact-detail-section">
                    <span class="title">Contact Details</span>
                    <div class="detail-group">
                        <label class="label">Name:</label>
                        <span class="value">
                            {{ shipmentDetails.dropStop.contact.name }}
                            <span>
                                <a
                                    class="link contact-detail"
                                    v-if="shipmentDetails.dropStop.contact.phone"
                                    :href="`tel:${shipmentDetails.dropStop.contact.phone}`"
                                >
                                    <md-icon>
                                        phone
                                        <md-tooltip md-direction="right">
                                            {{ shipmentDetails.dropStop.contact.phone }}
                                        </md-tooltip>
                                    </md-icon>
                                </a>
                                <a
                                    class="link contact-detail"
                                    v-if="shipmentDetails.dropStop.contact.email"
                                    :href="`mailto:${shipmentDetails.dropStop.contact.email}`"
                                >
                                    <md-icon>
                                        email
                                        <md-tooltip md-direction="right">
                                            {{ shipmentDetails.dropStop.contact.email }}
                                        </md-tooltip>
                                    </md-icon>
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="detail-section" v-if="shipmentDetails.dropStop.driverInstructions">
                    <span class="title">Driver's Instructions</span>
                    <DriverInstructions :driver-instructions="shipmentDetails.dropStop.driverInstructions" />
                </div>

                <div class="line-separator" v-if="shipmentDetails.pickupStop.stopItems.length > 0"></div>
                <div
                    class="detail-section contact-detail-section"
                    v-if="shipmentDetails.pickupStop.stopItems.length > 0"
                >
                    <span class="title">Items</span>
                    <div v-for="(line, index) in shipmentDetails.pickupStop.stopItems" :key="index">
                        <div class="detail-group item-reference">
                            <router-link
                                title="View item details"
                                :to="{ name: 'Inventory Details', params: { itemId: line.itemId } }"
                                target="_blank"
                            >
                                <div
                                    :class="
                                        `custom-badge inventory-status-${line.status.toLowerCase().replace(/\s+/g, '')}`
                                    "
                                >
                                    {{ line.status }}
                                </div>
                                <span class="label status-label">{{ line.description }}</span>
                            </router-link>

                            <md-tooltip class="line-tooltip" md-direction="left">
                                <div class="label" v-if="line.currentLocationName">
                                    {{ line.currentLocationName }}
                                </div>
                                <div v-if="line.barcode">
                                    {{ line.barcode }}
                                </div>
                            </md-tooltip>
                        </div>
                    </div>
                </div>

                <div class="line-separator" v-if="shipmentDetails.notes"></div>
                <div class="detail-section" v-if="shipmentDetails.notes">
                    <span class="title">Notes</span>
                    <div class="detail-group notes-content">
                        {{ shipmentDetails.notes }}
                    </div>
                </div>
                <div
                    class="line-separator"
                    v-if="
                        shipmentDetails.batchImportId ||
                            (shipmentDetails.importErrors && shipmentDetails.importErrors.length > 0)
                    "
                ></div>
                <div
                    class="detail-section"
                    v-if="
                        shipmentDetails.batchImportId ||
                            (shipmentDetails.importErrors && shipmentDetails.importErrors.length > 0)
                    "
                >
                    <span class="title">Import Details</span>
                    <div class="detail-group" v-if="shipmentDetails.batchImportId">
                        <label class="label">Import Id:</label>
                        <span class="value">
                            {{ shipmentDetails.batchImportId }}
                        </span>
                    </div>
                    <div
                        class="detail-group"
                        v-if="shipmentDetails.importErrors && shipmentDetails.importErrors.length > 0"
                    >
                        <label class="label">Import Errors:</label>
                        <span class="value">
                            <ul>
                                <li
                                    v-for="(importError, importErrorIndex) in shipmentDetails.importErrors"
                                    :key="importErrorIndex"
                                >
                                    {{ importError }}
                                </li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div class="detail-section" v-if="shipmentDetails.contents">
                    <span class="title">Shipment Contents</span>
                    <ShipmentContentsDisplay :contents="shipmentDetails.contents" />
                </div>
            </div>
            <div v-else>
                <div class="shipmentbar-loader">
                    <fade-loader :loading="loading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import { GeneralMixin, MapOverviewMixin, CarrierMixin } from '@/mixins';
import { GenerateLabelButton, QuotesButton } from '@/components';
import { handleRequests, getTeamRegionName } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { mapGetters } from 'vuex';
import CarrierDownloadLabelButton from '@/components/JobOffers/CarrierDownloadLabelButton';
import { ViewAddressModal } from '..';
import { UpdateShipmentButton, DeleteShipmentButton, ReassignShipmentButton, ShipmentStatusButton } from './buttons';
import { ShipmentContentsDisplay } from './components';
import { StopPdfDownloadButton } from '../Stops/buttons';
import { DriverInstructions } from '../Stops/components';

export default {
    name: 'ShipmentSidebar',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        shipmentId: {
            type: [Number, String],
            default: () => null
        },
        teamMembers: {
            type: Array,
            default: () => []
        }
    },
    components: {
        FadeLoader,
        UpdateShipmentButton,
        DeleteShipmentButton,
        ReassignShipmentButton,
        ShipmentStatusButton,
        GenerateLabelButton,
        StopPdfDownloadButton,
        QuotesButton,
        CarrierDownloadLabelButton,
        ShipmentContentsDisplay,
        DriverInstructions
    },
    data() {
        return {
            loading: true,
            shipmentDetails: null,
            customFieldValues: {},
            shipmentCustomFieldList: [],
            loadCapacityList: [],
            loadValues: {},
            isCarrierType: false
        };
    },
    mixins: [GeneralMixin, MapOverviewMixin, CarrierMixin],
    computed: {
        ...mapGetters({
            getUser: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isReadOnlyUser: 'user/isReadOnlyUser',
            teamRegions: 'team/teamRegions'
        }),
        teamRegionName() {
            return getTeamRegionName(this.shipmentDetails?.teamRegionId, this.teamRegions);
        },
        isCarrierAssigned() {
            return this.shipmentDetails && this.shipmentDetails.carrierTeamId;
        },
        carrierTrackingUrl() {
            if (!this.isCarrierAssigned) 
                return null;
            if (this.shipmentDetails.assignedCarrierBooking?.trackingUrl)
                return this.shipmentDetails.assignedCarrierBooking?.trackingUrl;

            return null;
        },
        carrierLabels() {
            if (!this.isCarrierAssigned) 
                return [];
            if (
                this.shipmentDetails.assignedCarrierBooking?.labels &&
                this.shipmentDetails.assignedCarrierBooking.labels.length
            ) {
                if (this.shipmentDetails.assignedCarrierBooking.labels.some((x) => x.blobDownloadUrl))
                    return [...this.shipmentDetails.assignedCarrierBooking.labels.filter((x) => x.blobDownloadUrl)];
            }

            return [];
        }
    },
    watch: {
        async shipmentId(newValue, oldValue) {
            await this.getShipmentDetails(newValue);
        },
        'data.assignedTo': function(newValue, oldValue) {
            this.shipmentDetails.assignedTo = newValue;
        },
        'data.tripDate': function(newValue, oldValue) {
            this.shipmentDetails.tripDate = newValue;
        },
        'data.status': function(newValue, oldValue) {
            this.shipmentDetails.status = newValue;
        }
    },
    async mounted() {
        const user = this.getUser;
        this.loadCapacityList = user.vehicleCapacityUnitsConfiguration || [];

        if (user.shipmentCustomFieldDefinitions) {
            this.shipmentCustomFieldList = user.shipmentCustomFieldDefinitions;
        }

        await this.getShipmentDetails(this.shipmentId);
    },
    methods: {
        isAssignedTo() {
            return Boolean(this.shipmentDetails.assignedTo.publicUserId);
        },
        async getShipmentDetails(id) {
            this.loading = true;
            const shipmentDetailsEndpoint = `/api/shipments/${id}?includePickupItemLines=true`;
            const data = await handleRequests(shipmentDetailsEndpoint);
            this.shipmentDetails = data.data;

            if (this.shipmentDetails.assignedCarrierBooking != null) 
                this.isCarrierType = true;

            this.loading = false;
            this.customFieldValues = this.shipmentDetails.customFields;

            if (this.loadCapacityList != null && this.loadCapacityList.length) {
                this.loadValues = this.shipmentDetails.load;
            }
        },
        assignShipment(response) {
            this.$emit('assignShipment', response);
        },
        changeShipmentStatus(response) {
            this.$set(shipment, 'status', response.status);
            this.$emit('changeShipmentStatus', response);
        },
        updateShipment(response) {
            this.$emit('updateShipment', response);
        },
        deleteShipment(response) {
            this.$emit('deleteShipment', response);
        },
        closeModal() {
            this.$emit('closeModal');
        },
        viewAddress(isPickup) {
            if (isPickup) {
                this.$modal.show(ViewAddressModal, { data: this.shipmentDetails.pickupStop });
            } else {
                this.$modal.show(ViewAddressModal, { data: this.shipmentDetails.dropStop });
            }
        },
        async createTrackingLink() {
            const data = {
                shipmentId: this.shipmentDetails.shipmentId,
                type: 'LR',
                recipient: {
                    name: this.shipmentDetails.contact.name,
                    phone: this.shipmentDetails.contact.phone,
                    email: this.shipmentDetails.contact.email
                },
                teamMemberId: this.shipmentDetails.assignedTo ? this.shipmentDetails.assignedTo.publicUserId : null
            };

            const api = '/api/links';
            const payload = {
                method: 'post',
                data
            };

            try {
                const response = await handleRequests(api, payload);
                this.shipmentDetails.trackingLinkUrl = response.data.url;
                this.$notify({
                    message: 'Successfully created a tracking link.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in creating the tracking link.';
                showErrorMessage(this, message, e);
            }
        },
        setTrackingLinkUrl(trackingLink) {
            // this is to make url shorteners behave like a link
            if (trackingLink.includes('https://')) 
                return `${trackingLink}?isAdmin=true&showPOD=true`;

            return `https://${trackingLink}?isAdmin=true&showPOD=true`;
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 30%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    @media (min-width: 1600px) {
        width: 25%;
    }

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old shipments that have no shipment reference numbers */
        z-index: 999;
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;
        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;
        .label {
            display: inline-block;
            width: 95px;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .value {
            display: inline-block;
            width: calc(100% - 105px);
            vertical-align: top;
            a {
                color: #2b93ff;
            }
        }

        .custom-btn,
        ::v-deep .custom-btn {
            padding: 4px 8px;
            margin: 0;
            width: initial;
            max-width: initial;
            padding: 4px 0;
            .md-ripple {
                padding: 0 !important;
            }
        }

        .address {
            color: #2b93ff;
            &:hover {
                text-decoration: underline !important;
                cursor: pointer;
            }
        }
        .status-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 0.75rem;
            width: calc(100% - 100px);
        }
        .notes {
            padding-left: 24px;
            width: 100%;
        }
    }

    .item-reference ::v-deep a {
        color: rgba(0, 0, 0, 0.87);
    }

    .action-detail-group {
        margin: 0;
        position: relative;
    }

    .profile-image {
        height: 50px;
        width: 50px;
        margin-top: -10px;
        position: absolute;
        left: 16px;
        margin-top: -16px;
        border-radius: 50%;
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1), 0 0px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }
}

::v-deep {
    .status {
        color: #fff !important;
        min-width: 90px;
        max-width: 150px;
        display: block;
        min-height: 31px;
        text-align: center;
    }
}

.note-image {
    height: 100px;
    width: initial;
    margin-right: 10px;
}

.shipmentbar-loader {
    position: absolute;
    top: 40%;
    left: 50%;
    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

a {
    &.link {
        color: #2b93ff;
    }
}

.contact-detail {
    float: right;
}
.brand-logo {
    max-height: 50px;
    padding: 2px 16px;
    display: block;
}

.custom-badge {
    max-width: 200px;
}

.quoting-container {
    .label {
        width: 115px !important;
    }
    .value {
        width: calc(100% - 120px) !important;
    }
}

.not-available {
    color: #aaa !important;
    font-style: italic;
}

// .quoting-badge {
//     max-width: 200px;;
// }
</style>
