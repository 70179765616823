<template>
    <div class="table">
        <div class="table-row table-header">
            <slot name="table-header"></slot>
        </div>
        <div style="height: 1px; width: 100%; background: #eee" />
        <slot name="table-content"></slot>
    </div>
</template>

<script>
export default {
    name: 'ProcessChargesTable'
};
</script>
<style scoped lang="scss">
.table {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    border-radius: 25px;
}
.table-row {
    display: grid;
    grid-template-columns: 50px 1.5fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    align-items: center;
    gap: 8px;

    &:first-child {
        padding-left: 8px;
    }
}
.table-header {
    padding-top: 23px;
    padding-bottom: 23px;
    background: #fff;
    color: #4caf50 !important;
}
</style>
