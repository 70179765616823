export const RatesEngineMixin = {
    methods: {
        $_getTotalAmount(ratesList) {
            let total = 0;
            ratesList.forEach((line) => {
                total += Math.round((line.quantity * line.unitPriceExTax + Number.EPSILON) * 100) / 100;
            });
            return total;
        },
        $_getRateAmount(rate) {
            const amount = rate.quantity * rate.unitPriceExTax;
            return Math.round((amount + Number.EPSILON) * 100) / 100;
        },
        $_getshipmentDataModel(
            shipmentDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            const startTime = this.earliestStartTime(shipmentDetails?.shipmentStatusHistory);
            const finishTime = this.latestFinishTime(shipmentDetails?.shipmentStatusHistory);

            const pickup = {
                name: shipmentDetails.pickupStop.name,
                address: shipmentDetails.pickupStop.address,
                location: {
                    latitude: shipmentDetails.pickupStop.location.latitude,
                    longitude: shipmentDetails.pickupStop.location.longitude
                },
                tripDate: shipmentDetails.pickupStop.tripDate,
                earliestStartTime: startTime ?? null
            };

            const drop = {
                name: shipmentDetails.dropStop.name,
                address: shipmentDetails.dropStop.address,
                location: {
                    latitude: shipmentDetails.dropStop.location.latitude,
                    longitude: shipmentDetails.dropStop.location.longitude
                },
                latestFinishTime: finishTime ?? null
            };

            let pickupLocation = null;
            let dropLocation = null;

            if (shipmentDetails.pickupStop.location === null || shipmentDetails.pickupStop.location?.latitude === 0 || shipmentDetails.pickupStop.location?.longitude === 0) {
                const status = ['Picked Up'];
                pickupLocation = this.getLocation(shipmentDetails?.shipmentStatusHistory, status);
            }

            if (shipmentDetails.dropStop.location === null || shipmentDetails.dropStop.location?.latitude === 0 || shipmentDetails.dropStop.location?.longitude === 0) {
                const status = ['Complete'];
                dropLocation = this.getLocation(shipmentDetails?.shipmentStatusHistory, status);
            }

            if (pickupLocation) {
                pickup.location = pickupLocation;
            }

            if (dropLocation) {
                drop.location = dropLocation;
            }

            // need to check if costs are a commission rate, and if so we need to update them based on the Base Charge.
            const isCommissionBasedCosts = this.costLinesAreCommissionBased(
                this.shipmentDetails.costLines,
                this.shipmentDetails.teamMemberInvoiceId
            );

            const data = {
                pickup: JSON.stringify(pickup),
                drop: JSON.stringify(drop),
                tripDate: shipmentDetails.pickupStop.tripDate,
                rateGroupId: shipmentDetails.rateGroupId ?? shipmentDetails.assignedTo?.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                shipmentId: shipmentDetails.shipmentId,
                isChargingRule: isCharging,
                isCostingRule: isCosting,
                getDistance: true,
                customerId: shipmentDetails.customerId === null ? null : shipmentDetails.customerId,
                addressChanged: addressChange,
                rateGroupChanged: rateGroupChange,
                assignedTeamMemberPublicId: shipmentDetails.assignToPublicUserId
                    ? shipmentDetails.assignToPublicUserId
                    : shipmentDetails.assignedTo?.publicUserId,
                distance: shipmentDetails.distance ?? 0,
                costsAreCommission: isCommissionBasedCosts,
                hasCustomerInvoice: shipmentDetails.customerInvoiceId !== null,
                hasTeamMemberInvoice: shipmentDetails.teamMemberInvoiceId !== null
            };

            return data;
        },
        $_getStopData(
            stopDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            let stopLoc = null;
            if (this.stopDetails.location === null || this.stopDetails.location?.latitude === 0 || this.stopDetails.location?.longitude === 0) {
                const status = ['Complete', 'Failed'];
                stopLoc = this.getLocation(this.stopDetails.stopStatusHistory, status);
            }

            const drop = {
                name: stopDetails.name,
                address: stopDetails.address,
                location: {
                    latitude: stopDetails.location.latitude,
                    longitude: stopDetails.location.longitude
                }
            };

            if (stopLoc) {
                drop.location = stopLoc;
            }

            // need to check if costs are a commission rate, and if so we need to update them based on the Base Charge.
            const isCommissionBasedCosts = this.costLinesAreCommissionBased(
                this.stopDetails.costLines,
                this.stopDetails.teamMemberInvoiceId
            );

            const data = {
                drop: JSON.stringify(drop),
                tripDate: stopDetails.tripDate,
                rateGroupId: stopDetails.rateGroupId ?? stopDetails.assignedTo?.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                stopId: stopDetails.stopId,
                isChargingRule: isCharging,
                isCostingRule: isCosting,
                customerId: stopDetails.customerId === null ? null : stopDetails.customerId,
                assignedTeamMemberPublicId: stopDetails.assignToPublicUserId
                    ? stopDetails.assignToPublicUserId
                    : stopDetails.assignedTo?.publicUserId,
                rateGroupChanged: rateGroupChange ?? false,
                addressChanged: addressChange ?? false,
                stopAttemptCounter: stopDetails.stopAttemptCounter,
                stopStatus: stopDetails.status,
                previousAttemptStopId: stopDetails.previousAttemptStopId,
                costsAreCommission: isCommissionBasedCosts,
                hasCustomerInvoice: stopDetails.customerInvoiceId !== null,
                hasTeamMemberInvoice: stopDetails.teamMemberInvoiceId !== null
            };

            return data;
        },
        $_getRateRuleData(
            chargeType,
            publicUserId,
            rateDetail,
            customerId,
            listLength,
            rateGroupId,
            driverRuleCreation,
            driverUserId
        ) {
            const obj = {
                type: chargeType.type,
                publicUserId,
                rule: JSON.stringify(rateDetail),
                customerId,
                priority: listLength + 1,
                rateGroupId: rateGroupId === 0 ? null : rateGroupId,
                isCostingRule: driverRuleCreation === true,
                isChargingRule: driverRuleCreation !== true,
                driverUserId
            };

            return obj;
        },
        earliestStartTime(shipmentStatusHistory) {
            if (!shipmentStatusHistory || shipmentStatusHistory?.length === 0) {
                return null;
            }

            const startStatus = ['Pending', 'Enroute to Pickup', 'Arrived at Pickup', 'Picked Up'];
            let earliestTimes = shipmentStatusHistory.filter((status) => startStatus.includes(status.newStatus));

            if (earliestTimes.length === 0) {
                return null;
            }

            if (earliestTimes.find((x) => x.newStatus === 'Picked Up')) {
                earliestTimes = earliestTimes.filter((x) => x.newStatus === 'Picked Up');
            }

            earliestTimes.sort((a, b) => a.actionDate - b.actionDate);
            let startTime = earliestTimes[0].actionDate;

            earliestTimes.forEach((status) => {
                if (status.actionDate >= startTime) {
                    startTime = status.actionDate;
                }
            });

            return startTime.toString();
        },
        latestFinishTime(shipmentStatusHistory) {
            if (!shipmentStatusHistory || shipmentStatusHistory?.length === 0) {
                return null;
            }

            const finishStatus = ['Pending', 'Enroute to Drop', 'Arrived at Drop', 'Complete'];
            let latestFinishTimes = shipmentStatusHistory.filter((status) => finishStatus.includes(status.newStatus));

            if (latestFinishTimes.length === 0) {
                return null;
            }

            if (latestFinishTimes.find((x) => x.newStatus === 'Complete')) {
                latestFinishTimes = latestFinishTimes.filter((x) => x.newStatus === 'Complete');
            }

            latestFinishTimes.sort((a, b) => a.actionDate - b.actionDate);
            let finishTime = latestFinishTimes[0].actionDate;

            latestFinishTimes.forEach((status) => {
                if (status.actionDate >= finishTime) {
                    finishTime = status.actionDate;
                }
            });

            return finishTime.toString();
        },
        $_getTripDataModel(
            tripDetails,
            user,
            loadValues = null,
            chargeList = null,
            costList = null,
            isCharging = false,
            isCosting = false,
            revertCharges = false,
            revertCosts = false,
            rateGroupChange = false,
            addressChange = false
        ) {
            const startAddress = {
                location: {
                    latitude: tripDetails.locationDetails.startLocation.latitude,
                    longitude: tripDetails.locationDetails.startLocation.longitude
                },
                tripDate: tripDetails.tripDate,
                earliestStartTime: tripDetails.actualStartTime
            };

            const endAddress = {
                location: {
                    latitude: tripDetails.locationDetails.endLocation?.latitude,
                    longitude: tripDetails.locationDetails.endLocation?.longitude
                },
                latestFinishTime: tripDetails.actualEndTime
            };

            const data = {
                pickup: JSON.stringify(startAddress),
                drop: JSON.stringify(endAddress),
                tripDate: tripDetails.tripDate,
                rateGroupId: tripDetails.rateGroupId,
                load: loadValues,
                teamId: user.teamId,
                publicUserId: user.publicUserId,
                speedUnits: user.speedUnits,
                chargeList: JSON.stringify(chargeList),
                costList: JSON.stringify(costList),
                revertToDefaultCharges: revertCharges,
                revertToDefaultCosts: revertCosts,
                tripId: tripDetails.tripId,
                isChargingRule: isCharging,
                isCostingRule: isCosting,
                getDistance: false,
                customerId: tripDetails.customerId === null ? null : tripDetails.customerId,
                addressChanged: addressChange,
                rateGroupChanged: rateGroupChange,
                assignedTeamMemberPublicId: tripDetails.assignToPublicUserId
                    ? tripDetails.assignToPublicUserId
                    : tripDetails.assignedTo?.publicUserId,
                distance: tripDetails.travelDistanceMetres,
                tripStartTime: this.tripDetails.actualStartTime,
                startTime: this.tripDetails.startTime,
                userDefaultTripStartTime: this.tripDetails.userDefaultTripStartTime,
                tripEndTime: this.tripDetails.actualEndTime,
                endTime: this.tripDetails.endTime,
                userDefaultTripEndTime: this.tripDetails.userDefaultTripEndTime,
                tripEstimatedEndTime: this.tripDetails.locationDetails.endEta,
                userTimeZone: this.tripDetails.userTimeZone
            };

            return data;
        },
        costLinesAreCommissionBased(costs, teamMemberInvoiceId) {
            const baseCharge = costs.find((c) => {
                return c.isBaseCharge === true;
            });

            if (baseCharge == null) 
                return false;

            if (baseCharge.type !== 'commission') {
                return false;
            }

            if (teamMemberInvoiceId) {
                return false;
            }

            return true;
        },
        getLocation(historyEvents, status) {
            if (!historyEvents || historyEvents?.length === 0)
                return null;

            const statuses = status;
            const latestFinishTimes = historyEvents.filter((status) => statuses.includes(status.newStatus));

            if (latestFinishTimes.length === 0) {
                return null;
            }

            latestFinishTimes.sort((a, b) => a.actionDate - b.actionDate);

            return latestFinishTimes.pop().location;
        }
    }
};
