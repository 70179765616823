<template>
    <div>
        <md-button
            title="Create Run Schedule"
            class="md-primary md-just-icon md-round pull-right header-button"
            @click="createRun"
            :disabled="!teamMembers.length"
        >
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { RUN_DETAILS_DEFAULTS } from '@/utils/defaults';
import { mapGetters } from 'vuex';
import CreateRunScheduleModal from './CreateRunScheduleModal';

export default {
    name: 'CreateRunSchedule',
    mixins: [GeneralMixin],
    props: {
        teamMembers: {
            type: Array,
            default: () => []
        },
        selectedMemberId: {
            type: [String, Number],
            default: () => 0
        }
    },
    data() {
        return {
            runDetails: Object.assign({}, RUN_DETAILS_DEFAULTS())
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        createRun() {
            this.resetRunState();

            if (this.selectedMemberId !== null) {
                this.runDetails.assignToPublicUserId = this.selectedMemberId;
            }
            this.runDetails = { ...this.runDetails, teamRegionId: this.user.teamRegionId };
            this.$modal
                .show(CreateRunScheduleModal, {
                    members: this.teamMembers,
                    runDetails: this.runDetails
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.emitRunCreated();
                        this.$modal.hide();
                    }
                });
        },
        emitRunCreated() {
            this.resetRunState();
            this.$emit('runCreated');
        },
        resetRunState() {
            Object.assign(this.runDetails, RUN_DETAILS_DEFAULTS());
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn {
    margin-right: 16px;
}
</style>
