<template>
    <div
        @click.stop="handleRowClick"
        class="row"
        :class="{ 'active-row': hasExpandedRow, 'inactive-row': !hasExpandedRow }"
        v-if="rootJob"
    >
        <div class="table-row">
            <md-checkbox
                class="checkbox"
                :value="rootJob"
                v-model="selectedJobs"
                @change="onCheckboxClick"
            ></md-checkbox>
            <span class="refNo">
                <md-icon v-if="showWarning(rootJob)">
                    warning
                    <CustomTooltip>
                        Not Geocoded
                        <br />
                        Confirm address to fix.
                    </CustomTooltip>
                </md-icon>
                <span v-if="rootJob.stopId">
                    <router-link
                        v-if="rootJob.stopId"
                        :to="{
                            name: 'Stop Details',
                            params: { stopId: rootJob.stopId }
                        }"
                        target="_blank"
                    >
                        {{
                            referenceHeaderValue.key === 'reference-number' ||
                            rootJob.sourceReference === null ||
                            rootJob.sourceReference === ''
                                ? rootJob.ref
                                : rootJob.sourceReference
                        }}
                    </router-link>
                </span>
                <span v-if="rootJob.shipmentId">
                    <router-link
                        :to="{
                            name: 'Shipment Details',
                            params: { shipmentId: rootJob.shipmentId }
                        }"
                        target="_blank"
                    >
                        {{
                            referenceHeaderValue.key === 'reference-number' ||
                            rootJob.sourceReference === null ||
                            rootJob.sourceReference === ''
                                ? rootJob.ref
                                : rootJob.sourceReference
                        }}
                    </router-link>
                </span>
                <span v-if="rootJob.tripId">
                    <router-link
                        :to="{
                            name: 'Trip Details',
                            params: { tripId: rootJob.tripId }
                        }"
                        target="_blank"
                    >
                        {{
                            referenceHeaderValue.key === 'reference-number' ||
                            rootJob.sourceReference === null ||
                            rootJob.sourceReference === ''
                                ? rootJob.ref
                                : rootJob.sourceReference
                        }}
                    </router-link>
                </span>
            </span>
            <span class="trip-date">{{ rootJob.tripDate | dateFormat(DATE_TYPES.standardDate) }}</span>
            <span class="customer-name">{{ rootJob.customerName }}</span>

            <div class="time" @click.stop="openUpdateTimeModal">
                <span>
                    {{ getTimeValue() }}
                    <md-icon class="edit-icon" v-if="rootJob.tripId">
                        edit
                    </md-icon>
                    <CustomTooltip>
                        <div v-for="(time, index) in getTimeTooltipText()" :key="index" class="time-tooltip-content">
                            <b>{{ time.label }}</b>
                            <span>{{ time.value }}</span>
                        </div>
                    </CustomTooltip>
                </span>
            </div>
            <div class="attempt">
                {{ rootJob.attempt }}
            </div>
            <div class="status">
                <JobStatus :variant="getStatusNormalized(rootJob.status)" />
            </div>
            <p class="driver-name">
                <span v-if="rootJob.assignedTo.publicUserId" href="#" class="custom-ellipsis">
                    <img
                        v-if="rootJob.assignedTo.fullName"
                        class="profile-image"
                        :src="
                            rootJob.assignedTo.photoUrl !== null ? rootJob.assignedTo.photoUrl : $root.defaultPhotoUrl
                        "
                        alt="avatar"
                        @error="$_setDefaultBrokenImage"
                    />
                    {{ rootJob.assignedTo.fullName }}
                </span>
            </p>
            <span class="charge-container">
                <span class="charge-span" v-if="rootJob.totalCharge < 0">
                    ({{ getTotalCharge | currency(rootJob.currency) }})
                </span>
                <span class="charge-span" v-else>
                    {{ getTotalCharge | currency(rootJob.currency) }}
                </span>
                <md-icon class="charge-icon" v-if="rootJob.customerInvoiceId">receipt_long</md-icon>
            </span>
            <span class="cost-container">
                <span class="cost-span" v-if="rootJob.totalCost < 0">
                    ({{ getTotalCost | currency(rootJob.currency) }})
                </span>
                <span class="cost-span" v-else>
                    {{ getTotalCost | currency(rootJob.currency) }}
                </span>
                <md-icon class="cost-icon" v-if="rootJob.teamMemberInvoiceId">receipt_long</md-icon>
            </span>
            <span class="margin">
                <span v-if="rootJob.margin < 0">({{ getMargin | currency(rootJob.currency) }})</span>
                <span v-else>
                    {{ getMargin | currency(rootJob.currency) }}
                </span>
            </span>
            <div class="actions">
                <div v-if="!rootJob.isRecalculating">
                    <md-button
                        @click.stop="updateRates"
                        title="Update Rates"
                        class="md-success md-just-icon md-round recalculate-icon"
                        :disabled="!rootJob.rateGroupId"
                    >
                        <md-icon>currency_exchange</md-icon>
                    </md-button>
                </div>
                <div v-else class="sync-loader">
                    <straight-line-loader />
                </div>
            </div>
            <template v-if="hasExpandedRow">
                <div class="more-info">
                    <div class="section-item">
                        <span>Address</span>
                        <span>
                            <span v-for="(address, index) in addresses" :key="index">
                                <span>
                                    <b>
                                        <span v-if="rootJob.pickupStopId && rootJob.dropStopId">
                                            <router-link
                                                v-if="rootJob.pickupStopId && address.label === 'Pickup Address'"
                                                :to="{
                                                    name: 'Stop Details',
                                                    params: { stopId: rootJob.pickupStopId }
                                                }"
                                                target="_blank"
                                            >
                                                {{ address.label }}
                                            </router-link>
                                            <router-link
                                                v-if="rootJob.dropStopId && address.label === 'Drop Address'"
                                                :to="{
                                                    name: 'Stop Details',
                                                    params: { stopId: rootJob.dropStopId }
                                                }"
                                                target="_blank"
                                            >
                                                {{ address.label }}
                                            </router-link>
                                        </span>
                                        <span v-else>{{ address.label }}</span>
                                    </b>
                                    : {{ address.address }}
                                </span>
                                <br />
                            </span>
                            <CustomTooltip v-if="!rootJob.stopId">
                                <div v-for="(address, index) in addresses" :key="index" class="address-tooltip-content">
                                    <b>{{ address.label }}</b>
                                    <span>{{ address.address }}</span>
                                </div>
                            </CustomTooltip>
                        </span>
                    </div>
                    <div class="section-item">
                        <span>{{ getTimeLabel() }}</span>
                        <span>
                            {{ getTimeValue() }}
                            <CustomTooltip>
                                <div
                                    v-for="(time, index) in getTimeTooltipText()"
                                    :key="index"
                                    class="time-tooltip-content"
                                >
                                    <b>{{ time.label }}</b>
                                    <span>{{ time.value }}</span>
                                </div>
                            </CustomTooltip>
                        </span>
                    </div>
                    <div class="section-item">
                        <span>Service Package</span>
                        <span>
                            {{ rootJob.rateGroupName }}
                        </span>
                    </div>
                    <div class="section-item" v-if="hasHourlyRate">
                        <span>Total Chargeable Hours</span>
                        <span>
                            {{ rootJob.chargeableHours }}
                        </span>
                    </div>
                    <div class="load-form" v-if="rootJob.loadDetails">
                        <div>
                            <div class="section-item">
                                <md-icon>
                                    <img class="capacity-images" src="/img/icons/capacity-items-gray.png" />
                                </md-icon>
                                <span>{{ getCapacityInfo(rootJob.loadDetails.quantity, 'items', 'quantity') }}</span>
                            </div>
                            <div class="section-item">
                                <md-icon>
                                    <img class="capacity-images" src="/img/icons/capacity-volume-gray.png" />
                                </md-icon>
                                <span>{{ getCapacityInfo(rootJob.loadDetails.volume, 'm3', 'volume') }}</span>
                            </div>
                            <div class="section-item">
                                <md-icon>
                                    <img class="capacity-images" src="/img/icons/capacity-weight-gray.png" />
                                </md-icon>
                                <span>{{ getCapacityInfo(rootJob.loadDetails.weight, 'kg', 'weight') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-form" v-if="rootJob.serviceLines.length > 0">
                    <div>
                        <b>
                            <span>Services:</span>
                        </b>
                        <div class="section-item">
                            <md-tooltip md-direction="top">
                                <b>Services:</b>
                                {{ getServiceNames }}
                            </md-tooltip>
                            <span>{{ getServiceNames }}</span>
                        </div>
                    </div>
                </div>
                <div class="total-form">
                    <div class="section-item">
                        <span>Total Charge:</span>
                        <span v-if="rootJob.totalCharge < 0">({{ getTotalCharge | currency(rootJob.currency) }})</span>
                        <span v-else>{{ getTotalCharge | currency(rootJob.currency) }}</span>
                    </div>
                    <div class="section-item">
                        <span>Total Cost:</span>
                        <span v-if="rootJob.totalCost < 0">({{ getTotalCost | currency(rootJob.currency) }})</span>
                        <span v-else>{{ getTotalCost | currency(rootJob.currency) }}</span>
                    </div>
                    <div class="section-item">
                        <span>Margin</span>
                        <span v-if="rootJob.margin < 0">({{ getMargin | currency(rootJob.currency) }})</span>
                        <span v-else>{{ getMargin | currency(rootJob.currency) }}</span>
                    </div>
                </div>
            </template>
        </div>
        <div class="nested-table-container" v-if="hasExpandedRow">
            <slot name="nested-table"></slot>
        </div>
        <div style="height: 1px; width: 100%; background: #eee" />
    </div>
</template>
<script>
import { GeneralMixin } from '@/mixins';
import { StraightLineLoader } from '@/components';
import moment from 'moment';
import JobStatus from '@/pages/ProcessCharges/components/JobStatus';
import CustomTooltip from '@/pages/ProcessCharges/components/CustomTooltip';
import UpdateJobTimeModal from '@/pages/ProcessCharges/components/UpdateJobTimeModal';
import { mapGetters } from 'vuex';

export default {
    name: 'ActiveRow',
    components: { CustomTooltip, JobStatus, StraightLineLoader },
    props: {
        rootJob: {
            type: Object,
            required: true
        },
        selectedJobs: {
            type: Array,
            required: true
        },
        hasExpandedRow: {
            type: Boolean,
            default: false
        },
        referenceHeaderValue: {
            type: Object,
            default: null
        }
    },
    mixins: [GeneralMixin],
    computed: {
        /**
         *
         * @returns {{ label: string, address: string }[]}
         */
        addresses() {
            /**
             *
             * @type {{ label: string, address: string}[]}
             */
            const addresses = [];

            if (this.rootJob.tripId !== null || this.rootJob.shipmentId !== null) {
                if (this.rootJob.tripId) {
                    addresses.push({
                        label: 'Start Address',
                        address: this.rootJob.pickupAddress
                    });
                    let endAddress = this.rootJob.dropAddress;
                    if (this.$_isEmptyOrSpaces(endAddress)) {
                        endAddress = 'Unspecified';
                    }
                    addresses.push({
                        label: 'End Address',
                        address: endAddress
                    });
                    return addresses;
                }
                if (this.rootJob.shipmentId) {
                    addresses.push({
                        label: 'Pickup Address',
                        address: this.rootJob.pickupAddress
                    });

                    let endAddress = this.rootJob.dropAddress;
                    if (this.$_isEmptyOrSpaces(endAddress)) {
                        endAddress = 'Unspecified';
                    }
                    addresses.push({
                        label: 'Drop Address',
                        address: endAddress
                    });
                    return addresses;
                }
            }
            addresses.push({
                label: 'Address',
                address: this.rootJob.dropAddress
            });
            return addresses;
        },
        hasHourlyRate() {
            return this.rootJob.rateGroupId && this.rootJob.rateGroupType.toLowerCase().includes('hourly');
        },
        getTotalCharge() {
            if (this.rootJob.totalCharge < 0) {
                const totalCharge = String(this.rootJob.totalCharge).slice(1);
                return parseFloat(totalCharge);
            }

            return this.rootJob.totalCharge;
        },
        getTotalCost() {
            if (this.rootJob.totalCost < 0) {
                const totalCost = String(this.rootJob.totalCost).slice(1);
                return parseFloat(totalCost);
            }

            return this.rootJob.totalCost;
        },
        getMargin() {
            if (this.rootJob.margin < 0) {
                const margin = String(this.rootJob.margin).slice(1);
                return parseFloat(margin);
            }

            return this.rootJob.margin;
        },
        getServiceNames() {
            let serviceDescription = [];
            serviceDescription = this.rootJob.serviceLines.map((x) => x.description);

            const des = serviceDescription.join(', ');
            return des;
        },
        ...mapGetters({
            user: 'user/user'
        })
    },
    methods: {
        openUpdateTimeModal() {
            // only open if the job is a trip
            if (!this.rootJob.tripId) {
                this.handleRowClick();
                return;
            }
            let times = {};
            if (this.rootJob.tripId || this.rootJob.shipmentId) {
                times = {
                    startTime: this.rootJob.actualStartTime,
                    endTime: this.rootJob.actualEndTime
                };
            } else {
                times = {
                    endTime: this.rootJob.actualEndTime
                };
            }
            let jobType = '';
            if (this.rootJob.tripId) {
                jobType = 'trip';
            } else if (this.rootJob.shipmentId) {
                jobType = 'shipment';
            } else {
                jobType = 'stop';
            }
            this.$modal
                .show(UpdateJobTimeModal, {
                    tripId: this.rootJob.tripId,
                    jobType,
                    initialStartTime: times.startTime,
                    initialEndTime: times.endTime
                })
                .then((response) => {
                    if (response && response?.code.toLowerCase() === 'ok') {
                        const { newTimes } = response;
                        this.$modal.hide();
                        this.$emit('update-time', {
                            tripId: this.rootJob.tripId,
                            newTimes
                        });
                    }
                });
        },
        showWarning(item) {
            // show warning if location is (0,0) or null
            // skip if item is a trip
            if (item.tripId) {
                return false;
            }
            return (
                !item.location ||
                !item.location.latitude ||
                !item.location.longitude ||
                item.location.longitude === '0' ||
                item.location.latitude === '0'
            );
        },
        handleRowClick() {
            this.$emit('row-click');
        },
        onCheckboxClick() {
            this.$emit('checkbox-click');
        },
        updateRates(job) {
            this.$emit('update-rates');
        },
        /**
         * @returns {{ label: string, value: string} []}
         */
        getTimeTooltipText() {
            const timesArr = [];
            if (!this.rootJob.tripId && !this.rootJob.shipmentId) {
                // this is just a stop
                const times = this.getStopTime();
                if (times.endTime) {
                    timesArr.push({
                        label: 'End',
                        value: times.endTime
                    });
                }
                if (times.actualEndTime) {
                    timesArr.push({
                        label: 'Actual End',
                        value: times.actualEndTime
                    });
                }
                return timesArr;
            }
            const times = this.getTripShipmentTime();
            if (times.startTime) {
                if (times.endTime) {
                    timesArr.push({
                        label: 'Scheduled Start',
                        value: times.startTime
                    });
                    timesArr.push({
                        label: 'Scheduled End',
                        value: times.endTime
                    });
                } else {
                    timesArr.push({
                        label: 'Scheduled Start',
                        value: times.startTime
                    });
                    timesArr.push({
                        label: 'Scheduled End',
                        value: 'Unspecified'
                    });
                }
            }
            if (times.actualStartTime) {
                if (times.actualEndTime) {
                    timesArr.push({
                        label: 'Actual Start',
                        value: times.actualStartTime
                    });
                    timesArr.push({
                        label: 'Actual End',
                        value: times.actualEndTime
                    });
                } else {
                    timesArr.push({
                        label: 'Actual Start',
                        value: times.actualStartTime
                    });
                    timesArr.push({
                        label: 'Actual End',
                        value: 'Unspecified'
                    });
                }
            }
            return timesArr;
        },
        getStatusNormalized(status) {
            const mapping = {
                complete: 'success',
                failed: 'error'
            };
            return mapping[status.toLowerCase()];
        },
        getTimeLabel() {
            if (this.rootJob.tripId) {
                return 'Start Time -> End Time';
            }
            if (this.rootJob.shipmentId) {
                return 'Pickup Time -> Drop Time';
            }
            return 'Time';
        },
        getTimeValue() {
            const timesArr = [];
            if (this.rootJob.tripId || this.rootJob.shipmentId) {
                const times = this.getTripShipmentTime();
                let startTimeString = '';
                if (times.startTime && times.actualStartTime) {
                    startTimeString = `${times.startTime} (${times.actualStartTime})`;
                } else {
                    startTimeString = times.startTime || times.actualStartTime;
                }

                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }

                timesArr.push(`${startTimeString} -> ${endTimeString}`);
            } else {
                // for stops, only the endTime is present
                // do the same thing for endTime and actualEndTime
                const times = this.getStopTime();
                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }
                timesArr.push(endTimeString);
            }
            return timesArr.join('');
        },
        /**
         * @returns {{actualStartTime: string | null, actualEndTime: string | null, startTime: string | null, endTime: string | null}}
         * @private
         */
        getTripShipmentTime() {
            const times = {
                startTime: null,
                endTime: null,
                actualStartTime: null,
                actualEndTime: null
            };
            if (this.rootJob.startTime) {
                times.startTime = moment(this.rootJob.startTime).format('hh:mm A');
            }
            if (this.rootJob.actualStartTime) {
                times.actualStartTime = moment(this.rootJob.actualStartTime).format('hh:mm A');
            }
            if (this.rootJob.endTime) {
                times.endTime = moment(this.rootJob.endTime).format('hh:mm A');
            }
            if (this.rootJob.actualEndTime) {
                times.actualEndTime = moment(this.rootJob.actualEndTime).format('hh:mm A');
            }
            return times;
        },
        /**
         *
         * @returns {{actualEndTime: string | null, endTime: string | null}}
         * @private
         */
        getStopTime() {
            const times = {
                endTime: null,
                actualEndTime: null
            };
            if (this.rootJob.endTime) {
                times.endTime = moment(this.rootJob.endTime).format('hh:mm A');
            }
            if (this.rootJob.actualEndTime) {
                times.actualEndTime = moment(this.rootJob.actualEndTime).format('hh:mm A');
            }
            return times;
        },
        getCapacityInfo(item, measurementLabel, configType) {
            let label = measurementLabel;

            if (this.user.vehicleCapacityUnitsConfiguration && this.user.vehicleCapacityUnitsConfiguration.length > 0) {
                const labelType = this.user.vehicleCapacityUnitsConfiguration.find((x) => x.type === configType);

                if (labelType && labelType.symbol) 
                    label = labelType.symbol;
            }

            const itemValue = `${item ?? '-'}`;

            return `${itemValue} ${label}`;
        }
    }
};
</script>

<style scoped lang="scss">
.row {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.active-row {
    background: #f5f5f5;
}

.inactive-row {
    background: #fff;
}

.table-row {
    display: grid;
    grid-template-columns: 50px 1.5fr 1fr 2fr 2fr 0.4fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-template-areas:
        'checkbox refNo tripDate customerName time attempt status driverName charge cost margin actions'
        '. more-info more-info more-info more-info more-info more-info service-info service-info total-form total-form .';
    align-items: center;
    gap: 8px;

    &:first-child {
        padding-left: 8px;
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 1600px) {
    .table-row {
        grid-template-columns: 50px 1.5fr 1fr 2fr 1fr 1fr 1fr 1fr 0.5fr;
        grid-template-areas:
            'checkbox refNo tripDate customerName status driverName charge cost actions'
            '. more-info more-info more-info service-info service-info total-form total-form .';
    }

    .attempt {
        display: none;
    }

    .time {
        display: none;
    }

    .margin {
        display: none;
    }
}

.checkbox {
    grid-area: checkbox;
}

.ref-no {
    grid-area: ref-no;
}

.trip-date {
    grid-area: tripDate;
}

.customer-name {
    grid-area: customerName;
}

.time {
    grid-area: time;
    cursor: pointer;
    padding: 5px; // add padding to time to increase clickable area

    & .edit-icon {
        scale: 80%;
    }
}

.status {
    grid-area: status;
}

.driver-name {
    grid-area: driverName;
}

.charge {
    grid-area: charge;

    justify-self: end;
    float: right !important;
}

.toggleIcon {
    grid-area: toggleIcon;
}

.margin {
    grid-area: margin;

    justify-self: end;
}

.actions {
    grid-area: actions;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.more-info {
    grid-area: more-info;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.more-info .section-item {
    display: grid;
    grid-template-columns: 1fr 7fr;
    width: 100%;
    align-items: start;
    justify-items: start;
    gap: 10px;
}

.total-form {
    align-self: start;
    grid-area: total-form;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
}

.total-form .section-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: start;
    justify-items: start;
    gap: 10px;
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: contain;
}

.address-tooltip-content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
    justify-items: start;
    align-items: start;
}

.time-tooltip-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-items: start;
    align-items: start;
}

.sync-loader {
    display: inline-block;
    background-color: #2b93ff;
    border-radius: 50%;
    width: 27px;
    height: 27px;

    svg {
        margin-top: 3px;
        margin-left: 2px;
    }
}

.nested-table-container {
    margin: 20px 50px 20px 50px;
}

.recalculate-icon {
    height: 28px !important;
    min-width: 28px !important;
    max-width: 28px !important;
}

.load-form {
    align-self: start;
    grid-area: more-info;
    display: inline-grid;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
}

.load-form .section-item {
    display: inline-grid;
    grid-template-columns: 1fr 4fr;
    align-items: start;
    justify-items: start;
    width: 33%;
    span {
        margin-left: 10px;
    }
}

.service-form {
    align-self: start;
    grid-area: service-info;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
}

.service-form .section-item {
    display: block;
    width: 80%;
}

.section-item {
    span {
        overflow: visible !important;
    }
}

.capacity-images {
    border-radius: 50%;
    transform: scale(1.2);
}

.cost-container {
    display: grid;
    grid-area: cost;
    position: relative;
    width: 100%;
    height: 100%;
}

.cost-span {
    grid-area: cost;
    align-self: start;
    justify-self: end;
    padding-right: 25px;
}

.cost-icon {
    grid-area: cost;
    justify-self: end;
    position: absolute;
}

.charge-container {
    display: grid;
    grid-area: charge;
    position: relative;
    width: 100%;
    height: 100%;
}

.charge-span {
    grid-area: charge;
    align-self: start;
    justify-self: end;
    padding-right: 25px;
}

.charge-icon {
    grid-area: charge;
    justify-self: end;
    position: absolute;
}

.attempt {
    grid-area: attempt;
    padding-left: 5px;
}
</style>
