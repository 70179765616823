<template>
    <div class="modal-container">
        <div class="modal-header">
            <span v-if="selectedQuestion === null">Add Question ({{ selectedGroup }})</span>
            <span v-if="selectedQuestion !== null">Edit ({{ selectedGroup }})</span>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body questions-form-modal">
            <div class="stop-form-container">
                <div v-if="!isEditMode && isBookingGroup">
                    <form-group>
                        <md-radio v-model="selectedKind" value="Standard">Standard</md-radio>
                        <md-radio v-model="selectedKind" value="Custom">Custom</md-radio>
                    </form-group>
                </div>
                <template v-if="!isEditMode && isBookingGroup && selectedKind === 'Standard'">
                    <div v-if="!questionStandardBookingOptions.length">No Standard questions available.</div>
                    <md-table v-else class="context-menu-support custom-paginated-table">
                        <md-table-row>
                            <md-table-head></md-table-head>
                            <md-table-head>Label</md-table-head>
                            <md-table-head>Description</md-table-head>
                            <md-table-head>Type</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(q, index) in questionStandardBookingOptions" :key="index">
                            <md-table-cell>
                                <md-checkbox
                                    class="stop-checkbox"
                                    :value="q"
                                    v-model="selectedStandardQuestions"
                                ></md-checkbox>
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.label }}
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.description }}
                            </md-table-cell>
                            <md-table-cell>
                                {{ q.type }}
                                <ul v-if="q.options && q.options.length">
                                    <li v-for="(opt, ndx) in q.options" :key="ndx">
                                        {{ opt }}
                                    </li>
                                </ul>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </template>
                <template v-else>
                    <form-wrapper :validator="$v.form" class="form-wrapper">
                        <form-group v-if="isBookingGroup" label="Name" name="questionName">
                            <md-input v-model.trim="form.questionName" :disabled="isEditMode" />
                        </form-group>
                        <form-group label="Label" name="label">
                            <md-input v-model="form.label" :disabled="isBookingStandard" />
                        </form-group>
                        <form-group name="type">
                            <vue-select
                                v-if="questionTypes"
                                :options="questionTypes"
                                placeholder="Type"
                                v-model="form.type"
                                :searchable="$root.isDesktop"
                                :disabled="isBookingStandard"
                            ></vue-select>
                        </form-group>
                        <div
                            class="md-layout md-medium-size-100 md-xsmall-size-100 md-size-100 options"
                            v-if="form.type === 'Dropdown'"
                        >
                            <div class="md-layout-item md-size-100">
                                <div v-if="isTextBoxOptions">
                                    <div
                                        class="md-layout-item md-size-10 option-toggle-button option-toggle-button-list"
                                    >
                                        <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                            <md-icon>list</md-icon>
                                            <md-tooltip md-direction="left">Use list view to add option</md-tooltip>
                                        </md-button>
                                    </div>
                                    <label class="label-text">Available Options:</label>
                                    <form-group name="options">
                                        <md-textarea v-model="optionValue" placeholder="Option" @change="addOptions" />
                                    </form-group>
                                </div>
                                <div v-else>
                                    <form-group name="options">
                                        <md-input v-model="optionValue" placeholder="Option" @keyup.enter="addOption" />
                                    </form-group>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-0 add-icon" v-if="!isTextBoxOptions" @click="addOption">
                                <md-icon>add</md-icon>
                            </div>
                        </div>
                        <div
                            v-if="this.form.options.length && !isTextBoxOptions && form.type === 'Dropdown'"
                            class="options-table"
                        >
                            <div class="md-layout-item md-size-10 option-toggle-button">
                                <md-button class="md-primary md-just-icon md-round" @click="toggleOptions">
                                    <md-icon>launch</md-icon>
                                    <md-tooltip md-direction="left">Use text box field</md-tooltip>
                                </md-button>
                            </div>
                            <label>Available Options:</label>
                            <md-table>
                                <md-table-row v-for="(option, index) in form.options" :key="index">
                                    <md-table-cell>
                                        <form-group>
                                            <md-input v-model="option.value" />
                                        </form-group>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-button
                                            class="md-just-icon md-danger md-simple"
                                            title="delete option "
                                            @click.native="removeOption(index)"
                                        >
                                            <md-icon>close</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                        <div>
                            <md-checkbox v-model="form.isRequired">Required</md-checkbox>
                        </div>
                        <div v-if="['proofofdelivery', 'arrivedatstop'].includes(selectedGroupNormalized)">
                            <md-checkbox v-model="form.includeInPdf">Include in Proof Of Delivery PDF</md-checkbox>
                        </div>
                        <div v-else-if="selectedGroupNormalized === 'faileddelivery'">
                            <md-checkbox v-model="form.includeInPdf">
                                Include in Failed Delivery PDF
                            </md-checkbox>
                        </div>
                        <div
                            v-if="
                                ['Proof of Delivery', 'Failed Delivery', 'Trip Start', 'Trip End'].includes(
                                    selectedGroup
                                )
                            "
                        >
                            <form-group label="Condition" class="group-filter">
                                <md-select v-model="form.conditions">
                                    <md-option
                                        v-for="(condition, index) in conditionsOptions"
                                        :key="index"
                                        :value="condition.value"
                                    >
                                        {{ condition.name }}
                                    </md-option>
                                </md-select>
                            </form-group>
                            <div class="multiple-field-container" v-if="form.conditions == 'teamRegions'">
                                <multiselect
                                    v-model="conditionsValue.teamRegions"
                                    :options="teamRegionsOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Pick team regions"
                                    label="name"
                                    track-by="teamRegionId"
                                >
                                    <template v-slot:option="{ option }">
                                        <span :style="{marginLeft: getIndentation(option)}">
                                            {{ !option.$isDisabled ? option.name : option.selectedLabel }}
                                        </span>
                                    </template>
                                </multiselect>
                                <div>
                                    <md-tooltip 
                                        class="team-region-popup" 
                                        md-direction="right" 
                                        :md-active.sync="teamRegionPopUpActive">{{teamRegionPopUpMessage}}
                                    </md-tooltip>
                                </div>
                            </div>
                            <div class="multiple-field-container" v-else-if="form.conditions == 'shipmentContents'">
                                <multiselect
                                    v-model="conditionsValue.shipmentContents"
                                    :options="shipmentContentsOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Pick shipment contentss"
                                    group-values="values"
                                    group-label="label"
                                    :group-select="true"
                                    track-by="name"
                                    label="name"
                                ></multiselect>
                            </div>
                            <div v-else-if="form.conditions == 'customFields'">
                                <div class="md-layout custom-fields">
                                    <div class="md-layout-item">
                                        <form-group label="Name" class="group-filter">
                                            <md-select v-model="conditionsValue.customFields.name">
                                                <md-optgroup label="Stop Custom Fields" class="custom-field-option">
                                                    <md-option
                                                        v-for="(stopCustomField, index) in stopCustomFieldsOptions"
                                                        :key="index"
                                                        :value="stopCustomField.name"
                                                    >
                                                        {{ stopCustomField.label }}
                                                    </md-option>
                                                </md-optgroup>
                                                <md-optgroup label="Shipment Custom Fields" class="custom-field-option">
                                                    <md-option
                                                        v-for="(shipmentCustomField,
                                                        index) in shipmentCustomFieldsOptions"
                                                        :key="index"
                                                        :value="shipmentCustomField.name"
                                                    >
                                                        {{ shipmentCustomField.label }}
                                                    </md-option>
                                                </md-optgroup>
                                            </md-select>
                                        </form-group>
                                    </div>
                                    <div class="md-layout-item">
                                        <form-group label="Operator" class="group-filter">
                                            <md-select v-model="conditionsValue.customFields.operator">
                                                <md-option
                                                    v-for="(operator, index) in operatorOptions"
                                                    :key="index"
                                                    :value="operator.value"
                                                >
                                                    {{ operator.name }}
                                                </md-option>
                                            </md-select>
                                        </form-group>
                                    </div>
                                    <div class="md-layout-item">
                                        <form-group label="Value" name="value">
                                            <md-input v-model="conditionsValue.customFields.value" />
                                        </form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form-group v-if="isBookingGroup" label="Description" name="Description">
                            <md-input v-model="form.description" />
                        </form-group>
                    </form-wrapper>
                </template>
            </div>
        </div>
        <div class="modal-footer">
            <md-button v-if="selectedQuestion === null" class="dialog-button md-primary" @click="addQuestion">
                Add
            </md-button>
            <md-button v-if="selectedQuestion !== null" class="dialog-button md-primary" @click="editQuestion">
                Save
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required, requiredIf, maxLength, helpers } from 'vuelidate/lib/validators';
import {
    QUESTION_TYPES_CONSTANTS,
    QUESTION_KIND_OPTIONS,
    QUESTION_STANDARD_BOOKING_OPTIONS,
    DEFAULT_SHIPMENT_CONTENTS_CONSTANTS,
    OPERATOR_CONSTANTS,
    CONDITION_CONSTANTS
} from '@/utils/constants';

export default {
    name: 'QuestionModal',
    mixins: [GeneralMixin],
    props: {
        selectedQuestion: {
            type: Object,
            default: null
        },
        selectedGroup: {
            type: String,
            default: null
        },
        questionsList: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            form: {
                questionName: '', // the 'name' field shows 'Asset Name is required' when validation fails
                label: '',
                type: '',
                isRequired: false,
                includeInPdf: false,
                options: [],
                description: '',
                kind: null,
                conditions: null
            },
            isTextBoxOptions: false,
            questionfield: {},
            questionTypes: [],
            optionValue: null,
            questionKindOptions: QUESTION_KIND_OPTIONS,
            conditionsOptions: CONDITION_CONSTANTS,
            conditionsValue: null,
            teamRegionsOptions: [],
            shipmentContentsOptions: DEFAULT_SHIPMENT_CONTENTS_CONSTANTS,
            stopCustomFieldsOptions: [],
            shipmentCustomFieldsOptions: [],
            operatorOptions: OPERATOR_CONSTANTS,
            questionStandardBookingOptions: [],
            selectedStandardQuestions: [],
            selectedKind: 'Standard',
            teamRegionPopUpActive: false,
            teamRegionPopUpMessage: '',
            teamRegionsInitialized: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            teamRegions: 'team/teamRegions'
        }),
        selectedGroupNormalized() {
            return this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '');
        },
        isBookingGroup() {
            return this.selectedGroup.toLowerCase() === 'booking';
        },
        isEditMode() {
            return !!this.selectedQuestion;
        },
        isBookingStandard() {
            return this.isBookingGroup && this.selectedQuestion?.kind === 'Standard';
        }
    },
    mounted() {
        this.teamRegionsOptions = this.teamRegions.map((x) => ({
            teamRegionId: x.teamRegionId,
            name: x.name,
            selectedLabel: `${x.name} (selected)`, // label for the team region when the its parent is selected
            level: x.level,
            parentTeamRegionId: x.parentTeamRegionId,
            $isDisabled: false // UI (Multiselect comp) needed this field for styling purposes
        }));
        if (this.user.stopCustomFieldDefinitions) {
            this.stopCustomFieldsOptions = this.user.stopCustomFieldDefinitions.map((x) => ({
                name: x.name,
                label: x.label
            }));
        }
        if (this.user.shipmentCustomFieldDefinitions) {
            this.shipmentCustomFieldsOptions = this.user.shipmentCustomFieldDefinitions.map((x) => ({
                name: x.name,
                label: x.label
            }));
        }

        this.questionTypes = [...QUESTION_TYPES_CONSTANTS];
        if (['Trip Start', 'Trip End'].includes(this.selectedGroup)) {
            this.questionTypes.push('Signature');
        }
        if (!this.isEditMode) {
            if (!this.isBookingGroup) {
                this.selectedKind = 'Custom';
            } else {
                // on create (booking group), set the selected kind to 'Standard' questions
                // and hide those that were already added
                this.selectedKind = 'Standard';
                // questionStandardBookingOptions: QUESTION_STANDARD_BOOKING_OPTIONS,
                this.questionStandardBookingOptions = QUESTION_STANDARD_BOOKING_OPTIONS.filter((std) => {
                    return (
                        this.questionsList.findIndex((curr) => curr.kind === std.kind && curr.name === std.name) === -1
                    );
                });
            }
        }

        if (this.selectedQuestion !== null) {
            this.form.questionName = this.selectedQuestion.name;
            this.form.label = this.selectedQuestion.label;
            this.form.type = this.selectedQuestion.type;
            this.form.isRequired = this.selectedQuestion.isRequired;
            this.form.includeInPdf = this.selectedQuestion.includeInPdf;
            this.form.options = this.selectedQuestion.options || [];
            this.form.description = this.selectedQuestion.description;
            this.conditionsValue = this.selectedQuestion.conditions;
        }
        this.form.conditions = this.conditionsValue === null ? 'none' : Object.keys(this.conditionsValue)[0];
    },
    watch: {
        'form.type': function(value) {
            if (value !== 'Dropdown') {
                this.form.options = [];
                this.optionValue = null;
            }
        },
        'form.conditions': function(newValue, oldValue) {
            if (!newValue[oldValue] && oldValue != null) {
                let value = 'none';
                if (newValue === 'teamRegions') {
                    value = {
                        teamRegions: null
                    };
                } else if (newValue === 'shipmentContents') {
                    value = {
                        shipmentContents: null
                    };
                } else if (newValue === 'customFields') {
                    value = {
                        customFields: {
                            name: null,
                            operator: null,
                            value: null
                        }
                    };
                }
                this.conditionsValue = value;
            }
        },
        'conditionsValue.teamRegions': function(newTeamRegionValues, oldTeamRegionValues) {
            if (JSON.stringify(newTeamRegionValues) === JSON.stringify(oldTeamRegionValues)) 
                return; // This block will avoid infinite loop

            if (!newTeamRegionValues && (!oldTeamRegionValues || oldTeamRegionValues.length === 0)) 
                return;

            // We need to initialize team region data of old questions if they lacks some property
            newTeamRegionValues.forEach(val => {
                const newVal = this.teamRegionsOptions.find(tr => tr.teamRegionId === val.teamRegionId);
                if (newVal){
                    if (!val.level)
                        val.level = newVal.level;
                    if (!val.parentTeamRegionId)
                        val.parentTeamRegionId = newVal.parentTeamRegionId;
                    if (!val.$isDisabled)
                        val.$isDisabled = newVal.$isDisabled;
                    if (!val.selectedLabel)
                        val.selectedLabel = newVal.selectedLabel;
                    
                    val.name = newVal.name;
                }
            });

            const processedTeamRegionIds = [];
            if (newTeamRegionValues && newTeamRegionValues.length > 0){
                let teamRegionsToDisplay = newTeamRegionValues;
    
                let maxHierarchyLevel = newTeamRegionValues[0].level ? newTeamRegionValues[0].level : 1;
                for (let index = 1; index < newTeamRegionValues.length; index++) {
                    const teamReg = newTeamRegionValues[index];
                    if (teamReg.level && +teamReg.level > maxHierarchyLevel) 
                        maxHierarchyLevel = teamReg.level;
                }
    
                for (let hierarchyLevel = 1; hierarchyLevel <= maxHierarchyLevel; hierarchyLevel++) {
                    const teamRegionsInThisLevel = newTeamRegionValues.filter(tr => tr.level === hierarchyLevel);
                
                    for (let trIndex = 0; trIndex < teamRegionsInThisLevel.length; trIndex++) {
                        const teamRegion = teamRegionsInThisLevel[trIndex];
                        
                        if (!processedTeamRegionIds.includes(teamRegion.teamRegionId)){
                            const allSiblingRegions = this.teamRegionsOptions
                                .filter(tr => tr.parentTeamRegionId === teamRegion.parentTeamRegionId);
                    
                            const allSiblingRegionsSelected = allSiblingRegions.every(br => 
                                newTeamRegionValues.map(tr => tr.teamRegionId).includes(br.teamRegionId));
                            
                            // Same level (siblings)
                            if (allSiblingRegionsSelected){
                                // all siblings are selected, we will tag and remove them on the multiselect field
                                allSiblingRegions.forEach(cr => {
                                    cr.$isDisabled = true;
                                    processedTeamRegionIds.push(cr.teamRegionId);
                                });
                                
                                teamRegion.$isDisabled = true;
        
                                // remove the siblings
                                teamRegionsToDisplay = teamRegionsToDisplay
                                    .filter(tr => !allSiblingRegions.map(chr => chr.teamRegionId).includes(tr.teamRegionId));
                                
                                // insert the parent region
                                if (!teamRegionsToDisplay.find(tr => tr.teamRegionId === teamRegion.parentTeamRegionId)){
                                    const parentTeamRegion = this.teamRegionsOptions.find(tr => tr.teamRegionId === teamRegion.parentTeamRegionId);
                                    teamRegionsToDisplay.push(parentTeamRegion);

                                    if (this.teamRegionsInitialized)
                                        this.setPopUpActive(parentTeamRegion); // fire a popup message
                                }
        
                                processedTeamRegionIds.push(teamRegion.parentTeamRegionId);
                            }
        
                            // Lower level (children)
                            const allChildRegions = this.teamRegionsOptions
                                .filter(tr => tr.parentTeamRegionId === teamRegion.teamRegionId);
                                
                            let grandChildRegionsToRemove = [];
                            allChildRegions.forEach(cr => {
                                cr.$isDisabled = true;
                                processedTeamRegionIds.push(cr.teamRegionId);

                                grandChildRegionsToRemove = this.teamRegionsOptions
                                    .filter(tr => tr.parentTeamRegionId === cr.teamRegionId);
                                
                                grandChildRegionsToRemove.forEach(gcr => {
                                    gcr.$isDisabled = true;
                                    processedTeamRegionIds.push(gcr.teamRegionId);
                                });
                            });
        
                            const childRegionsToRemove = teamRegionsToDisplay
                                .filter(tr => allChildRegions.map(cr => cr.teamRegionId).includes(tr.teamRegionId));
                            
                            // remove the child regions
                            teamRegionsToDisplay = teamRegionsToDisplay
                                .filter(tr => !childRegionsToRemove.map(chr => chr.teamRegionId).includes(tr.teamRegionId));

                            if (grandChildRegionsToRemove.length > 0){
                                // remove the grand child regions
                                teamRegionsToDisplay = teamRegionsToDisplay
                                    .filter(tr => !grandChildRegionsToRemove.map(chr => chr.teamRegionId).includes(tr.teamRegionId));
                            }
            
                            processedTeamRegionIds.push(teamRegion.teamRegionId);
                        }
                    }
                }
    
                this.conditionsValue.teamRegions = teamRegionsToDisplay;
            }

            this.teamRegionsOptions.forEach(trOption => {
                if (!processedTeamRegionIds.find(id => id === trOption.teamRegionId))
                    trOption.$isDisabled = false;
            });

            this.teamRegionsInitialized = true;
        }
    },
    methods: {
        toggleOptions() {
            this.isTextBoxOptions = !this.isTextBoxOptions;
            if (this.isTextBoxOptions) {
                this.optionValue = this.form.options.length ? this.form.options.map((x) => x.value).join('\n') : null;
            } else {
                this.optionValue = null;
            }
        },
        isDuplicateOption(value) {
            const index = this.form.options.findIndex((x) => x.value.toLowerCase() === value.toLowerCase());

            if (index === -1) {
                return false;
            }

            return index;
        },
        showWarningDuplicate(value) {
            const message = `Option name ${value} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        },
        addOptions() {
            const options = [];
            this.optionValue.split('\n').forEach((option) => {
                const index = options.findIndex((x) => x.value.toLowerCase() === option.toLowerCase());
                if (option && index === -1) {
                    options.push({
                        value: option
                    });
                }
            });
            this.form.options = options;
            this.optionValue = options.map((x) => x.value).join('\n');
        },
        addOption() {
            if (this.optionValue != null) {
                if (!this.form.options) {
                    this.form.options = [
                        {
                            value: this.optionValue
                        }
                    ];
                    this.optionValue = null;
                } else if (!this.isDuplicateOption(this.optionValue)) {
                    this.form.options.push({
                        value: this.optionValue
                    });
                    this.optionValue = null;
                } else {
                    this.showWarningDuplicate(this.optionValue);
                }
            }
        },
        removeOption(index) {
            this.form.options.splice(index, 1);
        },
        addQuestion() {
            if (this.selectedKind === 'Standard') {
                if (!this.selectedStandardQuestions.length) {
                    this.$notify({
                        message: 'Please select at least 1 Standard question.',
                        type: 'warning'
                    });
                    return;
                }

                // check for duplicate question 'name'
                const duplicateQuestions = this.questionsList.filter((curr) => {
                    return this.selectedStandardQuestions.findIndex((nu) => nu.name === curr.name) > -1;
                });

                if (duplicateQuestions.length) {
                    const duplicateNames = duplicateQuestions.map((d) => d.name);
                    this.$notify({
                        message: `Question with the same name already exist. ${duplicateNames.join()}`,
                        type: 'warning'
                    });
                    return;
                }

                const standardQuestions = {
                    booking: [...this.selectedStandardQuestions]
                };

                this.resolve(standardQuestions);
            } else {
                // other question group or booking/custom
                this.$v.form.$touch();
                if (!this.$v.form.$invalid) {
                    let nameField = null;
                    if (this.isBookingGroup) {
                        nameField = this.form.questionName;
                    } else {
                        nameField = this.form.label
                            .toLowerCase()
                            .replace(/[^\w]/gi, '')
                            .substring(0, 20); // name to a max of 20 chars
                    }

                    this.questionfield = {
                        [this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '')]: [
                            {
                                kind: 'Custom',
                                name: nameField,
                                label: this.form.label,
                                type: this.form.type,
                                isRequired: this.form.isRequired,
                                includeInPdf: this.form.includeInPdf,
                                options: this.form.options,
                                description: this.form.description,
                                conditions: this.form.conditions === 'none' ? null : this.conditionsValue
                            }
                        ]
                    };
                    this.resolve(this.questionfield);
                }
            }
        },
        editQuestion() {
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.questionfield = { ...this.form, name: this.form.questionName, conditions: this.conditionsValue };
                this.resolve(this.questionfield);
            }
        },
        getIndentation(option) {
            if (!option) 
                return '0px';

            const optionLevel = option.level;
            let indentation = 0;

            if (optionLevel > 1) 
                indentation = (+optionLevel - 1) * 20;

            return `${indentation}px`
        },
        setPopUpActive(teamRegion){
            if (!teamRegion || !teamRegion.name) 
                return;

            this.teamRegionPopUpMessage = `You have selected all child regions of '${teamRegion.name}' region.`;
            this.teamRegionPopUpActive = true;

            setTimeout(() => {
                this.teamRegionPopUpActive = false;
                this.teamRegionPopUpMessage = '';
            }, 5000);
        }
    },
    validations() {
        return {
            form: {
                questionName: {
                    // name is a free-input for Booking group to let Carrier customise it for their own purpose
                    required: requiredIf(() => {
                        return this.isBookingGroup;
                    }),
                    maxLength: maxLength(40),
                    alpha: () => {
                        if (!this.isBookingGroup) 
                            return true;

                        // validate if under booking group
                        const alpha = helpers.regex('alpha', /^[a-zA-Z]*$/);
                        return alpha(this.form.questionName);
                    },
                    duplicate: () => {
                        if (!this.isBookingGroup) 
                            return true;

                        // validate if under booking group
                        const questionsData = this.questionsList.filter((item) => item.name === this.form.questionName);
                        return !(!this.isEditMode && questionsData.length);
                    }
                },
                label: {
                    required
                },
                type: {
                    required
                },
                options: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.form.type === 'Dropdown';
                        })
                }
            }
        };
    }
};
</script>
<style lang="scss" scoped>
.questions-form-modal {
    max-width: 550px;
    width: 550px;
}
.options {
    ::v-deep .md-layout-item {
        padding: 0px;
    }
    ::v-deep input {
        border-bottom: 1px solid #dedede !important;
        margin: 0;
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 10px;
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
    }
    ::v-deep .md-field {
        padding: 0px;
        margin-top: 5px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    ::v-deep .md-textarea {
        padding: 5px 8px !important;
        border: 1px solid #eee !important;
        resize: none !important;
        min-height: 150px !important;
    }
    ::v-deep .md-error {
        padding-top: 12px;
    }
    .add-icon {
        margin-left: -25px;
        float: right;
        cursor: pointer;
        z-index: 100;
        margin-top: 10px;
    }
    .label-text {
        padding: 0px !important;
    }
}
.options-table {
    ::v-deep .md-table-cell {
        padding: 0px;
        border: 0px;
    }
    ::v-deep input {
        border: 1px solid #dedede;
        width: 100%;
        padding: 8px;
    }
    ::v-deep .md-field {
        padding: 0px;
        &::before,
        &::after {
            bottom: 0px;
            display: none;
        }
    }
    .md-table {
        max-height: 250px;
    }
}
.option-toggle-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: -30px;
    margin-right: 5px;

    .md-button {
        // background-color: #2b93ff  !important;
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 0;
        ::v-deep i {
            color: #2b93ff !important;
        }
        background-color: transparent !important;
    }
    .md-button:active,
    .md-button:focus {
        background-color: transparent !important;
        ::v-deep i {
            color: #2b93ff !important;
        }
    }
    .md-button:hover {
        background: none !important;
        background-color: #2b93ff !important;
        ::v-deep i {
            color: #fff !important;
        }
    }
}
.option-toggle-button-list {
    margin-left: -15px !important;
}

.modal-container {
    width: 550px !important;
    max-width: 550px !important;
}
.modal-body {
    width: 550px !important;
}

.modal-footer {
    width: 550px !important;
}

::v-deep .vs__search {
    padding: 0 !important;
}
.multiple-field-container {
    margin: 13px 0 20px 0;
}
.custom-fields {
    ::v-deep .md-layout-item {
        padding: 0 0 0 10px;
        width: 30%;
    }
}
.custom-field-option {
    ::v-deep .md-subheader {
        text-transform: capitalize;
        font-weight: bold;
    }

    ::v-deep .md-list-item {
        margin-left: 15px !important;
    }
}
.team-region-popup{
    position: absolute; 
    top: 0; 
    left: 100%;
}
</style>
