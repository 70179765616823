<template>
    <div class="profile-container">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
                <md-card class="md-card-profile">
                    <div
                        class="md-card-avatar upload-container"
                        @mouseover="showUploadButton = true"
                        @mouseleave="showUploadButton = false"
                    >
                        <img
                            v-if="!photoPreview"
                            class="profile-image"
                            :src="member.photoUrl !== null ? member.photoUrl : $root.defaultPhotoUrl"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <img
                            v-if="photoPreview"
                            class="profile-image"
                            :src="photoPreview"
                            @error="$_setDefaultBrokenImage"
                            alt
                        />
                        <div v-if="showUploadButton && canUpdateMember">
                            <input ref="photoInput" hidden type="file" @change="onPhotoSelected" />
                            <div>
                                <button class="custom-btn" @click="$refs.photoInput.click()">Update Photo</button>
                            </div>
                        </div>
                    </div>

                    <md-card-content>
                        <h6 class="category text-gray">
                            {{ displayRole }}
                        </h6>
                        <span class="card-description">
                            Date Joined: {{ member.dateJoined | dateFormat(DATE_TYPES.standardDate) }}
                        </span>
                        <h4 class="card-title">
                            {{ displayName }}
                        </h4>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-medium-size-75 md-xsmall-size-100 md-size-75">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>person</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div class="member-information field-spacing-container">
                            <form-wrapper :validator="$v.member" class="form-wrapper">
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="firstName" label="First Name">
                                                <md-input v-model="member.firstName" :disabled="!canUpdateMember" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="lastName" label="Last Name">
                                                <md-input v-model="member.lastName" :disabled="!canUpdateMember" />
                                            </form-group>
                                        </div>
                                    </div>

                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="email" label="Email Address">
                                                <md-input v-model="member.email" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="phone" label="Phone">
                                                <md-input v-model="member.phone" :disabled="!canUpdateMember" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="company" label="Company">
                                                <md-input v-model="member.company" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group
                                                name="businessRegistrationNumber"
                                                :label="businessRegistrationNumberLabel"
                                            >
                                                <md-input v-model="member.businessRegistrationNumber" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout half-width">
                                        <div class="md-layout-item">
                                            <form-group name="accountemail" label="Accounts Email Address">
                                                <md-input v-model="member.accountsEmail" />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="skillList.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="skillfield-container">
                                                <label for="skills" v-if="member.skills.length">Skills</label>
                                                <multiselect
                                                    v-model="member.skills"
                                                    :options="skillList"
                                                    :multiple="true"
                                                    :close-on-select="true"
                                                    :disabled="!canUpdateMember"
                                                    placeholder="Pick skills"
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="serviceAreasList && serviceAreasList.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <div class="skillfield-container">
                                                <label for="serviceAreas">
                                                    Service Areas
                                                </label>
                                                <multiselect
                                                    v-model="member.serviceAreas"
                                                    :options="serviceAreasList"
                                                    :multiple="true"
                                                    :close-on-select="true"
                                                    placeholder="Pick service areas"
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box" v-if="capacityDefinitions.length">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <capacity-inputs
                                                v-for="(capacity, index) in capacityDefinitions"
                                                :key="index"
                                                :capacity-definition="capacity"
                                                :initial-value="capacityValues[capacity.type]"
                                                :disabled="!canUpdateMember"
                                                @changed="handleCapacityChanged"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div v-if="showTeamRegion" class="md-layout-item">
                                            <form-group name="team-region" label="Team Region">
                                                <team-region-options
                                                    mode="update"
                                                    :selected="member.teamRegionId"
                                                    :show-auto="false"
                                                    :enabled="canEditOwnTeamRegion && canUpdateMember"
                                                    @selectedOption="handleTeamRegionChanged"
                                                />
                                            </form-group>
                                        </div>

                                        <div class="md-layout-item">
                                            <form-group
                                                name="rateGroup"
                                                label="Default Service Package"
                                                class="md-field-split"
                                            >
                                                <md-select v-model="rateGroupId" :disabled="!canUpdateMember">
                                                    <md-option
                                                        v-for="item in rateGroups"
                                                        :key="item.rateGroupId"
                                                        :value="item.rateGroupId"
                                                    >
                                                        {{ item.rateGroupName }}
                                                    </md-option>
                                                </md-select>
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div v-if="!hasTeam" class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="company" label="Company">
                                                <md-input v-model="member.company" :disabled="!canUpdateMember" />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item">
                                            <form-group name="industry" label="Industry">
                                                <md-input v-model="member.industry" :disabled="!canUpdateMember" />
                                            </form-group>
                                        </div>
                                    </div>
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group name="role" label="Team Role">
                                                <md-select
                                                    v-model="member.role"
                                                    @md-selected="getSelectedRole"
                                                    :disabled="
                                                        user.publicUserId === member.publicUserId || !canUpdateMember
                                                    "
                                                >
                                                    <md-option
                                                        v-for="memberRole in memberRoles"
                                                        :key="memberRole.id"
                                                        :value="memberRole.name"
                                                    >
                                                        {{ memberRole.name }}
                                                    </md-option>
                                                </md-select>
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item vehicle-box">
                                            <label>Vehicle:</label>
                                            <md-radio
                                                v-model="member.vehicleType"
                                                value="Bike"
                                                :disabled="!canUpdateMember"
                                            >
                                                Bike
                                            </md-radio>
                                            <md-radio
                                                v-model="member.vehicleType"
                                                value="Car"
                                                :disabled="!canUpdateMember"
                                            >
                                                Car
                                            </md-radio>
                                            <md-radio
                                                v-model="member.vehicleType"
                                                value="Walk"
                                                :disabled="!canUpdateMember"
                                            >
                                                Walk
                                            </md-radio>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <form-group
                                                name="role"
                                                label="Mobile Location Tracking Behaviour"
                                                class="md-field-split"
                                            >
                                                <gps-collection-dropdown
                                                    :selected="member.gpsDataCollectionRule"
                                                    @selectedOption="getSelectedTrackingBehaviour"
                                                    :disabled="!canUpdateMember"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                v-if="isLoaded"
                                                name="defaultTripStartTime"
                                                label="Start Time"
                                                class="timer-field hide-timer-clear-button"
                                            >
                                                <time-picker
                                                    :time="member.defaultTripStartTime"
                                                    :all-time-options="false"
                                                    :clear-none="true"
                                                    :disabled="!canUpdateMember"
                                                    @selectedTime="getStartTime"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                v-if="isLoaded"
                                                name="defaultTripEndTime"
                                                label="End Time"
                                                class="timer-field"
                                            >
                                                <time-picker
                                                    :time="member.defaultTripEndTime"
                                                    :all-time-options="false"
                                                    :disabled="!canUpdateMember"
                                                    @selectedTime="getEndTime"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group
                                                name="defaultStopDurationMinutes"
                                                label="Default Stop Duration (in Minutes)"
                                            >
                                                <md-input
                                                    type="number"
                                                    v-model.number="member.defaultStopDurationMinutes"
                                                />
                                            </form-group>
                                        </div>
                                        <div class="md-layout-item md-xsmall-size-100 md-size-25">
                                            <form-group name="stopDurationMultiplier" label="Stop Duration Multiplier">
                                                <md-input
                                                    type="number"
                                                    v-model.number="member.stopDurationMultiplier"
                                                />
                                            </form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <google-autocomplete
                                                label="Start Address"
                                                ref="startaddress"
                                                id="startaddress"
                                                classname="form-control autocomplete-input"
                                                placeholder=""
                                                v-model="startLocation"
                                                @placechanged="handleStartLocationChange"
                                                :disabled="!canUpdateMember"
                                            ></google-autocomplete>
                                        </div>
                                    </div>
                                </div>

                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <google-autocomplete
                                                label="End Address"
                                                ref="endaddress"
                                                id="endaddress"
                                                classname="form-control autocomplete-input"
                                                placeholder=""
                                                v-model="endLocation"
                                                @placechanged="handleEndLocationChange"
                                                :disabled="!canUpdateMember"
                                            ></google-autocomplete>
                                        </div>
                                    </div>
                                </div>

                                <payment-method-inputs :detail="member" />

                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item">
                                            <md-field>
                                                <label>Bio</label>
                                                <md-textarea v-model="member.bio" :disabled="!canUpdateMember" />
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item custom-switch">
                                            <label class="label-text">
                                                Is this user a driver?
                                                <md-icon class="icon-info">info</md-icon>
                                                <md-tooltip class="tooltip-width" md-direction="right">
                                                    This setting is used to determine whether the user can be assigned
                                                    stops or shipments.
                                                </md-tooltip>
                                            </label>
                                            <md-switch v-model="member.isDriver"></md-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item custom-switch">
                                            <label class="label-text">Enable Stop/Shipment Offer Notifications</label>
                                            <md-switch v-model="member.canReceiveOffers"></md-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="information-box">
                                    <div class="md-layout">
                                        <div class="md-layout-item custom-switch">
                                            <label class="label-text">
                                                Apply tax for invoicing?
                                            </label>
                                            <md-switch v-model="member.shouldApplyTax"></md-switch>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <md-button
                                        v-if="canRequestDeviceLog(member.deviceType)"
                                        class="md-primary dialog-button pull-right"
                                        @click="handleRequestDeviceLog(member.publicUserId)"
                                    >
                                        Request Device Logs
                                    </md-button>
                                    <md-button
                                        v-if="
                                            member.disabledDate === null &&
                                                user.publicUserId !== member.publicUserId &&
                                                canUpdateMember
                                        "
                                        class="md-default pull-right dialog-button"
                                        @click="updateTeamMemberStatus(member.publicUserId, 'disable')"
                                    >
                                        Disable Member
                                    </md-button>
                                    <md-button
                                        v-if="
                                            member.disabledDate != null &&
                                                user.publicUserId != member.publicUserId &&
                                                canUpdateMember
                                        "
                                        class="md-default pull-right dialog-button"
                                        @click="updateTeamMemberStatus(member.publicUserId, 'enable')"
                                    >
                                        Enable Member
                                    </md-button>
                                    <md-button
                                        v-if="
                                            member.isLockedOut &&
                                                user.publicUserId != member.publicUserId &&
                                                canUpdateMember
                                        "
                                        class="md-default pull-right dialog-button unlock-button"
                                        @click="updateTeamMemberStatus(member.publicUserId, 'unlock')"
                                    >
                                        Unlock Member
                                    </md-button>
                                    <md-button
                                        v-if="canUpdateMember"
                                        class="md-primary dialog-button pull-right"
                                        @click="validateForm"
                                    >
                                        Update Profile
                                    </md-button>
                                </div>
                            </form-wrapper>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import {
    handleRequests,
    isPhoneValid,
    showErrorMessage,
    showTeamRegionControl,
    minStopDuration,
    maxStopDuration,
    isStopDurationNull
} from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters } from 'vuex';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import { GPS_DATA_COLLECTION_RULE_CONSTANTS } from '@/utils/constants';
import { BlockBlobClient } from '@azure/storage-blob';
import {
    GoogleAutocomplete,
    TimePicker,
    GpsCollectionDropdown,
    CapacityInputs,
    TeamRegionOptions,
    PaymentMethodInputs
} from '@/components';

export default {
    name: 'TeamMemberDetails',
    mixins: [GeneralMixin],
    components: {
        GoogleAutocomplete,
        TimePicker,
        GpsCollectionDropdown,
        CapacityInputs,
        TeamRegionOptions,
        PaymentMethodInputs
    },
    data() {
        return {
            member: {
                paymentDetails: {
                    paymentMethod: 'None',
                    bank: {
                        name: null,
                        accountNumber: null,
                        bsb: null
                    }
                },
                skills: [],
                teamRegionId: null
            },
            memberRoles: [],
            oldRole: null,
            gpsDataCollectionOptions: GPS_DATA_COLLECTION_RULE_CONSTANTS,
            skillList: [],
            serviceAreasList: [],
            selectedPhoto: null,
            photoPreview: null,
            startLocation: {},
            endLocation: {},
            showUploadButton: false,
            displayName: '',
            displayRole: '',
            oldMemberRole: null,
            isLoaded: false,
            capacityDefinitions: [],
            capacityValues: {},
            oldEmail: null,
            rateGroups: [],
            rateGroupId: -1,
            oldAccountNumber: null,
            businessRegistrationNumberLabel: 'Business Registration Number'
        };
    },
    validations: {
        member: {
            firstName: {
                required
            },
            email: {
                required,
                email
            },
            role: {
                required
            },
            defaultTripStartTime: {
                required
            },
            defaultTripEndTime: {
                required:
                    // eslint-disable-next-line func-names
                    requiredIf(function() {
                        return (
                            this.member.gpsDataCollectionRule === 'Business Hours' && !this.member.defaultTripEndTime
                        );
                    })
            },
            phone: {
                phoneValid: isPhoneValid
            },
            defaultStopDurationMinutes: {
                minValue_customerStopDuration: minStopDuration,
                maxValue_customerStopDuration: maxStopDuration, // max duration value of 24 hours,
                numeric_duration: isStopDurationNull
            },
            stopDurationMultiplier: {
                minValue_Multiplier(duration) {
                    if (duration === '' || duration === null) 
                        return true;

                    if (duration < 0.1) 
                        return false;

                    return true;
                },
                numeric_duration: isStopDurationNull
            },
            paymentDetails: {
                bank: {
                    accountNumber: {
                        required:
                            // eslint-disable-next-line func-names
                            requiredIf(function() {
                                return (
                                    this.member.paymentDetails.paymentMethod === 'Bank Transfer' &&
                                    !this.member.paymentDetails.bank.accountNumber
                                );
                            })
                    },
                    bsb: {
                        required:
                            // eslint-disable-next-line func-names
                            requiredIf(function() {
                                return (
                                    this.member.paymentDetails.paymentMethod === 'Bank Transfer' &&
                                    !this.member.paymentDetails.bank.bsb
                                );
                            })
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            hasTeam: 'user/hasTeam',
            hasAssetAccess: 'user/hasAssetAccess',
            isSupportUser: 'user/isSupportUser',
            isSupportAdminUser: 'user/isSupportAdminUser',
            canEditOwnTeamRegion: 'user/canEditOwnTeamRegion',
            teamRegions: 'team/teamRegions'
        }),
        showTeamRegion() {
            return showTeamRegionControl(
                {
                    user: this.user,
                    hasAssetAccess: this.hasAssetAccess,
                    teamRegions: this.teamRegions
                },
                this.member.teamRegionId
            );
        },
        canUpdateMember() {
            // can be moved to somewhere else
            const roleWeight = {
                'Team Owner': 13,
                'Office Staff': 8,
                'Team Manager': 5,
                'Team Member': 3
            };

            return roleWeight[this.user.role] >= (roleWeight[this.member.role] || 0);
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);

        const list = await this.getRateGroupsList();
        if (list && list.length) 
            this.rateGroups = [...list];

        const apirole = '/api/roles';
        handleRequests(apirole).then((response) => {
            // Asset removed from the list
            this.memberRoles = response.data.data.filter((x) => x.name !== 'Asset');
        });

        const api = `/api/team-members/${this.$route.params.publicUserId}/`;
        handleRequests(api).then((response) => {
            const details = { ...response.data.data };
            if (!details.teamRegionId) 
                details.teamRegionId = -1; // Not Set

            this.member = details;

            this.rateGroupId = this.member.rateGroupId || -1;

            this.startLocation = {
                address: this.member.startAddress,
                location: this.member.startLocation ? this.member.startLocation : { latitude: null, longitude: null }
            };
            this.endLocation = {
                address: this.member.endAddress,
                location: this.member.endLocation ? this.member.endLocation : { latitude: null, longitude: null }
            };
            this.oldRole = this.member.role;
            this.member.roles = [this.member.role];
            this.displayName = this.member.fullName;
            this.displayRole = this.member.role;
            this.oldMemberRole = this.member.role;
            this.capacityValues = this.member.vehicleCapacity || {};
            this.oldEmail = this.member.email;

            this.capacityDefinitions = this.user.vehicleCapacityUnitsConfiguration || [];
            this.member.paymentDetails = this.member.paymentDetails || {
                paymentMethod: 'None',
                bank: {
                    name: null,
                    accountNumber: null,
                    bsb: null
                }
            };
            this.oldAccountNumber = this.member.paymentDetails.bank.accountNumber;

            this.SetBusinessRegistrationNumberLabel(this.member.countryCode);

            this.$_handleLoaderState(false);
            this.isLoaded = true;
        });

        this.getSkills();
        this.getServiceAreas();
    },
    methods: {
        async getRateGroupsList() {
            let response = {
                data: []
            };
            try {
                this.isListLoading = true;
                const endpoint = `/api/rate-groups`;

                response = await handleRequests(endpoint);
            } catch (error) {
                const message = 'Error in getting the rate groups list';
                showErrorMessage(this, message, error);
            }
            this.isListLoading = false;

            let rateGroups = response.data.map((rg) => {
                return {
                    rateGroupId: rg.rateGroupId,
                    rateGroupName: rg.rateGroupName
                };
            });

            rateGroups = [
                {
                    rateGroupId: -1,
                    rateGroupName: 'Default'
                },
                ...rateGroups
            ];

            return rateGroups;
        },
        validateForm() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.update();
            }
        },
        getSelectedRole(role) {
            if (this.oldRole !== role) {
                this.oldRole = role;
                this.member.roles.push(role);
            }
        },
        update() {
            if (!this.endLocation.address) {
                this.member.endAddress = null;
                this.member.endLocation = null;
            }

            // When user enters a blank value for defaultStopDurationMinute, it's passed to the API as an empty string. This
            // results in the API not being able to deserialise the data, resulting in a null reference exception on the server.
            // So we need to convert a blank string to null for this field.
            if (this.member.defaultStopDurationMinutes === '') 
                this.member.defaultStopDurationMinutes = null;
            if (this.member.stopDurationMultiplier === '') 
                this.member.stopDurationMultiplier = null;
            if (!this.member.serviceAreas?.length) 
                this.member.serviceAreas = null;
            if (this.member.accountsEmail === '') 
                this.member.accountsEmail = null;

            if (!this.startLocation.address) {
                this.$notify({
                    message: 'Start Address is required',
                    type: 'danger'
                });
                return;
            }
            if (
                this.member.paymentDetails.bank.accountNumber &&
                this.member.paymentDetails.bank.accountNumber.includes('*')
            ) {
                this.member.paymentDetails.bank.accountNumber = this.oldAccountNumber;
            }

            if (this.oldEmail !== this.member.email) {
                this.$messageBox
                    .show({
                        title: 'Confirm Email and Username Change',

                        body:
                            "This will change both this team member's email address AND username, and they will have to re-verify their email address.  Continue?",

                        buttons: ['Yes', 'No']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'yes') 
                            this.updateRequest();
                    });
            } else {
                this.updateRequest();
            }
        },
        updateRequest() {
            this.$_handleLoaderState(true, 'UPDATING...');

            this.member.rateGroupId = this.rateGroupId !== -1 ? this.rateGroupId : null;

            this.member.vehicleCapacity = this.capacityValues;

            const api = `/api/team-members/${this.$route.params.publicUserId}`;
            const payload = {
                method: 'put',
                data: {
                    Role: this.member.roles,
                    Member: this.member
                }
            };
            handleRequests(api, payload).then(
                (response) => {
                    this.$_handleLoaderState(false);
                    if (this.oldMemberRole !== this.member.role) {
                        this.oldMemberRole = this.member.role;

                        this.$messageBox.show({
                            body:
                                'Please note that the team member will need to log out and back in to have their role change take effect',
                            buttons: ['Ok']
                        });
                    } else {
                        this.$notify({
                            message: 'Member profile successfully updated',
                            type: 'success'
                        });
                    }
                    this.displayName = `${this.member.firstName} ${this.member.lastName}`;
                    this.displayRole = this.member.role;
                    this.accountNumberMasking();
                },
                (error) => {
                    const message = 'Error in updating member profile';
                    showErrorMessage(this, message, error);
                    this.$_handleLoaderState(false);
                }
            );
        },
        updateTeamMemberStatus(publicUserId, type) {
            this.$_handleLoaderState(true, 'UPDATING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/team-members/${publicUserId}/${type}`;
            handleRequests(api, payload).then(
                (response) => {
                    this.$_handleLoaderState(false);
                    const msg = type === 'unlock' ? 'Member unlocked!' : `Member ${type}d!`;
                    this.$notify({
                        message: msg,
                        type: 'success'
                    });
                    if (type === 'enable') {
                        this.member.disabledDate = null;
                    } else if (type === 'unlock') {
                        this.member.isLockedOut = false;
                    } else {
                        this.member.disabledDate = new Date().getUTCDate;
                    }
                },
                (error) => {
                    this.$_handleLoaderState(false);
                    const message = 'Error in enabling/disabling member';
                    showErrorMessage(this, message, error);
                }
            );
        },
        getSkills() {
            if (this.user.skillOptions) {
                this.skillList = this.user.skillOptions.map((x) => x.name);
            }
        },
        getServiceAreas() {
            this.serviceAreasList = this.user.serviceAreasOptions;
        },
        onPhotoSelected(event) {
            const [file] = event.target.files;
            this.selectedPhoto = file;
            this.photoPreview = URL.createObjectURL(this.selectedPhoto);
            this.onUploadImage();
        },
        async onUploadImage() {
            this.$_handleLoaderState(true, 'UPLOADING...');
            const file = this.selectedPhoto;
            const fileType = file.type.split('/');

            let ext = fileType[1];

            if (ext === 'jpeg') {
                ext = 'jpg';
            }

            const api = `/api/team-members/${this.$route.params.publicUserId}/photo/?fileType=${ext}`;
            const response = await handleRequests(api, { method: 'post' });

            const blockBlobClient = new BlockBlobClient(response.data.uri);

            try {
                await blockBlobClient.upload(file, file.size);

                this.$notify({
                    message: `Team member's photo has been successfully uploaded. Please wait a moment for it to be processed - it will reflect on your team member profile shortly.`,
                    type: 'success'
                });

                this.selectedPhoto = null;
                this.$_handleLoaderState(false);
            } catch (e) {
                const message = 'Error in uploading photo';
                showErrorMessage(this, message, e);
                this.$_handleLoaderState(false);
            }
        },
        getStartTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.member.defaultTripStartTime = appointmentTime;
            } else {
                this.member.defaultTripStartTime = null;
            }
        },
        getEndTime(selectedTime) {
            if (selectedTime != null) {
                const { appointmentTime } = selectedTime;
                this.member.defaultTripEndTime = appointmentTime;
            } else {
                this.member.defaultTripEndTime = null;
            }
        },
        handleStartLocationChange(data) {
            this.member.startAddress = data.address;
            this.member.startLocation = data.location;
            if (data && data.addressComponents) {
                this.SetBusinessRegistrationNumberLabel(data.addressComponents.countryCode);
            }
        },
        handleEndLocationChange(data) {
            this.member.endAddress = data.address;
            this.member.endLocation = data.location;
        },
        getSelectedTrackingBehaviour(value) {
            this.member.gpsDataCollectionRule = value;
        },
        handleCapacityChanged({ name, value }) {
            this.capacityValues[name] = value;
        },
        handleTeamRegionChanged(value) {
            if (!value) {
                this.member.teamRegionId = null;
            }
            this.member.teamRegionId = value;
        },
        accountNumberMasking() {
            if (
                this.member.paymentDetails.bank.accountNumber &&
                !this.member.paymentDetails.bank.accountNumber.includes('*') &&
                this.member.paymentDetails.bank.accountNumber.length > 2
            ) {
                const { accountNumber } = this.member.paymentDetails.bank;
                this.member.paymentDetails.bank.accountNumber = this.$_getMaskingText(accountNumber);
                this.oldAccountNumber = this.member.paymentDetails.bank.accountNumber;
            }
        },
        SetBusinessRegistrationNumberLabel(countryCode) {
            switch (countryCode) {
                case 'AU':
                    this.businessRegistrationNumberLabel = 'ABN/ACN';
                    break;
                case 'NZ':
                    this.businessRegistrationNumberLabel = 'NZBN';
                    break;
                default:
                    this.businessRegistrationNumberLabel = 'Business Registration Number';
                    break;
            }
        },
        /**
         *
         * @param {string | null} deviceType - either iOS or Android. may be null if the user hasn't logged in on the mobile app yet.
         * @returns {boolean}
         */
        canRequestDeviceLog(deviceType) {
            return (this.isSupportUser || this.isSupportAdminUser) && deviceType !== null;
        },
        /**
         *
         * @param {string} publicUserId
         * @returns {Promise<void>}
         */
        async handleRequestDeviceLog(publicUserId) {
            this.$_handleLoaderState(true, 'PROCESSING...');
            const payload = {
                method: 'post'
            };
            const api = `/api/support/device-log/${publicUserId}`;
            try {
                await handleRequests(api, payload);
                this.$_handleLoaderState(false);
                this.$notify({
                    message: 'Successfully sent request for device log',
                    type: 'success'
                });
            } catch (error) {
                this.$_handleLoaderState(false);
                const message = 'Error in getting request for device log';
                showErrorMessage(this, message, error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .custom-btn {
    margin-right: 10px;
}

.md-field.md-theme-default.md-invalid:after {
    background-color: red !important;
}
.edit-actions {
    margin-top: 20px;
    padding-right: 30px;
}
.vehicle-box {
    label {
        margin-right: 10px;
    }
    .md-radio {
        width: 65px;
    }
}

.profile-container {
    margin-top: 20px;
}
.custom-switch {
    margin-top: 22px;
    margin-bottom: 0px;
    label {
        float: left;
        font-weight: 400;
        color: #aaaaaa !important;
        line-height: 1.4;
    }
    .md-checked {
        ::v-deep .md-switch-container {
            background-color: #2b93ff !important;
        }
        ::v-deep .md-switch-thumb {
            border: 1px solid #2b93ff !important;
        }
    }
    ::v-deep .md-switch-label {
        margin-top: 0px;
    }
    .md-switch {
        margin: 0;
        margin-bottom: 10px;
        padding-left: 10px;
    }
}
.unlock-button {
    ::v-deep .md-button-content {
        color: #4caf50;
    }
}
.icon-info {
    color: #2b93ff !important;
    cursor: pointer;
    padding-bottom: 4px;
    margin-right: 15px;
}
.tooltip-width {
    max-width: 600px;
    text-align: justify;
}
.half-width {
    width: 50% !important;
}
</style>
