<template>
    <div class="team-dashboard">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="hasTeam">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 id="team-members-list-title" class="title">Team Members</h3>
                        <team-member-filter-component
                            :team-region-id="selectedTeamRegionId"
                            @onFilterMembers="handleFilterOrSearch"
                        />
                    </div>
                    <div class="custom-toolbar-end">
                        <search-component ref="search" @onSearch="handleSearch" :placeholder="placeholderText" />
                        <div class="header-button-container">
                            <md-button
                                :disabled="memberRoles.length == 0"
                                title="Invite member"
                                class="md-primary md-just-icon md-round"
                                @click="onInviteMember"
                            >
                                <md-icon>contact_mail</md-icon>
                            </md-button>
                            <md-button
                                :disabled="memberRoles.length == 0"
                                title="Add member"
                                class="md-primary md-just-icon md-round"
                                @click="onAddMember"
                            >
                                <md-icon>person_add</md-icon>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-warning">
                        <div class="card-icon">
                            <md-icon>group</md-icon>
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!hasTeam && !isLoading">
                            <h3>Team Members</h3>
                            <p>
                                You are currently set up as an individual user of Locate2u. You can join an existing
                                team by requesting an invite from the team manager.
                            </p>
                            <p>
                                If you want to create your own team, click here to set one up. This will allow you to
                                invite people to your team and manage them via this portal.
                            </p>

                            <router-link to="/team/profile" class="custom-a-blue">Create Team</router-link>
                        </div>
                        <div v-if="hasTeam">
                            <tabs
                                :tab-name="['Team Members', 'List Of Invites']"
                                color-button="success"
                                class="custom-tab-list"
                                @switch-panel="switchTab"
                                :selected-panel="replaceDashToSpacesAndCapitalizeFirstLetter(activeTab)"
                            >
                                <template slot="tab-pane-1">
                                    <div class="member-container">
                                        <div class="custom-switch pull-right">
                                            <md-switch v-model="toggleDisableMembers">Show disabled members</md-switch>
                                        </div>
                                        <members-table
                                            :logged-in-user-email="user.email"
                                            :show-disabled-members="toggleDisableMembers"
                                            :search-text="searchText"
                                            :new-user="newUser"
                                            :team-region-id="selectedTeamRegionId"
                                        />
                                    </div>
                                </template>
                                <template slot="tab-pane-2">
                                    <invites-table
                                        :new-invite-id="newInviteId"
                                        :search-text="searchText"
                                        @fetchInvitationList="getInvitationList"
                                        @inviteTeamMember="onInviteMember"
                                    />
                                </template>
                            </tabs>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Tabs, SearchComponent } from '@/components';
import InvitesTable from './ListOfInviteTables';
import MembersTable from './MemberListTable';
import InviteTeamMemberModal from './InviteTeamMemberModal';
import AddTeamMemberModal from './AddTeamMemberModal';
import TeamMemberFilterComponent from './components/TeamMemberFilterComponent';

export default {
    name: 'TeamMemberList',
    components: { Tabs, InvitesTable, MembersTable, SearchComponent, TeamMemberFilterComponent },
    mixins: [GeneralMixin],
    data() {
        return {
            invitationList: [],
            memberRoles: [],
            toggleDisableMembers: false,
            placeholderText: '',
            searchText: '',
            newInviteId: null,
            newUser: null,
            selectedTeamRegionId: null
        };
    },
    computed: {
        activeTab() {
            const { type } = this.$route.params;
            return type || 'team-members';
        },
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user'
        })
    },
    async mounted() {
        // this.selectedTeamRegionId = this.user.teamRegionId ? this.user.teamRegionId : null; // null = 'All'
        this.selectedTeamRegionId = null; // null = 'All'
        if (this.hasTeam) {
            this.setSearchPlaceholder(this.activeTab);

            const { currentPage } = this.$route.query;
            this.$router.replace({ path: `/team/${this.activeTab}`, query: { currentPage } });
            const api = '/api/roles';
            const { data } = await handleRequests(api);
            // Asset removed from the list
            this.memberRoles = data.data.filter((x) => x.name !== 'Asset');
        }
    },
    methods: {
        replaceDashToSpacesAndCapitalizeFirstLetter(str) {
            return str
                .toLowerCase()
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        getInvitationList(list) {
            this.invitationList = list;
        },
        onInviteMember() {
            this.$modal
                .show(InviteTeamMemberModal, {
                    memberRoles: this.memberRoles
                })
                .then((result) => {
                    const existingInvitation = this.invitationList.find(
                        (res) => res.teamInviteId === result.teamInviteId
                    );
                    if (existingInvitation === undefined) {
                        this.newInviteId = Number(result.teamInviteId);
                    } else {
                        existingInvitation.status = result.status;
                        existingInvitation.createdDate = result.createdDate;
                        existingInvitation.acceptedDate = result.acceptedDate;
                        existingInvitation.role = result.role;
                    }
                });
        },
        onAddMember() {
            this.$modal
                .show(AddTeamMemberModal, {
                    memberRoles: this.memberRoles
                })
                .then((result) => {
                    this.newUser = result;
                });
        },
        handleSearch(text) {
            this.searchText = text.searchText;
        },
        switchTab(panel) {
            const tab = panel
                .toLowerCase()
                .split(' ')
                .join('-');
            this.$router.replace({ path: `/team/${tab}`, query: {} });
            this.setSearchPlaceholder(tab);

            this.$refs.search.handleClear();
            this.searchText = null;
        },
        setSearchPlaceholder(panel) {
            let text = '';
            if (panel === 'team-members') {
                text = 'by name';
            } else if (panel === 'list-of-invites') {
                text = 'by email';
            }

            this.placeholderText = `Search ${text}`;
        },
        async handleFilterOrSearch(val) {
            this.selectedTeamRegionId = val.teamRegionId;
        }
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: inline-block;
    width: 150px;
}

::v-deep {
    .custom-btn {
        margin-right: 10px;
        font-weight: 400;
        height: 40px;
        width: 45%;
    }
}

.form-wrapper .custom-btn {
    margin-bottom: 0;
    margin-top: 25px;
}

.member-container {
    position: relative;
    .custom-switch {
        position: absolute;
        right: 15px;
        top: -35px;
        .md-switch {
            margin: 0;
            margin-bottom: 10px;
        }
    }
}

.team-dashboard {
    .page-title {
        // margin: 0;
        display: inline-block;
        h3 {
            margin: 0;
            display: inline-block;
        }
    }
}
</style>
