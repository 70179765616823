var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-group",
          {
            staticClass: "timer-field",
            attrs: { name: "endtime", label: "Trip Start Time" },
          },
          [
            _c("md-icon", [_vm._v("access_time")]),
            _c("time-picker", {
              attrs: {
                time: _vm.startTripTime === null ? "None" : _vm.startTripTime,
                "all-time-options": false,
              },
              on: { selectedTime: _vm.setStartTime },
            }),
          ],
          1
        ),
        _c(
          "form-group",
          {
            staticClass: "timer-field",
            attrs: { name: "endtime", label: "Trip End Time" },
          },
          [
            _c("md-icon", [_vm._v("access_time")]),
            _c("time-picker", {
              attrs: {
                time: _vm.endTripTime === null ? "None" : _vm.endTripTime,
                "all-time-options": false,
              },
              on: { selectedTime: _vm.setEndTime },
            }),
          ],
          1
        ),
        _vm.user.vehicleCapacityUnitsConfiguration.length
          ? _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                { staticClass: "md-layout-item" },
                _vm._l(
                  _vm.user.vehicleCapacityUnitsConfiguration,
                  function (capacity, index) {
                    return _c("capacity-inputs", {
                      key: index,
                      attrs: {
                        "capacity-definition": capacity,
                        "initial-value": _vm.capacityValues[capacity.type],
                        disabled: false,
                      },
                      on: { changed: _vm.handleCapacityChanged },
                    })
                  }
                ),
                1
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "md-primary dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onSettingsChange($event)
              },
            },
          },
          [_vm._v("Save")]
        ),
        _c(
          "md-button",
          {
            staticClass: "md-default dialog-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Close")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Team member Settings"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }