var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-dashboard" }, [
    _c("div", { staticClass: "md-layout" }, [
      _vm.hasTeam
        ? _c(
            "div",
            {
              staticClass:
                "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
            },
            [
              _c("div", { staticClass: "custom-toolbar" }, [
                _c(
                  "div",
                  { staticClass: "custom-toolbar-start" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        attrs: { id: "team-members-list-title" },
                      },
                      [_vm._v("Team Members")]
                    ),
                    _c("team-member-filter-component", {
                      attrs: { "team-region-id": _vm.selectedTeamRegionId },
                      on: { onFilterMembers: _vm.handleFilterOrSearch },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "custom-toolbar-end" },
                  [
                    _c("search-component", {
                      ref: "search",
                      attrs: { placeholder: _vm.placeholderText },
                      on: { onSearch: _vm.handleSearch },
                    }),
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-primary md-just-icon md-round",
                            attrs: {
                              disabled: _vm.memberRoles.length == 0,
                              title: "Invite member",
                            },
                            on: { click: _vm.onInviteMember },
                          },
                          [_c("md-icon", [_vm._v("contact_mail")])],
                          1
                        ),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-primary md-just-icon md-round",
                            attrs: {
                              disabled: _vm.memberRoles.length == 0,
                              title: "Add member",
                            },
                            on: { click: _vm.onAddMember },
                          },
                          [_c("md-icon", [_vm._v("person_add")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-warning" },
                [
                  _c(
                    "div",
                    { staticClass: "card-icon" },
                    [_c("md-icon", [_vm._v("group")])],
                    1
                  ),
                ]
              ),
              _c("md-card-content", [
                !_vm.hasTeam && !_vm.isLoading
                  ? _c(
                      "div",
                      [
                        _c("h3", [_vm._v("Team Members")]),
                        _c("p", [
                          _vm._v(
                            "\n                            You are currently set up as an individual user of Locate2u. You can join an existing\n                            team by requesting an invite from the team manager.\n                        "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "\n                            If you want to create your own team, click here to set one up. This will allow you to\n                            invite people to your team and manage them via this portal.\n                        "
                          ),
                        ]),
                        _c(
                          "router-link",
                          {
                            staticClass: "custom-a-blue",
                            attrs: { to: "/team/profile" },
                          },
                          [_vm._v("Create Team")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasTeam
                  ? _c(
                      "div",
                      [
                        _c(
                          "tabs",
                          {
                            staticClass: "custom-tab-list",
                            attrs: {
                              "tab-name": ["Team Members", "List Of Invites"],
                              "color-button": "success",
                              "selected-panel":
                                _vm.replaceDashToSpacesAndCapitalizeFirstLetter(
                                  _vm.activeTab
                                ),
                            },
                            on: { "switch-panel": _vm.switchTab },
                          },
                          [
                            _c("template", { slot: "tab-pane-1" }, [
                              _c(
                                "div",
                                { staticClass: "member-container" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "custom-switch pull-right" },
                                    [
                                      _c(
                                        "md-switch",
                                        {
                                          model: {
                                            value: _vm.toggleDisableMembers,
                                            callback: function ($$v) {
                                              _vm.toggleDisableMembers = $$v
                                            },
                                            expression: "toggleDisableMembers",
                                          },
                                        },
                                        [_vm._v("Show disabled members")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("members-table", {
                                    attrs: {
                                      "logged-in-user-email": _vm.user.email,
                                      "show-disabled-members":
                                        _vm.toggleDisableMembers,
                                      "search-text": _vm.searchText,
                                      "new-user": _vm.newUser,
                                      "team-region-id":
                                        _vm.selectedTeamRegionId,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "template",
                              { slot: "tab-pane-2" },
                              [
                                _c("invites-table", {
                                  attrs: {
                                    "new-invite-id": _vm.newInviteId,
                                    "search-text": _vm.searchText,
                                  },
                                  on: {
                                    fetchInvitationList: _vm.getInvitationList,
                                    inviteTeamMember: _vm.onInviteMember,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }