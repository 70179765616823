var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$root.isMobileOnly
    ? _c(
        "div",
        { staticClass: "custom-button-container" },
        [
          _vm.type === "text" && !_vm.isSingleUser && !_vm.isSingleTeamMember
            ? _c(
                "span",
                {
                  class: "value custom-a-blue " + _vm.className,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleAssignShipment({
                        shipmentId: _vm.item.shipmentId,
                        assignedTo: _vm.item.assignedTo.publicUserId,
                        assignedCarrierTeamId: _vm.item.carrierTeamId,
                        shipmentRef: _vm.item.shipmentRef,
                      })
                    },
                  },
                },
                [
                  Boolean(_vm.item.assignedTo.publicUserId)
                    ? _c(
                        "a",
                        { attrs: { href: "#", title: "reassign shipment" } },
                        [
                          _vm.item.assignedTo.fullName
                            ? _c("img", {
                                staticClass: "profile-image",
                                attrs: {
                                  src:
                                    _vm.item.assignedTo.photoUrl !== null
                                      ? _vm.item.assignedTo.photoUrl
                                      : _vm.$root.defaultPhotoUrl,
                                  alt: "avatar",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.assignedTo.fullName) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm.item.carrierTeamId
                    ? _c(
                        "a",
                        {
                          staticClass: "custom-ellipsis",
                          attrs: { href: "#", title: "reassign shipment" },
                        },
                        [
                          _vm.item.carrierTeam && _vm.item.carrierTeam.logoUrl
                            ? _c("img", {
                                staticClass: "profile-image",
                                attrs: {
                                  src:
                                    _vm.item.carrierTeam.logoUrl !== null
                                      ? _vm.item.carrierTeam.logoUrl
                                      : _vm.$root.defaultPhotoUrl,
                                  alt: "carrier logo",
                                },
                                on: { error: _vm.$_setDefaultBrokenImage },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.carrierTeam.company) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "a",
                        { attrs: { href: "#", title: "reassign shipment" } },
                        [_vm._v("\n            Unassigned\n        ")]
                      ),
                ]
              )
            : _vm._e(),
          _vm.type === "text" && (_vm.isSingleUser || _vm.isSingleTeamMember)
            ? _c("span", { class: "value " + _vm.className }, [
                _vm.item.assignedTo.fullName
                  ? _c("img", {
                      staticClass: "profile-image",
                      attrs: {
                        src:
                          _vm.item.assignedTo.photoUrl !== null
                            ? _vm.item.assignedTo.photoUrl
                            : _vm.$root.defaultPhotoUrl,
                        alt: "avatar",
                      },
                      on: { error: _vm.$_setDefaultBrokenImage },
                    })
                  : _vm._e(),
                _vm._v(
                  "\n        " + _vm._s(_vm.item.assignedTo.fullName) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm.type === "icon"
            ? _c(
                "md-button",
                {
                  class: "md-info md-just-icon md-round " + _vm.className,
                  attrs: { title: "Re-assign driver" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleAssignShipment({
                        shipmentId: _vm.item.shipmentId,
                        assignedTo: _vm.item.assignedTo.publicUserId,
                        assignedCarrierTeamId: _vm.item.carrierTeamId,
                        shipmentRef: _vm.item.shipmentRef,
                      })
                    },
                  },
                },
                [_c("md-icon", [_vm._v("person_pin")])],
                1
              )
            : _vm._e(),
          _vm.type === "button" && !_vm.isCustomer
            ? _c(
                "md-button",
                {
                  class: "custom-btn " + _vm.className,
                  attrs: { title: "Re-assign driver" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleAssignShipment({
                        shipmentId: _vm.item.shipmentId,
                        assignedTo: _vm.item.assignedTo.publicUserId,
                        assignedCarrierTeamId: _vm.item.carrierTeamId,
                        shipmentRef: _vm.item.shipmentRef,
                      })
                    },
                  },
                },
                [_vm._v("\n        Assign\n    ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "reassign-btn-mobile",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleAssignShipment({
                shipmentId: _vm.item.shipmentId,
                assignedTo: _vm.item.assignedTo.publicUserId,
                assignedCarrierTeamId: _vm.item.carrierTeamId,
                shipmentRef: _vm.item.shipmentRef,
              })
            },
          },
        },
        [
          _vm.item.assignedTo != null && _vm.item.assignedTo.fullName
            ? _c("span", { staticClass: "blue-span" }, [_vm._v("Reallocate")])
            : _c("span", [_vm._v("Allocate")]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }