var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("account_box")])],
            1
          ),
        ]
      ),
      _c("md-card-content", [
        !_vm.stopDetails.contact.name &&
        !_vm.stopDetails.contact.phone &&
        !_vm.stopDetails.contact.email
          ? _c("p", { staticClass: "grayedout-text" }, [
              _vm._v("\n            No contact details provided.\n        "),
            ])
          : _c("div", [
              _vm.stopDetails.contact.name
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Contact Name")]),
                    _c("span", [_vm._v(_vm._s(_vm.stopDetails.contact.name))]),
                  ])
                : _vm._e(),
              _vm.stopDetails.contact.phone
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Phone")]),
                    _c(
                      "a",
                      {
                        attrs: { href: "tel:" + _vm.stopDetails.contact.phone },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.stopDetails.contact.phone) +
                            "\n                "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.stopDetails.contact.email
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Email")]),
                    _c("span", [_vm._v(_vm._s(_vm.stopDetails.contact.email))]),
                  ])
                : _vm._e(),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }