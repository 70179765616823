<template>
    <div class="md-layout" v-if="hasTeam">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
            <div class="custom-toolbar">
                <div class="custom-toolbar-start">
                    <router-link
                        :to="{
                            name: 'Team Settings'
                        }"
                    >
                        <h3 class="title">Team Settings</h3>
                    </router-link>
                    <h3 class="title">&nbsp;&nbsp; > &nbsp;&nbsp; {{ $t('menus.setting.questions') }}</h3>
                </div>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>question_answer</md-icon>
                    </div>
                </md-card-header>

                <md-card-content>
                    <div class="cf-container">
                        <div class="cf-action-container">
                            <md-button
                                title="Add question "
                                class="md-primary md-just-icon md-round"
                                @click.stop="handleAddQuestion()"
                            >
                                <md-icon>add</md-icon>
                            </md-button>
                            <md-button
                                title="Save Questions configurations"
                                class="md-primary md-just-icon md-round"
                                @click.stop="saveQuestionsList()"
                                v-if="questionsList.length > 0"
                            >
                                <md-icon>save</md-icon>
                            </md-button>
                        </div>
                        <form-group label="Group" class="group-filter">
                            <md-select v-model="selectedGroup">
                                <md-option v-for="(group, index) in groupOptions" :key="index" :value="group">
                                    {{ group }}
                                </md-option>
                            </md-select>
                        </form-group>
                        <md-table class="custom-paginated-table">
                            <draggable v-model="questionsList" draggable=".drag-item" class="custom-draggable">
                                <md-table-row slot="header">
                                    <md-table-head></md-table-head>
                                    <md-table-head v-if="isBookingGroup">Kind</md-table-head>
                                    <md-table-head v-if="isBookingGroup">Name</md-table-head>
                                    <md-table-head>Label</md-table-head>
                                    <md-table-head>Type</md-table-head>
                                    <md-table-head class="actions">Required</md-table-head>
                                    <md-table-head
                                        v-if="['proofofdelivery', 'arrivedatstop'].includes(selectedGroupNormalized)"
                                    >
                                        Include in Proof Of Delivery PDF
                                    </md-table-head>
                                    <md-table-head v-else-if="selectedGroupNormalized === 'faileddelivery'">
                                        Include in Failed Delivery PDF
                                    </md-table-head>
                                    <md-table-head class="actions">Actions</md-table-head>
                                </md-table-row>

                                <md-table-row v-for="(question, index) in questionsList" :key="index" class="drag-item">
                                    <md-table-cell>
                                        <md-icon>drag_indicator</md-icon>
                                    </md-table-cell>
                                    <md-table-cell v-if="isBookingGroup">
                                        {{ question.kind }}
                                    </md-table-cell>
                                    <md-table-cell v-if="isBookingGroup">
                                        {{ question.name }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ question.label }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        {{ question.type }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <span v-if="question.isRequired">Yes</span>
                                        <span v-else>No</span>
                                    </md-table-cell>
                                    <md-table-cell
                                        v-if="
                                            ['proofofdelivery', 'faileddelivery', 'arrivedatstop'].includes(
                                                selectedGroupNormalized
                                            )
                                        "
                                    >
                                        {{ question.includeInPdf ? 'Yes' : 'No' }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-button
                                            title="edit question "
                                            class="md-warning md-just-icon md-round"
                                            @click.stop="handleEditQuestion(question)"
                                        >
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                        <md-button
                                            title="delete question "
                                            class="md-danger md-just-icon md-round delete-action"
                                            @click.stop="removeQuestion(index)"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </draggable>
                        </md-table>
                        <div class="text-center" v-if="questionsList.length == 0">
                            <p class="no-result-message">No results matching this filter could be found.</p>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { GROUP_OPTIONS_CONSTANTS } from '@/utils/constants';
import QuestionModal from './QuestionModal';

export default {
    name: 'QuestionList',
    mixins: [GeneralMixin],
    data() {
        return {
            questionsList: [],
            questionsListBase: {},
            shouldShowAddQuestionModal: false,
            selectedGroup: 'Proof of Delivery'
        };
    },
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            teamType: 'user/teamType'
        }),
        selectedGroupNormalized() {
            return this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '');
        },
        groupOptions() {
            if (this.teamType === 'Carrier') {
                return GROUP_OPTIONS_CONSTANTS.map((g) => g.text);
            }
            return GROUP_OPTIONS_CONSTANTS.filter((g) => g.teamType !== 'Carrier').map((g) => g.text);
        },
        isBookingGroup() {
            return this.selectedGroup && this.selectedGroup.toLowerCase() === 'booking';
        }
    },
    watch: {
        selectedGroup(value) {
            this.questionsList = this.questionsListBase[value.toLowerCase().replace(/[^\w]/gi, '')] || [];
        }
    },
    async mounted() {
        this.getQuestionData();
    },
    methods: {
        async getQuestionData() {
            this.$_handleLoaderState(true);
            const api = '/api/teams/questions';
            const response = await handleRequests(api);
            if (response.data !== '') {
                this.questionsListBase = response.data;
                this.questionsList = response.data[this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '')] || [];
            }
            this.$_handleLoaderState(false);
        },
        removeSomeTeamRegionFields(data){
            if (!data?.conditions?.teamRegions || data.conditions.teamRegions.length === 0) 
                return data;

            data.conditions.teamRegions = data.conditions.teamRegions.map(d => {
                return {
                    teamRegionId: d.teamRegionId,
                    name: d.name
                }
            })

            return data;
        },
        handleAddQuestion() {
            const { selectedGroup } = this;
            this.$modal.show(QuestionModal, { selectedGroup, questionsList: this.questionsList }).then((response) => {
                const rawData = response[selectedGroup.toLowerCase().replace(/[^\w]/gi, '')][0];
                const responseData = this.removeSomeTeamRegionFields(rawData);

                if (!this.questionsListBase.booking) 
                    this.questionsListBase.booking = [];

                if (this.isBookingGroup && responseData.kind === 'Standard') {
                    response.booking.forEach((element) => {
                        this.questionsListBase.booking.push(element);
                    });

                    this.saveQuestion('SAVING...');
                } else {
                    const { name } = responseData;
                    const questionsData = this.questionsList.filter((item) => item.name.substring(0, 20) === name);

                    // added number to end, if the field name already exists
                    if (questionsData.length) {
                        responseData.name = `${name}-${questionsData.length + 1}`;
                    }
                    const questions = this.questionsList.find((item) => item.name === responseData.name);
                    // to prevent duplicate error from happening, we add a randomString at the end if name already exists.
                    if (questions) {
                        const randomString = Math.random()
                            .toString(20)
                            .substr(2, 4);
                        responseData.name = `${responseData.name}-${randomString}`;
                    }

                    if (!this.isDuplicateQuestion(responseData) && this.isDuplicateQuestion(responseData) !== 0) {
                        const existingQuestions = this.questionsListBase[
                            selectedGroup.toLowerCase().replace(/[^\w]/gi, '')
                        ];
                        if (!existingQuestions) {
                            this.questionsListBase[selectedGroup.toLowerCase().replace(/[^\w]/gi, '')] = [responseData];
                        } else {
                            this.questionsListBase[selectedGroup.toLowerCase().replace(/[^\w]/gi, '')].push(
                                responseData
                            );
                        }
                        this.saveQuestion('SAVING...');
                    } else {
                        this.showWarningDuplicate(responseData.label);
                    }
                }
            });
        },
        handleEditQuestion(selectedQuestion) {
            const { selectedGroup } = this;
            this.$modal
                .show(QuestionModal, { selectedQuestion, selectedGroup, questionsList: this.questionsList })
                .then((rawResponseData) => {
                    let hasDuplicate = false;
                    const response = this.removeSomeTeamRegionFields(rawResponseData);
                    const questions = this.questionsListBase[selectedGroup.toLowerCase().replace(/[^\w]/gi, '')].find(
                        (item) => item.name === response.name
                    );

                    const hasChanges = !Object.keys(questions).every((key) => questions[key] === response[key]);
                    const questionsIndex = this.questionsList.findIndex((item) => item.name === response.name);
                    if (hasChanges || questions.type.toLowerCase() === 'dropdown') {
                        if (this.isDuplicateQuestion(response) >= 0 && this.isDuplicateQuestion(response) !== false) {
                            hasDuplicate = !(this.isDuplicateQuestion(response) === questionsIndex);
                            if (hasDuplicate) {
                                this.showWarningDuplicate(response.label);
                            }
                        }

                        if (!hasDuplicate) {
                            this.$set(questions, 'label', response.label);
                            this.$set(questions, 'type', response.type);
                            this.$set(questions, 'isRequired', response.isRequired);
                            this.$set(questions, 'includeInPdf', response.includeInPdf);
                            this.$set(questions, 'description', response.description);
                            this.$set(
                                questions,
                                'conditions',
                                response.conditions === 'none' ? null : response.conditions
                            );
                            this.saveQuestion('UPDATING...');
                        }
                    }
                });
        },
        toggleModal(shouldToggle, modalToToggle) {
            this[modalToToggle] = shouldToggle;
        },
        removeQuestion(index) {
            this.$messageBox
                .show({
                    title: 'Delete Question',
                    body: 'Are you sure you want to delete this question?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.questionsList.splice(index, 1);
                        this.saveQuestion('DELETING...');
                    }
                });
        },
        async saveQuestion(text) {
            this.$_handleLoaderState(true, text);
            const payload = {
                method: 'post',
                data: this.questionsListBase
            };
            const api = `/api/teams/questions`;
            try {
                await handleRequests(api, payload);

                let action = 'saved';

                if (text.toLowerCase().includes('updating')) {
                    action = 'updated';
                } else if (text.toLowerCase().includes('deleting')) {
                    action = 'deleted';
                }

                this.$notify({
                    message: `Question  ${action}!`,
                    type: 'success'
                });
                this.getQuestionData();
            } catch (e) {
                const message = 'Cannot save question .';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        isDuplicateQuestion(question) {
            const index = this.questionsList.findIndex((x) => x.label.toLowerCase() === question.label.toLowerCase());

            if (index === -1) {
                return false;
            }

            return index;
        },
        showWarningDuplicate(label) {
            const message = `Question name ${label} already exists.`;
            this.$notify({
                message,
                type: 'warning'
            });
        },
        async saveQuestionsList() {
            const groupName = this.selectedGroup.toLowerCase().replace(/[^\w]/gi, '');

            Object.keys(this.questionsListBase)
                .filter((propName) => { return propName === groupName })
                .forEach((propName) => {
                    this.questionsListBase[propName] = [];
                    this.questionsList.forEach((question) => {
                        this.questionsListBase[propName].push(question);
                    });
                })

            this.saveQuestion('SAVING...');
        }
    }
};
</script>
<style lang="scss" scoped>
.actions {
    width: 10%;
}

.delete-action {
    margin-left: 10px;
}

.md-checkbox {
    margin: 0px;
}

.group-filter {
    margin: 0;
    width: 140px;
    position: absolute;
    right: 90px;
    top: -36px;
}

.custom-draggable {
    display: table;
    width: 100%;

    tr > td:first-child {
        width: 45px;
    }

    tr {
        cursor: move;
    }

    tr:first-child:hover {
        cursor: context-menu;
        background-color: #fff;
    }
}

.card-icon {
    background: #dd4b39 !important;
}
</style>
