var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inventoryDetails
    ? _c("div", { staticClass: "details-page" }, [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item" }, [
            _c("div", { staticClass: "custom-toolbar" }, [
              _c("div", { staticClass: "custom-toolbar-start" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    "Item details ( " +
                      _vm._s(_vm.inventoryDetails.barcode) +
                      " )"
                  ),
                ]),
              ]),
              !_vm.$root.isMobileOnly
                ? _c("div", { staticClass: "custom-toolbar-end" }, [
                    _c(
                      "div",
                      { staticClass: "header-button-container" },
                      [
                        _c("inventory-status-button", {
                          staticClass: "status-btn",
                          attrs: {
                            "item-status": _vm.inventoryDetails.status,
                            "item-id": _vm.inventoryDetails.itemId,
                            "inventory-details": _vm.inventoryDetails,
                          },
                          on: {
                            statusUpdated: function ($event) {
                              return _vm.statusUpdated()
                            },
                          },
                        }),
                        _c("update-item-button", {
                          staticClass: "status-btn",
                          attrs: {
                            item: _vm.inventoryDetails,
                            "class-name": "header-button",
                            "use-icon": false,
                            "is-delivered":
                              _vm.inventoryDetails.status === "Delivered",
                            "stop-status":
                              _vm.inventoryDetails.lines.length > 0
                                ? _vm.inventoryDetails.lines.at(-1).stopStatus
                                : null,
                            "shipment-status":
                              _vm.inventoryDetails.lines.length > 0
                                ? _vm.inventoryDetails.lines.at(-1)
                                    .shipmentStatus
                                : null,
                          },
                          on: { itemUpdated: _vm.statusUpdated },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-left content-layout-panel",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-blue",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("description")])],
                              1
                            ),
                            _c("h4", { staticClass: "title" }, [
                              _vm._v("Inventory Information"),
                            ]),
                          ]
                        ),
                        _c("md-card-content", [
                          _c("div", { staticClass: "left-details" }, [
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Type")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.inventoryDetails.type)),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Description")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.inventoryDetails.description)
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("Order Number"),
                              ]),
                              _vm.inventoryDetails.order
                                ? _c(
                                    "span",
                                    { staticClass: "value" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "Orders",
                                              params: {
                                                orderId:
                                                  _vm.inventoryDetails.order
                                                    .orderId,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.inventoryDetails.order
                                                  .orderNumber
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "right-details" }, [
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Warehouse")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getWarehouseName(
                                      _vm.inventoryDetails.warehouseId
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Current Location")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.inventoryDetails.currentLocationName
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details-box" }, [
                              _c("label", [_vm._v("Possesed by")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFullNameFromTeamMemberId(
                                      _vm.inventoryDetails.userId
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.inventoryDetails.lines
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                      },
                      [
                        _c(
                          "md-card",
                          [
                            _c(
                              "md-card-header",
                              {
                                staticClass:
                                  "md-card-header-icon md-card-header-green",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-icon" },
                                  [_c("md-icon", [_vm._v("control_camera")])],
                                  1
                                ),
                                _c("h4", { staticClass: "title" }, [
                                  _vm._v("Stop / Shipment Details"),
                                ]),
                              ]
                            ),
                            _c(
                              "md-card-content",
                              [
                                _vm.inventoryDetails.lines.length > 0
                                  ? _c(
                                      "md-table",
                                      {
                                        staticClass:
                                          "context-menu-support custom-paginated-table",
                                      },
                                      [
                                        _c(
                                          "md-table-row",
                                          [
                                            _c("md-table-head", [
                                              _vm._v("Status"),
                                            ]),
                                            _c("md-table-head", [
                                              _vm._v("Reference"),
                                            ]),
                                            _c("md-table-head", [
                                              _vm._v("Stop Name"),
                                            ]),
                                            _c("md-table-head", [
                                              _vm._v("Trip Date"),
                                            ]),
                                            _c("md-table-head", [
                                              _vm._v("Team member"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.inventoryDetails.lines,
                                          function (item) {
                                            return _c(
                                              "md-table-row",
                                              { key: item.stopLineId },
                                              [
                                                _c("md-table-cell", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "inputs.itemStatuses." +
                                                          item.status
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "md-table-cell",
                                                  [
                                                    !item.shipmentId
                                                      ? _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "Stop Details",
                                                                params: {
                                                                  stopId:
                                                                    item.stopId,
                                                                },
                                                              },
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  item.stopRef
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "Shipment Details",
                                                                params: {
                                                                  shipmentId:
                                                                    item.shipmentId,
                                                                },
                                                              },
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  item.shipmentRef
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                                _c("md-table-cell", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                                _c(
                                                  "md-table-cell",
                                                  [
                                                    item.tripDate
                                                      ? [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "dateFormat"
                                                                )(
                                                                  item.tripDate,
                                                                  _vm.DATE_TYPES
                                                                    .standardDate
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "md-table-cell",
                                                  [
                                                    item.publicUserId
                                                      ? [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.getFullNameFromTeamMemberId(
                                                                  item.publicUserId
                                                                )
                                                              ) +
                                                              "\n                                    "
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.inventoryDetails.order
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100",
                      },
                      [
                        _c(
                          "md-card",
                          [
                            _c(
                              "md-card-header",
                              {
                                staticClass:
                                  "md-card-header-icon md-card-header-blue",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-icon" },
                                  [_c("md-icon", [_vm._v("checklist")])],
                                  1
                                ),
                                _c("h4", { staticClass: "title" }, [
                                  _vm._v("Product Details"),
                                ]),
                              ]
                            ),
                            _c(
                              "md-card-content",
                              [
                                _c(
                                  "md-table",
                                  {
                                    staticClass:
                                      "context-menu-support custom-paginated-table",
                                  },
                                  [
                                    _c(
                                      "md-table-row",
                                      [
                                        _c("md-table-head", [
                                          _vm._v("Barcode"),
                                        ]),
                                        _c("md-table-head", [_vm._v("Name")]),
                                        _c("md-table-head", [_vm._v("Size")]),
                                        _c("md-table-head", [_vm._v("Color")]),
                                        _c("md-table-head", [_vm._v("Price")]),
                                        _c("md-table-head", [
                                          _vm._v("Quantity"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.inventoryDetails.order.orderLines,
                                      function (item) {
                                        return _c(
                                          "md-table-row",
                                          { key: item.orderLineId },
                                          [
                                            _c("md-table-cell", [
                                              _vm._v(
                                                _vm._s(
                                                  item.productVariantBarcode
                                                )
                                              ),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(_vm._s(item.productName)),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(
                                                _vm._s(item.productVariantSize)
                                              ),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(
                                                _vm._s(
                                                  item.productVariantColour
                                                )
                                              ),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(_vm._s(item.productPrice)),
                                            ]),
                                            _c("md-table-cell", [
                                              _vm._v(_vm._s(item.quantity)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-warning",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("history")])],
                              1
                            ),
                            _c("h4", { staticClass: "title" }, [
                              _vm._v("Status History"),
                            ]),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _vm.inventoryDetails.itemStatusHistory.length > 0
                              ? _c(
                                  "time-line",
                                  { attrs: { plain: "", type: "simple" } },
                                  _vm._l(
                                    _vm.inventoryDetails.itemStatusHistory,
                                    function (item) {
                                      return _c(
                                        "time-line-item",
                                        {
                                          key: item.itemStatusHistoryId,
                                          attrs: {
                                            inverted: "",
                                            "badge-type":
                                              "inventory-status-" +
                                              item.newStatus
                                                .toLowerCase()
                                                .replace(/\s+/g, ""),
                                            "badge-icon": "place",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "badge",
                                              class:
                                                "inventory-status-" +
                                                item.newStatus
                                                  .toLowerCase()
                                                  .replace(/\s+/g, ""),
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inputs.itemStatuses." +
                                                        item.newStatus
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "time",
                                              attrs: { slot: "headerTime" },
                                              slot: "headerTime",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("dateTimeFormat")(
                                                      item.actionDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "h6",
                                            {
                                              staticClass: "note",
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.notes) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "grayedout-text" }, [
                                  _vm._v("No Status History Available"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50",
                  },
                  [
                    _c(
                      "md-card",
                      [
                        _c(
                          "md-card-header",
                          {
                            staticClass:
                              "md-card-header-icon md-card-header-warning",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "card-icon" },
                              [_c("md-icon", [_vm._v("control_camera")])],
                              1
                            ),
                            _c("h4", { staticClass: "title" }, [
                              _vm._v("Movement History"),
                            ]),
                          ]
                        ),
                        _c(
                          "md-card-content",
                          [
                            _vm.inventoryDetails.itemMovementHistory.length > 0
                              ? _c(
                                  "time-line",
                                  { attrs: { plain: "", type: "simple" } },
                                  _vm._l(
                                    _vm.inventoryDetails.itemMovementHistory,
                                    function (item) {
                                      return _c(
                                        "time-line-item",
                                        {
                                          key: item.itemMovementHistoryId,
                                          attrs: {
                                            inverted: "",
                                            "badge-type":
                                              "inventory-movement-" +
                                              item.newLocationName
                                                .toLowerCase()
                                                .replace(/\s+/g, ""),
                                            "badge-icon": "place",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "badge",
                                              class:
                                                "inventory-movement-" +
                                                item.newLocationName
                                                  .toLowerCase()
                                                  .replace(/\s+/g, ""),
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inputs.itemLocations." +
                                                        item.newLocationName
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "time",
                                              attrs: { slot: "headerTime" },
                                              slot: "headerTime",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("dateTimeFormat")(
                                                      item.actionDate,
                                                      _vm.DATE_TYPES
                                                        .standardDate
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "h6",
                                            {
                                              staticClass: "note",
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(item.notes) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "grayedout-text" }, [
                                  _vm._v("No Movement History Available"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }