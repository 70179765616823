var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "detail-container" }, [
      _c(
        "div",
        { staticClass: "close-button" },
        [
          _c(
            "md-button",
            {
              staticClass: "md-default md-just-icon md-round pull-right",
              on: { click: _vm.closeModal },
            },
            [_c("md-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("div", [
            _c("div", { staticClass: "detail-section text-center" }, [
              _vm.shipmentDetails.shipmentRef != null
                ? _c(
                    "span",
                    { staticClass: "title blue-ref" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Shipment Details",
                              params: {
                                shipmentId: _vm.shipmentDetails.shipmentId,
                              },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.shipmentDetails.shipmentRef) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "title" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "Shipment Details",
                              params: {
                                shipmentId: _vm.shipmentDetails.shipmentId,
                              },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.shipmentDetails.id) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "detail-group text-center action-detail-group" },
                [
                  _vm.shipmentDetails.assignedTo.fullName
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.shipmentDetails.assignedTo.photoUrl !== null
                              ? _vm.shipmentDetails.assignedTo.photoUrl
                              : _vm.$root.defaultPhotoUrl,
                          alt: "avatar",
                        },
                      })
                    : _vm._e(),
                  _vm.shipmentDetails.carrierTeam != null
                    ? _c("img", {
                        staticClass: "profile-image",
                        attrs: {
                          src:
                            _vm.shipmentDetails.carrierTeam.logoUrl !== null
                              ? _vm.shipmentDetails.carrierTeam.logoUrl
                              : _vm.$root.defaultLogoUrl,
                          alt: "avatar",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "md-button md-success md-just-icon md-round",
                      attrs: {
                        title: "View shipment details",
                        to: {
                          name: "Shipment Details",
                          params: {
                            shipmentId: _vm.shipmentDetails.shipmentId,
                          },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _c("div", { staticClass: "md-ripple" }, [
                        _c(
                          "div",
                          { staticClass: "md-button-content" },
                          [_c("md-icon", [_vm._v("visibility")])],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm.isCarrierAssigned
                    ? [
                        _vm.carrierTrackingUrl
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-primary md-just-icon md-round",
                                attrs: {
                                  href: _vm.carrierTrackingUrl,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [_c("md-icon", [_vm._v("place")])],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : [
                        _vm.shipmentDetails.trackingLinkUrl
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-primary md-just-icon md-round",
                                attrs: {
                                  href: _vm.setTrackingLinkUrl(
                                    _vm.shipmentDetails.trackingLinkUrl
                                  ),
                                  target: "_blank",
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [_c("md-icon", [_vm._v("place")])],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm.shipmentDetails.trackingLinkUrl == null &&
                            !_vm.isReadOnlyUser
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "md-button md-default md-just-icon md-round",
                                attrs: { target: "_blank" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createTrackingLink(
                                      _vm.shipmentDetails
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "md-ripple" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-button-content" },
                                    [
                                      _c("md-icon", [
                                        _vm._v("add_location_alt"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                  _vm.shipmentDetails.carrierTeam != null
                    ? _c("QuotesButton", {
                        staticClass: "quotes-button",
                        attrs: { shipment: _vm.shipmentDetails, type: "icon" },
                        on: { assignedUser: _vm.assignShipment },
                      })
                    : _vm._e(),
                  !_vm.isReadOnlyUser
                    ? _c("update-shipment-button", {
                        attrs: {
                          shipment: _vm.shipmentDetails.shipmentId,
                          "team-members": _vm.teamMembers,
                          "carrier-team-id": _vm.shipmentDetails.carrierTeamId,
                        },
                        on: { shipmentUpdated: _vm.updateShipment },
                      })
                    : _vm._e(),
                  !_vm.isSingleUser &&
                  !_vm.isSingleTeamMember &&
                  !_vm.isReadOnlyUser
                    ? _c("reassign-shipment-button", {
                        attrs: {
                          type: "icon",
                          "class-name": "small-icon",
                          item: _vm.shipmentDetails,
                          "team-members": _vm.teamMembers,
                        },
                        on: { assignedUser: _vm.assignShipment },
                      })
                    : _vm._e(),
                  !_vm.isReadOnlyUser
                    ? _c("delete-shipment-button", {
                        attrs: {
                          "shipment-id": _vm.shipmentDetails.shipmentId,
                          "team-members": _vm.teamMembers,
                          "has-attached-items":
                            _vm.shipmentDetails.pickupStop.stopItems.length > 0,
                        },
                        on: { shipmentDeleted: _vm.deleteShipment },
                      })
                    : _vm._e(),
                  _vm.isCarrierAssigned && _vm.carrierLabels.length
                    ? _c("CarrierDownloadLabelButton", {
                        attrs: { "use-icon": true, labels: _vm.carrierLabels },
                      })
                    : _vm._e(),
                  _vm.shipmentDetails.pickupStop.stopItems.length > 0
                    ? _c("generate-label-button", {
                        attrs: {
                          id: _vm.shipmentDetails.shipmentId,
                          type: "Shipment",
                        },
                      })
                    : _vm._e(),
                  !_vm.shipmentDetails.carrierTeamId
                    ? _c("stop-pdf-download-button", {
                        attrs: {
                          status: _vm.shipmentDetails.status.toLowerCase(),
                          "stop-id": _vm.shipmentDetails.dropStop.stopId,
                          "stop-ref": _vm.shipmentDetails.dropStop.stopRef,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "line-separator" }),
            _vm.shipmentDetails.quotingStatus != null &&
            _vm.shipmentDetails.quotingStatus != "JobAssigned"
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("div", { staticClass: "detail-group quoting-container" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Quoting Status:"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "custom-badge quoting-badge",
                          class:
                            "status-" +
                            _vm.$_getQuotingStatusBadgeColor(
                              _vm.shipmentDetails.quotingStatus
                            ),
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$_getQuotingStatusText(
                                  _vm.shipmentDetails.quotingStatus
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.shipmentDetails.quotingStatus != null &&
            _vm.shipmentDetails.quotingStatus != "JobAssigned"
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentDetails.carrierTeam != null &&
            _vm.shipmentDetails.assignedCarrierBooking != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Carrier Details"),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Carrier:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.shipmentDetails.carrierTeam.company) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Reference #:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.shipmentDetails.assignedCarrierBooking
                              .supportReference
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Service Type:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _c(
                        "span",
                        {
                          staticClass: "custom-badge",
                          class:
                            "status-" +
                            _vm.$_getServiceLevelBadgeColor(
                              _vm.shipmentDetails.assignedCarrierBooking
                                .timeWindowEnd
                            ),
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  _vm.shipmentDetails.assignedCarrierBooking
                                    .serviceLevel
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Price:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.shipmentDetails.assignedCarrierBooking
                                .feeAfterTax,
                              _vm.shipmentDetails.assignedCarrierBooking
                                .currency
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Pickup ETA:"),
                    ]),
                    _vm.shipmentDetails.assignedCarrierBooking.pickupEta
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.shipmentDetails.assignedCarrierBooking
                                    .pickupEta,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", { staticClass: "not-available" }, [
                          _vm._v("Not available"),
                        ]),
                  ]),
                  _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Drop ETA:"),
                    ]),
                    _vm.shipmentDetails.assignedCarrierBooking.dropOffEta
                      ? _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.shipmentDetails.assignedCarrierBooking
                                    .dropOffEta,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", { staticClass: "not-available" }, [
                          _vm._v("Not available"),
                        ]),
                  ]),
                ])
              : _vm._e(),
            _vm.shipmentDetails.carrierTeam != null &&
            _vm.shipmentDetails.assignedCarrierBooking != null
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("Shipment Details"),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Status:")]),
                _c(
                  "span",
                  { staticClass: "value" },
                  [
                    _c("shipment-status-button", {
                      attrs: { shipment: _vm.shipmentDetails },
                      on: { statusUpdated: _vm.changeShipmentStatus },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("When:")]),
                _c("div", { staticClass: "value" }, [
                  _vm.shipmentDetails.tripDate == null &&
                  _vm.shipmentDetails.appointmentTime == null &&
                  _vm.shipmentDetails.timeWindowStart == null &&
                  _vm.shipmentDetails.timeWindowEnd == null
                    ? _c("span", [_c("i", [_vm._v("Unscheduled")])])
                    : _vm._e(),
                  _vm.shipmentDetails.tripDate != null &&
                  (_vm.shipmentDetails.dropStop.tripDate == null ||
                    _vm.shipmentDetails.pickupStop.tripDate ===
                      _vm.shipmentDetails.dropStop.tripDate)
                    ? _c(
                        "span",
                        [
                          _vm.shipmentDetails.tripId && !_vm.isCarrierAssigned
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Trip Details",
                                      params: {
                                        tripId: _vm.shipmentDetails.tripId,
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.shipmentDetails.tripDate,
                                          _vm.DATE_TYPES.standardDate
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.shipmentDetails.tripDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _vm.shipmentDetails.tripId && !_vm.isCarrierAssigned
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Trip Details",
                                      params: {
                                        tripId: _vm.shipmentDetails.tripId,
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.shipmentDetails.pickupStop
                                            .tripDate,
                                          _vm.DATE_TYPES.standardDate
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                  _c("strong", [_vm._v("->")]),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.shipmentDetails.dropStop.tripDate,
                                          _vm.DATE_TYPES.standardDate
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.shipmentDetails.pickupStop.tripDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                                "
                                ),
                                _c("strong", [_vm._v("->")]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.shipmentDetails.dropStop.tripDate,
                                        _vm.DATE_TYPES.standardDate
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                        ],
                        1
                      ),
                ]),
              ]),
              _vm.teamRegionName
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Team Region:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.teamRegionName) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.shipmentDetails.runNumber
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Run:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.shipmentDetails.runNumber) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "detail-section contact-detail-section" },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Contact Details"),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.shipmentDetails.contact.name) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Phone:")]),
                  _c(
                    "a",
                    {
                      staticClass: "value link",
                      attrs: {
                        href: "tel:" + _vm.shipmentDetails.contact.name,
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.shipmentDetails.contact.phone) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Email:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.shipmentDetails.contact.email) +
                        "\n                    "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.shipmentDetails.brandId != null
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Brand Details"),
                  ]),
                  _vm.shipmentDetails.brandLogoUrl
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: {
                          src:
                            _vm.shipmentDetails.brandLogoUrl !== null
                              ? _vm.shipmentDetails.brandLogoUrl
                              : _vm.$root.defaultPhotoUrl,
                          title: _vm.shipmentDetails.brandName,
                          alt: _vm.shipmentDetails.brandName,
                        },
                        on: { error: _vm.$_setDefaultBrokenImage },
                      })
                    : _vm._e(),
                  !_vm.shipmentDetails.brandLogoUrl
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.shipmentDetails.brandName)),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.shipmentDetails.skills.length > 0
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentDetails.skills.length > 0
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Required Skills"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "detail-group md-chips md-primary" },
                      _vm._l(
                        _vm.shipmentDetails.skills,
                        function (skill, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "md-chip" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(skill) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm.shipmentCustomFieldList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentCustomFieldList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Custom fields"),
                    ]),
                    _vm._l(
                      _vm.shipmentCustomFieldList,
                      function (customField, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(customField.label) + ":"),
                            ]),
                            _vm.customFieldValues !== null &&
                            _vm.customFieldValues[customField.name] !== null
                              ? _c("div", { staticClass: "value" }, [
                                  customField.type.toLowerCase() === "date"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm._f("dateTimeFormat")(
                                                _vm.customFieldValues[
                                                  customField.name
                                                ],
                                                _vm.DATE_TYPES.standardDate
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.customFieldValues[
                                              customField.name
                                            ]
                                          )
                                        ),
                                      ]),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.loadCapacityList.length
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.loadCapacityList.length
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Load")]),
                    _vm._l(
                      _vm.loadCapacityList,
                      function (loadCapacity, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "detail-group" },
                          [
                            loadCapacity.type !== "dimensions"
                              ? _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(loadCapacity.label) + ":"),
                                  ]),
                                  _vm.loadValues !== null &&
                                  _vm.loadValues[loadCapacity.type] !== null
                                    ? _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.loadValues[loadCapacity.type]
                                            ) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            loadCapacity.type === "dimensions"
                              ? _c("div", [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(loadCapacity.label)),
                                  ]),
                                  _vm.loadValues !== null &&
                                  _vm.loadValues[loadCapacity.type] !== null
                                    ? _c("div", { staticClass: "value" }, [
                                        _vm._v(
                                          "\n                            L: " +
                                            _vm._s(_vm.loadValues["length"]) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            " W: " +
                                            _vm._s(_vm.loadValues["width"]) +
                                            "\n                            " +
                                            _vm._s(loadCapacity.symbol) +
                                            " H: " +
                                            _vm._s(_vm.loadValues["height"]) +
                                            " " +
                                            _vm._s(loadCapacity.symbol) +
                                            "\n                        "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [_vm._v("Pickup Details")]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Pickup Ref:")]),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Stop Details",
                            params: {
                              stopId: _vm.shipmentDetails.pickupStop.stopId,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.shipmentDetails.pickupStop.stopRef) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.shipmentDetails.pickupStop.tripDate != null &&
              _vm.shipmentDetails.pickupStop.tripDate !==
                _vm.shipmentDetails.dropStop.tripDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("When:")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.shipmentDetails.pickupStop.tripDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.shipmentDetails.pickupStop.originalEta != null &&
              _vm.shipmentDetails.pickupStop.status != "Arrived" &&
              _vm.shipmentDetails.pickupStop.status != "Departed" &&
              _vm.shipmentDetails.pickupStop.status != "Complete"
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("ETA:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.pickupStop.originalEta,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Address:")]),
                _c("div", { staticClass: "value" }, [
                  _vm.shipmentDetails.pickupStop.name &&
                  !_vm.$_validateCoordinate(
                    _vm.shipmentDetails.pickupStop.address
                  )
                    ? _c("div", { staticClass: "address-name" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.shipmentDetails.pickupStop.name) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "address",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.viewAddress(true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$_displayAddressName(
                              _vm.shipmentDetails.pickupStop
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Duration:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.shipmentDetails.pickupStop.durationMinutes) +
                      " minutes"
                  ),
                ]),
              ]),
              _vm.shipmentDetails.arrivalDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Arrival:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.pickupStop.arrivalDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.shipmentDetails.departureDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Departure:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.pickupStop.departureDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "detail-section contact-detail-section" },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Contact Details"),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.shipmentDetails.pickupStop.contact.name) +
                        "\n                        "
                    ),
                    _c("span", [
                      _vm.shipmentDetails.pickupStop.contact.phone
                        ? _c(
                            "a",
                            {
                              staticClass: "link contact-detail",
                              attrs: {
                                href:
                                  "tel:" +
                                  _vm.shipmentDetails.pickupStop.contact.phone,
                              },
                            },
                            [
                              _c(
                                "md-icon",
                                [
                                  _vm._v(
                                    "\n                                    phone\n                                    "
                                  ),
                                  _c(
                                    "md-tooltip",
                                    { attrs: { "md-direction": "right" } },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.shipmentDetails.pickupStop
                                              .contact.phone
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shipmentDetails.pickupStop.contact.email
                        ? _c(
                            "a",
                            {
                              staticClass: "link contact-detail",
                              attrs: {
                                href:
                                  "mailto:" +
                                  _vm.shipmentDetails.pickupStop.contact.email,
                              },
                            },
                            [
                              _c(
                                "md-icon",
                                [
                                  _vm._v(
                                    "\n                                    email\n                                    "
                                  ),
                                  _c(
                                    "md-tooltip",
                                    { attrs: { "md-direction": "right" } },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.shipmentDetails.pickupStop
                                              .contact.email
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm.shipmentDetails.pickupStop.driverInstructions
              ? _c(
                  "div",
                  { staticClass: "detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Driver's Instructions"),
                    ]),
                    _c("DriverInstructions", {
                      attrs: {
                        "driver-instructions":
                          _vm.shipmentDetails.pickupStop.driverInstructions,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "line-separator" }),
            _c("div", { staticClass: "detail-section" }, [
              _c("span", { staticClass: "title" }, [_vm._v("Drop Details")]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v("Drop off Ref:"),
                ]),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Stop Details",
                            params: {
                              stopId: _vm.shipmentDetails.dropStop.stopId,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.shipmentDetails.dropStop.stopRef) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.shipmentDetails.dropStop.tripDate != null &&
              _vm.shipmentDetails.pickupStop.tripDate !==
                _vm.shipmentDetails.dropStop.tripDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("When:")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.shipmentDetails.dropStop.tripDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.shipmentDetails.dropStop.originalEta != null &&
              _vm.shipmentDetails.dropStop.status != "Arrived" &&
              _vm.shipmentDetails.dropStop.status != "Departed" &&
              _vm.shipmentDetails.dropStop.status != "Complete"
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("ETA:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.dropStop.originalEta,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Address:")]),
                _c("div", { staticClass: "value" }, [
                  _vm.shipmentDetails.dropStop.name &&
                  !_vm.$_validateCoordinate(
                    _vm.shipmentDetails.dropStop.address
                  )
                    ? _c("div", { staticClass: "address-name" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.shipmentDetails.dropStop.name) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "address",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.viewAddress(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$_displayAddressName(
                              _vm.shipmentDetails.dropStop
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail-group" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Duration:")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.shipmentDetails.dropStop.durationMinutes) +
                      " minutes"
                  ),
                ]),
              ]),
              _vm.shipmentDetails.dropStop.arrivalDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Arrival:")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.dropStop.arrivalDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.shipmentDetails.dropStop.departureDate
                ? _c("div", { staticClass: "detail-group" }, [
                    _c("label", { staticClass: "label" }, [
                      _vm._v("Departure:"),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("dateTimeFormat")(
                              _vm.shipmentDetails.dropStop.departureDate,
                              _vm.DATE_TYPES.standardDate
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "detail-section contact-detail-section" },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v("Contact Details"),
                ]),
                _c("div", { staticClass: "detail-group" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.shipmentDetails.dropStop.contact.name) +
                        "\n                        "
                    ),
                    _c("span", [
                      _vm.shipmentDetails.dropStop.contact.phone
                        ? _c(
                            "a",
                            {
                              staticClass: "link contact-detail",
                              attrs: {
                                href:
                                  "tel:" +
                                  _vm.shipmentDetails.dropStop.contact.phone,
                              },
                            },
                            [
                              _c(
                                "md-icon",
                                [
                                  _vm._v(
                                    "\n                                    phone\n                                    "
                                  ),
                                  _c(
                                    "md-tooltip",
                                    { attrs: { "md-direction": "right" } },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.shipmentDetails.dropStop.contact
                                              .phone
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shipmentDetails.dropStop.contact.email
                        ? _c(
                            "a",
                            {
                              staticClass: "link contact-detail",
                              attrs: {
                                href:
                                  "mailto:" +
                                  _vm.shipmentDetails.dropStop.contact.email,
                              },
                            },
                            [
                              _c(
                                "md-icon",
                                [
                                  _vm._v(
                                    "\n                                    email\n                                    "
                                  ),
                                  _c(
                                    "md-tooltip",
                                    { attrs: { "md-direction": "right" } },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.shipmentDetails.dropStop.contact
                                              .email
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm.shipmentDetails.dropStop.driverInstructions
              ? _c(
                  "div",
                  { staticClass: "detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Driver's Instructions"),
                    ]),
                    _c("DriverInstructions", {
                      attrs: {
                        "driver-instructions":
                          _vm.shipmentDetails.dropStop.driverInstructions,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.shipmentDetails.pickupStop.stopItems.length > 0
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentDetails.pickupStop.stopItems.length > 0
              ? _c(
                  "div",
                  { staticClass: "detail-section contact-detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("Items")]),
                    _vm._l(
                      _vm.shipmentDetails.pickupStop.stopItems,
                      function (line, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "detail-group item-reference" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    title: "View item details",
                                    to: {
                                      name: "Inventory Details",
                                      params: { itemId: line.itemId },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "custom-badge inventory-status-" +
                                        line.status
                                          .toLowerCase()
                                          .replace(/\s+/g, ""),
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(line.status) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "label status-label" },
                                    [_vm._v(_vm._s(line.description))]
                                  ),
                                ]
                              ),
                              _c(
                                "md-tooltip",
                                {
                                  staticClass: "line-tooltip",
                                  attrs: { "md-direction": "left" },
                                },
                                [
                                  line.currentLocationName
                                    ? _c("div", { staticClass: "label" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(line.currentLocationName) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  line.barcode
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(line.barcode) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.shipmentDetails.notes
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentDetails.notes
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("Notes")]),
                  _c("div", { staticClass: "detail-group notes-content" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.shipmentDetails.notes) +
                        "\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.shipmentDetails.batchImportId ||
            (_vm.shipmentDetails.importErrors &&
              _vm.shipmentDetails.importErrors.length > 0)
              ? _c("div", { staticClass: "line-separator" })
              : _vm._e(),
            _vm.shipmentDetails.batchImportId ||
            (_vm.shipmentDetails.importErrors &&
              _vm.shipmentDetails.importErrors.length > 0)
              ? _c("div", { staticClass: "detail-section" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Import Details"),
                  ]),
                  _vm.shipmentDetails.batchImportId
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Import Id:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.shipmentDetails.batchImportId) +
                              "\n                    "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.shipmentDetails.importErrors &&
                  _vm.shipmentDetails.importErrors.length > 0
                    ? _c("div", { staticClass: "detail-group" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Import Errors:"),
                        ]),
                        _c("span", { staticClass: "value" }, [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.shipmentDetails.importErrors,
                              function (importError, importErrorIndex) {
                                return _c("li", { key: importErrorIndex }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(importError) +
                                      "\n                            "
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.shipmentDetails.contents
              ? _c(
                  "div",
                  { staticClass: "detail-section" },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Shipment Contents"),
                    ]),
                    _c("ShipmentContentsDisplay", {
                      attrs: { contents: _vm.shipmentDetails.contents },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "shipmentbar-loader" },
              [
                _c("fade-loader", {
                  attrs: { loading: _vm.loading, color: "#333333" },
                }),
                _c("span", [_vm._v("LOADING")]),
              ],
              1
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }