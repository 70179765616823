<template>
    <div class="rate-editor-page">
        <div class="md-layout" v-if="isValidType">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <div class="custom-toolbar md-layout filter-steps--container">
                    <md-field v-show="hasRateGroups">
                        <charging-type-options
                            class="filter-steps--choices status-filter"
                            @selectedOption="handleRateTypeChanged"
                            @hasRateGroups="hasRateGroupList"
                        />
                    </md-field>

                    <md-field>
                        <customer-filter-options
                            class="filter-steps--choices status-filter"
                            @onCustomerSelected="onCustomerSelected"
                            @onCustomerSelectedName="onCustomerSelectedName"
                        />
                    </md-field>

                    <md-field>
                        <div class="filter-steps--choices status-filter">
                            <vue-select
                                :reduce="(fullName) => fullName.userId"
                                label="fullName"
                                :options="allTeamMembers"
                                placeholder="Team Member"
                                v-model.number="teamMemberFilterValue"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </div>
                    </md-field>

                    <div class="custom-toolbar-end">
                        <rate-configuration
                            class="filter-steps--choices status-filter"
                            :customer-id="selectedCustomerId"
                            :customer-name="selectedCustomerName"
                            :driver-user-id="selectedTeamMemberUserId"
                            :driver-name="selectedDriverName"
                            :rate-configuration-rule="rateConfigurationRule"
                            :disable-click="isLoading"
                            :rate-group-id="chargingTypeId"
                            :rate-group-name="rateGroupName"
                            @fetchRateConfig="fetchRateConfiguration"
                        />
                    </div>
                </div>
            </div>
            <div v-if="hasCurrency" class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card class="custom-card">
                    <md-card-header class="md-card-header-icon md-card-header-green">
                        <div>
                            <card-header-dropdown
                                :dropdown-options="headerDropdown"
                                @selectedOption="handleSelectedView"
                                :selected-option="headerSelectedDropDown"
                            />
                        </div>
                    </md-card-header>

                    <md-card-content>
                        <div v-if="!isLoading">
                            <md-switch class="custom-switch" v-model="toggleHideRuleConditions">
                                Hide Conditions
                            </md-switch>
                            <tabs
                                :tab-name="tabNames"
                                color-button="success"
                                class="custom-tab-list"
                                @switch-panel="switchTab"
                                :selected-panel="replaceDashToSpacesAndCapitalizeFirstLetter(activeTab)"
                                :active-tabs="activeTabNames"
                            >
                                <template v-for="(tabName, index) in tabNames" :slot="`tab-pane-${index + 1}`">
                                    <div class="pull-right rule-contents" :key="tabName">
                                        <component
                                            class="rule-contents"
                                            :is="tabComponents[index]"
                                            :customer-id="selectedCustomerId"
                                            :rate-id="chargingTypeId"
                                            :hide-condition-rules="toggleHideRuleConditions"
                                            :rate-zones="rateZones"
                                            :driver-user-id="selectedTeamMemberUserId"
                                            :driver-rule-creation="driverRuleCreation"
                                            ref="tabComponent"
                                        ></component>
                                    </div>
                                </template>
                            </tabs>
                        </div>
                        <div v-else class="body-list">
                            <div class="content-loader">
                                <fade-loader :loading="isLoading" color="#333333" />
                                <span>LOADING</span>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div
                v-if="!hasCurrency && !callBackFromCurrency"
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
                <md-card-content>
                    <div class="body-list">
                        <div class="content-loader">
                            <span class="currency-container">
                                Team currency is required. Please follow link to setup before creating rates.
                                <router-link
                                    to="/settings/accounts?setupCurrency=true"
                                    class="custom-a-blue currency-btn"
                                >
                                    Add Currency
                                </router-link>
                            </span>
                        </div>
                    </div>
                </md-card-content>
            </div>
        </div>
        <div v-else>
            <p class="text-center">Type Not Valid</p>
        </div>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { Tabs, CardHeaderDropdown } from '@/components';
import { handleRequests, showErrorMessage } from '@/helpers';
import { mapGetters } from 'vuex';
import {
    RATE_RULE_TAB_HEADERS,
    RATE_CHARGING_TYPES,
    RATES_DASHBOARD_HEADER_DROPDOWN_CONSTANTS
} from '@/utils/constants';
import { RATE_RULE } from '@/utils/defaults';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import CustomerFilterOptions from '@/components/CustomerFilterOptions';
import ChargingTypeOptions from '@/components/ChargingTypeOptions';
import RatesSurchargeDetails from './RatesSurchargeDetails';
import RatesLevyDetails from './RatesLevyDetails';
import RatesItemDetails from './RatesItemDetails';
import RatesHourlyDetails from './RatesHourlyDetails';
import RatesWeightDetails from './RatesWeightDetails';
import RatesDistanceDetails from './RatesDistanceDetails';
import RatesVolumeDetails from './RatesVolumeDetails';
import RateConfiguration from './ModalComponents/RateConfiguration';

export default {
    name: 'RatesDashboard',
    components: {
        Tabs,
        RatesHourlyDetails,
        RatesItemDetails,
        FadeLoader,
        RatesLevyDetails,
        RatesSurchargeDetails,
        CustomerFilterOptions,
        ChargingTypeOptions,
        RatesWeightDetails,
        RatesDistanceDetails,
        RatesVolumeDetails,
        CardHeaderDropdown,
        RateConfiguration
    },
    mixins: [GeneralMixin],
    data() {
        return {
            tabNames: [...RATE_RULE_TAB_HEADERS],
            rateGroups: [...RATE_CHARGING_TYPES],
            isValidType: true,
            activeTab: this.$route.params.type || 'Levy',
            isLoading: false,
            currentTab: null,
            rateDetail: Object.assign({}, RATE_RULE()),
            selectedCustomerId: null,
            chargingTypeId: null,
            chargingType: null,
            toggleHideRuleConditions: false,
            rateZones: [],
            driverUserId: null,
            isCostingRule: false,
            isChargingRule: false,
            teamMemberFilterValue: null,
            allTeamMembers: [],
            driverRuleCreation: false,
            rateTitle: 'Customer Rates',
            selectedTeamMemberUserId: null,
            activeTabNames: [...RATE_RULE_TAB_HEADERS],
            selectedTab: null,
            tabComponents: [
                'RatesLevyDetails',
                'RatesSurchargeDetails',
                'RatesHourlyDetails',
                'RatesItemDetails',
                'RatesWeightDetails',
                'RatesDistanceDetails',
                'RatesVolumeDetails'
            ],
            hasRateGroups: false,
            headerDropdown: [...RATES_DASHBOARD_HEADER_DROPDOWN_CONSTANTS],
            headerSelectedDropDown: RATES_DASHBOARD_HEADER_DROPDOWN_CONSTANTS[0],
            hasCurrency: false,
            callBackFromCurrency: Boolean(this.$route.query.callBackFromCurrency),
            selectedCustomerName: null,
            selectedDriverName: null,
            rateConfigurationRule: {
                rateRuleId: null,
                isConfigRule: true,
                reattemptCharges: null,
                reattemptCosts: null,
                customerId: null,
                userId: null,
                weightEquivalence: null,
                rateGroupId: null
            },
            rateGroupName: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        })
    },
    watch: {
        teamMemberFilterValue(newValue) {
            this.onTeamMemberSelected(newValue);
        },
        driverRuleCreation(value) {
            this.updateRateList();
        },
        selectedCustomerId() {
            // fetch the rate configuration
            this.fetchRateConfiguration();
        },
        selectedTeamMemberUserId() {
            this.fetchRateConfiguration();
        }
    },
    async created() {
        this.rateZones = await this.getRateZones();
    },
    async mounted() {
        this.hasCurrency = this.user.teamCurrency;

        if (!this.user.teamCurrency) {
            await this.$store.dispatch('user/FETCH_USER');
            this.hasCurrency = this.user.teamCurrency;
        }

        this.allTeamMembers = await this.getTeamMambers();
        await this.fetchRateConfiguration();

        if (!this.tabNames.includes(this.replaceDashToSpacesAndCapitalizeFirstLetter(this.activeTab))) {
            this.isValidType = false;
            return;
        }

        const currentPage = Number(this.$route.query.currentPage);
        if (currentPage) {
            this.$router.replace({ path: `/rates/list/${this.activeTab}`, query: { currentPage } });
        } else {
            this.$router.replace({ path: `/rates/list/${this.activeTab}`, query: {} });
        }
    },
    methods: {
        async getRateZones() {
            try {
                const payload = {
                    method: 'get'
                };

                const enpoint = '/api/teams/rate-zones/';
                const { data } = await handleRequests(enpoint, payload);

                const zones = [];

                data.forEach((zone, index) => {
                    zones.push(zone);
                });

                return zones;
            } catch (error) {
                const message = 'Error in getting the rate details';
                showErrorMessage(this, message, error);
            }

            return null;
        },
        async getTeamMambers() {
            let response = null;
            try {
                this.isLoading = true;
                const payload = {
                    method: 'get'
                };

                const endpoint = '/api/team-members/';

                response = await handleRequests(endpoint, payload);
            } catch (error) {
                const message = 'Error in getting the Team Member list';
                showErrorMessage(this, message, error);
            }

            this.isLoading = false;
            return response.data.data;
        },
        async handleRateTypeChanged(rateRule) {
            this.chargingType = rateRule;
            this.chargingTypeId = rateRule?.rateGroupId ?? null;
            this.rateGroupName = rateRule?.rateGroupName;

            this.fetchRateConfiguration();

            if (rateRule === null) {
                this.activeTabNames = [...RATE_RULE_TAB_HEADERS];
            } else {
                this.activeTabNames = ['Levy', 'Surcharge'];

                this.tabNames.forEach((tab, index) => {
                    if (this.canShow(tab)) {
                        this.activeTabNames.push(tab);
                    }
                });
                const setTab = RATE_CHARGING_TYPES.find((x) => x.type === rateRule.rateGroupType).key.toLowerCase();
                this.activeTab = setTab;
                this.$router.replace({ path: `/rates/list/${setTab}`, query: {} });
                this.currentTab = setTab;
            }
        },
        async onCustomerSelected(customerId) {
            if (customerId !== null) {
                this.selectedCustomerId = customerId;
            } else {
                this.selectedCustomerId = null;
            }
        },
        async onCustomerSelectedName(customerName) {
            if (customerName !== null) {
                this.selectedCustomerName = customerName;
            } else {
                this.selectedCustomerName = null;
            }
        },
        async onTeamMemberSelected(driverUserId) {
            if (driverUserId !== null) {
                this.selectedTeamMemberUserId = driverUserId;
                this.onSelectedDriverName();
            } else {
                this.selectedTeamMemberUserId = null;
                this.onSelectedDriverName();
            }
        },
        async onSelectedDriverName() {
            if (this.allTeamMembers.length > 0) {
                const driver = this.allTeamMembers.find((driver) => driver.userId === this.selectedTeamMemberUserId);
                if (driver !== null && driver !== undefined) {
                    this.selectedDriverName = driver.fullName;
                } else {
                    this.selectedDriverName = null;
                }
            }
        },
        async updateRateList() {
            if (!this.hasCurrency) {
                return;
            }

            const {
                data: { rateList, totalItems, currency, speedMeasurement, weightMeasurement }
            } = await this.$refs.tabComponent[0].getRateList(
                this.selectedCustomerId,
                this.chargingTypeId,
                this.selectedTeamMemberUserId,
                this.driverRuleCreation,
                1
            );

            this.$refs.tabComponent[0].setRatesAndTotal(
                rateList,
                totalItems,
                currency,
                speedMeasurement,
                weightMeasurement
            );
            this.$refs.tabComponent[0].pagination.currentPage = 1;
            this.$refs.tabComponent[0].pagination.perPage = 50;
        },
        replaceDashToSpacesAndCapitalizeFirstLetter(str) {
            return str
                .toLowerCase()
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        async switchTab(panel) {
            const tab = panel
                .toLowerCase()
                .split(' ')
                .join('-');
            this.activeTab = tab;
            this.$router.replace({ path: `/rates/list/${tab}`, query: {} });
            this.currentTab = tab;
        },
        resetRateData() {
            Object.assign(this.rateDetail, RATE_RULE());
        },
        changingRateCreation() {
            this.rateTitle = this.driverRuleCreation === true ? 'Team Member Rates' : 'Customer Rates';
        },
        canShow(tabName) {
            if (this.chargingType === null) {
                return true;
            }

            const tab = this.rateGroups.find((x) => x.key === tabName);

            if (this.chargingType.rateGroupType === tab.type) {
                return true;
            }

            return false;
        },
        hasRateGroupList(hasData) {
            this.hasRateGroups = hasData;
        },
        handleSelectedView(value) {
            this.headerSelectedDropDown = value;

            if (value.key === 'customer') {
                this.driverRuleCreation = false;
            } else if (value.key === 'teamMember') {
                this.driverRuleCreation = true;
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.headerSelectedDropDown = this.RATES_DASHBOARD_HEADER_DROPDOWN_CONSTANTS[0];
                this.driverRuleCreation = false;
            }
        },
        async fetchRateConfiguration() {
            try {
                this.isLoading = true;

                const customerId = this.selectedCustomerId;
                const userId = this.selectedTeamMemberUserId;
                const rateGroupId = this.chargingTypeId;

                const endpoint = `/api/rates/rate-configuration?customerId=${customerId}&userId=${userId}&rateGroupId=${rateGroupId}`;
                const response = await handleRequests(endpoint);

                if (response.data !== null && response.data.isConfigRule) {
                    this.rateConfigurationRule = response.data;
                } else {
                    this.rateConfigurationRule.customerId = customerId;
                    this.rateConfigurationRule.userId = userId;
                    this.rateConfigurationRule.reattemptCharges = null;
                    this.rateConfigurationRule.reattemptCosts = null;
                    this.rateConfigurationRule.rateRulesId = null;
                    this.rateConfigurationRule.weightEquivalence = null;
                    this.rateConfigurationRule.rateGroupId = rateGroupId;
                }
            } catch (error) {
                const message = 'Error fetching config.';
                showErrorMessage(this, message, null);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.main-btn ::v-deep .custom-btn {
    margin-right: 10px;
}

::v-deep .vs--searchable .vs__dropdown-toggle {
    border: none;
    padding-bottom: 5px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;
}

.custom-toolbar-end {
    ::v-deep .search-button--container .md-button {
        z-index: initial !important;
    }
}

.custom-card {
    z-index: initial;
}

.custom-tab-list {
    position: initial;
}

.status-filter {
    min-width: 250px;
    padding-right: 30px !important;
    min-height: initial;
    position: absolute;
}

::v-deep .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;
}

.custom-switch {
    position: absolute;
    right: 15px;
    top: -35px;

    .md-switch {
        margin: 0;
        margin-bottom: 10px;
    }
}

.toggle-creation-rates {
    position: inherit !important;
    left: 15px !important;
    top: -35px;

    .md-switch {
        margin: 0;
        margin-bottom: 10px;
    }
}

::v-deep .vs__search::placeholder {
    color: #aaaaaa;
    font-size: 14px;
}
.span-toggle {
    padding-right: 10px;
    margin-top: -1.5px !important;
}

.toggle-spacing {
    font-size: 14px !important;
    color: black !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    font-display: auto !important;
    font-weight: 200 !important;
}

::v-deep .filter-steps--container {
    margin-top: 6px;
    content: '';
    .filter-steps--choices {
        margin-top: 0;
        padding-bottom: 0;

        > .md-input {
            display: initial;
        }
    }
}

.filter-steps--container {
    ::v-deep .md-field {
        display: inline-block;
        width: 200px;
        min-height: 48px;
        margin-right: 20px;
        margin-top: 0;
        vertical-align: top;
    }
}

.rule-contents {
    display: contents !important;
}

.currency-container {
    width: 350px !important;
    left: calc((100% - 200px)) !important;
    background-color: white;
    padding: 30px;
    border-radius: 3px;
}

.currency-btn {
    display: block;
    margin-inline: auto;
    margin-top: 15px !important;
}
</style>
