<template>
    <div class="modal-container" v-if="!isLoading">
        <div class="modal-header">
            <h4 class="modal-title">Update Shipment</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div>
            <md-button class="md-success chargeButton-toggle" @click="openCharges()">
                Charges ({{ chargeList.length }})
            </md-button>
            <charge-list-modal
                :rates-list="chargeList"
                :rates-is-loading="ratesIsLoading"
                :rate-list-data="rateListData"
                :currency="shipmentDetails.currency"
            />
        </div>

        <div class="modal-body shipment-form-modal">
            <form-wrapper :validator="$v.shipmentDetails" class="form-wrapper">
                <tabs
                    :tab-name="['Shipment', 'Pickup Options', 'Drop off Options']"
                    color-button="warning"
                    class="custom-tab-list"
                >
                    <template slot="tab-pane-1">
                        <div class="shipment-form-container">
                            <charging-type-options
                                :selected-charge-type-id="shipmentDetails.rateGroupId"
                                @selectedOption="handleRateTypeChanged"
                            />

                            <team-region-member-controls
                                :team-region-id="shipmentDetails.teamRegionId"
                                :public-user-id="shipmentDetails.assignedTo.publicUserId"
                                :carrier-team-id="shipmentDetails.carrierTeamId"
                                :show-label-if-member-not-in-team-region="true"
                                @onChanged="handleTeamRegionMemberChanged"
                                mode="update"
                                :strict-team-region-filter="false"
                            />
                            <div>
                                <form-group name="sourceReference" label="Source Reference">
                                    <md-input v-model="shipmentDetails.sourceReference" />
                                </form-group>
                            </div>
                            <div>
                                <brand-filter v-model="shipmentDetails" />
                            </div>
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Pickup"
                                        id="pickupAutoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        v-model="shipmentDetails.pickupStop"
                                        name="pickupStop.address"
                                        @handleCoordinates="handlePickupCoordinates"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Drop off"
                                        id="dropStopAutoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        v-model="shipmentDetails.dropStop"
                                        name="dropStop.address"
                                        @handleCoordinates="handleDropCoordinates"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="contact-details">
                                <span>Contact Details</span>
                                <div class="contact-details">
                                    <customer-autocomplete
                                        :label="shipmentDetails.customerId == null ? 'Name' : 'Customer'"
                                        id="autocustomerShipment"
                                        placeholder=""
                                        :should-focus="false"
                                        v-model="shipmentDetails"
                                        @change="handleCustomerFieldChange"
                                        @changeCustomerRateGroup="handleCustomerChangeRateGroup"
                                    />
                                    <form-group
                                        name="name"
                                        label="Contact Name"
                                        v-if="shipmentDetails.customerId != null"
                                    >
                                        <md-input v-model="shipmentDetails.contact.name" />
                                    </form-group>
                                    <form-group name="phone" label="Phone">
                                        <md-input class="shipment-contact" v-model="shipmentDetails.contact.phone" />
                                    </form-group>
                                    <form-group name="email" label="Email">
                                        <md-input class="shipment-contact" v-model="shipmentDetails.contact.email" />
                                    </form-group>
                                </div>
                            </div>
                            <form-group name="skills" label="Skills" v-if="skillOptions.length">
                                <multiselect
                                    v-model="shipmentDetails.skills"
                                    :options="skillOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Pick skills"
                                ></multiselect>
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.notes" />
                            </form-group>
                            <div v-if="shipmentCustomFieldList !== null">
                                <custom-field-inputs
                                    v-for="(customField, index) in shipmentCustomFieldList"
                                    :key="index"
                                    :custom-field-definition="customField"
                                    :initial-value="customFieldValues[customField.name]"
                                    @changed="handleCustomFieldChanged"
                                />
                            </div>
                            <div v-if="loadFieldList !== null">
                                <load-inputs
                                    v-for="(load, index) in loadFieldList"
                                    :key="index"
                                    :load-definition="load"
                                    :initial-value="loadValues"
                                    @changed="handleLoadChanged"
                                />
                            </div>
                            <div>
                                <form-group name="runnumber" label="Run (Optional)">
                                    <md-input type="number" v-model.number="shipmentDetails.runNumber" min="1" />
                                </form-group>
                            </div>
                            <div class="tripdate">
                                <md-checkbox v-model="isSetTripDate" :disabled="tripDateRequired">
                                    Set trip date for this shipment?
                                </md-checkbox>
                            </div>
                            <div v-if="isSetTripDate">
                                <div class="stop-container">
                                    <md-datepicker
                                        v-model="shipmentDetails.pickupStop.tripDate"
                                        md-immediately
                                        :md-disabled-dates="disabledDates"
                                        :md-debounce="10"
                                    >
                                        <label>Trip Date</label>
                                    </md-datepicker>
                                    <span
                                        v-if="
                                            isSetTripDate &&
                                                tripDateRequired &&
                                                shipmentDetails.pickupStop.tripDate == null
                                        "
                                        class="md-error"
                                    >
                                        Trip date is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-2">
                        <div class="contact-details">
                            <span>Pickup Details</span>
                            <customer-autocomplete
                                :label="shipmentDetails.pickupStop.customerId == null ? 'Name' : 'Customer'"
                                id="autocustomerShipmentPickup"
                                placeholder=""
                                :should-focus="false"
                                v-model="shipmentDetails.pickupStop"
                            />
                            <!-- <div>
                            <brand-filter v-model="shipmentDetails.pickupStop" :list-of-brands="brands" />
                        </div> -->
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="shipmentDetails.pickupStop.customerId != null"
                            >
                                <md-input v-model="shipmentDetails.pickupStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="shipmentDetails.pickupStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="shipmentDetails.pickupStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.pickupStop.notes" />
                            </form-group>
                        </div>
                        <div v-if="isSetTripDate">
                            <div class="stop-container">
                                <div class="trip-settings">
                                    <form-group name="time" label="Time" class="time-picker">
                                        <time-picker
                                            :time="pickupTime"
                                            :additional-time-options="additionalPickupTimeOption"
                                            @selectedTime="getPickupTime"
                                        />
                                    </form-group>
                                </div>
                                <div class="trip-settings duration-setting">
                                    <form-group name="pickupStop.durationMinutes" label="Duration (mins)">
                                        <md-input type="number" v-model="shipmentDetails.pickupStop.durationMinutes" />
                                        <!-- <span class="md-suffix">minutes</span> -->
                                    </form-group>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-3">
                        <div class="contact-details">
                            <span>Drop off Details</span>
                            <customer-autocomplete
                                :label="shipmentDetails.dropStop.customerId == null ? 'Name' : 'Customer'"
                                id="autocustomerShipmentDrop"
                                placeholder=""
                                :should-focus="false"
                                v-model="shipmentDetails.dropStop"
                            />
                            <!-- <div>
                            <brand-filter v-model="shipmentDetails.dropStop" :list-of-brands="brands" />
                        </div> -->
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="shipmentDetails.dropStop.customerId != null"
                            >
                                <md-input v-model="shipmentDetails.dropStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="shipmentDetails.dropStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="shipmentDetails.dropStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.dropStop.notes" />
                            </form-group>
                        </div>
                        <div class="shipment-datepicker" v-if="isSetTripDate">
                            <div>
                                <md-datepicker
                                    v-model="shipmentDetails.dropStop.tripDate"
                                    md-immediately
                                    :md-disabled-dates="dropOffDisabledDates"
                                    :md-debounce="10"
                                >
                                    <label>Trip Date</label>
                                </md-datepicker>
                                <span
                                    v-if="
                                        isSetTripDate &&
                                            tripDateRequired &&
                                            (shipmentDetails.dropStop.tripDate == null ||
                                                shipmentDetails.dropStop.tripDate == '')
                                    "
                                    class="error"
                                >
                                    Trip date is required.
                                </span>
                            </div>
                            <div>
                                <form-group name="time" label="Time" class="time-picker">
                                    <time-picker
                                        :time="dropTime"
                                        :additional-time-options="additionalDropTimeOption"
                                        @selectedTime="getDropTime"
                                    />
                                </form-group>
                            </div>
                            <div>
                                <form-group name="dropStop.durationMinutes" label="Duration (mins)">
                                    <md-input type="number" v-model="shipmentDetails.dropStop.durationMinutes" />
                                    <!-- <span class="md-suffix">minutes</span> -->
                                </form-group>
                            </div>
                        </div>
                    </template>
                </tabs>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" :disabled="ratesIsLoading" @click="updateShipment">
                Update
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, isAValidCoordinate, showErrorMessage } from '@/helpers';
import { requiredIf, numeric, maxLength } from 'vuelidate/lib/validators';
import {
    TimePicker,
    GoogleAutocomplete,
    Tabs,
    CustomFieldInputs,
    LoadInputs,
    CustomerAutocomplete,
    TeamRegionMemberControls,
    ChargingTypeOptions,
    ChargeListModal,
    BrandFilter
} from '@/components';
import moment from 'moment';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { DATE_TYPES, TIME_WINDOW_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    name: 'UpdateShipmentModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        Tabs,
        CustomFieldInputs,
        LoadInputs,
        CustomerAutocomplete,
        TeamRegionMemberControls,
        ChargingTypeOptions,
        ChargeListModal,
        BrandFilter
    },
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        shipment: {
            type: [Number, Object, String],
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            hasRateRules: 'user/hasRateRules',
            isSingleTeamMemberUser: 'user/isSingleTeamMember'
        })
    },
    created() {
        const tempMembers = this.members.map((member) => ({
            publicUserId: member.publicUserId,
            fullName: `${member.firstName || ''} ${member.lastName || ''}`,
            email: member.email
        }));

        const defaultOption = {
            publicUserId: null,
            fullName: 'Unassigned',
            email: null
        };

        this.teamMemberOptions = [defaultOption].concat(tempMembers);
    },
    async mounted() {
        let details = {};
        if (typeof this.shipment === 'number' || typeof this.shipment === 'string') {
            const data = await this.getShipmentDetails(this.shipment);
            data.tripDate =
                data.tripDate != null
                    ? this.$options.filters.timeFormat(data.tripDate, DATE_TYPES.internationalDate)
                    : null;
            data.pickupStop.tripDate =
                data.pickupStop.tripDate != null
                    ? this.$options.filters.timeFormat(data.pickupStop.tripDate, DATE_TYPES.internationalDate)
                    : null;
            data.dropStop.tripDate =
                data.dropStop.tripDate != null
                    ? this.$options.filters.timeFormat(data.dropStop.tripDate, DATE_TYPES.internationalDate)
                    : null;
            details = Object.assign({}, data);
        } else {
            details = Object.assign({}, this.shipment);
        }

        if (details.teamRegionId === null) 
            details.teamRegionId = -1;

        this.shipmentDetails = { ...details };

        if (this.shipmentDetails.assignedTo === null) {
            this.shipmentDetails.assignedTo = {
                publicUserId: 0
            };
        }

        // this is to support disabled members that is not included in the members list. we need to append them at the bottom of the list
        // so that when updating a stop that belongs to them, their name will be shown instead of their publicUserId
        if (this.shipmentDetails.assignedTo.publicUserId != null) {
            const user = this.members.find((x) => x.publicUserId === this.shipmentDetails.assignedTo.publicUserId);
            if (!user) {
                this.teamMemberOptions.push({
                    publicUserId: this.shipmentDetails.assignedTo.publicUserId,
                    fullName: this.shipmentDetails.assignedTo.fullName,
                    email: this.shipmentDetails.assignedTo.email
                });
            }
        }

        if (this.shipmentDetails.contact == null) {
            this.shipmentDetails.contact = this.emptyContact;
        }

        if (this.shipmentDetails.pickupStop.contact == null) {
            this.shipmentDetails.pickupStop.contact = this.emptyContact;
        }

        if (this.shipmentDetails.dropStop.contact == null) {
            this.shipmentDetails.dropStop.contact = this.emptyContact;
        }

        this.isLoading = false;

        // eslint-disable-next-line eqeqeq
        this.isSetTripDate =
            this.shipmentDetails.tripDate !== null ||
            this.shipmentDetails.pickupStop.appointmentTime !== null ||
            this.shipmentDetails.pickupStop.timeWindowStart !== null ||
            this.shipmentDetails.pickupStop.timeWindowEnd !== null ||
            this.shipmentDetails.dropStop.appointmentTime !== null ||
            this.shipmentDetails.dropStop.timeWindowStart !== null ||
            this.shipmentDetails.dropStop.timeWindowEnd !== null ||
            this.isSingleTeamMemberUser;

        this.tripDateRequired =
            !!(
                this.shipmentDetails.assignedTo.publicUserId !== null &&
                this.shipmentDetails.assignedTo.publicUserId !== 0
            ) || this.isSingleTeamMemberUser;

        this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;

        if (
            this.shipmentCustomFieldList != null &&
            this.shipmentCustomFieldList.length &&
            this.shipmentDetails.customFields != null
        ) {
            this.customFieldValues = this.shipmentDetails.customFields;
        }

        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];
        if (this.loadFieldList != null && this.loadFieldList.length && this.shipmentDetails.load != null) {
            this.loadValues = this.shipmentDetails.load;
        }

        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }
        this.shipmentDetails.assignToPublicUserId = this.shipmentDetails.assignedTo.publicUserId;
        this.origTripDate = this.shipmentDetails.tripDate;

        this.pickupTime = this.getStopTime(
            this.shipmentDetails.pickupStop.timeWindowStart,
            this.shipmentDetails.pickupStop.timeWindowEnd,
            this.shipmentDetails.pickupStop.appointmentTime,
            this.additionalPickupTimeOption
        );
        this.shipmentDetails.pickupStop.timeWindowStart = this.formatStopTime(
            this.shipmentDetails.pickupStop.timeWindowStart,
            DATE_TYPES.militaryTime
        );
        this.shipmentDetails.pickupStop.timeWindowEnd = this.formatStopTime(
            this.shipmentDetails.pickupStop.timeWindowEnd,
            DATE_TYPES.militaryTime
        );
        this.shipmentDetails.pickupStop.appointmentTime = this.formatStopTime(
            this.shipmentDetails.pickupStop.appointmentTime,
            DATE_TYPES.militaryTime
        );

        this.dropTime = this.getStopTime(
            this.shipmentDetails.dropStop.timeWindowStart,
            this.shipmentDetails.dropStop.timeWindowEnd,
            this.shipmentDetails.dropStop.appointmentTime,
            this.additionalDropTimeOption
        );
        this.shipmentDetails.dropStop.timeWindowStart = this.formatStopTime(
            this.shipmentDetails.dropStop.timeWindowStart,
            DATE_TYPES.militaryTime
        );
        this.shipmentDetails.dropStop.timeWindowEnd = this.formatStopTime(
            this.shipmentDetails.dropStop.timeWindowEnd,
            DATE_TYPES.militaryTime
        );
        this.shipmentDetails.dropStop.appointmentTime = this.formatStopTime(
            this.shipmentDetails.dropStop.appointmentTime,
            DATE_TYPES.militaryTime
        );

        this.pickupStopDurationMinutes = this.shipmentDetails.pickupStop.durationMinutes;
        this.dropStopDurationMinutes = this.shipmentDetails.dropStop.durationMinutes;

        this.chargeList = this.shipmentDetails.chargeLines;
        this.costList = this.shipmentDetails.costLines;
    },
    watch: {
        isSetTripDate(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue) {
                if (this.origTripDate !== this.shipmentDetails.tripDate) {
                    this.shipmentDetails.tripDate = moment().format(DATE_TYPES.internationalDate);
                    this.shipmentDetails.pickupStop.tripDate = moment().format(DATE_TYPES.internationalDate);
                    this.shipmentDetails.dropStop.tripDate = moment().format(DATE_TYPES.internationalDate);
                }
            } else {
                this.shipmentDetails.tripDate = null;
                this.shipmentDetails.pickupStop.appointmentTime = null;
                this.shipmentDetails.pickupStop.tripDate = null;
                this.shipmentDetails.dropStop.appointmentTime = null;
                this.shipmentDetails.dropStop.tripDate = null;
            }
        },
        'shipmentDetails.pickupStop.tripDate': function(newValue, oldValue) {
            if (
                oldValue === this.shipmentDetails.dropStop.tripDate ||
                this.shipmentDetails.dropStop.tripDate < newValue
            ) {
                this.shipmentDetails.dropStop.tripDate = newValue;
            }
            this.shipmentDetails.tripDate = newValue;
        },
        'shipmentDetails.dropStop.tripDate': function(newValue, oldValue) {
            if (
                oldValue === this.shipmentDetails.dropStop.tripDate ||
                this.shipmentDetails.pickupStop.tripDate > newValue
            ) {
                this.shipmentDetails.pickupStop.tripDate = newValue;
            }
        },
        'shipmentDetails.assignedTo.publicUserId': function(newValue, oldValue) {
            if (newValue !== oldValue && oldValue) {
                this.setDurationMinutes(newValue);
            }

            // eslint-disable-next-line eqeqeq
            if (newValue !== null && newValue != 0) {
                this.isSetTripDate = true;
                this.tripDateRequired = true;
            } else {
                this.tripDateRequired = false;
            }
        },
        'shipmentDetails.tripDate': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== oldValue) {
                if (this.shipmentDetails.tripDate !== newValue) 
                    this.validateCanGenerateRates();
            }
        }
    },
    methods: {
        async getShipmentDetails(shipmentId) {
            this.$_handleLoaderState(true);
            const api = `/api/shipments/${shipmentId}?includeStatusHistory=true`;
            const data = await handleRequests(api);
            this.$_handleLoaderState(false);
            return data.data;
        },
        async updateShipment() {
            if (
                (this.isSetTripDate && this.tripDateRequired && this.shipmentDetails.pickupStop.tripDate == null) ||
                this.isTimeValid === false
            )
                return;

            const {
                assignedTo: { publicUserId }
            } = this.shipmentDetails;
            this.$v.shipmentDetails.$touch();

            if (!this.$v.shipmentDetails.$invalid) {
                this.$_handleLoaderState(true, 'UPDATING...');
                // eslint-disable-next-line eqeqeq
                if (!publicUserId) 
                    this.shipmentDetails.assignedTo.publicUserId = null;

                // eslint-disable-next-line radix
                this.shipmentDetails.pickupStop.durationMinutes =
                    parseInt(this.shipmentDetails.pickupStop.durationMinutes, 10) || 15;

                // eslint-disable-next-line radix
                this.shipmentDetails.dropStop.durationMinutes =
                    parseInt(this.shipmentDetails.dropStop.durationMinutes, 10) || 15;

                if (this.shipmentDetails.pickupStop.tripDate) {
                    this.shipmentDetails.tripDate = moment(this.shipmentDetails.pickupStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                    this.shipmentDetails.pickupStop.tripDate = moment(this.shipmentDetails.pickupStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                }

                if (this.shipmentDetails.dropStop.tripDate) {
                    this.shipmentDetails.dropStop.tripDate = moment(this.shipmentDetails.dropStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                }

                this.shipmentDetails.customFields = null;
                this.shipmentDetails.currentLocation = null;
                this.shipmentDetails.customFields = JSON.stringify(this.customFieldValues);
                this.shipmentDetails.load = this.loadValues;

                if (!this.isSetTripDate) {
                    this.shipmentDetails.tripDate = null;
                    this.shipmentDetails.pickupStop.appointmentTime = null;
                    this.shipmentDetails.pickupStop.timeWindowStart = null;
                    this.shipmentDetails.pickupStop.timeWindowEnd = null;
                    this.shipmentDetails.pickupStop.tripDate = null;
                    this.shipmentDetails.dropStop.appointmentTime = null;
                    this.shipmentDetails.dropStop.timeWindowStart = null;
                    this.shipmentDetails.dropStop.timeWindowEnd = null;
                    this.shipmentDetails.dropStop.tripDate = null;
                }
                /* this.shipmentDetails.pickupStop.runNumber = this.shipmentDetails.runNumber;
                this.shipmentDetails.dropStop.runNumber = this.shipmentDetails.runNumber; */

                // eslint-disable-next-line no-unused-expressions
                this.chargeList?.forEach((rate, index) => {
                    rate.shipmentId = this.shipmentDetails.shipmentId;
                });

                // eslint-disable-next-line no-unused-expressions
                this.costList?.forEach((cost, index) => {
                    cost.shipmentId = this.shipmentDetails.shipmentId;
                });

                this.shipmentDetails.updatedChargeLines = this.updatedCharges;
                this.shipmentDetails.updatedCostLines = this.updatedCosts;

                const api = `/api/shipments/${this.shipmentDetails.shipmentId}`;
                const payload = {
                    method: 'put',
                    data: this.shipmentDetails
                };

                try {
                    const responseData = await handleRequests(api, payload);
                    this.$notify({
                        message: 'Shipment updated.',
                        type: 'success'
                    });

                    const feedbackMessage = responseData?.data;
                    if (feedbackMessage && typeof feedbackMessage === 'string' && feedbackMessage.trim().length !== 0) {
                        this.$notifyWarning(feedbackMessage, 7000);
                    }

                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    let message = 'Could not update shipment.';
                    if (e && e.data) 
                        [{ message }] = e.data;
                    showErrorMessage(this, message, e);
                    this.$_handleLoaderState(false);
                }
            }
        },
        getPickupTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.shipmentDetails.pickupStop.appointmentTime = appointmentTime;
                this.shipmentDetails.pickupStop.timeWindowStart = timeWindowStart;
                this.shipmentDetails.pickupStop.timeWindowEnd = timeWindowEnd;
                this.pickupTime = this.getStopTime(
                    this.shipmentDetails.pickupStop.timeWindowStart,
                    this.shipmentDetails.pickupStop.timeWindowEnd,
                    this.shipmentDetails.pickupStop.appointmentTime,
                    this.additionalPickupTimeOption
                );
            } else {
                this.shipmentDetails.pickupStop.appointmentTime = null;
                this.shipmentDetails.pickupStop.timeWindowStart = null;
                this.shipmentDetails.pickupStop.timeWindowEnd = null;
                this.pickupTime = null;
            }

            this.isPickupTimeValid = isTimeValid;
        },
        getDropTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.shipmentDetails.dropStop.appointmentTime = appointmentTime;
                this.shipmentDetails.dropStop.timeWindowStart = timeWindowStart;
                this.shipmentDetails.dropStop.timeWindowEnd = timeWindowEnd;
                this.dropTime = this.getStopTime(
                    this.shipmentDetails.dropStop.timeWindowStart,
                    this.shipmentDetails.dropStop.timeWindowEnd,
                    this.shipmentDetails.dropStop.appointmentTime,
                    this.additionalDropTimeOption
                );
            } else {
                this.shipmentDetails.dropStop.appointmentTime = null;
                this.shipmentDetails.dropStop.timeWindowStart = null;
                this.shipmentDetails.dropStop.timeWindowEnd = null;
                this.dropTime = null;
            }

            this.isDropTimeValid = isTimeValid;
        },
        resetShipmentState() {
            Object.assign(this.shipmentDetails, STOP_DETAILS_DEFAULTS());
            this.$v.$reset();
        },
        getStopTime(stopTimeWindowStart, stopTimeWindowEnd, stopAppointmentTime, additionalTimeOption) {
            let stopTime = null;
            if (stopAppointmentTime != null) {
                stopTime = this.formatStopTime(stopAppointmentTime, DATE_TYPES.militaryTime);
            } else if (stopTimeWindowStart != null && stopTimeWindowEnd != null) {
                const timeWindowStart = this.formatStopTime(stopTimeWindowStart, DATE_TYPES.militaryTime);
                const timeWindowEnd = this.formatStopTime(stopTimeWindowEnd, DATE_TYPES.militaryTime);

                const timeWindowStartStandard =
                    stopTimeWindowStart.length > 5
                        ? moment(stopTimeWindowStart).format(DATE_TYPES.standardTime)
                        : moment(stopTimeWindowStart, DATE_TYPES.militaryTime).format(DATE_TYPES.standardTime);
                const timeWindowEndStandard =
                    stopTimeWindowEnd.length > 5
                        ? moment(stopTimeWindowEnd).format(DATE_TYPES.standardTime)
                        : moment(stopTimeWindowEnd, DATE_TYPES.militaryTime).format(DATE_TYPES.standardTime);

                let hasMatch = false;

                TIME_WINDOW_CONSTANTS.some((x) => {
                    if (timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd) {
                        stopTime = x.name;
                        hasMatch = true;
                    }
                    return timeWindowStart === x.timeWindowStart && timeWindowEnd === x.timeWindowEnd;
                });

                if (!hasMatch) {
                    stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                    additionalTimeOption.push({
                        name: stopTime,
                        timeWindowStart,
                        timeWindowEnd
                    });
                }
            } else {
                stopTime = 'None';
            }
            return stopTime;
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleLoadChanged({ name, value }) {
            let canGetRules = false;
            if (this.loadValues[name] !== parseFloat(value)) {
                canGetRules = true;
            }

            this.loadValues[name] = parseFloat(value);

            if (canGetRules) 
                this.ruleGenerationLoadDetails();
        },
        handleCustomerFieldChange() {
            this.ruleGenerationLoadDetails();
        },
        handleTeamRegionMemberChanged(val) {
            if (this.shipmentDetails.assignedTo.publicUserId !== val.publicUserId) {
                this.validateCanGenerateRates();
            }

            this.shipmentDetails.teamRegionId = val.teamRegionId;
            this.shipmentDetails.pickupStop.teamRegionId = val.teamRegionId;
            this.shipmentDetails.dropStop.teamRegionId = val.teamRegionId;
            this.shipmentDetails.assignToPublicUserId = val.publicUserId;
            this.shipmentDetails.assignedTo.publicUserId = val.publicUserId;
            this.shipmentDetails.carrierTeamId = val.carrierTeamId;
        },
        setDurationMinutes(publicUserId) {
            const teamMember = this.members.find((x) => x.publicUserId === publicUserId);
            const defaultStopDurationMinutes =
                teamMember?.defaultStopDurationMinutes ?? this.user.defaultStopDurationMinutes;
            // User user or team default stop duration, if null use value from Stop details
            this.shipmentDetails.pickupStop.durationMinutes =
                defaultStopDurationMinutes ?? this.pickupStopDurationMinutes;
            this.shipmentDetails.dropStop.durationMinutes = defaultStopDurationMinutes ?? this.dropStopDurationMinutes;
        },
        formatStopTime(stopTime, dateType) {
            if (stopTime != null) {
                return stopTime.length > 5 ? moment(stopTime).format(dateType) : stopTime;
            }
            return stopTime;
        },
        handlePickupCoordinates(value) {
            this.usePickupCoordinates = value;
            this.$v.$reset();
        },
        handleDropCoordinates(value) {
            this.useDropCoordinates = value;
            this.$v.$reset();
        },
        handleRateTypeChanged(rateRule) {
            this.shipmentDetails.rateGroupId = rateRule?.rateGroupId ?? null;

            if (this.shipmentDetails.rateGroupId === null) {
                this.chargeList = [];
                this.costList = [];

                this.shipmentDetails.chargeLines = this.chargeList;
                this.shipmentDetails.costLines = this.costList;

                this.updatedCharges = [];
                this.updatedCosts = [];
            }

            this.ruleGenerationDebounce();
        },
        // eslint-disable-next-line func-names
        handleShipmentDetailsChanged: _.debounce(function() {
            this.validateCanGenerateRates(true, false);
        }, 500),
        validateCanGenerateRates(addressChanged, rateGroupChanged) {
            if (
                this.shipmentDetails.pickupStop.address != null &&
                this.shipmentDetails.dropStop.address != null &&
                this.ratesIsLoading === false
            ) {
                this.generateRates(addressChanged, rateGroupChanged);
            }
        },
        async generateRates(addressChanged, rateGroupChanged) {
            if (!this.hasRateRules) 
                return;

            if (!this.shipmentDetails.rateGroupId) {
                this.rateListData = null;
                this.chargeList = [];
                this.costList = [];
                this.updatedCharges = [];
                this.updatedCosts = [];

                return;
            }

            this.ratesIsLoading = true;

            let response = {
                data: {
                    rateList: null
                }
            };

            // eslint-disable-next-line no-unused-expressions
            this.chargeList?.forEach((rate, index) => {
                rate.shipmentId = this.shipmentDetails.shipmentId;
                rate.isChargingRule = true;
                rate.customerId = this.shipmentDetails.customerId;
            });

            // eslint-disable-next-line no-unused-expressions
            this.costList?.forEach((cost, index) => {
                cost.shipmentId = this.shipmentDetails.shipmentId;
                cost.isCostingRule = true;
                cost.customerId = this.shipmentDetails.customerId;
            });

            const hasNoCustomerInvoice = this.shipmentDetails.customerInvoiceId === null;
            const hasNoTeamMemberInvoice = this.shipmentDetails.teamMemberInvoiceId === null;

            try {
                const data = this.$_getshipmentDataModel(
                    this.shipmentDetails,
                    this.user,
                    this.loadValues,
                    this.chargeList,
                    this.costList,
                    hasNoCustomerInvoice,
                    hasNoTeamMemberInvoice,
                    false,
                    false,
                    rateGroupChanged,
                    addressChanged
                );

                const payload = {
                    method: 'post',
                    data
                };

                // response = await handleRequests(`/api/rates/shipment/${this.shipmentDetails.shipmentId}/false/false/false/false`, payload);
                response = await handleRequests('/api/rates/generate-rates', payload);

                if (response.data) {
                    this.rateListData = response.data;
                    this.chargeList = response.data.charge ?? [];
                    this.costList = response.data.cost ?? [];

                    this.shipmentDetails.distance = response.data.distance ?? 0;

                    this.updatedCharges = response.data.charge ?? [];
                    this.updatedCosts = response.data.cost ?? [];
                }
                this.ratesIsLoading = false;
            } catch (error) {
                const message = `Error generating rates, if issue persists please contact support`;
                showErrorMessage(this, message, null);
                this.ratesIsLoading = false;
            }
        },
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        // eslint-disable-next-line func-names
        ruleGenerationDebounce: _.debounce(function() {
            this.validateCanGenerateRates(false, true);
        }, 500),
        // eslint-disable-next-line func-names
        ruleGenerationLoadDetails: _.debounce(function() {
            this.validateCanGenerateRates(false, false);
        }, 1000),
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.shipmentDetails.rateGroupId === null || this.shipmentDetails.rateGroupId === undefined) {
                this.shipmentDetails.rateGroupId = customerRateGroupId;
            }
        }
    },
    data() {
        return {
            disabledDates: (date) => {
                const now = new Date();
                return !moment(date).isSameOrAfter(now, 'day');
            },
            dropOffDisabledDates: (date) => {
                const dropOffDate = new Date(this.shipmentDetails.pickupStop.tripDate);
                return !moment(date).isSameOrAfter(dropOffDate, 'day');
            },
            isSetTripDate: false,
            tripDateRequired: false,
            isTimeValid: true,
            shipmentCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            teamMemberOptions: [],
            shipmentDetails: {
                assignedTo: {},
                pickupStop: {
                    contact: {}
                },
                dropStop: {
                    contact: {}
                },
                contact: {}
            },
            pickupTime: null,
            dropTime: null,
            origTripDate: null,
            additionalPickupTimeOption: [],
            additionalDropTimeOption: [],
            emptyContact: {
                name: '',
                phone: '',
                email: ''
            },
            isLoading: true,
            usePickupCoordinates: false,
            useDropCoordinates: false,
            pickupStopDurationMinutes: null,
            dropStopDurationMinutes: null,
            rateListData: {
                totalCharge: 0,
                totalCost: 0
            },
            chargeList: [],
            ratesIsLoading: false,
            costList: [],
            updatedCharges: [],
            updatedCosts: []
        };
    },
    validations: {
        shipmentDetails: {
            pickupStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.usePickupCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.usePickupCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.isSetTripDate;
                        }),
                    numeric
                }
            },
            dropStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.useDropCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.useDropCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.isSetTripDate;
                        }),
                    numeric
                }
            },
            sourceReference: {
                maxLength: maxLength(200)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    // max-width: 500px;
    max-width: 600px;
    // min-height: 500px;
    .modal-body {
        min-height: 400px;
        padding-bottom: 0;

        ::v-deep .md-card-tabs .tab-content {
            max-height: 65vh;
            overflow: auto;
        }
    }
    .stats {
        flex-grow: 8;
        .md-icon {
            color: #ffd900;
        }
    }
    .tip {
        font-size: 12px;
    }
}

.custom-tab-list {
    margin-top: -20px;
    ::v-deep .md-card-header {
        padding: 0;
    }

    ::v-deep .md-card-content {
        padding: 0;
        .md-list {
            margin: 0;
            .md-list-item:first-child {
                margin-left: 0;
            }
        }
    }
}
.shipment-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}
.trip-settings {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    min-width: 125px;
    max-width: 30%;
}
.duration-setting {
    min-width: 90px;
    max-width: 100px;
}
.contact-details {
    margin-top: 20px;
}
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.shipment-datepicker {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    ::v-deep .md-clear {
        display: none;
    }
    > div {
        margin-right: 20px;
        min-width: 125px;
    }
    > div:first-child {
        width: 35%;
    }
    > div:last-child {
        margin-right: 0;
        min-width: 100px;
        max-width: 100px;
    }
    .error {
        font-size: 12px;
        color: red;
        margin-top: -10px;
        display: block;
    }

    .md-input {
        width: 100%;
    }

    .time-picker:after {
        height: 0;
    }

    .time-window {
        width: 100%;
        > div {
            width: 100%;
        }
        > div:first-child {
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            font-weight: 600;
            text-align: left;
        }
        > div:nth-child(2) {
            ::v-deep .md-radio {
                margin: 0;
                margin-right: 15px;
            }
            text-transform: uppercase;
            text-align: left;
            font-size: 12px;
        }
    }
}
.charge-wrapper {
    padding-left: 25px;
}

.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}

.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}

.chargeButton-toggle {
    float: right;
    border: none;
    background-color: #4caf50;
    position: relative;
    place-items: center;
    display: grid;
    color: white !important;
}

.chargeButton-toggle:hover {
    background: #409443;
}
</style>
