<template>
    <div>
        <div v-if="!isListLoading">
            <md-table class="custom-paginated-table">
                <md-table-row>
                    <md-table-head>Name</md-table-head>
                    <md-table-head>Tracker Reference</md-table-head>
                    <!--<md-table-head>Status</md-table-head>-->
                    <!-- <md-table-head></md-table-head> -->
                    <md-table-head>Team Member</md-table-head>
                    <!--<md-table-head>Disable Date</md-table-head>-->
                    <md-table-head>Actions</md-table-head>
                </md-table-row>

                <md-table-row v-for="(item, index) in list" :key="index" @click.stop="showAssetGroupDetails(item)">
                    <md-table-cell>
                        <router-link
                            :to="{
                                name: 'Asset Group Details',
                                params: { assetGroupId: item.assetGroupId }
                            }"
                            target="_blank"
                        >
                            {{ item.groupName }}
                        </router-link>
                    </md-table-cell>
                    <md-table-cell>
                        {{ item.trackerReference }}
                    </md-table-cell>
                    <!-- <md-table-cell>
                        {{ item.status }}
                    </md-table-cell> -->
                    <md-table-cell>
                        <div v-if="item.ownerId != null">
                            <img
                                v-if="item.ownerPhotoUrl"
                                class="circle-profile-image"
                                :src="item.ownerPhotoUrl"
                                @error="$_setDefaultBrokenImage"
                                alt
                            />
                            <img v-else class="circle-profile-image" :src="$root.defaultPhotoUrl" alt />
                            <router-link
                                v-if="user.publicUserId != item.ownerId"
                                class="custom-a-blue"
                                :to="{
                                    name: 'Team Member Profile',
                                    params: { publicUserId: item.ownerId }
                                }"
                                target="_blank"
                            >
                                {{ item.ownerName }}
                            </router-link>
                            <router-link
                                v-else
                                class="custom-a-blue"
                                :to="{
                                    name: 'User Profile'
                                }"
                                target="_blank"
                            >
                                {{ item.ownerName }}
                            </router-link>
                        </div>
                    </md-table-cell>
                    <!--<md-table-cell>
                        {{ item.disabledDate | dateFormat(DATE_TYPES.standardDate) }}
                    </md-table-cell>-->
                    <md-table-cell class="action-buttons">
                        <md-button
                            title="Update asset group name"
                            class="md-warning md-just-icon md-round"
                            @click.stop="handleUpdateAssetGroup(item)"
                        >
                            <md-icon>edit</md-icon>
                        </md-button>
                        <md-button
                            v-if="!item.disabledDate"
                            title="Assign a member"
                            class="md-info md-just-icon md-round"
                            @click.stop="
                                handleAssignAssetGroup({
                                    assetGroupId: item.assetGroupId,
                                    ownedBy: item.ownerId,
                                    groupName: item.groupName,
                                    teamRegionId: item.teamRegionId
                                })
                            "
                        >
                            <md-icon>person_pin</md-icon>
                        </md-button>
                        <!-- remove disable of asset group so that automatically created groups won't get disabled -->
                        <!-- <md-button
                            v-if="!item.disabledDate"
                            title="Disable Asset Group"
                            class="md-danger md-just-icon md-round"
                            @click.stop="disableAssetGroupConfirmation(item.assetGroupId, index)"
                        >
                            <md-icon>not_interested</md-icon>
                        </md-button> -->
                    </md-table-cell>
                </md-table-row>
            </md-table>
            <div v-if="list.length == 0 && !isListLoading" class="text-center">
                <p class="no-result-message">No results matching your search/filter could be found.</p>
            </div>
            <md-card-actions class="page-footer" md-alignment="space-between" v-if="list.length > 0">
                <div>
                    <p v-if="total === pagination.perPage" class="card-category">
                        Page {{ pagination.currentPage }} of many
                    </p>
                    <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ totalPages }}</p>
                </div>
                <pagination
                    v-model="pagination.currentPage"
                    class="pagination-no-border pagination-success"
                    :per-page="pagination.perPage"
                    :total="total"
                    @change-page="handleChangePage($event, pagination.perPage)"
                />
            </md-card-actions>
        </div>
        <div v-else>
            <div class="body-list">
                <div class="list-loader">
                    <fade-loader :loading="isListLoading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </div>
        <transition name="slide">
            <asset-group-sidebar
                v-if="showSideBar"
                :asset-group-id="assetGroupId"
                @closeModal="toggleAssetGroupDetailsWindow"
            />
        </transition>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination } from '@/components';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import AssignToAssetGroupModal from './ModalComponents/AssignToAssetGroupModal';
import UpdateAssetGroupModal from './ModalComponents/UpdateAssetGroupModal';
import AssetGroupSidebar from './AssetGroupSidebar';
// import Buttons from '../Dashboard/Components/Buttons';

export default {
    name: 'AssetGroupListTable',
    mixins: [GeneralMixin],
    components: {
        Pagination,
        FadeLoader,
        AssetGroupSidebar
    },
    props: {
        // to be able to detect if there is a new asset group created
        newAssetGroupId: {
            type: Number,
            default: () => null
        },
        filters: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            pagination: PAGINATION_DEFAULTS,
            teamMembers: [],
            list: [],
            maxPage: 1,
            loading: true,
            isListLoading: false,
            prevFilters: {
                status: 'active',
                teamRegionId: undefined,
                memberPublicUserId: undefined,
                searchText: undefined
            },
            assetGroupId: null,
            showSideBar: false
        };
    },
    watch: {
        filters: {
            deep: true,
            handler(newValue, oldValue) {
                this.handleFilterOrSearch(newValue);
            }
        },
        newAssetGroupId(newValue) {
            this.handleChangePage();
        }
    },
    async mounted() {
        if (this.user.teamRegionId) {
            this.prevFilters.teamRegionId = this.user.teamRegionId;
        }

        this.getTeamMembers();
        let currentPage = 1;
        const { type } = this.$route.params;
        // When switching between tabs there is a delay in replacing the route hence we need to
        // compare the type in the url if it does not match then current page should be set to 1.
        if (type === 'asset-groups') {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }

        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        await this.handleFilterOrSearch({ teamRegionId: this.prevFilters.teamRegionId });
    },
    methods: {
        async fetchAssetGroupList(pageNumber = 1, itemsPerPage = 50) {
            const newFilters = { ...this.filters, pageNumber, itemsPerPage };
            let params = {};
            let shouldSearch = false;
            Object.entries(newFilters).forEach(([key, value]) => {
                shouldSearch = shouldSearch || (value || null) !== (this.prevFilters[key] || null);
                params = { ...params, [key]: value };
            });

            if (!shouldSearch) 
                return; // exit function
            this.isListLoading = true;
            this.prevFilters = { ...this.prevFilters, ...newFilters };
            const endpoint = `/api/asset-groups/list`;
            const response = await handleRequests(endpoint, {
                params
            });
            const {
                data: { assetGroups, totalAssetGroup }
            } = response;
            this.assignToAssetGroup(assetGroups, totalAssetGroup || assetGroups.length);
            this.isListLoading = false;
        },
        assignToAssetGroup(assetGroups, total) {
            this.list = assetGroups;
            this.pagination.total = total;
        },
        cleanFilters() {
            Object.keys(this.filters).forEach((e) => {
                if (!this.filters[e]) 
                    delete this.filters[e];
            });
            return this.filters;
        },
        getMaxPage(page) {
            this.maxPage = page;
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
            await this.fetchAssetGroupList(currentPage, perPage);
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
        },
        async handleFilterOrSearch(val) {
            // this.isListLoading = true;
            this.filters = Object.assign(this.filters, val);
            await this.fetchAssetGroupList(this.pagination.currentPage, this.pagination.perPage);
            // this.isListLoading = false;
        },
        async getTeamMembers() {
            const teamMembersEndpoint = `/api/team-members`;
            const {
                data: { data }
            } = await handleRequests(teamMembersEndpoint);
            const tempMembers = data.map((member) => ({
                publicUserId: member.publicUserId,
                fullName: `${member.firstName || ''} ${member.lastName || ''}`,
                email: member.email
            }));

            const defaultOption = {
                publicUserId: 0,
                fullName: 'Unassigned',
                email: null
            };

            this.teamMembers = [defaultOption].concat(tempMembers);
        },
        disableAssetGroupConfirmation(assetGroupId, index) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Disable Asset Group',
                    body: 'Are you sure you want to disable this asset group?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') 
                        await this.disableAssetGroup(assetGroupId, index);
                });
        },
        async disableAssetGroup(assetGroupId, index) {
            this.$_handleLoaderState(true);
            try {
                const api = `/api/asset-groups/${assetGroupId}/disable`;
                await handleRequests(api, { method: 'post' });
                this.$notify({
                    message: 'Asset Group successfully disabled.',
                    type: 'success'
                });
                const assignedGroup = this.list.find((item) => item.assetGroupId === assetGroupId);
                this.$set(assignedGroup, 'disabledDate', new Date());
                this.$emit('removeAssetFromGroup', assetGroupId);
            } catch (e) {
                const message = 'Something went wrong! Please Try again.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        },
        /**
         * @param {{ assignedTo: { fullName: string | null, publicUserId: string | null }, assetGroupId: number, teamRegionId: number }} param0
         */
        onAssignedUser({ assignedTo, assetGroupId, teamRegionId }) {
            // assignedTo.fullName and assignedTo.publicUserId are both null if the user unsets the assigned user
            if (assignedTo.fullName !== null && assignedTo.publicUserId !== null) {
                // we need to unset the other asset group that the same user is assigned to
                const otherAssetGroups = this.list.filter(
                    /**
                     * @param {{ ownerId: string, ownerName: string, teamRegionId: number }} item
                     * @returns {boolean}
                     */
                    (item) => item.ownerId === assignedTo.publicUserId
                );
                if (otherAssetGroups) {
                    otherAssetGroups.forEach((otherAssetGroup) => {
                        this.$set(otherAssetGroup, 'ownerName', null);
                        this.$set(otherAssetGroup, 'ownerId', null);
                        this.$set(otherAssetGroup, 'teamRegionId', teamRegionId);
                    });
                }
            }
            const assignedGroup = this.list.find((item) => item.assetGroupId === assetGroupId);
            this.$set(assignedGroup, 'ownerName', assignedTo.fullName);
            this.$set(assignedGroup, 'ownerId', assignedTo.publicUserId);
            this.$set(assignedGroup, 'teamRegionId', teamRegionId);
        },
        handleAssignAssetGroup({ assetGroupId, ownedBy, groupName, teamRegionId }) {
            this.$modal
                .show(AssignToAssetGroupModal, {
                    assetGroupId,
                    // teamMembers: this.teamMembers,
                    ownedBy,
                    groupName,
                    teamRegionId
                })
                .then((response) => {
                    this.onAssignedUser(response);
                });
        },
        handleUpdateAssetGroup(assetGroupData) {
            this.$modal.show(UpdateAssetGroupModal, { assetGroupData }).then((response) => {
                const existingAssetGroup = this.list.find(
                    (assetGroup) => assetGroup.assetGroupId === response.assetGroupId
                );

                this.$set(existingAssetGroup, 'groupName', response.groupName);
                this.$set(existingAssetGroup, 'teamRegionId', response.teamRegionId);
            });
        },
        showAssetGroupDetails(item) {
            this.showSideBar = true;
            this.assetGroupId = item.assetGroupId;
        },
        toggleAssetGroupDetailsWindow() {
            if (!this.$modal.isModalShown && !this.$messageBox.isMessageBoxShown) 
                this.showSideBar = !this.showSideBar;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table-cell {
    height: 40px;
    .custom-btn {
        margin: 0;
    }
}

::v-deep .md-table-cell:last-child .md-table-cell-container {
    justify-content: flex-start;
}

.custom-paginated-table {
    a {
        color: rgba(0, 0, 0, 0.87);
    }
}

.md-card-actions.page-footer {
    padding-bottom: 0 !important;
}
</style>
