<template>
    <div class="nested-table">
        <div class="nested-table-row nested-table-header">
            <md-checkbox v-model="isSelectAll" @change="onSelectAll"></md-checkbox>
            <h5>{{ referenceHeaderValue.name }}</h5>
            <h5>Customer</h5>
            <h5>Address</h5>
            <h5>Time</h5>
            <h5>Status</h5>
            <div class="finance-column">
                <h5>Charge</h5>
            </div>
            <div class="finance-column">
                <h5>Cost</h5>
            </div>
            <div class="finance-column">
                <h5>Margin</h5>
            </div>
            <h5>Actions</h5>
        </div>
        <div class="line-separator" />
        <div v-for="(job, index) in rootJob.childrenJobs" :key="index">
            <div class="nested-table-row">
                <md-checkbox :value="job" v-model="selectedJobs"></md-checkbox>
                <span class="ref-no">
                    <md-icon class="warming-icon" v-if="showWarning(job)">
                        warning
                        <CustomTooltip>
                            Not Geocoded
                            <br />
                            Confirm address to fix.
                        </CustomTooltip>
                    </md-icon>
                    <span v-if="job.stopId">
                        <router-link
                            v-if="job.stopId"
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: job.stopId }
                            }"
                            target="_blank"
                        >
                            {{
                                referenceHeaderValue.key ===
                                    'reference-number' ||
                                job.sourceReference === null ||
                                job.sourceReference === ''
                                    ? job.ref
                                    : job.sourceReference
                            }}
                        </router-link>
                    </span>
                    <span v-if="job.shipmentId">
                        <router-link
                            :to="{
                                name: 'Shipment Details',
                                params: { shipmentId: job.shipmentId }
                            }"
                            target="_blank"
                        >
                            {{
                                referenceHeaderValue.key ===
                                    'reference-number' ||
                                job.sourceReference === null ||
                                job.sourceReference === ''
                                    ? job.ref
                                    : job.sourceReference
                            }}
                        </router-link>
                    </span>
                    <span v-if="job.tripId">
                        <router-link
                            :to="{
                                name: 'Trip Details',
                                params: { tripId: job.tripId }
                            }"
                            target="_blank"
                        >
                            {{
                                referenceHeaderValue.key ===
                                    'reference-number' ||
                                job.sourceReference === null ||
                                job.sourceReference === ''
                                    ? job.ref
                                    : job.sourceReference
                            }}
                        </router-link>
                    </span>
                </span>
                <span>{{ job.customerName }}</span>
                <span>
                    {{ job.dropAddress }}
                </span>
                <div class="time" @click.stop="openUpdateTimeModal">
                    <span>
                        {{ getTimeValue(job) }}
                        <md-icon class="edit-icon" v-if="job.tripId">
                            edit
                        </md-icon>
                        <CustomTooltip>
                            <div v-for="(time, idx) in getTimeTooltipText(job)" :key="idx" class="time-tooltip-content">
                                <b>{{ time.label }}</b>
                                <span>{{ time.value }}</span>
                            </div>
                        </CustomTooltip>
                    </span>
                </div>

                <span>
                    <JobStatus :variant="getStatusNormalized(job.status)" />
                </span>
                <div class="finance-column">
                    <span v-if="job.totalCharge < 0">
                        ({{ getTotalCharge(job) | currency(job.currency) }})
                    </span>
                    <span v-else>
                        {{ getTotalCharge(job) | currency(job.currency) }}
                    </span>
                </div>
                <div class="finance-column">
                    <span v-if="job.totalCost < 0">
                        ({{ getTotalCost(job) | currency(job.currency) }})
                    </span>
                    <span v-else>
                        {{ getTotalCost(job) | currency(job.currency) }}
                    </span>
                </div>
                <div class="finance-column">
                    <span v-if="job.margin < 0">
                        ({{ getMargin(job) | currency(job.currency) }})
                    </span>
                    <span v-else>
                        {{ getMargin(job) | currency(job.currency) }}
                    </span>
                </div>
                <div class="actions">
                    <div v-if="!job.isRecalculating">
                        <md-button
                            @click.stop="updateRates(job)"
                            title="Update Rates"
                            class="md-success md-just-icon md-round recalculate-icon"
                            :disabled="!job.rateGroupId"
                        >
                            <md-icon>currency_exchange</md-icon>
                        </md-button>
                    </div>
                    <div v-else class="sync-loader">
                        <straight-line-loader />
                    </div>
                </div>
            </div>

            <div class="line-separator" />
        </div>
    </div>
</template>
<script>
import CustomTooltip from '@/pages/ProcessCharges/components/CustomTooltip';
import UpdateJobTimeModal from '@/pages/ProcessCharges/components/UpdateJobTimeModal';
import moment from 'moment/moment';
import JobStatus from '@/pages/ProcessCharges/components/JobStatus';
import { StraightLineLoader } from '@/components';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'NestedTable',
    components: { StraightLineLoader, JobStatus, CustomTooltip },
    data() {
        return {
            isSelectAll: false
        };
    },
    props: {
        rootJob: {
            type: Object,
            required: true
        },
        selectedJobs: {
            type: Array,
            required: true
        },
        referenceHeaderValue: {
            type: Object,
            default: null
        }
    },
    methods: {
        updateRates(job) {
            this.$emit('update-rates', job);
        },
        /**
         *
         * @param job
         * @returns {{ identifier: number, identifierType: string }}
         */
        getJobIdentifier(job) {
            const { stopId, tripId, shipmentId } = job;
            let identifierType = '';
            if (stopId) {
                identifierType = 'stopId';
            } else if (tripId) {
                identifierType = 'tripId';
            } else if (shipmentId) {
                identifierType = 'shipmentId';
            }
            let identifier = 0;
            if (stopId) {
                identifier = stopId;
            } else if (tripId) {
                identifier = tripId;
            } else if (shipmentId) {
                identifier = shipmentId;
            }
            return {
                identifierType,
                identifier
            };
        },
        onSelectAll() {
            this.$nextTick(() => {
                const items = this.rootJob.childrenJobs.map((item) => this.getJobIdentifier(item));
                if (this.isSelectAll) {
                    this.$emit('select-items', items);
                } else {
                    this.$emit('deselect-items', items);
                }
            });
        },
        getStatusNormalized(status) {
            const mapping = {
                complete: 'success',
                failed: 'error'
            };
            return mapping[status.toLowerCase()];
        },
        getTripShipmentTime(job) {
            const times = {
                startTime: null,
                endTime: null,
                actualStartTime: null,
                actualEndTime: null
            };
            if (job.startTime) {
                times.startTime = moment(job.startTime).format(DATE_TYPES.standardTime);
            }
            if (job.actualStartTime) {
                times.actualStartTime = moment(job.actualStartTime).format(DATE_TYPES.standardTime);
            }
            if (job.endTime) {
                times.endTime = moment(job.endTime).format(DATE_TYPES.standardTime);
            }
            if (job.actualEndTime) {
                times.actualEndTime = moment(job.actualEndTime).format(DATE_TYPES.standardTime);
            }
            return times;
        },
        /**
         *
         * @returns {{actualEndTime: string | null, endTime: string | null}}
         * @private
         */
        getStopTime(job) {
            const times = {
                endTime: null,
                actualEndTime: null
            };
            if (job.endTime) {
                times.endTime = moment(job.endTime).format(DATE_TYPES.standardTime);
            }
            if (job.actualEndTime) {
                times.actualEndTime = moment(job.actualEndTime).format(DATE_TYPES.standardTime);
            }
            return times;
        },
        openUpdateTimeModal(job) {
            // only open if the job is a trip
            let times = {};
            if (job.tripId || job.shipmentId) {
                times = {
                    startTime: job.actualStartTime,
                    endTime: job.actualEndTime
                };
            } else {
                times = {
                    endTime: job.actualEndTime
                };
            }
            let jobType = '';
            if (job.tripId) {
                jobType = 'trip';
            } else if (job.shipmentId) {
                jobType = 'shipment';
            } else {
                jobType = 'stop';
            }
            this.$modal
                .show(UpdateJobTimeModal, {
                    stopId: job.stopId,
                    jobType,
                    initialStartTime: times.startTime,
                    initialEndTime: times.endTime
                })
                .then((response) => {
                    if (response && response?.code.toLowerCase() === 'ok') {
                        const { newTimes } = response;
                        this.$modal.hide();
                        this.$emit('update-time', {
                            stopId: job.stopId,
                            newTimes
                        });
                    }
                });
        },
        /**
         * @returns {{ label: string, value: string} []}
         */
        getTimeTooltipText(job) {
            const timesArr = [];
            if (!job.tripId && !job.shipmentId) {
                // this is just a stop
                const times = this.getStopTime(job);
                if (times.endTime) {
                    timesArr.push({
                        label: 'End',
                        value: times.endTime
                    });
                }
                if (times.actualEndTime) {
                    timesArr.push({
                        label: 'Actual End',
                        value: times.actualEndTime
                    });
                }
                return timesArr;
            }
            const times = this.getTripShipmentTime(job);
            if (times.startTime) {
                if (times.endTime) {
                    timesArr.push({
                        label: 'Scheduled Start',
                        value: times.startTime
                    });
                    timesArr.push({
                        label: 'Scheduled End',
                        value: times.endTime
                    });
                } else {
                    timesArr.push({
                        label: 'Scheduled Start',
                        value: times.startTime
                    });
                    timesArr.push({
                        label: 'Scheduled End',
                        value: 'Unspecified'
                    });
                }
            }
            if (times.actualStartTime) {
                if (times.actualEndTime) {
                    timesArr.push({
                        label: 'Actual Start',
                        value: times.actualStartTime
                    });
                    timesArr.push({
                        label: 'Actual End',
                        value: times.actualEndTime
                    });
                } else {
                    timesArr.push({
                        label: 'Actual Start',
                        value: times.actualStartTime
                    });
                    timesArr.push({
                        label: 'Actual End',
                        value: 'Unspecified'
                    });
                }
            }
            return timesArr;
        },
        getTimeValue(job) {
            const timesArr = [];
            if (job.tripId || job.shipmentId) {
                const times = this.getTripShipmentTime(job);
                let startTimeString = '';
                if (times.startTime && times.actualStartTime) {
                    startTimeString = `${times.startTime} (${times.actualStartTime})`;
                } else {
                    startTimeString = times.startTime || times.actualStartTime;
                }

                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }

                timesArr.push(`${startTimeString} -> ${endTimeString}`);
            } else {
                // for stops, only the endTime is present
                // do the same thing for endTime and actualEndTime
                const times = this.getStopTime(job);
                let endTimeString = '';
                if (times.endTime && times.actualEndTime) {
                    endTimeString = `${times.endTime} (${times.actualEndTime})`;
                } else {
                    endTimeString = times.endTime || times.actualEndTime;
                }
                timesArr.push(endTimeString);
            }
            return timesArr.join('');
        },
        showWarning(item) {
            // show warning if location is (0,0) or null
            // skip if item is a trip
            if (item.tripId) {
                return false;
            }
            return (
                !item.location ||
                !item.location.latitude ||
                !item.location.longitude ||
                item.location.longitude === '0' ||
                item.location.latitude === '0'
            );
        },
        getTotalCharge(job) {

            if (job.totalCharge < 0) {
                const totalCharge = String(job.totalCharge).slice(1);
                return parseFloat(totalCharge);
            }

            return job.totalCharge;
        },
        getTotalCost(job) {
            if (job.totalCost < 0) {
                const totalCost = String(job.totalCost).slice(1);
                return parseFloat(totalCost);
            }

            return job.totalCost;
        },
        getMargin(job) {
            if (job.margin < 0) {
                const margin = String(job.margin).slice(1);
                return parseFloat(margin);
            }

            return job.margin ?? 0;
        }
    }
};
</script>

<style scoped lang="scss">
.nested-table {
    background: #fff;
    padding: 0 15px 0 15px;
}

.nested-table-row {
    display: grid;
    grid-template-columns: 50px 1.5fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr; // checkbox, refNo, customerName, address, time, status, charge, cost, margin ,actions
    align-items: center;
    gap: 8px;
}

.nested-table-header {
    padding-top: 13px;
    padding-bottom: 13px;
    background: #fff;
    color: #4caf50 !important;
}

.finance-column {
    justify-self: end;
}

h5 {
    margin: 0;
}

.address-tooltip-content {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
    justify-items: start;
    align-items: start;
}

.time-tooltip-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-items: start;
    align-items: start;
}

.sync-loader {
    display: inline-block;
    background-color: #2b93ff;
    border-radius: 50%;
    width: 27px;
    height: 27px;

    svg {
        margin-top: 3px;
        margin-left: 2px;
    }
}

.line-separator {
    height: 1px;
    width: 100%;
    background: #eee;
}

.recalculate-icon {
    height: 30px !important;
    min-width: 30px !important;
    max-width: 30px !important;
}

</style>
