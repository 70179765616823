var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "md-card",
        { staticClass: "invoice-setting" },
        [
          _c(
            "md-card-content",
            [
              _c(
                "form-wrapper",
                {
                  staticClass: "invoice-form",
                  attrs: { validator: _vm.$v.settings },
                },
                [
                  _c("div", { staticClass: "md-layout" }, [
                    _c(
                      "label",
                      { staticClass: "md-layout-item md-size-5 md-form-label" },
                      [
                        _vm._v(
                          "\n                        Currency:\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout-item md-size-20" },
                      [
                        _c("currencies-autocomplete", {
                          attrs: {
                            label: "",
                            placeholder: "",
                            value: _vm.settings.currency,
                            "should-focus": true,
                          },
                          model: {
                            value: _vm.settings.currency,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "currency", $$v)
                            },
                            expression: "settings.currency",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout-item md-size-20" },
                      [
                        _c(
                          "form-group",
                          {
                            attrs: {
                              label: "Payment Terms (Days)",
                              name: "paymentTermsInDay",
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.settings.paymentTermsInDays,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "paymentTermsInDays",
                                    $$v
                                  )
                                },
                                expression: "settings.paymentTermsInDays",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "md-layout-item md-size-20" },
                      [
                        _vm.taxSettings.length
                          ? _c(
                              "form-group",
                              { attrs: { label: "Default tax settings" } },
                              [
                                _c(
                                  "md-select",
                                  {
                                    model: {
                                      value: _vm.settings.defaultTaxSettingsId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings,
                                          "defaultTaxSettingsId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "settings.defaultTaxSettingsId",
                                    },
                                  },
                                  [
                                    _c("md-option", { attrs: { value: "" } }, [
                                      _vm._v("N/A"),
                                    ]),
                                    _vm._l(_vm.taxSettings, function (tax) {
                                      return _c(
                                        "md-option",
                                        {
                                          key: tax.taxSettingsId,
                                          attrs: { value: tax.taxSettingsId },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(tax.type) +
                                              " (" +
                                              _vm._s(tax.description) +
                                              ")\n                                "
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "form-wrapper",
            { attrs: { validator: _vm.$v.settings } },
            [
              _c("invoice-affix-settings", {
                attrs: {
                  "invoice-affix-settings": _vm.settings.invoiceAffixSettings,
                },
              }),
            ],
            1
          ),
          _c("payment-method-inputs", {
            attrs: { detail: _vm.settings, "show-remittance": true },
          }),
          _c("md-card-actions", { attrs: { "md-alignment": "left" } }, [
            _c(
              "button",
              { staticClass: "custom-btn", on: { click: _vm.validateForm } },
              [_vm._v("\n                Save\n            ")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }