import {
    UPDATE_MAX_RADIUS_VALUE,
    UPDATE_MAX_RADIUS_UNITS,
    UPDATE_DISTANCE_COST,
    UPDATE_TIME_COST,
    UPDATE_FIXED_COST,
    UPDATE_ROUTE_CENTERING_PENALTY,
    UPDATE_DISTRIBUTE_WORKLOAD_COST
} from '@/store/types';

const INIT_STATE = () => ({
    radiusValue: null,
    radiusUnits: null,
    distanceCost: null,
    timeCost: null,
    fixedCost: null,
    routeCenteringPenalty: null,
    distributeWorkloadCost: 10
});

const getters = {
    maxRadiusValue: (state) => state.radiusValue,
    maxRadiusUnits: (state) => state.radiusUnits,
    getDistanceCost: (state) => state.distanceCost,
    getTimeCost: (state) => state.timeCost,
    getFixedCost: (state) => state.fixedCost,
    getRouteCenteringPenalty: (state) => state.routeCenteringPenalty,
    getDistributeWorkloadCost: (state) => state.distributeWorkloadCost
};

const mutations = {
    [UPDATE_MAX_RADIUS_VALUE]: (state, payload) => {
        state.radiusValue = payload;
    },
    [UPDATE_MAX_RADIUS_UNITS]: (state, payload) => {
        state.radiusUnits = payload;
    },
    [UPDATE_DISTANCE_COST]: (state, payload) => {
        state.distanceCost = payload;
    },
    [UPDATE_TIME_COST]: (state, payload) => {
        state.timeCost = payload;
    },
    [UPDATE_FIXED_COST]: (state, payload) => {
        state.fixedCost = payload;
    },
    [UPDATE_ROUTE_CENTERING_PENALTY]: (state, payload) => {
        state.routeCenteringPenalty = payload;
    },
    [UPDATE_DISTRIBUTE_WORKLOAD_COST]: (state, payload) => {
        state.distributeWorkloadCost = payload;
    }
};

const state = () => ({ ...INIT_STATE() });

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
