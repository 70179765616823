import {
    UPDATE_START_LOCATION_MODE,
    UPDATE_START_LOCATION,
    UPDATE_START_TIME,
    UPDATE_END_TIME,
    UPDATE_END_LOCATION_MODE,
    UPDATE_END_LOCATION,
    UPDATE_SKILL_MATCHING,
    UPDATE_TEAM_MEMBER_TIMING_OVERRIDE,
    UPDATE_RETURN_START_LOCATION_STOP,
    UPDATE_IGNORE_TIME_WINDOWS,
    UPDATE_WAREHOUSE_START_LOCATION,
    UPDATE_WAREHOUSE_END_LOCATION,
    UPDATE_TEAM_REGION_START_LOCATION,
    UPDATE_USE_NEW_OPTIMISE_STOPS,
    UPDATE_CONTIGUITY_CONSTRAINT
} from '@/store/types';

const INIT_STATE = () => ({
    startLocationMode: 'manual',
    startLocation: {},
    startTime: '09:00',
    endTime: '18:00',
    endLocationMode: 'none',
    endLocation: {},
    skillMatching: true,
    teamMemberTimingOverride: false,
    returnStartLocationStop: false,
    ignoreTimeWindows: false,
    warehouseStartLocation: null,
    warehouseEndLocation: null,
    teamRegionStartLocation: null,
    useNewOptimiseStops: false
});

const getters = {
    getStartLocationMode: (state) => state.startLocationMode,
    getStartLocation: (state) => state.startLocation,
    getStartTime: (state) => state.startTime,
    getEndTime: (state) => state.endTime,
    getEndLocationMode: (state) => state.endLocationMode,
    getEndLocation: (state) => state.endLocation,
    getSkillMatching: (state) => state.skillMatching,
    getTeamMemberTimingOverride: (state) => state.teamMemberTimingOverride,
    getReturnStartLocationStop: (state) => state.returnStartLocationStop,
    getIgnoreTimeWindows: (state) => state.ignoreTimeWindows,
    getWarehouseStartLocation: (state) => state.warehouseStartLocation,
    getWarehouseEndLocation: (state) => state.warehouseEndLocation,
    getTeamRegionStartLocation: (state) => state.teamRegionStartLocation,
    getUseNewOptimiseStops: (state) => state.useNewOptimiseStops,
    getContiguityConstraint: (state) => state.contiguityConstraint
};

const mutations = {
    [UPDATE_START_LOCATION_MODE]: (state, payload) => {
        state.startLocationMode = payload;
    },
    [UPDATE_START_LOCATION]: (state, payload) => {
        state.startLocation = payload;
    },
    [UPDATE_START_TIME]: (state, payload) => {
        state.startTime = payload;
    },
    [UPDATE_END_TIME]: (state, payload) => {
        state.endTime = payload;
    },
    [UPDATE_END_LOCATION_MODE]: (state, payload) => {
        state.endLocationMode = payload;
    },
    [UPDATE_END_LOCATION]: (state, payload) => {
        state.endLocation = payload;
    },
    [UPDATE_SKILL_MATCHING]: (state, payload) => {
        state.skillMatching = payload;
    },
    [UPDATE_TEAM_MEMBER_TIMING_OVERRIDE]: (state, payload) => {
        state.teamMemberTimingOverride = payload;
    },
    [UPDATE_RETURN_START_LOCATION_STOP]: (state, payload) => {
        state.returnStartLocationStop = payload;
    },
    [UPDATE_IGNORE_TIME_WINDOWS]: (state, payload) => {
        state.ignoreTimeWindows = payload;
    },
    [UPDATE_WAREHOUSE_START_LOCATION]: (state, payload) => {
        state.warehouseStartLocation = payload;
    },
    [UPDATE_WAREHOUSE_END_LOCATION]: (state, payload) => {
        state.warehouseEndLocation = payload;
    },
    [UPDATE_TEAM_REGION_START_LOCATION]: (state, payload) => {
        state.teamRegionStartLocation = payload;
    },
    [UPDATE_USE_NEW_OPTIMISE_STOPS]: (state, payload) => {
        state.useNewOptimiseStops = payload;
    },
    [UPDATE_CONTIGUITY_CONSTRAINT]: (state, payload) => {
        state.contiguityConstraint = payload;
    }
};

const state = () => ({ ...INIT_STATE() });

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
