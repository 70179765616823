var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-overlay" },
    [
      _c("div", { staticClass: "create-run-layout" }, [
        _c(
          "div",
          { staticClass: "optimise-header" },
          _vm._l(_vm.headerTabs, function (header, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                {
                  class:
                    _vm.step == index
                      ? "header-cell-highlighted"
                      : "header-cell",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(header) +
                      "\n                "
                  ),
                ]
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "wizard-step" },
          [
            _c("div", { staticClass: "wizard-header" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step != _vm.headerTabs.length - 1,
                      expression: "step != headerTabs.length - 1",
                    },
                  ],
                  staticClass: "header-button next-button",
                  attrs: { type: "button", disabled: !_vm.isValidated },
                  on: {
                    click: function ($event) {
                      return _vm.next()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "button-text" }, [_vm._v("Next")]),
                  _c("md-icon", { staticClass: "icon-chevron-right" }, [
                    _vm._v("chevron_right"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "button-separator" }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step != 0,
                      expression: "step != 0",
                    },
                  ],
                  staticClass: "header-button back-button",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.prev()
                    },
                  },
                },
                [
                  _c("md-icon", { staticClass: "icon-chevron-left" }, [
                    _vm._v("chevron_left"),
                  ]),
                  _c("span", { staticClass: "button-text" }, [_vm._v("Back")]),
                ],
                1
              ),
              _c("span", { staticClass: "header-text" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.headerTabs[_vm.step]) +
                    "\n                    "
                ),
                _c("span", { staticClass: "header-sub-text" }, [
                  _vm._v(_vm._s(_vm.labelHeaderSubText)),
                ]),
              ]),
            ]),
            _c("start-location-details-step", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 0,
                  expression: "step === 0",
                },
              ],
              attrs: {
                data: _vm.optimiseConfiguration,
                warehouses: _vm.warehouses,
              },
              on: {
                validated: _vm.validated,
                setStartEndLocationPin: _vm.setStartEndLocationPin,
                handleStartTeamRegionChanged: _vm.handleStartTeamRegionChanged,
              },
            }),
            _c("select-stops-step", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 1,
                  expression: "step === 1",
                },
              ],
              attrs: {
                "stop-list": _vm.stopList,
                map: _vm.map,
                "stops-markers": _vm.stopsMarkers,
                "stop-list-sorting": _vm.stopListSorting,
                "time-window-filter-options": _vm.timeWindowFilterOptions,
                "skill-options": _vm.skillOptions,
                "start-location-mode":
                  _vm.optimiseConfiguration.startLocationMode,
                "selected-circle-stops": _vm.circleStops,
                "drawing-manager": _vm.drawingManager,
                "active-validation-filter": _vm.activeValidationFilter,
                "optimise-configuration": _vm.optimiseConfiguration,
                "team-regions": _vm.teamRegions,
                "unassigned-stops-without-team-region":
                  _vm.unassignedStopsWithoutTeamRegion,
                "get-capacity-info": _vm.getCapacityInfo,
                "has-capacity-settings": _vm.hasCapacitySettings,
              },
              on: {
                selectedAllUnassignedStops: _vm.selectedAllUnassignedStops,
                selectedUnassignedStops: _vm.selectedUnassignedStops,
                onStopMouseOverOut: _vm.onStopMouseOverOut,
                calculateStopsDistance: _vm.calculateStopsDistance,
                applyTimeWindowFilter: _vm.applyTimeWindowFilter,
                applyStopsBySkillsFilter: _vm.applyStopsBySkillsFilter,
                clearTimeWindowFilter: _vm.clearTimeWindowFilter,
                selectDrawing: _vm.selectDrawing,
                handleFilterStopsClick: _vm.handleFilterStopsClick,
                handleFilterValidationClick: _vm.handleFilterValidationClick,
                handleFilterTeamRegionClick: _vm.handleFilterTeamRegionClick,
                handleFilterServiceAreaClick: _vm.handleFilterServiceAreaClick,
              },
            }),
            _c("team-member-step", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 2,
                  expression: "step === 2",
                },
              ],
              attrs: {
                "team-members": _vm.filteredTeamMembers,
                "start-location-mode":
                  _vm.optimiseConfiguration.startLocationMode,
                "optimise-configuration": _vm.optimiseConfiguration,
                "is-loading": _vm.isLoading,
                "team-regions": _vm.teamRegions,
              },
              on: { selectedTeamMembers: _vm.selectedTeamMembers },
            }),
            _c("trip-summary", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step === 3,
                  expression: "step === 3",
                },
              ],
              attrs: {
                "trip-list": _vm.allocateStops,
                "unallocated-stop-list": _vm.unallocatedStops,
                "has-displayed": _vm.hasDisplayed,
                "start-location-mode":
                  _vm.optimiseConfiguration.startLocationMode,
                "is-start-routing": _vm.isStartRouting,
                "loaded-save-model": _vm.loadedSaveModel,
                "start-trip-time": _vm.optimiseConfiguration.startTime,
                "end-trip-time": _vm.optimiseConfiguration.endTime,
              },
              on: {
                onExportTrips: _vm.onExportTrips,
                onCreateTrips: _vm.onCreateTrips,
                handleAssignedStopList: _vm.handleAssignedStopList,
                handleAssignedTrip: _vm.handleAssignedTrip,
                handleChangeTeamMemberSettings:
                  _vm.handleChangeTeamMemberSettings,
                handleUnassignedStopList: _vm.handleUnassignedStopList,
                allocateStopMarkers: _vm.allocateStopMarkers,
                onSaveOptimise: _vm.onSaveOptimise,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", {
        ref: "googleMap",
        staticClass: "google-map",
        class: _vm.isTrialExpired ? "not-paid-map-overlay" : "",
        attrs: { id: "map_canvas" },
      }),
      _c("info-window-component", {
        attrs: {
          content: _vm.infoWindowContent,
          styles: _vm.infoWindowStyles,
          "show-info-window": _vm.showInfoWindow,
          "loaded-save-model": _vm.loadedSaveModel,
          "get-capacity-info": _vm.getCapacityInfo,
          "has-capacity-settings": _vm.hasCapacitySettings,
        },
        on: {
          handleDeleteStop: _vm.handleDeleteStop,
          handleReassignedStop: _vm.handleReassignedStop,
          handleShowStreetView: _vm.handleShowStreetView,
          closeInfoWindow: function () {
            this$1.showInfoWindow = false
          },
        },
      }),
      _c("trip-stop-window", {
        attrs: {
          content: _vm.tripStopWindowContent,
          "show-trip-stop-window": _vm.showTripStopWindow,
          "loaded-save-model": _vm.loadedSaveModel,
        },
        on: {
          handleDeleteStop: _vm.handleDeleteStop,
          handleReassignedStop: _vm.handleReassignedStop,
          closeTripStopWindow: _vm.closeTripStopWindow,
        },
      }),
      !_vm.loadedSaveModel
        ? _c("routing-settings-component", {
            attrs: {
              "start-location-mode":
                _vm.optimiseConfiguration.startLocationMode,
              "is-start-routing": _vm.isStartRouting,
              "is-route-loading": _vm.isRouteLoading,
              "route-last-changed-time": _vm.routeLastChangedTime,
              "total-routed-stops": _vm.totalRoutedStops,
              "total-stops": _vm.totalStops,
              "is-next-routing": _vm.isNextRouting,
              "count-route-changed": _vm.countRouteChanged,
              "is-distribute-workload":
                _vm.optimiseConfiguration.isDistributeWorkload,
              "trip-date": _vm.optimiseConfiguration.tripDate,
              step: _vm.step,
            },
            on: {
              handleRoutingSettings: _vm.handleRoutingSettings,
              onStopRouting: _vm.onStopRouting,
              handleLoadModel: _vm.handleLoadModel,
            },
          })
        : _vm._e(),
      _vm.optimiseConfiguration.showServiceArea
        ? _c("AreasLayer", {
            attrs: {
              map: _vm.map,
              "drawing-manager": _vm.drawingManager,
              "id-name": "rateZoneId",
              "end-point-name": "/api/teams/service-areas",
              "load-shapes-only": true,
              "service-area-list-options": _vm.serviceAreaOptions,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }