import Vue from 'vue';
import VueCompositionApi, { provide } from '@vue/composition-api';
import Router from 'vue-router';
import swal from 'sweetalert2';
import Chartist from 'chartist';
import Vuelidate from 'vuelidate';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import moment from 'moment';
import VueSelect from 'vue-select';
import VueLazyload from 'vue-lazyload';
import MultiSelect from 'vue-multiselect';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import draggable from 'vuedraggable';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './App';

import 'vue-select/dist/vue-select.css';

// Modal Plugin
import ModalPlugin from './plugins/ModalPlugin';
import { MessageBox } from './components';
import 'vue-multiselect/dist/vue-multiselect.min.css';

// Directive
import {
    Tooltip,
    PlaybackCircle,
    VisibilityHandler,
    Focus,
    ScrollTop,
    OverflowTextTooltip,
    OverflowTooltip
} from './directives/index';

// Plugins
import DashboardPlugin from './material-dashboard';

// router setup
import router from './router/router';

// authService
import AuthService from './services/AuthService';

// vuex store setup
import { store } from './store/store';

import FormGroup from './components/ErrorMessage';

import { DATE_TYPES } from './utils/constants';
import i18n from './i18n';

LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-053836}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Locate2u_Pty_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Locate2u}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Locate2u}_need_to_be_licensed___{Locate2u}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_January_2025}____[v3]_[01]_MTczNzMzMTIwMDAwMA==98145bf5c60daaf5220486affc904d4f'
);

Vue.directive('tooltip', Tooltip);
Vue.directive('playbackcircle', PlaybackCircle);
Vue.directive('visibilityhandler', VisibilityHandler);
Vue.directive('focus', Focus);
Vue.directive('scrollTop', ScrollTop);
Vue.directive('overflow-text-tooltip', OverflowTextTooltip);
Vue.directive('overflow-tooltip', OverflowTooltip);
Vue.use(DashboardPlugin);
Vue.use(Router);
Vue.use(ModalPlugin);
Vue.use(VueCompositionApi);
Vue.component('multiselect', MultiSelect);

// get user locale based on browser's userLanguage
// usetLanguage is for IE browsers, languages is for all other modern browsers
const userLocale = window.navigator.userLanguage || window.navigator.language;
moment.locale(userLocale);

Vue.component('MessageBox', MessageBox);
function dialogService(Vue) {
    Vue.$swal = swal;
    Vue.prototype.$dialogService = Vue.$swal;
}

Vue.use(dialogService);
window.auth = new AuthService();
Object.defineProperty(Vue.prototype, '$Chartist', {
    get() {
        return this.$root.Chartist;
    }
});

Vue.use(Vuelidate);
Vue.component('vue-select', VueSelect);
Vue.component('draggable', draggable);

const messages = {
    required: '{attribute} is required',
    required_skill: 'Skill name is required',
    phoneValid: 'Phone is invalid',
    coordinatesValid: 'GPS coordinates are invalid',
    email: '{attribute} is not a proper email address',
    numeric: '{attribute} must only contain numeric values',
    maxLength: '{attribute} is too long',
    minValue: '{attribute} min value is {min}',
    maxValue: '{attribute} max value is {max}',
    minValue_customerStopDuration: 'min value is 0',
    maxValue_customerStopDuration: 'max value is 24 hours or 1440 minutes',
    numeric_duration: 'duration should only contain numeric values',
    minValue_Multiplier: 'min value is 0.1',
    minLength: '{attribute} is too short',
    valid_separator: "Skill name must not include special separator '||'",
    requiredIMEI: 'IMEI number is required',
    numericIMEI: 'IMEI number must only contain numeric values',
    validIMEI: 'IMEI number is invalid',
    decimal: '{attribute} must only contain decimal values',
    alpha: '{attribute} must only contain alphabet',
    duplicate: '{attribute} already exist',
    invalid: '{attribute} is in invalid'
};

Vue.use(VuelidateErrorExtractor, {
    messages,
    attributes: {
        email: 'Email Address',
        industry: 'Industry',
        company: 'Company',
        firstName: 'First Name',
        lastName: 'Last Name',
        groupName: 'Asset Group Name',
        name: 'Asset Name',
        appName: 'App Name',
        modelYear: 'Model Year',
        // trackerReference: 'Tracker IMEI',
        model: 'Model',
        make: 'Vehicle Make',
        brand: 'Brand',
        licensePlate: 'License Plate',
        address: 'Destination Address',
        durationMinutes: 'Duration',
        assetType: 'Asset type',
        label: 'Label',
        type: 'Type',
        group: 'Group',
        options: 'Options',
        template: 'Template',
        defaultTripStartTime: 'Start Time',
        defaultTripEndTime: 'End Time',
        customerName: 'Customer Name',
        'pickupStop.address': 'Pickup Address',
        'dropStop.address': 'Drop Address',
        'pickupStop.durationMinutes': 'Pickup duration',
        'dropStop.durationMinutes': 'Drop duration',
        'data.trackerReference': 'Data Tracker IMEI',
        'tracker.trackerReference': 'tracker Tracker IMEI',
        runName: 'Name',
        etaTimeWindowWidthInMinutes: 'ETA Time Window Width In Minutes',
        skills: 'Skills',
        defaultValue: 'Default value',
        formatString: 'Format string',
        newGeofenceTitle: 'Name',
        'currentShape.teamRegionId': 'Team Region',
        action: 'Action Type',
        eventTrigger: 'Event Trigger',
        'filter.status': 'Filter',
        'settings.emailRecipientTypes': 'Email Recipients',
        'settings.recipientEmailAddresses': 'Email Address(es)',
        'settings.emailTemplateId': 'Email Template Id',
        'settings.requestQuote': 'Request Quote',
        'settings.preferredQuote': 'Preferred Quote',
        maintenanceName: 'Maintenance Name',
        maintenanceType: 'Maintenance Type',
        maintenanceSubType: 'Maintenance Sub Type',
        currentEngineHoursValue: '',
        currentOdometerValue: '',
        timeScheduleInterval: '',
        timeScheduleBefore: '',
        timeScheduleAfter: '',
        nextDueEngineHours: '',
        engineHoursDue: '',
        engineHoursBefore: '',
        engineHoursAfter: '',
        nextDueOdometer: '',
        odometerDue: '',
        odometerBefore: '',
        odometerAfter: '',
        startingEngineHours: 'Engine hours',
        startingOdometer: 'Odometer',
        assetId: 'Asset Name',
        cost: 'Cost',
        dueEngineHours: 'Engine hour',
        dueDistanceReading: 'Odometer',
        status: 'Status',
        reason: 'Reason',
        maxRetry: 'Max Retry',
        retryDelay: 'Retry Delay',
        band_name: 'Band Name',
        band_minProximity: 'Minimum Proximity',
        band_maxProximity: 'Maximum Proximity',
        band_sendingMode: 'Sending Mode',
        band_dripFeedDelay: 'Drip Feed Delay',
        band_nextBandDelay: 'Next Band Delay',
        offerExpiry: 'Offer Expiry',
        developerId: 'Developer Id',
        apiKey: 'Api Key',
        apiSecret: 'Api Secret',
        webhookToken: 'Webhook Token',
        sendleId: 'Sendle Id',
        username: 'Username',
        password: 'Password',
        webhookSecret: 'Webhook Secret',
        customerKey: 'Customer Key',
        customerId: 'Customer Id',
        clientId: 'Client Id',
        clientSecret: 'Client Secret',
        pacApiKey: 'Postage Assessment Calculation (PAC) Api Key',
        account: 'Account',
        accountCode: 'Account Code',
        questionName: 'Name',
        areaName: 'Name',
        'paymentDetails.bank.name': 'Account Name (optional)',
        'paymentDetails.bank.bsb': 'BSB',
        'paymentDetails.bank.accountNumber': 'Account Number',
        vehicleLicensePlate: 'Vehicle License Plate',
        sourceReference: 'Source Reference',
        locationName: 'Location Name',
        locationType: 'Location Type',
        'invoiceAffixSettings.teamMemberInvoiceNumberPrefix': 'Team Member Invoice Prefix',
        'invoiceAffixSettings.teamMemberInvoiceNumberSuffix': 'Team Member Invoice Suffix',
        'invoiceAffixSettings.customerInvoiceNumberPrefix': 'Customer Invoice Prefix',
        'invoiceAffixSettings.customerInvoiceNumberSuffix': 'Customer Invoice Suffix',
        'proofOfDeliveryTemplate.name': 'Template name',
        contactEmail: 'Contact Email'
    }
});

Vue.use(VueLazyload);

Vue.component('FormGroup', FormGroup);
Vue.component('FormWrapper', templates.FormWrapper);

Vue.filter('currency', (value, currency = 'AUD') => {
    if (!value && value !== '0')
        return Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency
        }).format(value);
    if (!currency) 
        return value;
    try {
        return Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency
        }).format(value);
    } catch {
        // Wrong currency (probably from the DB).
        return value;
    }
});
Vue.filter('dateFormat', (value, format = DATE_TYPES.internationalDate) => {
    if (!value) 
        return '';
    return moment(value).format(format);
});
Vue.filter('dateTimeFormat', (value, format = DATE_TYPES.internationalDate) => {
    if (!value) 
        return '';
    const dateTimeFormat = `${format} ${DATE_TYPES.standardTime}`;
    return moment(value).format(dateTimeFormat);
});
Vue.filter('quoteDateTimeFormat', (value) => {
    if (!value) 
        return '';

    const currentDate = moment();
    const givenDate = moment(value);

    const currentYear = currentDate.get('year');
    const givenYear = givenDate.get('year');
    const currentMonth = currentDate.get('month');
    const givenMonth = givenDate.get('month');
    const currentDay = currentDate.get('day');
    const givenDay = givenDate.get('day');
    const currentDateFormatted = moment([currentYear, currentMonth, currentDay]);
    const givenDateFormatted = moment([givenYear, givenMonth, givenDay]);
    const differenceDays = givenDateFormatted.diff(currentDateFormatted, 'days');

    if (currentYear === givenYear) {
        if (differenceDays === 0) {
            const timeFormat = moment(value).format('LT');
            return `Today at ${timeFormat}`;
            // eslint-disable-next-line no-else-return
        } else if (differenceDays === 1) {
            const timeFormat = moment(value).format('LT');
            return `Tomorrow at ${timeFormat}`;
        } else {
            return moment(value).format('ddd, MMM D h:mm a');
        }
    }

    return moment(value).format(DATE_TYPES.standardDate);
});

// for refactoring
Vue.filter('timeFormat', (value, format = DATE_TYPES.standardTime, isTimePicker = false) => {
    if (!value) 
        return '';
    // this is only used in the timepicker function to convert the inputted time to the standard format
    if (isTimePicker) 
        return moment(value, DATE_TYPES.standardTime).format(format);
    return moment(value).format(format);
});

Vue.filter('numberWithCommas', (value) => {
    /**
     * adds comma to number, ex 1234.56 > returns 1,234.56
     */
    if (!value) 
        return '';

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter('timeSpanDesc', (timeSpan) => {
    /**
     * timeSpan format:
     *    could be '1.02:03:04', including day > returns 1 day 2 hrs 3 mins 4 secs
     *    or just '02:03:04' > returns 2 hrs 3 mins 4 secs
     */
    /* eslint-disable  no-restricted-globals */
    if (!timeSpan) {
        return '-';
    }

    let d = 0;
    let h = 0;
    let m = 0;
    let s = 0;

    const parts = timeSpan.split(':');
    if (parts.length === 3) {
        s = Number(parts[2]);
        m = Number(parts[1]);

        // try splitting days and hours
        const dayHourParts = parts[0].split('.');
        if (dayHourParts.length === 2) {
            h = Number(dayHourParts[1]);
            d = Number(dayHourParts[0]);
        } else if (dayHourParts.length === 1) {
            // only hour, no day
            h = Number(dayHourParts[0]);
        }
    }

    let formatted = '';
    if (d >= 2) {
        formatted = `${d} days`;
    } else if (d === 1) {
        formatted = `${d} day`;
    }
    if (h >= 2) {
        formatted = `${formatted} ${h} hrs `;
    } else if (h === 1) {
        formatted = `${formatted} ${h} hr `;
    }
    if (m >= 2) {
        formatted = `${formatted} ${m} mins `;
    } else if (m === 1) {
        formatted = `${formatted} ${m} min `;
    }
    if (s >= 2) {
        formatted = `${formatted} ${s} secs `;
    } else if (s === 1) {
        formatted = `${formatted} ${s} sec `;
    }
    return formatted.length ? formatted.trim() : '-';
});

Vue.filter('yesNoDesc', (booleanValue) => {
    if (booleanValue) 
        return 'Yes';
    if (!booleanValue) 
        return 'No';
    return '';
});

const globalData = {
    Chartist,
    defaultPhotoUrl: '/img/faces/profile-pic.png',
    defaultLogoUrl: '/img/logos/logo-placeholder.png',
    defaultCarrierSidebarImageUrl: '/img/carrier-banner.jpg',
    defaultReportIcon: '/img/icons/report-icon.png',
    defaultBrokenImage: '/img/broken-image.jpg',
    drawingToolIcon: '/img/icons/drawing-icon.png',
    isDesktop: !isMobile,
    isMobileOnly,
    isTablet
};

const DEFAULT_TITLE = 'Locate2u';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        const historyName = to.meta.title || to.name;
        document.title = `${DEFAULT_TITLE} - ${historyName}` || DEFAULT_TITLE;
    });
});

// disable any development-related debugging features in production
Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}

/* eslint-disable no-new */
new Vue({
    el: '#app',
    data: globalData,
    render: (h) => h(App),

    setup() {
        provide('vuex-store', store);
    },

    router,
    i18n,
    store
});
