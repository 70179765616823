<template>
    <div class="details-page" v-if="stopDetails != null">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-start">
                        <h3 class="title" v-if="stopDetails.stopRef != null">
                            {{ stopDetails.type || 'Stop' }} {{ stopDetails.stopRef }}
                        </h3>
                        <h3 class="title" v-else>{{ stopDetails.type || 'Stop' }} {{ stopDetails.stopId }}</h3>
                    </div>
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <stop-status-button
                                :stop="stopDetails"
                                @statusUpdated="handleChangedStatus"
                                @statusHistoryUpdated="handleStatusHistoryUpdated"
                                @refreshStop="getStopDetails"
                                class-name="md-sm"
                                class="status-btn"
                            />
                            <update-stop-button
                                :stop="stopDetails"
                                :team-members="teamMembers"
                                @stopUpdated="reloadDetails"
                                class-name="header-button"
                                :use-icon="false"
                                :is-failed="stopDetails.status.toLowerCase() === 'failed'"
                            />
                            <generate-label-button
                                :id="stopDetails.stopId"
                                :use-icon="false"
                                :type="'Stop'"
                                @download-success="handleGeneratedStopLabel"
                            />

                            <stop-pdf-download-button
                                v-if="!stopDetails.carrierTeamId"
                                :status="stopDetails.status.toLowerCase()"
                                :stop-type="stopDetails.type"
                                :stop-id="stopDetails.stopId"
                                :stop-ref="stopDetails.stopRef"
                                :use-icon="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-66 md-xlarge-size-50"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon">
                                <md-icon>description</md-icon>
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div class="details-box" v-if="stopDetails.shipmentId > 0">
                                <label>Shipment Ref</label>
                                <span class="value">
                                    <router-link
                                        :to="{
                                            name: 'Shipment Details',
                                            params: { shipmentId: stopDetails.shipmentId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ stopDetails.stopRef.slice(0, -2) }}
                                    </router-link>
                                </span>
                            </div>
                            <div class="address-details">
                                <div class="details-box">
                                    <label>Address</label>
                                    <div v-if="stopDetails.name" class="address-name">{{ stopDetails.name }}</div>
                                    <span>{{ $_displayAddressName(stopDetails) }}</span>
                                </div>
                                <div class="details-box duration" v-if="stopDetails.durationMinutes > 0">
                                    <label>Stop duration</label>
                                    <span>
                                        {{ `${stopDetails.durationMinutes} minutes` }}
                                    </span>
                                </div>
                                <div class="details-box previous-stop">
                                    <div class="details-box previous-stop">
                                        Attempt #: {{ stopDetails.stopAttemptCounter }}
                                    </div>
                                    <div v-if="stopDetails.supersededStop">
                                        <label>Superseded Stop</label>
                                        <div>
                                            <router-link
                                                :to="{
                                                    name: 'Stop Details',
                                                    params: { stopId: stopDetails.supersededStop.stopId }
                                                }"
                                                target="_blank"
                                            >
                                                {{ stopDetails.supersededStop.stopReference }}
                                            </router-link>
                                        </div>
                                    </div>

                                    <div v-if="stopDetails.previousAttemptStop">
                                        <label class="previous-stop">Previous Attempted Stop</label>
                                        <div>
                                            <router-link
                                                :to="{
                                                    name: 'Stop Details',
                                                    params: { stopId: stopDetails.previousAttemptStop.stopId }
                                                }"
                                                target="_blank"
                                            >
                                                {{ stopDetails.previousAttemptStop.stopReference }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="stop-time-details">
                                <div v-if="stopDetails.tripDate">
                                    <div class="details-box">
                                        <label>Trip date</label>
                                        <span v-if="stopDetails.tripId && !isCarrierAssigned">
                                            <router-link
                                                :to="{
                                                    name: 'Trip Details',
                                                    params: {
                                                        tripId: stopDetails.tripId
                                                    }
                                                }"
                                                target="_blank"
                                            >
                                                {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                            </router-link>
                                        </span>
                                        <span v-else>
                                            {{ stopDetails.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="stopDetails.timeWindowStart && stopDetails.timeWindowEnd">
                                    <div class="details-box">
                                        <label>Time Window</label>
                                        <span>
                                            {{ stopDetails.timeWindowStart | timeFormat(DATE_TYPES.standardTime) }}
                                            - {{ stopDetails.timeWindowEnd | timeFormat(DATE_TYPES.standardTime) }}
                                        </span>
                                    </div>
                                </div>
                                <div v-else-if="stopDetails.appointmentTime">
                                    <div class="details-box">
                                        <label>Appointment time</label>
                                        <span>
                                            {{ stopDetails.appointmentTime | timeFormat(DATE_TYPES.standardTime) }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="stopDetails.originalEta && !hideETA">
                                    <div class="details-box">
                                        <label>Estimated Time of Arrival</label>
                                        <span>
                                            {{ stopDetails.originalEta | timeFormat(DATE_TYPES.standardDateTime) }}
                                        </span>
                                    </div>
                                </div>
                                <div v-if="stopDetails.arrivalDate && hideETA">
                                    <div class="details-box">
                                        <label>Actual Arrival Time</label>
                                        <span>
                                            {{ stopDetails.arrivalDate | timeFormat(DATE_TYPES.standardDateTime) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="run-number" v-if="stopDetails.runNumber || stopDetails.runName">
                                    <div class="details-box">
                                        <label>Run</label>
                                        <span v-if="stopDetails.runName">
                                            {{ stopDetails.runName }}
                                        </span>
                                        <span v-else>
                                            {{ stopDetails.runNumber }}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <template v-if="isCarrierAssigned">
                                        <a v-if="carrierTrackingUrl" :href="carrierTrackingUrl" target="_blank">
                                            <md-icon>place</md-icon>
                                            Tracking Link
                                        </a>
                                    </template>
                                    <template v-else>
                                        <a
                                            v-if="stopDetails.trackingLinkUrl"
                                            :href="setTrackingLinkUrl(stopDetails.trackingLinkUrl)"
                                            target="_blank"
                                        >
                                            <md-icon>place</md-icon>
                                            Tracking Link
                                        </a>
                                        <a
                                            v-else-if="stopDetails.trackingLinkUrl == null && !isReadOnlyUser"
                                            @click="createTrackingLink"
                                        >
                                            <md-icon>place</md-icon>
                                            Create tracking link
                                        </a>
                                    </template>
                                </div>
                            </div>
                            <div class="details-box" v-if="stopDetails.rateGroup">
                                <label>Service Package</label>
                                <span>
                                    {{ stopDetails.rateGroup.rateGroupName }}
                                </span>
                            </div>
                            <div
                                class="details-box md-chips md-primary md-theme-default md-has-placeholder md-theme-default"
                                v-if="stopDetails.skills.length > 0"
                            >
                                <label>Required skills:</label>
                                <div
                                    class="md-chip md-theme-default custom-chip"
                                    v-for="(skill, index) in stopDetails.skills"
                                    :key="index"
                                >
                                    {{ skill }}
                                </div>
                            </div>
                            <div class="stop-time-details" v-if="stopCustomFieldList !== null">
                                <div
                                    class="details-box"
                                    v-for="(customField, index) in stopCustomFieldList"
                                    :key="index"
                                >
                                    <label>{{ customField.label }}</label>
                                    <span
                                        v-if="
                                            customFieldValues !== null && customFieldValues[customField.name] !== null
                                        "
                                    >
                                        {{ customFieldValues[customField.name] }}
                                    </span>
                                    <span v-else>&nbsp;</span>
                                </div>
                            </div>
                            <div
                                class="stop-time-details"
                                v-if="
                                    stopDetails.shipmentId && shipmentCustomFieldList && shipmentCustomFieldList.length
                                "
                            >
                                <div
                                    class="details-box"
                                    v-for="(customField, index) in shipmentCustomFieldList"
                                    :key="index"
                                >
                                    <label>{{ customField.label }}</label>
                                    <span
                                        v-if="
                                            shipmentCustomFieldValues !== null &&
                                                shipmentCustomFieldValues[customField.name] !== null
                                        "
                                    >
                                        {{ shipmentCustomFieldValues[customField.name] }}
                                    </span>
                                    <span v-else>&nbsp;</span>
                                </div>
                            </div>
                            <div class="stop-time-details" v-if="loadCapacityList !== null">
                                <div class="details-box" v-for="(loadCapacity, index) in loadCapacityList" :key="index">
                                    <div v-if="loadCapacity.type !== 'dimensions'">
                                        <label>{{ loadCapacity.label }}</label>
                                        <span v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null">
                                            {{ loadValues[loadCapacity.type] }} {{ loadCapacity.symbol }}
                                        </span>
                                    </div>
                                    <div v-if="loadCapacity.type === 'dimensions'">
                                        <label>{{ loadCapacity.label }}</label>
                                        <span v-if="loadCapacity !== null && loadValues[loadCapacity.type] !== null">
                                            L: {{ loadValues['length'] }} {{ loadCapacity.symbol }} W:
                                            {{ loadValues['width'] }} {{ loadCapacity.symbol }} H:
                                            {{ loadValues['height'] }} {{ loadCapacity.symbol }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="teamRegionName" class="details-box">
                                <label>Team Region</label>
                                <div>{{ teamRegionName }}</div>
                            </div>
                            <div v-if="stopDetails.brandId != null" class="stop-time-details">
                                <div>
                                    <div class="details-box">
                                        <label>Brand</label>
                                        <img
                                            v-if="stopDetails.brandLogoUrl"
                                            class="brand-logo"
                                            :src="
                                                stopDetails.brandLogoUrl !== null
                                                    ? stopDetails.brandLogoUrl
                                                    : $root.defaultPhotoUrl
                                            "
                                            @error="$_setDefaultBrokenImage"
                                            :title="stopDetails.brandName"
                                            :alt="stopDetails.brandName"
                                        />
                                        <span v-if="!stopDetails.brandLogoUrl">{{ stopDetails.brandName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="details-box">
                                <label>Notes</label>
                                <span v-if="stopDetails.notes" class="stop-notes">{{ stopDetails.notes }}</span>
                                <span v-else class="grayedout-text">No notes found</span>
                            </div>

                            <div v-if="stopDetails.teamMemberInvoiceId" class="details-box">
                                <label class="label">Team Member Invoice:</label>

                                <span class="value">
                                    <div
                                        :class="
                                            `custom-badge payment-status-${stopDetails.teamMemberInvoiceStatus
                                                .toLowerCase()
                                                .replace(/\s+/g, '')}`
                                        "
                                    >
                                        {{ $t('inputs.paymentStatuses.' + stopDetails.teamMemberInvoiceStatus) }}
                                    </div>

                                    <router-link
                                        :to="{
                                            name: 'Team Member Invoice Details',
                                            params: { invoiceId: stopDetails.teamMemberInvoiceId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ stopDetails.teamMemberInvoiceNumber }}
                                    </router-link>
                                </span>
                            </div>

                            <div v-if="stopDetails.customerInvoiceId" class="details-box">
                                <label class="label">Customer Invoice:</label>

                                <span class="value">
                                    <div
                                        :class="
                                            `custom-badge payment-status-${stopDetails.customerInvoiceStatus
                                                .toLowerCase()
                                                .replace(/\s+/g, '')}`
                                        "
                                    >
                                        {{ $t('inputs.paymentStatuses.' + stopDetails.customerInvoiceStatus) }}
                                    </div>

                                    <router-link
                                        :to="{
                                            name: 'Customer Invoice Details',
                                            params: { invoiceId: stopDetails.customerInvoiceId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ stopDetails.customerInvoiceNumber }}
                                    </router-link>
                                </span>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>

                <div
                    class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                    v-if="!$root.isDesktop"
                >
                    <driver-details
                        :stop-details="stopDetails"
                        @handleAssignedUser="handleAssignedUser"
                        :team-members="teamMembers"
                    />
                </div>

                <div
                    class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                    v-if="!$root.isDesktop"
                >
                    <contact-details :stop-details="stopDetails" />
                </div>

                <div
                    class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box"
                    v-if="$root.isDesktop"
                >
                    <map-overlay
                        :stop-details="stopDetails"
                        :stop-status-history="stopStatusHistory"
                        :clicked-history="clickedHistory"
                    />
                </div>
                <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100">
                    <notes
                        :list="stopDetails.stopNotes"
                        :stop-id="stopDetails.stopId"
                        @notesAdded="handleAddedStopNote"
                    />
                </div>

                <div
                    class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100 map-box"
                    v-if="stopDetails.assignedTo.fullName && !$root.isDesktop"
                >
                    <map-overlay
                        :stop-details="stopDetails"
                        :stop-status-history="stopStatusHistory"
                        :clicked-history="clickedHistory"
                    />
                </div>

                <div
                    v-if="!isIndividualUser && !stopDetails.shipmentId"
                    class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100"
                >
                    <charge-rates-list
                        v-if="isTeamOwner || isAdmin || isReadOnlyUser"
                        :charge-lines="stopDetails.chargeLines"
                        :stop-details="stopDetails"
                        title="Charges"
                        @updateStopDetails="getStopDetails"
                        :has-invoice="stopDetails.customerInvoiceId !== null"
                        :rates-have-changed="updateChargesInBackground"
                    />
                </div>

                <div
                    v-if="!isIndividualUser"
                    class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100 stop-line-box"
                >
                    <stop-lines
                        :lines="stopDetails.items"
                        :stop-id="stopDetails.stopId"
                        @createdLine="getStopDetails"
                        :belongs-to-shipment="stopDetails.shipmentId !== null"
                        :stop-status="stopDetails.status"
                        :is-invoiced="
                            stopDetails.teamMemberInvoiceId !== null || stopDetails.customerInvoiceId !== null
                        "
                    />
                </div>

                <div
                    v-if="!isIndividualUser"
                    class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100"
                >
                    <services-lines
                        :lines="stopDetails.lines"
                        :stop-id="stopDetails.stopId"
                        @createdLine="getStopDetails"
                        :belongs-to-shipment="stopDetails.shipmentId !== null"
                        :stop-status="stopDetails.status"
                        :is-invoiced="
                            stopDetails.teamMemberInvoiceId !== null || stopDetails.customerInvoiceId !== null
                        "
                    />
                </div>
            </div>

            <div
                class="md-layout-item md-small-size-100 md-layout md-medium-size-100 md-large-size-33 md-xlarge-size-50"
                v-if="$root.isDesktop"
            >
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50">
                    <driver-details
                        :stop-details="stopDetails"
                        @handleAssignedUser="handleAssignedUser"
                        :team-members="teamMembers"
                    />
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50">
                    <contact-details :stop-details="stopDetails" />
                </div>
                <div
                    class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50"
                    v-if="$root.isDesktop"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-warning">
                            <div class="card-icon">
                                <md-icon>history</md-icon>
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <time-line plain type="simple" v-if="stopStatusHistory.length > 0">
                                <time-line-item
                                    inverted
                                    :badge-type="`status-${history.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                                    badge-icon="place"
                                    v-for="(history, index) in stopStatusHistory"
                                    :key="index"
                                    :is-clickable="true"
                                    @clicked="showHistoryInMap(history)"
                                >
                                    <span
                                        slot="header"
                                        class="badge"
                                        :class="`status-${history.newStatus.toLowerCase().replace(/\s+/g, '')}`"
                                    >
                                        {{ history.newStatus }}
                                    </span>

                                    <span slot="headerTime" class="time">
                                        {{ history.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                                    </span>

                                    <h6 slot="footer">
                                        <i class="ti-time" />

                                        By {{ history.changedByUserFullName }}
                                    </h6>
                                </time-line-item>
                            </time-line>
                            <p v-else class="grayedout-text">No Status History Available</p>
                        </md-card-content>
                    </md-card>
                </div>
                <div
                    class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50"
                    v-if="$root.isDesktop"
                >
                    <assignment-history-timeline :list="stopDetails.stopAssignmentHistory" />
                </div>

                <div
                    class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-50"
                    v-if="$root.isDesktop"
                >
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon icon-style">
                                <md-icon>checklist</md-icon>
                                Driver's Instructions
                            </div>
                        </md-card-header>
                        <md-card-content>
                            <DriverInstructions :driver-instructions="stopDetails.driverInstructions" />
                        </md-card-content>
                    </md-card>
                </div>

                <div
                    v-if="!isIndividualUser && !stopDetails.shipmentId"
                    class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100"
                >
                    <cost-rates-list
                        v-if="isTeamOwner || isAdmin"
                        :cost-lines="stopDetails.costLines"
                        :stop-details="stopDetails"
                        title="Costs"
                        @updateStopDetails="getStopDetails"
                        :has-invoice="stopDetails.teamMemberInvoiceId !== null"
                        :rates-have-changed="updateCostsInBackground"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { handleRequests, showErrorMessage, getTeamRegionName } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import {
    TimeLine,
    TimeLineItem,
    PhotoPreviewModal,
    AssignmentHistoryTimeline,
    Notes,
    CostRatesList,
    ChargeRatesList,
    GenerateLabelButton
} from '@/components';
import { mapGetters } from 'vuex';
import marked from 'marked';
import { NOTE_TYPE_CONSTANTS } from '@/utils/constants';
// import NotesModal from '../NotesModal';
import { MapOverlay, DriverDetails, ContactDetails, StopLines, ServicesLines, DriverInstructions } from './components';
import { StopStatusButton, UpdateStopButton, StopPdfDownloadButton } from './buttons';

const signalR = require('@aspnet/signalr');

export default {
    name: 'StopDetails',
    components: {
        TimeLine,
        TimeLineItem,
        MapOverlay,
        StopStatusButton,
        UpdateStopButton,
        StopPdfDownloadButton,
        DriverDetails,
        ContactDetails,
        StopLines,
        ServicesLines,
        AssignmentHistoryTimeline,
        Notes,
        CostRatesList,
        ChargeRatesList,
        GenerateLabelButton,
        DriverInstructions
    },
    mixins: [GeneralMixin],
    computed: {
        ...mapGetters({
            hasTeam: 'user/hasTeam',
            isLoading: 'isLoading',
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser',
            isIndividualUser: 'user/isIndividualUser',
            teamMemberSelfAllocationAllowed: 'user/teamMemberSelfAllocationAllowed',
            teamRegions: 'team/teamRegions',
            isTeamOwner: 'user/isTeamOwner'
        }),
        hideETA() {
            return ['Complete', 'Failed', 'Cancelled'].includes(this.stopDetails.status);
        },
        teamRegionName() {
            return getTeamRegionName(this.stopDetails?.teamRegionId, this.teamRegions);
        },
        isCarrierAssigned() {
            return this.stopDetails && this.stopDetails.carrierTeamId;
        },
        carrierTrackingUrl() {
            if (!this.isCarrierAssigned) 
                return null;
            if (this.stopDetails.assignedCarrierBooking?.trackingUrl)
                return this.stopDetails.assignedCarrierBooking?.trackingUrl;
            if (this.stopDetails.shipment?.assignedCarrierBooking?.trackingUrl)
                return this.stopDetails.shipment?.assignedCarrierBooking?.trackingUrl;
            return null;
        }
    },
    async mounted() {
        this.$_handleLoaderState(true);
        this.stopCustomFieldList = this.user.stopCustomFieldDefinitions;
        this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;
        this.loadCapacityList = this.user.vehicleCapacityUnitsConfiguration || [];

        await this.getStopDetails();
        await this.getTeamMembers();
        this.$_handleLoaderState(false);

        if (this.isTeamOwner || this.isAdmin) {
            this.setupSignalR();
        }
    },
    data() {
        return {
            stopDetails: null,
            stopStatusHistory: [],
            stopId: null,
            status: null,
            selectedTeamMemberId: '',
            teamMembers: [],
            shouldShowAddStopNotesModal: false,
            stopCustomFieldList: [],
            shipmentCustomFieldList: [],
            shipmentCustomFieldValues: [],
            loadCapacityList: [],
            clickedHistory: {},
            stopNotePolling: null,
            selectedCharge: null,
            selectedCost: null,
            isAdmin: Boolean(this.$route.query.isAdmin),
            updateCostsInBackground: false,
            updateChargesInBackground: false
        };
    },
    methods: {
        async getStopDetails() {
            try {
                const api = `/api/stops/${
                    this.$route.params.stopId
                }?includeStatusHistory=true&includeAssignmentHistory=true&includeNotes=true&includeStopLines=true`;
                const {
                    data: { data }
                } = await handleRequests(api);

                const items = data.lines.filter((item) => item.itemId !== null);
                const lines = data.lines.filter((item) => item.serviceId !== null);

                // Services
                data.lines = lines;
                // Items
                data.items = items;
                this.stopDetails = data;

                this.stopStatusHistory = data.stopStatusHistory;
                document.title = `Locate2u - Stop ${this.stopDetails.stopRef}`;

                if (this.stopCustomFieldList != null && this.stopCustomFieldList.length) {
                    this.customFieldValues = this.stopDetails.customFields;
                }
                if (this.shipmentCustomFieldList != null && this.shipmentCustomFieldList.length) {
                    if (this.stopDetails.shipmentId) {
                        this.shipmentCustomFieldValues = this.stopDetails.shipment.customFields;
                    }
                }
                if (this.loadCapacityList != null && this.loadCapacityList.length) {
                    this.loadValues = this.stopDetails.load;
                }
            } catch (error) {
                const message = 'Error in loading the details';
                showErrorMessage(this, message, error);
            }
        },
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        camelCaseToSentenceCase(text) {
            const result = text.replace(/([A-Z])/g, ' $1');
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        showHistoryInMap(history) {
            const { actionDate, oldStatus, newStatus } = history;
            if (!oldStatus) 
                return;
            this.clickedHistory = { status: newStatus.toLowerCase(), actionDate };
        },
        async handleAssignedUser(data) {
            await this.getStopDetails();
        },
        handleChangedStatus({ stopId, status }) {
            this.$set(this.stopDetails, 'status', status);
        },
        handleStatusHistoryUpdated({ stopId, oldStatus, newStatus }) {
            this.stopStatusHistory.push({
                actionDate: moment(),
                changedByUserFullName: this.user.fullName,
                location: null,
                oldStatus,
                newStatus,
                stopId
            });
        },
        compiledMarkdown(note) {
            return marked(note, { sanitize: true });
        },
        async handleGeneratedStopLabel() {
            this.$notify({
                message: 'Successfully generated label.',
                type: 'success'
            });

            await this.refreshItemList();
        },
        async handleAddedStopNote() {
            await this.refreshNoteList();
        },
        async reloadDetails() {
            this.$_handleLoaderState(true);
            await this.getStopDetails();
            this.$_handleLoaderState(false);
        },
        async refreshNoteList() {
            const api = `/api/stops/${this.$route.params.stopId}/notes`;
            const result = await handleRequests(api);
            this.stopDetails.stopNotes = result.data;
        },
        async refreshItemList() {
            const api = `/api/stops/${this.$route.params.stopId}/lines`;
            const result = await handleRequests(api);
            this.stopDetails.items = result.data;
        },
        notePhotoLoading(event, source) {
            // If the photo thumbnail couldn't be loaded, it's probably because the photo hasn't been processed by the Image Processor function.
            // It should be processed soon, so show a wait indicator instead, and try and load it again after 10 seconds.
            event.target.src = '/img/loading-ico-60px.gif';

            setTimeout(() => {
                event.target.src = source;
            }, 10000);
        },
        getBadgeColor(type) {
            const noteTypes = [...NOTE_TYPE_CONSTANTS];

            const result = noteTypes.find((noteType) => noteType.name === type);

            if (result) 
                return result.color;

            return 'pending';
        },
        async createTrackingLink() {
            const data = {
                stopId: this.stopDetails.stopId,
                type: 'LR',
                recipient: {
                    name: this.stopDetails.contact.name,
                    phone: this.stopDetails.contact.phone,
                    email: this.stopDetails.contact.email
                },
                teamMemberId: this.stopDetails.assignedTo ? this.stopDetails.assignedTo.publicUserId : null
            };

            const api = '/api/links';
            const payload = {
                method: 'post',
                data
            };

            try {
                const response = await handleRequests(api, payload);
                this.stopDetails.trackingLinkUrl = response.data.url;
                this.$notify({
                    message: 'Successfully created a tracking link.',
                    type: 'success'
                });
            } catch (e) {
                const message = 'Error in creating the tracking link.';
                showErrorMessage(this, message, e);
            }
        },
        setTrackingLinkUrl(trackingLink) {
            // this is to make url shorteners behave like a link
            if (trackingLink.includes('https://')) 
                return `${trackingLink}?isAdmin=true&showPOD=true`;

            return `https://${trackingLink}?isAdmin=true&showPOD=true`;
        },
        viewPhoto(photos, selected) {
            this.$modal
                .show(PhotoPreviewModal, {
                    photos,
                    selectedPhoto: selected
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async setupSignalR() {
            const userCrendentials = await window.auth.getUser();

            this.connection = new signalR.HubConnectionBuilder()
                .withUrl('/api/RatesEngineUpdateHub', {
                    accessTokenFactory: () => {
                        return userCrendentials.access_token;
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            try {
                await this.connection.start().then((result) => {
                    this.connection.invoke('JoinToTeamChannel');
                });

                this.connection.onclose(async () => {
                    await this.setupSignalR();
                });

                this.connection.on('StopRatesUpdated', async (stopId, updateCosts, updateCharges) => {
                    this.handleStopRatesUpdated(stopId, updateCosts, updateCharges);
                });

                this.connection.on('ProcessRecalculationStopComplete', async (stopId) => {
                    this.handleStopRatesUpdated(stopId, true, true);
                });
            } catch (err) {
                setTimeout(this.setupSignalR, 5000);
            }
        },
        async handleStopRatesUpdated(stopId, updateCosts, updateCharges) {
            if (Number(stopId) === this.stopDetails.stopId) {
                // fetch the new rates
                try {
                    if (
                        this.stopDetails.teamMemberInvoiceId === null ||
                        this.stopDetails.teamMemberInvoiceId === undefined
                    ) {
                        this.updateCostsInBackground = Boolean(updateCosts);
                    }

                    if (
                        this.stopDetails.customerInvoiceId === null ||
                        this.stopDetails.customerInvoiceId === undefined
                    ) {
                        this.updateChargesInBackground = Boolean(updateCharges);
                    }

                    const endpoint = `/api/stops/get-rates/${stopId}`;
                    const result = await handleRequests(endpoint);

                    if (this.updateCostsInBackground) {
                        this.stopDetails.costLines = result.data.costLines;
                    }

                    if (this.updateChargesInBackground) {
                        this.stopDetails.chargeLines = result.data.chargeLines;
                    }
                } finally {
                    this.updateCostsInBackground = false;
                    this.updateChargesInBackground = false;
                }
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.stopNotePolling);

        if (this.connection) {
            this.connection.invoke('RemoveFromTeamChannel');
        }
    }
};
</script>

<style lang="scss" scoped>
$status-pending: #c2907f;
$status-enroute: #4ed2ff;
$status-arrived: #2b93ff;
$status-departed: #ffb42b;
$status-cancelled: #ff5245;
$status-delayed: #384553;
$status-complete: #0bda8e;
.details-page {
    padding-top: 40px;
    .booking-details {
        display: table;
        width: 100%;
        div {
            display: table-cell;
            vertical-align: middle;
        }
        .title {
            font-size: 18px;
        }
        .status-pending {
            background-color: $status-pending !important;
            color: #fff !important;
        }
        .status-pending:hover,
        .status-pending:focus {
            color: #fff !important;
        }
        .status-enroute {
            background-color: $status-enroute !important;
        }
        .status-arrived {
            background-color: $status-arrived !important;
        }
        .status-departed {
            background-color: $status-departed !important;
        }
        .status-complete {
            background-color: $status-complete !important;
        }
        .status-delayed {
            background-color: $status-delayed !important;
        }
        .status-cancelled {
            background-color: $status-cancelled !important;
        }
    }

    ::v-deep .md-table-row:hover {
        background-color: #fafafa;
        cursor: pointer;
    }

    ::v-deep .badge {
        display: inline-block;
        border-radius: 12px;
        padding: 5px 12px;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;

        position: initial;
        width: initial;
        height: initial;
        top: initial;
        right: initial;
        margin-top: initial;
        margin-right: initial;
        max-width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .address-details {
        > div {
            display: inline-block;
            width: 400px;
        }
        .duration {
            width: 150px;
        }
    }

    .stop-time-details {
        > div {
            display: inline-block;
            width: 200px;
        }
        a {
            cursor: pointer;
        }
        .run-number {
            width: 100px;
        }
    }
    .grayedout-text {
        color: #aaa !important;
        -webkit-text-fill-color: #aaa !important;
    }

    ::v-deep .md-field.md-disabled:after {
        border-bottom: none;
    }

    ::v-deep .md-field:after {
        height: 0;
    }

    ::v-deep .md-field:before {
        background-color: initial !important;
    }

    .custom-label {
        font-size: 0.6875rem;
        color: #aaa;
    }

    ::v-deep .timeline.timeline-simple {
        margin-top: 0;
    }

    ::v-deep .md-card-timeline {
        margin-top: 0;
        margin-bottom: 0;
    }

    ::v-deep .timeline > li > .timeline-panel {
        padding: 5px 20px;
        background-color: #fafafa;
    }

    ::v-deep .timeline-panel > h6 > h6 {
        text-transform: none;
        margin-top: 0;
    }

    ::v-deep .timeline-panel > h6 {
        margin-top: 0px;
    }

    ::v-deep .timeline-heading {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li {
        margin-bottom: 5px;
    }

    ::v-deep .timeline > li > .timeline-panel:after,
    ::v-deep .timeline > li > .timeline-panel:before {
        border-left-color: #fafafa;
        border-right-color: #fafafa;
    }

    ::v-deep .timeline.timeline-simple > li > .timeline-panel {
        width: 83%;
    }

    ::v-deep .timeline-heading .time {
        margin-left: 10px;
        color: #333333;
        font-weight: 400;
        font-size: 0.75rem;
    }
    .stop-notes {
        white-space: pre-wrap;
    }
}

.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    img {
        border-radius: 3px;
    }
}

::v-deep .md-card-header-icon {
    z-index: 9;
}
.stop-line-box {
    z-index: 0;
}

.map-box ::v-deep .md-card-content {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: -22px;
}

.custom-chip {
    margin-top: 0px;
}

.attachment-title {
    margin-bottom: 0;
    margin-top: 0;
}

.assignment-history-time {
    margin-left: 0 !important;
}

.header-button-container {
    > button {
        margin-right: 0;
    }
    .status-btn {
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        > ::v-deep span {
            margin: 6px 8px;
            height: 36px;
            line-height: 36px;
            border-radius: 2px;
            margin-right: 0px;
        }

        > ::v-deep .dropdown-menu {
            margin-left: 9px;
            margin-top: 0;
        }
    }
}

.custom-toolbar {
    .title {
        margin-bottom: 0;
    }
}

.content-layout-panel {
    > div {
        padding: 0;
    }
}

@media (min-width: 768px) {
    .details-page {
        padding-top: 0;
    }
}

.brand-logo {
    max-height: 50px;
    width: initial;
}

.previous-stop {
    float: right;
}

.icon-style {
    color: white;
}
</style>
