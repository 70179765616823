var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("receipt_long")])],
            1
          ),
        ]
      ),
      _c("md-card-content", [
        !_vm.lines || _vm.lines.length === 0
          ? _c("p", { staticClass: "grayedout-text text-center" }, [
              _vm._v("\n            No items found\n        "),
            ])
          : _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    staticClass: "context-menu-support custom-paginated-table",
                  },
                  [
                    _c(
                      "md-table-row",
                      [
                        _c("md-table-head", [_vm._v("Status")]),
                        _c("md-table-head", [_vm._v("Barcode")]),
                        _c("md-table-head", [_vm._v("Description")]),
                        _vm.$root.isDesktop && !_vm.isReadOnlyUser
                          ? _c("md-table-head", [_vm._v("Actions")])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.lines, function (line) {
                      return _c(
                        "md-table-row",
                        {
                          key: line.StopLines,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openInventoryItem(line.itemId)
                            },
                          },
                        },
                        [
                          _c("md-table-cell", [
                            _c(
                              "div",
                              {
                                class:
                                  "custom-badge inventory-status-" +
                                  line.status.toLowerCase().replace(/\s+/g, ""),
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "inputs.itemStatuses." + line.status
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]),
                          _c("md-table-cell", [_vm._v(_vm._s(line.barcode))]),
                          _c("md-table-cell", [
                            _vm._v(_vm._s(line.description)),
                          ]),
                          _vm.$root.isDesktop && !_vm.isReadOnlyUser
                            ? _c(
                                "md-table-cell",
                                { staticClass: "action-buttons" },
                                [
                                  !_vm.isReadOnlyUser
                                    ? _c("update-item-button", {
                                        attrs: {
                                          item: line,
                                          "is-delivered":
                                            line.status === "Delivered",
                                          "stop-status": _vm.stopStatus,
                                          "is-invoiced": _vm.isInvoiced,
                                        },
                                        on: {
                                          itemUpdated: _vm.handleUpdateItem,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "action-button" },
                                    [
                                      !_vm.isReadOnlyUser
                                        ? _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-danger md-just-icon md-round",
                                              attrs: {
                                                title: "Unlink item",
                                                disabled:
                                                  _vm.isInvoiced ||
                                                  _vm.belongsToShipment ||
                                                  line.status === "Delivered" ||
                                                  _vm.stopStatus.toLowerCase() ===
                                                    "failed" ||
                                                  _vm.stopStatus.toLowerCase() ===
                                                    "completed",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleUnlinkItem(
                                                    line.itemId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("md-icon", [
                                                _vm._v("link_off"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isInvoiced
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                You cannot unlink an item when the stop is invoiced.\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm.belongsToShipment
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "left" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                This stop belongs to a shipment.\n                                "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                                Open the shipment details from the top of the page to unlink the item.\n                            "
                                              ),
                                            ]
                                          )
                                        : line.status === "Delivered"
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "top" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                You cannot unlink a delivered item.\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm.stopStatus !== null &&
                                          _vm.stopStatus.toLowerCase() ===
                                            "failed"
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "top" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                You cannot edit an item when the stop is marked as Failed\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm.stopStatus !== null &&
                                          _vm.stopStatus.toLowerCase() ===
                                            "complete"
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              staticClass: "line-tooltip",
                                              attrs: { "md-direction": "top" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                You cannot edit an item when the stop is marked as Complete\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
      ]),
      _c(
        "md-card-actions",
        { attrs: { "md-alignment": "right" } },
        [
          _vm.lines.length > 0
            ? _c("generate-label-button", {
                attrs: { id: _vm.stopId, "use-icon": false },
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "md-button",
                {
                  staticClass: "md-success",
                  attrs: {
                    disabled:
                      _vm.isInvoiced ||
                      _vm.belongsToShipment ||
                      _vm.stopStatus.toLowerCase() === "complete" ||
                      _vm.stopStatus.toLowerCase() === "failed",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAddLine()
                    },
                  },
                },
                [_vm._v("\n                Add Item\n            ")]
              ),
              _vm.isInvoiced
                ? _c(
                    "md-tooltip",
                    {
                      staticClass: "line-tooltip",
                      attrs: { "md-direction": "left" },
                    },
                    [
                      _vm._v(
                        "\n                Not allowed to add an item when the stop is already invoiced.\n            "
                      ),
                    ]
                  )
                : _vm.belongsToShipment
                ? _c(
                    "md-tooltip",
                    {
                      staticClass: "line-tooltip",
                      attrs: { "md-direction": "left" },
                    },
                    [
                      _vm._v(
                        "\n                This stop belongs to a shipment.\n                "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Open the shipment details from the top of the page to add new item.\n            "
                      ),
                    ]
                  )
                : _vm.stopStatus.toLowerCase() === "failed"
                ? _c(
                    "md-tooltip",
                    {
                      staticClass: "line-tooltip",
                      attrs: { "md-direction": "left" },
                    },
                    [
                      _vm._v(
                        "\n                Not allowed to add an item when the stop is already marked as Failed.\n            "
                      ),
                    ]
                  )
                : _vm.stopStatus.toLowerCase() === "complete"
                ? _c(
                    "md-tooltip",
                    {
                      staticClass: "line-tooltip",
                      attrs: { "md-direction": "left" },
                    },
                    [
                      _vm._v(
                        "\n                Not allowed to add an item when the stop is already marked as Complete.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }