var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nested-table" },
    [
      _c(
        "div",
        { staticClass: "nested-table-row nested-table-header" },
        [
          _c("md-checkbox", {
            on: { change: _vm.onSelectAll },
            model: {
              value: _vm.isSelectAll,
              callback: function ($$v) {
                _vm.isSelectAll = $$v
              },
              expression: "isSelectAll",
            },
          }),
          _c("h5", [_vm._v(_vm._s(_vm.referenceHeaderValue.name))]),
          _c("h5", [_vm._v("Customer")]),
          _c("h5", [_vm._v("Address")]),
          _c("h5", [_vm._v("Time")]),
          _c("h5", [_vm._v("Status")]),
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _c("h5", [_vm._v("Actions")]),
        ],
        1
      ),
      _c("div", { staticClass: "line-separator" }),
      _vm._l(_vm.rootJob.childrenJobs, function (job, index) {
        return _c("div", { key: index }, [
          _c(
            "div",
            { staticClass: "nested-table-row" },
            [
              _c("md-checkbox", {
                attrs: { value: job },
                model: {
                  value: _vm.selectedJobs,
                  callback: function ($$v) {
                    _vm.selectedJobs = $$v
                  },
                  expression: "selectedJobs",
                },
              }),
              _c(
                "span",
                { staticClass: "ref-no" },
                [
                  _vm.showWarning(job)
                    ? _c(
                        "md-icon",
                        { staticClass: "warming-icon" },
                        [
                          _vm._v(
                            "\n                    warning\n                    "
                          ),
                          _c("CustomTooltip", [
                            _vm._v(
                              "\n                        Not Geocoded\n                        "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                        Confirm address to fix.\n                    "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  job.stopId
                    ? _c(
                        "span",
                        [
                          job.stopId
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "Stop Details",
                                      params: { stopId: job.stopId },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.referenceHeaderValue.key ===
                                          "reference-number" ||
                                          job.sourceReference === null ||
                                          job.sourceReference === ""
                                          ? job.ref
                                          : job.sourceReference
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  job.shipmentId
                    ? _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Shipment Details",
                                  params: { shipmentId: job.shipmentId },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.referenceHeaderValue.key ===
                                      "reference-number" ||
                                      job.sourceReference === null ||
                                      job.sourceReference === ""
                                      ? job.ref
                                      : job.sourceReference
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  job.tripId
                    ? _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Trip Details",
                                  params: { tripId: job.tripId },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.referenceHeaderValue.key ===
                                      "reference-number" ||
                                      job.sourceReference === null ||
                                      job.sourceReference === ""
                                      ? job.ref
                                      : job.sourceReference
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("span", [
                _vm._v(_vm._s(job.customerName || _vm.rootJob.customerName)),
              ]),
              _c("span", [
                _vm._v(
                  "\n                " +
                    _vm._s(job.dropAddress) +
                    "\n            "
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "time",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openUpdateTimeModal($event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getTimeValue(job)) +
                          "\n                    "
                      ),
                      job.tripId
                        ? _c("md-icon", { staticClass: "edit-icon" }, [
                            _vm._v(
                              "\n                        edit\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "CustomTooltip",
                        _vm._l(
                          _vm.getTimeTooltipText(job),
                          function (time, idx) {
                            return _c(
                              "div",
                              { key: idx, staticClass: "time-tooltip-content" },
                              [
                                _c("b", [_vm._v(_vm._s(time.label))]),
                                _c("span", [_vm._v(_vm._s(time.value))]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "span",
                [
                  _c("JobStatus", {
                    attrs: { variant: _vm.getStatusNormalized(job.status) },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "finance-column" }, [
                job.totalCharge < 0
                  ? _c("span", [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getTotalCharge(job),
                              job.currency
                            )
                          ) +
                          ")\n                "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getTotalCharge(job),
                              job.currency
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "finance-column" }, [
                job.totalCost < 0
                  ? _c("span", [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getTotalCost(job),
                              job.currency
                            )
                          ) +
                          ")\n                "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.getTotalCost(job),
                              job.currency
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "finance-column" }, [
                job.margin < 0
                  ? _c("span", [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm._f("currency")(_vm.getMargin(job), job.currency)
                          ) +
                          ")\n                "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("currency")(_vm.getMargin(job), job.currency)
                          ) +
                          "\n                "
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "actions" }, [
                !job.isRecalculating
                  ? _c(
                      "div",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass:
                              "md-success md-just-icon md-round recalculate-icon",
                            attrs: {
                              title: "Update Rates",
                              disabled: !job.rateGroupId,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.updateRates(job)
                              },
                            },
                          },
                          [_c("md-icon", [_vm._v("currency_exchange")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "sync-loader" },
                      [_c("straight-line-loader")],
                      1
                    ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "line-separator" }),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "finance-column" }, [
      _c("h5", [_vm._v("Charge")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "finance-column" }, [
      _c("h5", [_vm._v("Cost")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "finance-column" }, [
      _c("h5", [_vm._v("Margin")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }