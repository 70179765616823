var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-group",
          { attrs: { name: "distanceCost", label: "Distance Cost" } },
          [
            _c("md-input", {
              attrs: { type: "number" },
              model: {
                value: _vm.distanceCost,
                callback: function ($$v) {
                  _vm.distanceCost = $$v
                },
                expression: "distanceCost",
              },
            }),
          ],
          1
        ),
        _c(
          "form-group",
          { attrs: { name: "timeCost", label: "Time Cost" } },
          [
            _c("md-input", {
              attrs: { type: "number" },
              model: {
                value: _vm.timeCost,
                callback: function ($$v) {
                  _vm.timeCost = $$v
                },
                expression: "timeCost",
              },
            }),
          ],
          1
        ),
        _vm.isDistributeWorkload === false
          ? _c(
              "form-group",
              { attrs: { name: "fixedCost", label: "Fixed Costs" } },
              [
                _c("md-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.fixedCost,
                    callback: function ($$v) {
                      _vm.fixedCost = $$v
                    },
                    expression: "fixedCost",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isDistributeWorkload === true
          ? _c(
              "form-group",
              {
                attrs: {
                  name: "distributeWorkloadCost",
                  label: "Distribute Workload Cost",
                },
              },
              [
                _c("md-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.distributeWorkloadCost,
                    callback: function ($$v) {
                      _vm.distributeWorkloadCost = $$v
                    },
                    expression: "distributeWorkloadCost",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.startLocationMode === "manual"
          ? _c(
              "form-group",
              {
                attrs: {
                  name: "routeCenteringPenalty",
                  label: "Route Centering Penalty",
                },
              },
              [
                _c("md-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.routeCenteringPenalty,
                    callback: function ($$v) {
                      _vm.routeCenteringPenalty = $$v
                    },
                    expression: "routeCenteringPenalty",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.saveRouringSetting },
          },
          [_vm._v("Save")]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Routing Setting")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }