export function STOP_DETAILS_DEFAULTS() {
    return {
        contact: {
            name: null,
            phone: null,
            email: null
        },
        name: null,
        address: null,
        location: {
            latitude: null,
            longitude: null
        },
        appointmentTime: null,
        timeWindowStart: null,
        timeWindowEnd: null,
        durationMinutes: 15,
        notes: null,
        tripDate: null,
        assignToPublicUserId: 0,
        customerId: null,
        skills: null,
        runNumber: null,
        brandId: null,
        chargeLines: [],
        updatedChargeLines: [],
        rateGroupId: null,
        rateGroup: {
            rateGroupName: null
        },
        costLines: [],
        updatedCostLines: [],
        sourceReference: null,
        type: null
    };
}

export function CUSTOMER_DETAILS_DEFAULTS() {
    return {
        customerName: null,
        firstName: null,
        lastName: null,
        company: null,
        address: null,
        location: {
            latitude: null,
            longitude: null
        },
        email: null,
        accountsEmail: null,
        phone: null,
        notes: null,
        defaultStopNotes: null,
        defaultStopDurationMinutes: null,
        createUserAccount: false,
        role: 'Customer',
        teamRegionId: null,
        brandId: null,
        defaultRateGroupId: null,
        assignedTeamMembers: []
    };
}

export function WAREHOUSE_DETAILS_DEFAULTS() {
    return {
        warehouseName: null,
        address: null,
        location: {
            latitude: null,
            longitude: null
        },
        teamRegionId: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        defaultStopNotes: null,
        isDefaultShipmentPickupStop: false,
        isDefaultShipmentDropStop: false
    };
}

export function SERVICE_DETAILS_DEFAULTS() {
    return {
        name: null,
        unitPriceExTax: null,
        priceCurrency: null,
        unitCostExTax: null,
        costCurrency: null,
        quantityType: '/hr'
    };
}

export function ASSET_LOGS_DEFAULTS() {
    return {
        logType: null,
        logSubType: null,
        cost: null,
        costCurrency: null,
        odometerUnitType: null,
        startAddressName: null,
        startAddress: null,
        startLocation: null,
        startingEngineHours: null,
        startingOdometer: null,
        startDate: null,
        notes: null
    };
}

export function MAINTENANCE_SCHEDULE_DEFAULTS() {
    return {
        maintenanceName: null,
        maintenanceType: null,
        maintenanceSubType: null,
        timeScheduleDue: null,
        timeScheduleBefore: null,
        timeScheduleAfter: null,
        timeScheduleInterval: null,
        timeScheduleUnitType: 'days',
        engineHoursBefore: null,
        engineHoursDue: null,
        engineHoursAfter: null,
        odometerBefore: null,
        odometerDue: null,
        odometerReminderUnitType: null,
        odometerAfter: null,
        notes: null,
        frequency: 'one-off',
        notificationConfiguration: {
            email: [],
            additionalEmails: null
        },
        currentEngineHoursValue: null,
        currentOdometerValue: null,
        nextDueEngineHours: null,
        nextDueOdometer: null
    };
}

export function SHIPMENT_DETAILS_DEFAULTS() {
    return {
        pickupStop: {
            brandId: null,
            customerId: null,
            contact: {
                name: null,
                phone: null,
                email: null
            },
            name: null,
            address: null,
            location: {
                latitude: null,
                longitude: null
            },
            tripDate: null,
            appointmentTime: null,
            timeWindowStart: null,
            timeWindowEnd: null,
            durationMinutes: 15,
            teamRegionId: null
        },
        dropStop: {
            brandId: null,
            customerId: null,
            contact: {
                name: null,
                phone: null,
                email: null
            },
            name: null,
            address: null,
            location: {
                latitude: null,
                longitude: null
            },
            tripDate: null,
            appointmentTime: null,
            timeWindowStart: null,
            timeWindowEnd: null,
            durationMinutes: 15,
            teamRegionId: null
        },
        brandId: null,
        customerId: null,
        contact: {
            name: null,
            phone: null,
            email: null
        },
        tripDate: null,
        notes: null,
        assignToPublicUserId: 0,
        runNumber: null,
        teamRegionId: null,
        rateGroupId: null,
        rateGroup: {
            rateGroupName: null
        },
        chargeLines: [],
        updatedChargeLines: [],
        costLines: [],
        updatedCostLines: [],
        distance: null
    };
}

export const PAGINATION_DEFAULTS = {
    perPage: 50,
    currentPage: 1,
    perPageOptions: [50, 100, 200],
    total: 0
};

export function RUN_DETAILS_DEFAULTS() {
    return {
        runName: null,
        runNumber: null,
        scheduleType: 'Weekly',
        startTime: null,
        endTime: null,
        activeFromDate: null,
        customerId: null,
        warehouseId: null,
        address: null,
        location: {
            latitude: null,
            longitude: null
        },
        runStartLocation: {
            name: null,
            address: null,
            location: {
                latitude: null,
                longitude: null
            }
        },
        runEndLocation: {
            name: null,
            address: null,
            location: {
                latitude: null,
                longitude: null
            }
        },
        contact: {
            name: null,
            phone: null,
            email: null
        },
        schedule: null,
        status: null,
        autoCreateTripDaysInAdvance: null,
        startLocationStopDurationMinutes: null,
        endLocationStopDurationMinutes: null,
        createEmptyTrip: false,
        rateGroupId: null
    };
}

export function TEAM_REGION_DEFAULTS() {
    return {
        name: '',
        parentTeamRegionId: 0,
        regionBoundary: null,
        shape: null,
        radiusValue: null,
        radiusUnit: null,
        radiusInMeters: null,
        notes: ''
    };
}

export const SERVICE_RATE_DETAILS_DEFAULTS = [
    {
        rateGroupId: null,
        rateGroupName: null,
        teamRegionId: null,
        unitCostExTax: null,
        unitPriceExTax: null,
        costCurrency: null,
        priceCurrency: null,
        ruleType: null
    }
];

export function TEAM_CUSTOM_ACTION_DEFAULTS() {
    return {
        eventTrigger: null,
        action: null,
        filter: {
            status: []
        },
        settings: {
            useSystemTemplate: true,
            emailTemplateId: null,
            emailTemplateContent: null,
            replyToEmail: null,
            emailRecipientTypes: [],
            recipientEmailAddresses: null, // supports semi-colon and comma delimited
            requestQuote: null,
            preferredQuote: null,
            conditions: []
        }
    };
}
export function APP_DETAILS_PERMISSIONS() {
    return {
        addStops: true,
        addStopsForCustomers: false,
        updateStops: true,
        deleteStops: true,
        changeStopStatuses: true,
        reorderStops: true,
        addShipments: true,
        updateShipments: true,
        deleteShipments: true,
        changeShipmentStatuses: true,
        discardShipments: true,
        updateTrips: true,
        askTripQuestionsForEachRun: false,
        createLinks: true,
        deleteLinks: true,
        addStopLines: true,
        updateStopLines: true,
        deleteStopLines: true,
        viewStopNotes: true,
        addStopNotes: true,
        discardStops: true,
        createCustomerInvoices: true,
        acceptPayments: true,
        updateUserStatus: true,
        updateUserProfile: true,
        changeUserPhoto: true,
        changeLogo: true,
        checkAssetsInAndOut: true,
        assetCheckInOut: {
            nfc: true,
            barcode: true,
            selectFromList: true
        },
        registerGpsTrackingDevice: true,
        pickupItems: true,
        returnItems: true,
        warehouseItemsScan: true,
        tripListMode: 'all',
        stopLineMode: 'noPricing',
        quickCapturePhotoNotes: false,
        uploadFromGallery: false,
        views: {
            map: true,
            stops: true,
            items: false,
            default: ''
        },
        selectCustomer: false
    };
}

export function RATE_RULE() {
    return {
        priority: null,
        rateRulesId: null,
        condition: null,
        hourlyRatePerHour: null,
        hourlyFlagfall: null,
        hourlyMinimumCharge: null,
        hourlyMinimumTime: null,
        includedTime: null,
        surchargeName: null,
        surchargePercentage: null,
        surchargeEnabled: null,
        surchargeFlatRate: null,
        surchargeFlatRateEnabled: null,
        fuelLevyPercentage: null,
        fuelLevyEnabled: null,
        futileCharge: null,
        includedItems: null,
        itemsFlagfall: null,
        itemsInterval: null,
        itemsRatePerInterval: null,
        itemsMinimumCharge: null,
        includedWeight: null,
        weightInterval: null,
        weightRatePerInterval: null,
        weightFlagfall: null,
        weightMinimumCharge: null,
        includedDistance: null,
        distanceRatePerKilometre: null,
        distanceFirstFlagfall: null,
        distanceMinimumCharge: null,
        includedVolume: null,
        volumeInterval: null,
        volumeFlagfall: null,
        volumeRatePerInterval: null,
        volumeMinimumCharge: null,
        saturdayPercentage: null,
        saturdayCharge: null,
        sundayPercentage: null,
        sundayCharge: null,
        validForWeekDays: null,
        driverCommission: null
    };
}

export function INVOICE_ADJUSTMENT_RULE() {
    return {
        invoiceAdjustmentRuleId: 0,
        invoiceAdjustmentRuleType: null,
        invoiceAdjustmentRuleTypeId: null,
        taxSettingsId: null,
        taxSettingsDescription: null,
        description: null,
        unitPrice: null,
        accountCode: null
    };
}

export function JOB_OFFER_SETTING_DEFAULTS() {
    return {
        teamRegionId: null,
        maxRetry: 1,
        retryDelay: '0.00:03:00',
        matchSkills: false,
        checkLoadCapacity: false,
        prioritizeEmptyVehicles: false,
        autoAllocate: false,
        failFast: true,
        alertLeadTime: '0.01:00:00', // 1 hour
        alertTypeScheme: 'Default',
        offerAllocationMethod: 'Manual'
    };
}

export function JOB_OFFER_BAND_DEFAULTS() {
    return {
        id: null,
        order: 0,
        name: null,
        minProximity: 0,
        minProximityUnit: 'km',
        minProximityMeters: 0,
        maxProximity: 0,
        maxProximityUnit: 'km',
        maxProximityMeters: 0,
        sendingMode: 'Broadcast',
        dripFeedDelay: null,
        nextBandDelay: '0.00:01:00' // 1 minute
    };
}

export function RATE_DETAILS_DEFAULTS() {
    return {
        shipmentId: null,
        stopId: null,
        load: null,
        tripDate: null,
        rateGroupId: null,
        pickup: null,
        drop: null,
        customerId: null,
        speedUnits: null,
        teamId: null,
        rateList: null,
        costList: null,
        publicUserId: null,
        originalRateList: [],
        revertToDefaultCharges: null,
        revertToDefaultCosts: null,
        teamMemberId: null,
        isCostingRule: false,
        isChargingRule: false,
        getDistance: false
    };
}

export function STOP_TABLE_HEADER_DISPLAY_OPTION() {
    return {
        columnKeys: {
            referenceSource: 'referenceSource'
        },
        tableColumnKeyOptions: {
            referenceSource: null
        },
        tableColumnOptions: {
            columns: {
                referenceSource: null
            }
        }
    };
}
export function RUN_STOP_DETAILS_DEFAULTS() {
    return {
        runScheduleId: 0,
        customerId: null,
        name: null,
        address: null,
        location: {
            latitude: null,
            longitude: null
        },
        contact: {
            name: null,
            phone: null,
            email: null
        },
        appointmentTime: null,
        timeWindowStart: null,
        timeWindowEnd: null,
        durationMinutes: 15,
        notes: null,
        skills: null,
        order: null,
        type: null
    };
}

/**
 *
 * @returns {import('@/jsDocTypes/ItemLocation').ItemLocation}
 */
export function ITEM_LOCATION_DETAILS_DEFAULTS() {
    return {
        locationId: null,
        parentId: null,
        warehouseId: null,
        assetId: null,
        teamMemberId: null,
        barcode: null,
        name: null,
        notes: null,
        colour: null,
        type: null
    };
}

/**
 *
 * @returns {import('@/jsDocTypes/UserAssetGroup').UserAssetGroup}
 */
export function USER_ASSET_GROUP_DEFAULTS() {
    return null;
}

export function CARRIER_CUSTOM_CONDITION_DEFAULTS() {
    return {
        order: 0,
        mainOperator: null,
        variable: null,
        operator: null,
        value: null,
        value2: null,
        dataType: null,
        parentObject: null,
        expression: null
    };
}
