var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-blue" },
        [
          _c(
            "div",
            { staticClass: "card-icon" },
            [_c("md-icon", [_vm._v("description")])],
            1
          ),
        ]
      ),
      _c(
        "md-card-content",
        [
          _c(
            "md-table",
            { staticClass: "custom-paginated-table" },
            [
              _vm.showAlert
                ? _c("div", { staticClass: "alert-section" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "alert alert-warning alert-dismissible fade show",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _c("strong", [_vm._v("ATTENTION!")]),
                            _vm._v(
                              "\n                            Please click the save button in order to save the changes made.\n                        "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-primary md-just-icon md-round",
                                attrs: { title: "Save new order of stops" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.saveRunStopOrder()
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("save")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "draggable",
                {
                  staticClass: "custom-draggable show-draggable",
                  attrs: { draggable: ".drag-item" },
                  on: { change: _vm.stopOrderChanged },
                  model: {
                    value: _vm.runDetails.stops,
                    callback: function ($$v) {
                      _vm.$set(_vm.runDetails, "stops", $$v)
                    },
                    expression: "runDetails.stops",
                  },
                },
                [
                  _c(
                    "md-table-row",
                    { attrs: { slot: "header" }, slot: "header" },
                    [
                      _c("md-table-head", { staticClass: "drag-icon" }),
                      _c("md-table-head", { staticClass: "stop-address" }, [
                        _vm._v("Address"),
                      ]),
                      _c("md-table-head", { staticClass: "stop-contact" }, [
                        _vm._v("Contact"),
                      ]),
                      _c("md-table-head", { staticClass: "stop-actions" }, [
                        _vm._v("Actions"),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.runDetails.stops, function (stopItem, stopIndex) {
                    return _c(
                      "md-table-row",
                      {
                        key: stopIndex,
                        staticClass: "drag-item",
                        class:
                          stopItem.isStartStop || stopItem.isEndStop
                            ? "startend-table-row"
                            : "",
                      },
                      [
                        _c(
                          "md-table-cell",
                          { staticClass: "drag-icon" },
                          [_c("md-icon", [_vm._v("drag_indicator")])],
                          1
                        ),
                        _c("md-table-cell", { staticClass: "stop-address" }, [
                          _c("span", { staticClass: "custom-ellipsis" }, [
                            _vm._v(_vm._s(_vm.$_displayAddressName(stopItem))),
                          ]),
                        ]),
                        _c("md-table-cell", { staticClass: "stop-contact" }, [
                          _c("span", { staticClass: "custom-ellipsis" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  stopItem.contact != null
                                    ? stopItem.contact.name
                                    : ""
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                        _c(
                          "md-table-cell",
                          { staticClass: "action-buttons" },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-button md-primary md-just-icon md-round btn-size-27",
                                attrs: { title: "Edit stop details" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updateRunStop(stopItem)
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("edit")])],
                              1
                            ),
                            _c(
                              "md-button",
                              {
                                staticClass:
                                  "md-danger md-just-icon md-round btn-size-27",
                                attrs: { title: "Delete stop" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDeleteRunStop(stopItem)
                                  },
                                },
                              },
                              [_c("md-icon", [_vm._v("delete")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }