var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadShapesOnly
    ? _c("div", { staticClass: "area-fence map-panel" }, [
        _c(
          "div",
          {
            staticClass: "area-wrapper",
            class: [
              { "drawing-area-fence": true },
              { "showing-area-list": _vm.showAreaList },
            ],
          },
          [
            _c("div", { staticClass: "area-main-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "nav-buttons",
                  class: _vm.listLevel == 2 ? "nav-buttons-three" : "",
                },
                [
                  _vm.listLevel == 2
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.isPanelCollapsed
                                ? _vm.expandPanel()
                                : _vm.collapsePanel()
                            },
                          },
                        },
                        [
                          _c("md-icon", [
                            _vm._v(
                              _vm._s(
                                _vm.isPanelCollapsed
                                  ? "arrow_back"
                                  : "arrow_forward"
                              )
                            ),
                          ]),
                          !_vm.isPanelCollapsed
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isPanelCollapsed ? "Expand" : "Collapse"
                                  ) + " panel"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: [{ "selected-tab": _vm.selectedTab == "draw" }],
                      on: {
                        click: function ($event) {
                          return _vm.selectDrawing("polygon")
                        },
                      },
                    },
                    [
                      _c("md-icon", [_vm._v("add")]),
                      !_vm.isPanelCollapsed
                        ? _c("span", [_vm._v("Draw " + _vm._s(_vm.title))])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: [{ "selected-tab": _vm.selectedTab == "search" }],
                      on: { click: _vm.searchList },
                    },
                    [
                      _c("md-icon", [_vm._v("search")]),
                      !_vm.isPanelCollapsed
                        ? _c("span", [_vm._v("Select from list")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm.selectedTab == "draw"
              ? _c(
                  "div",
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.showAreaList
                        ? _c(
                            "div",
                            { staticClass: "area-list-wrapper" },
                            [
                              _vm.areaList.length
                                ? _c(
                                    "md-card-content",
                                    { staticClass: "area-table-body" },
                                    [
                                      _c("div", { staticClass: "list-title" }, [
                                        _vm._v(
                                          "\n                            List of " +
                                            _vm._s(_vm.title) +
                                            ":\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "preview-btn",
                                            on: {
                                              click: _vm.turnOffMainListPreview,
                                            },
                                          },
                                          [
                                            _c("md-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toggleMainPreviewList
                                                    ? "visibility"
                                                    : "visibility_off"
                                                )
                                              ),
                                            ]),
                                            _c("md-tooltip", [
                                              _vm._v(
                                                "Turn On/Off preview on the map screen"
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "ul",
                                        _vm._l(_vm.areaList, function (fence) {
                                          return _c(
                                            "li",
                                            {
                                              key: fence[_vm.idName],
                                              class:
                                                fence[_vm.idName] ===
                                                [_vm.idName]
                                                  ? "highlight-item"
                                                  : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "name",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.zoomToFence(
                                                        fence[_vm.idName]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  fence.suburbLocalities
                                                    .length == 0
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v("polyline"),
                                                          ]),
                                                          _c("md-tooltip", [
                                                            _vm._v(
                                                              "This " +
                                                                _vm._s(
                                                                  _vm.title
                                                                ) +
                                                                " was drawn using the polyline"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v(
                                                              "playlist_add"
                                                            ),
                                                          ]),
                                                          _c("md-tooltip", [
                                                            _vm._v(
                                                              "\n                                            This " +
                                                                _vm._s(
                                                                  _vm.title
                                                                ) +
                                                                " was created from the list of suburbs\n                                        "
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(fence.name) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                              !_vm.loadShapesOnly
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pushed-right cta-list",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title: "Edit",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editArea(
                                                                fence[
                                                                  _vm.idName
                                                                ]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v("edit"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title:
                                                              "Toggle preview",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleMainListPreview(
                                                                fence[
                                                                  _vm.idName
                                                                ]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v(
                                                              "visibility"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title: "Delete",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteArea(
                                                                fence[
                                                                  _vm.idName
                                                                ]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("md-icon", [
                                                            _vm._v("delete"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  )
                                : _c("div", { staticClass: "small" }, [
                                    _vm._v(
                                      "\n                        Draw an area on the map by clicking points to mark out the area boundary, and click the\n                        start point again or double click on the map twice to complete the area boundary.\n                    "
                                    ),
                                  ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.newArea
                        ? _c("div", { staticClass: "area-list-wrapper" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "form-wrapper",
                                  {
                                    staticClass: "form-wrapper",
                                    attrs: { validator: _vm.$v },
                                  },
                                  [
                                    _c(
                                      "form-group",
                                      {
                                        attrs: {
                                          name: "areaName",
                                          label: "Name",
                                        },
                                      },
                                      [
                                        _c("md-input", {
                                          directives: [
                                            {
                                              name: "focus",
                                              rawName: "v-focus",
                                            },
                                          ],
                                          attrs: { "aria-placeholder": "Name" },
                                          model: {
                                            value: _vm.areaName,
                                            callback: function ($$v) {
                                              _vm.areaName = $$v
                                            },
                                            expression: "areaName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-button" },
                                  [
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-primary dialog-button md-theme-default",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addNewArea(false)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Save\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-default dialog-button md-theme-default",
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelNewArea(false)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Cancel\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedTab == "search"
              ? _c(
                  "div",
                  {
                    class: _vm.isPanelCollapsed
                      ? "full-container-size"
                      : "half-container-size",
                  },
                  [
                    _vm.isPanelLoading
                      ? _c(
                          "div",
                          { staticClass: "loader" },
                          [
                            _c("fade-loader", {
                              attrs: {
                                loading: _vm.isPanelLoading,
                                color: "#333333",
                              },
                            }),
                            _c("span", [_vm._v("LOADING")]),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            {
                              class:
                                _vm.listLevel == 1
                                  ? "list-container-state"
                                  : "list-container",
                            },
                            [
                              _vm.listLevel == 1
                                ? _c("div", [
                                    _vm.listOfStates.length
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.listOfStates,
                                            function (state) {
                                              return _c(
                                                "div",
                                                {
                                                  key: state.stateId,
                                                  staticClass: "state-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleClickState(
                                                        state.stateId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(state.province) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "no-result-container",
                                          },
                                          [
                                            _vm._v(
                                              "\n                            We currently don't support your country please contact support or email us at\n                            "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "mailto:support@locate2u.com",
                                                },
                                              },
                                              [_vm._v("support@locate2u.com")]
                                            ),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                              _vm.listLevel == 2 && !_vm.isPanelCollapsed
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "back-btn",
                                        on: { click: _vm.backToStates },
                                      },
                                      [
                                        _c("md-icon", [_vm._v("arrow_back")]),
                                        _vm._v(
                                          "\n                            Back to list of states\n                        "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "small small-note" },
                                          [
                                            _vm._v(
                                              "\n                                You can either do a search and add the suburbs individually or you can paste a list\n                                of suburb **For multiple suburb support please separate them using comma.\n                            "
                                            ),
                                          ]
                                        ),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.suburbNames,
                                              expression: "suburbNames",
                                            },
                                          ],
                                          staticClass: "c-textarea",
                                          domProps: { value: _vm.suburbNames },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.suburbNames =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _c(
                                          "md-button",
                                          {
                                            staticClass:
                                              "validate-btn md-primary",
                                            on: {
                                              click:
                                                _vm.handleMultipleSuburbSearch,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Validate\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "search-section" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "preview-title" },
                                          [
                                            _vm._v(
                                              "\n                                Result/s: (" +
                                                _vm._s(
                                                  _vm.listOfSuburbSearchResult
                                                    .length
                                                ) +
                                                ")\n                                "
                                            ),
                                            _vm.listOfSuburbSearchResult
                                              .length > 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "preview-btn",
                                                    on: {
                                                      click:
                                                        _vm.toggleSearchResultPreview,
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            _vm.toggleSearchResultList
                                                              ? "visibility"
                                                              : "visibility_off"
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ]),
                                                    _c("md-tooltip", [
                                                      _vm._v(
                                                        "Turn On/Off preview on the map screen"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.listOfSuburbSearchResult
                                              .length > 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "preview-btn",
                                                    on: {
                                                      click:
                                                        _vm.addSearchResultToPreviewList,
                                                    },
                                                  },
                                                  [
                                                    _c("md-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                    _c("md-tooltip", [
                                                      _vm._v(
                                                        "Add search result to preview list"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "search-container" },
                                          [
                                            _vm.isLoading
                                              ? _c(
                                                  "div",
                                                  { staticClass: "loader" },
                                                  [
                                                    _c("fade-loader", {
                                                      attrs: {
                                                        loading: _vm.isLoading,
                                                        color: "#333333",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("LOADING"),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _c("div", [
                                                  _vm.listOfSuburbSearchResult
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-list",
                                                        },
                                                        _vm._l(
                                                          _vm.listOfSuburbSearchResult,
                                                          function (suburb) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: suburb.suburbLocalityId,
                                                                staticClass:
                                                                  "state-item",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addToPreviewList(
                                                                              suburb
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-icon",
                                                                        {
                                                                          staticClass:
                                                                            "blue-icon",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "add"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "md-tooltip",
                                                                        [
                                                                          _vm._v(
                                                                            "Add to list"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleSuburbPreview(
                                                                              suburb,
                                                                              true
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-icon",
                                                                        {
                                                                          staticClass:
                                                                            "info-icon",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "visibility"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "md-tooltip",
                                                                        [
                                                                          _vm._v(
                                                                            "Show map preview"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      suburb.namePostCode
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm
                                                        .listOfSuburbSearchResult
                                                        .length == 0 &&
                                                      _vm.currentSearchTextValue
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-result-container",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "No results matching your search could be found."
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-result-container",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        There's no data to show.\n                                        "
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            "\n                                        Start typing to show results here.\n                                    "
                                                          ),
                                                        ]
                                                      ),
                                                ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.listLevel == 2
                            ? _c(
                                "div",
                                { staticClass: "list-container-search" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "selected-preview" },
                                    [
                                      _c(
                                        "form-wrapper",
                                        {
                                          staticClass: "form-wrapper",
                                          attrs: { validator: _vm.$v },
                                        },
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              attrs: {
                                                name: "areaName",
                                                label: "Name",
                                              },
                                            },
                                            [
                                              _c("md-input", {
                                                directives: [
                                                  {
                                                    name: "focus",
                                                    rawName: "v-focus",
                                                  },
                                                ],
                                                attrs: {
                                                  "aria-placeholder": "Name",
                                                },
                                                model: {
                                                  value: _vm.areaName,
                                                  callback: function ($$v) {
                                                    _vm.areaName = $$v
                                                  },
                                                  expression: "areaName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div"),
                                      _c(
                                        "div",
                                        { staticClass: "preview-title" },
                                        [
                                          _vm._v(
                                            "\n                            Your selection/s: (" +
                                              _vm._s(
                                                _vm.previewSuburbList.length
                                              ) +
                                              ")\n                            "
                                          ),
                                          _vm.previewSuburbList.length > 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "preview-btn",
                                                  on: {
                                                    click:
                                                      _vm.turnOffAllSelectedPreview,
                                                  },
                                                },
                                                [
                                                  _c("md-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.togglePreviewList
                                                          ? "visibility"
                                                          : "visibility_off"
                                                      )
                                                    ),
                                                  ]),
                                                  _c("md-tooltip", [
                                                    _vm._v(
                                                      "Turn On/Off preview on the map screen"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm.previewSuburbList.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "preview-box" },
                                            _vm._l(
                                              _vm.previewSuburbList,
                                              function (previewSuburb) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: previewSuburb.suburbLocalityId,
                                                    staticClass: "preview-item",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeFromList(
                                                                previewSuburb
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              staticClass:
                                                                "red-icon",
                                                            },
                                                            [_vm._v("close")]
                                                          ),
                                                          _c("md-tooltip", [
                                                            _vm._v(
                                                              "Remove item from list"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleSuburbPreview(
                                                                previewSuburb
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "md-icon",
                                                            {
                                                              staticClass:
                                                                "info-icon",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            visibility\n                                        "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("md-tooltip", [
                                                            _vm._v(
                                                              "Show preview in the map screen"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          previewSuburb.namePostCode
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "preview-box" },
                                            [_vm._m(0)]
                                          ),
                                    ],
                                    1
                                  ),
                                  _vm.listLevel == 2
                                    ? _c(
                                        "div",
                                        { staticClass: "list-btn" },
                                        [
                                          _c(
                                            "md-button",
                                            {
                                              staticClass: "md-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addNewSuburbArea()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.isUpdateMultipleArea
                                                      ? "Update"
                                                      : "Create"
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "md-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clearSelectedSuburb()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Clear\n                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-result-container" }, [
      _vm._v(
        "\n                                There's no data to show.\n                                "
      ),
      _c("br"),
      _vm._v(
        "\n                                Start adding your suburbs now.\n                            "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }