<template>
    <drop-down
        :should-close="shouldCloseDropdown"
        @closeDropdown="(val) => (this.shouldCloseDropdown = val)"
        @closeOtherDropDown="closeOtherDropDown"
    >
        <span
            class="status"
            slot="title"
            data-toggle="dropdown"
            :class="
                `status-${stop.status.toLowerCase().replace(/\s+/g, '')} ${className} ${
                    statuses.length ? '' : 'no-pointer'
                }`
            "
        >
            {{ stop.status }}
        </span>
        <ul
            class="dropdown-menu"
            v-if="(!isReadOnlyUser && statuses.length) || (isCustomerAdmin && stop.status === 'Pending')"
        >
            <li v-for="(status, index) in statuses" :key="index">
                <a @click.stop="handleChangeStopStatus(status)">
                    {{ status }}
                </a>
            </li>
        </ul>
    </drop-down>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage } from '@/helpers';
import {
    STATUS_CONSTANTS,
    UNASSIGNED_STATUS_CONSTANTS,
    STATUS_STOP_SHIPMENT_CONSTANTS,
    FUTURE_STATUS_STOP_SHIPMENT_CONSTANTS,
    PAST_STATUS_STOP_SHIPMENT_CONSTANTS,
    UNASSIGNED_STATUS_STOP_SHIPMENT_CONSTANTS,
    FUTURE_STATUS_CONSTANTS,
    CUSTOMER_ADMIN_ROLE_STATUS_CONSTANTS,
    DATE_TYPES,
    PAST_STATUS_CONSTANTS
} from '@/utils/constants';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'StopStatusButton',
    mixins: [GeneralMixin],
    props: {
        stop: {
            type: Object,
            default: () => {}
        },
        className: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            statuses: STATUS_CONSTANTS,
            shouldCloseDropdown: false
        };
    },
    mounted() {
        this.updateStatus();
    },
    watch: {
        'stop.assignedTo.publicUserId': 'updateStatus',
        'stop.tripDate': 'updateStatus'
    },
    computed: {
        ...mapGetters({
            isReadOnlyUser: 'user/isReadOnlyUser',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isIndividualUser: 'user/isIndividualUser'
        })
    },
    methods: {
        updateStatus() {
            const today = moment().format(DATE_TYPES.internationalDate);
            const tripDate = moment(this.stop.tripDate);
            const shipmentId = this.stop && this.stop.shipmentId;

            this.statuses = shipmentId ? STATUS_STOP_SHIPMENT_CONSTANTS : STATUS_CONSTANTS;

            if (this.stop.assignedTo.publicUserId == null) {
                if (this.stop.carrierTeamId) {
                    this.statuses = [];
                } else {
                    this.statuses = shipmentId
                        ? UNASSIGNED_STATUS_STOP_SHIPMENT_CONSTANTS
                        : UNASSIGNED_STATUS_CONSTANTS;
                }
            } else {
                if (tripDate.isAfter(today)) {
                    this.statuses = shipmentId ? FUTURE_STATUS_STOP_SHIPMENT_CONSTANTS : FUTURE_STATUS_CONSTANTS;
                }

                if (tripDate.isBefore(today)) {
                    this.statuses = shipmentId ? PAST_STATUS_STOP_SHIPMENT_CONSTANTS : PAST_STATUS_CONSTANTS;
                }
            }

            if (this.isCustomerAdmin) {
                this.statuses = CUSTOMER_ADMIN_ROLE_STATUS_CONSTANTS;
            }
        },
        async handleChangeStopStatus(newStatus) {
            this.shouldCloseDropdown = true;
            const { stopId, status, tripId = null } = this.stop;
            if (status !== newStatus) {
                const payload = {
                    method: 'post',
                    data: {
                        newStatus,
                        oldStatus: status
                    }
                };

                // to toggle the context menu
                this.$nextTick(() => {
                    this.shouldCloseDropdown = false;
                });

                let proceed = false;
                if (!this.isIndividualUser && newStatus.toLowerCase() === 'failed') {
                    const response = await this.$messageBox.show({
                        class: 'sm-modal-container',
                        title: 'Warning',
                        body:
                            '<p>No change can be made upon changing the status to Failed. All the changes must be done on the newly created unassigned stop.</p><p>Are you sure you want to proceed in marking this stop as Failed?</p>',
                        buttons: ['Yes', 'Cancel']
                    });
                    if (response.toLowerCase() === 'yes') {
                        proceed = true;
                    }
                } else 
                    proceed = true;

                if (proceed) {
                    this.$emit('statusUpdated', { stopId, status: newStatus, tripId });
                    try {
                        const api = `/api/stops/${stopId}/change-status`;
                        await handleRequests(api, payload);

                        this.$notify({
                            message: `Stop status was changed to ${newStatus}!`,
                            type: 'success'
                        });
                        this.$emit('statusHistoryUpdated', { stopId, oldStatus: status, newStatus });

                        if (newStatus === 'Complete') {
                            this.$emit('refreshStop');
                        }
                    } catch (e) {
                        const message = 'Cannot change stop status.';
                        showErrorMessage(this, message, e);
                        this.$emit('statusUpdated', { stopId, status, tripId });
                    }
                }
            } else {
                const message = 'Cannot change status to the same status';
                this.$notify({
                    message,
                    type: 'danger'
                });
            }
        },
        closeOtherDropDown() {
            const dropdowns = document.querySelectorAll('.open');
            dropdowns.forEach((dropdown) => dropdown.classList.remove('open'));
        }
    }
};
</script>

<style lang="scss" scoped>
.status {
    color: #fff !important;
    min-width: 90px;
    text-align: center;
    display: block;
    line-height: 31px;
    font-weight: 400;
    max-width: 90px;
    cursor: pointer;
}

::v-deep .dropdown-menu {
    li {
        cursor: pointer !important;
    }
}

.no-pointer {
    cursor: not-allowed !important;
}
</style>
