<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
                <md-icon>account_box</md-icon>
            </div>
        </md-card-header>
        <md-card-content>
            <p
                v-if="!tripDetails.contact.name && !tripDetails.contact.phone && !tripDetails.contact.email"
                class="grayedout-text"
            >
                No contact details provided.
            </p>
            <div v-else>
                <div class="details-box" v-if="tripDetails.contact.name">
                    <label>Customer Name</label>
                    <span>{{ tripDetails.contact.name }}</span>
                </div>
                <div class="details-box" v-if="tripDetails.contact.phone">
                    <label>Phone</label>
                    <a :href="`tel:${tripDetails.contact.phone}`">
                        {{ tripDetails.contact.phone }}
                    </a>
                </div>
                <div class="details-box" v-if="tripDetails.contact.email">
                    <label>Email</label>
                    <span>{{ tripDetails.contact.email }}</span>
                </div>
                <div class="details-box" v-if="tripDetails.customerRateGroupId && !tripDetails.rateGroupId && showServicePackage()">
                    <label>Service Package</label>
                    <span>{{ tripDetails.customerRateGroupName }}</span>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<script>
import { GeneralMixin, RatesEngineMixin } from '@/mixins';

export default {
    name: 'TripContactDetails',
    mixins: [GeneralMixin, RatesEngineMixin],
    props: {
        tripDetails: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        showServicePackage() {
            // eslint-disable-next-line no-bitwise
            if (this.tripDetails.customerApplyRatesTo & this.getKeyByLabelInApplyRatesTo('Trip')) {
                return true;
            }

            return false;
        }
    }
};
</script>
