<template>
    <div>
        <md-card-content class="body-list">
            <div v-if="!isListLoading">
                <div v-if="cubicRatelist != null && cubicRatelist.length > 0">
                    <div class="header-button-container add-button">
                        <md-button
                            title="Add New Rate"
                            class="md-primary md-just-icon md-round"
                            @click="createNewRate()"
                        >
                            <md-icon>note_add</md-icon>
                        </md-button>
                    </div>
                    <md-table class="custom-paginated-table">
                        <draggable
                            v-model="cubicRatelist"
                            draggable=".drag-item"
                            class="custom-draggable"
                            @change="rateListChanged(cubicRatelist.item)"
                            @end="onEnd(cubicRatelist)"
                        >
                            <md-table-row slot="header">
                                <md-table-head class="drag-icon"></md-table-head>
                                <md-table-head>Flag Fall</md-table-head>
                                <md-table-head>Volume Interval</md-table-head>
                                <md-table-head>Charge Per Interval</md-table-head>
                                <md-table-head>Included Volume</md-table-head>
                                <md-table-head>Minimum Charge</md-table-head>
                                <md-table-head>Availability</md-table-head>
                                <md-table-head class="action-class">Actions</md-table-head>
                            </md-table-row>

                            <md-table-row v-for="item in cubicRatelist" :key="item.rateRulesId" class="drag-item">
                                <md-table-cell class="drag-icon">
                                    <md-icon>
                                        drag_indicator
                                    </md-icon>
                                </md-table-cell>
                                <md-table-cell>
                                    <div v-if="item.volumeFlagfall !== null">
                                        {{ item.volumeFlagfall | currency(item.currency) }}
                                    </div>
                                    <div v-else><br /></div>
                                    <div v-if="item.condition !== null && !hideConditionRules">
                                        <md-table-cell style="display:contents;">
                                            <md-chips class="md-primary" md-static style="flex-wrap: nowrap;">
                                                <template v-for="(oper, index) in item.condition">
                                                    <md-chip :key="index" v-if="checkForOperatorData(oper)">
                                                        {{ formatOperatorData(oper) }}
                                                    </md-chip>
                                                    <md-chip :key="index">{{ formatConditionData(oper) }}</md-chip>
                                                </template>
                                            </md-chips>
                                        </md-table-cell>
                                    </div>
                                </md-table-cell>
                                <md-table-cell>{{ item.volumeInterval }}</md-table-cell>
                                <md-table-cell>
                                    {{ item.volumeRatePerInterval | currency(item.currency) }}
                                </md-table-cell>
                                <md-table-cell>{{ item.includedVolume }}</md-table-cell>
                                <md-table-cell>{{ item.volumeMinimumCharge | currency(item.currency) }}</md-table-cell>
                                <md-table-cell>
                                    <selected-day :rule="item" />
                                </md-table-cell>
                                <md-table-cell class="action-buttons">
                                    <md-button
                                        title="Update rate details"
                                        class="md-warning md-just-icon md-round"
                                        @click.stop="updateRate(item.rateRulesId)"
                                    >
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                    <md-button
                                        title="Delete rule"
                                        class="md-danger md-just-icon md-round"
                                        @click.stop="validateDeleteRate(item.rateRulesId)"
                                    >
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                    <md-button
                                        title="Add Condition"
                                        class="md-success md-just-icon md-round"
                                        @click.stop="openCreateCondition(item.rateRulesId)"
                                    >
                                        <md-icon>rule</md-icon>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </draggable>
                    </md-table>

                    <md-card-actions class="page-footer" md-alignment="space-between" v-if="cubicRatelist.length > 0">
                        <div>
                            <p v-if="total === pagination.perPage" class="card-category">
                                Page {{ pagination.currentPage }} of many
                            </p>
                            <p v-else class="card-category">Page {{ pagination.currentPage }} of {{ maxPage }}</p>
                        </div>
                        <pagination
                            v-model="pagination.currentPage"
                            class="pagination-no-border pagination-success"
                            :per-page="pagination.perPage"
                            :total="total"
                            @change-page="handleChangePage($event, pagination.perPage)"
                        />
                    </md-card-actions>
                </div>
                <div v-if="cubicRatelist.length == 0 && !isListLoading" class="text-center">
                    <p class="no-result-message">No Rates Created</p>
                    <div>
                        <button class="custom-btn" @click="createNewRate">
                            Add New Rule
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="body-list">
                <div class="content-loader">
                    <fade-loader :loading="isListLoading" color="#333333" />
                    <span>LOADING</span>
                </div>
            </div>
        </md-card-content>
    </div>
</template>

<script>
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { handleRequests, showErrorMessage, formatCondition, formatOperator, checkForOperator } from '@/helpers';
import { PAGINATION_DEFAULTS } from '@/utils/defaults';
import { Pagination, SelectedDay } from '@/components';
import { mapGetters } from 'vuex';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { RATE_CHARGING_TYPES, RATES_DAYS_OF_THE_WEEK } from '@/utils/constants';
import CreateCubicRuleContent from '@/pages/Rates/ModalComponents/CreateCubicRuleContent';
import CreateConditionRuleContent from '@/pages/Rates/ModalComponents/CreateConditionRuleContent';

export default {
    name: 'RatesVolumeDetails',
    components: {
        Pagination,
        FadeLoader,
        SelectedDay
    },
    props: {
        customerId: {
            type: Number,
            default: null
        },
        rateId: {
            type: Number,
            default: null
        },
        hideConditionRules: {
            type: Boolean,
            default: false
        },
        rateZones: {
            type: Array,
            default: () => []
        },
        driverUserId: {
            type: Number,
            default: null
        },
        driverRuleCreation: {
            type: Boolean,
            default: false
        }
    },
    mixins: [GeneralMixin],
    data() {
        return {
            pagination: PAGINATION_DEFAULTS,
            cubicRatelist: [],
            maxPage: 1,
            loading: true,
            isListLoading: true,
            rateChargingTypes: RATE_CHARGING_TYPES,
            currency: null,
            daysOfWeek: [...RATES_DAYS_OF_THE_WEEK]
        };
    },
    watch: {
        rateId(newValue, oldValue) {
            this.updateRateList();
        },
        customerId(newValue, oldValue) {
            this.updateRateList();
        },
        driverUserId(newValue, oldValue) {
            this.updateRateList();
        }
    },
    async mounted() {
        let currentPage = 1;
        const { type } = this.$route.params;

        if (type === 'volume') {
            currentPage = Number(this.$route.query.currentPage) || 1;
        }

        if (currentPage) {
            this.pagination.currentPage = currentPage;
        }

        await this.updateRateList(currentPage);
    },
    methods: {
        async updateRateList(currentPage = 1) {
            const {
                data: { rateList, totalItems, currency }
            } = await this.getRateList(
                this.customerId,
                this.rateId,
                this.driverUserId,
                this.driverRuleCreation,
                currentPage
            );

            this.setRatesAndTotal(rateList, totalItems, currency);
        },
        formatPercentage(data) {
            if (data == null) 
                return '';

            return `${data}%`;
        },
        async getRateList(
            customerId = null,
            rateId = null,
            driverUserId = null,
            driverRuleCreation = null,
            pageNumber = 1,
            totalItems = 50,
            type = 'Volume'
        ) {
            const chargeType = this.rateChargingTypes.find((x) => x.key === 'Volume');
            const rateType = chargeType.type;

            let response = {
                data: {
                    rateList: [],
                    totalItems: null
                }
            };

            try {
                this.isListLoading = true;
                const endpoint = '/api/rates/rate';

                response = await handleRequests(endpoint, {
                    params: {
                        rateType,
                        pageNumber,
                        totalItems,
                        customerId,
                        rateId,
                        driverUserId,
                        driverRuleCreation
                    }
                });
            } catch (error) {
                const message = 'Error in getting Levy Rates';
                showErrorMessage(this, message, null);
            }
            this.isListLoading = false;
            return response;
        },
        async recordAddedHandlePageRefresh() {
            await this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
        },
        async handleChangePage(currentPage = 1, perPage = 50) {
            this.$router.replace({ path: this.$route.path, query: { currentPage } });
            const {
                data: { rateList, totalItems, currency }
            } = await this.getRateList(
                this.customerId,
                this.rateId,
                this.driverUserId,
                this.driverRuleCreation,
                currentPage,
                perPage
            );

            this.setRatesAndTotal(rateList, totalItems, currency);
            this.pagination.currentPage = currentPage;
            this.pagination.perPage = perPage;
        },
        setRatesAndTotal(data, total, currency) {
            this.cubicRatelist = data;
            this.pagination.total = total || data.length;
            this.currency = currency;
            this.maxPage = Math.ceil(this.pagination.total / this.pagination.perPage);
        },
        rateListChanged(itemChanged) {},
        async onEnd(list) {
            const obj = {
                rateList: list
            };

            const endpoint = '/api/rates/update';
            const payload = {
                method: 'post',
                data: obj
            };

            try {
                const response = await handleRequests(endpoint, payload);

                if (response.status === 200) {
                    this.$notify({
                        message: 'Successfully updated Priority.',
                        type: 'success'
                    });
                } else if (response.status === 204) {
                    //
                } else {
                    showErrorMessage(this, 'Failed updating priority.', null);
                }
            } catch (error) {
                showErrorMessage(this, error.data, null);
            }
        },
        validateDeleteRate(rateRuleId) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Rate',
                    body: 'Are you sure you want to delete this Rule?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.handleDeleteRate(rateRuleId);
                    }
                });
        },
        async handleDeleteRate(rateRulesId) {
            const payload = {
                method: 'post'
            };
            const api = `/api/rates/disableRate/${rateRulesId}`;
            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Rate was deleted!',
                    type: 'success'
                });

                // If the item deleted was the last one, then move to the previous page.
                if ((this.pagination.currentPage - 1) * this.pagination.perPage === this.pagination.total - 1) {
                    this.pagination.currentPage -= 1;
                    if (this.pagination.currentPage < 1) 
                        this.pagination.currentPage = 1;
                }

                this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
            } catch (e) {
                const message = 'Error in deleting a rate.';
                showErrorMessage(this, message, e);
                this.isListLoading = false;
            }
        },
        async updateRate(rateRulesId) {
            const rateDetail = await this.getRateRulesDetails(rateRulesId);

            this.$modal
                .show(CreateCubicRuleContent, {
                    rateDetail,
                    isUpdate: true
                })
                .then(({ record }) => {
                    if (record === 200) {
                        this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                        this.$modal.hide();
                    }
                });
        },
        async getRateRulesDetails(rateRulesId) {
            try {
                const payload = {
                    method: 'get'
                };
                const enpoint = `/api/rates/${rateRulesId}`;
                const { data } = await handleRequests(enpoint, payload);

                return data;
            } catch (error) {
                const message = 'Error in getting the rate details';
                showErrorMessage(this, message, error);
                return null;
            }
        },
        async openCreateCondition(rateRulesId) {
            const rateDetail = await this.getRateRulesDetails(rateRulesId);

            this.$modal
                .show(CreateConditionRuleContent, {
                    isUpdate: false,
                    rateRuleId: rateRulesId,
                    rateDetail,
                    rateZones: this.rateZones
                })
                .then(({ record }) => {
                    this.handleChangePage(this.pagination.currentPage, this.pagination.perPage);
                    this.$modal.hide();
                });
        },
        formatConditionData(data) {
            return formatCondition(data, this.rateZones);
        },
        formatOperatorData(data) {
            return formatOperator(data);
        },
        checkForOperatorData(data) {
            return checkForOperator(data);
        },
        createNewRate() {
            this.$modal
                .show(CreateCubicRuleContent, {
                    rateDetail: { ...this.rateDetail },
                    listLength: this.cubicRatelist.length,
                    customerId: this.customerId,
                    rateGroupId: this.rateId,
                    driverRuleCreation: this.driverRuleCreation,
                    driverUserId: this.driverUserId
                })
                .then(({ record }) => {
                    this.recordAddedHandlePageRefresh();
                    this.$modal.hide();
                });
        },
        selectedDay(label, length, dayIndex) {
            const index = dayIndex + 1;
            let text = label;
            if (Number(length) !== Number(index)) {
                text = ` ${text}-`;
            }
            return text;
        }
    },
    computed: {
        currentPageMax() {
            const highBound = this.currentPageMin + this.pagination.perPage;
            return this.total < highBound ? this.total : highBound;
        },
        currentPageMin() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.pagination.total;
        },
        totalPages() {
            if (this.total > 0) {
                return Math.ceil(this.total / this.pagination.perPage);
            }
            return 1;
        },
        ...mapGetters({
            user: 'user/user'
        })
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-table {
    .drag-icon {
        width: 5%;
    }
}

.drag-item {
    cursor: grab;
}

.custom-draggable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.action-class {
    width: 150px;
}

.add-button {
    float: right !important;
}
</style>
