import Router from 'vue-router';

import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout';
import AuthLayout from '@/pages/Dashboard/Pages/AuthLayout';

// Map Pages
import MapOverview from '@/pages/Map/MapOverview';

// Stops Pages
import StopsOverview from '@/pages/Stops/StopsOverview';
import StopDetails from '@/pages/Stops/StopDetails';
import StopSearch from '@/pages/Stops/StopSearch';

// Trips Pages
import TripsOverview from '@/pages/Trips/TripsOverview';
import TripDetails from '@/pages/Trips/TripDetails';

// Shipment Pages
import ShipmentDetails from '@/pages/Shipments/ShipmentDetail';
import ShipmentOverview from '@/pages/Shipments/ShipmentOverview';

// stops schedulers
import StopsScheduler from '@/pages/Scheduler/StopsScheduler';

// Reports
import LocationHistoryReport from '@/pages/Reports/LocationHistoryReport';
import DistanceReport from '@/pages/Reports/DistanceReport';
import StopReport from '@/pages/Reports/StopReport';
import IgnitionReport from '@/pages/Reports/IgnitionReport';
import SafetyReport from '@/pages/Reports/SafetyReport';
import SafetySummaryReport from '@/pages/Reports/SafetySummaryReport';
import SpeedingReport from '@/pages/Reports/SpeedingReport';
import GeofenceReport from '@/pages/Reports/GeofenceReport';
import GeofenceTimesheetReport from '@/pages/Reports/GeofenceTimesheetReport';
import DriverPaymentSummary from '@/pages/Reports/DriverPaymentSummary';
import StopRatingReport from '@/pages/Reports/StopRatingReport';

// Billing
import PaymentSuccess from '@/pages/Billing/PaymentSuccess';
import Pricing from '@/pages/Billing/Pricingv2';

// Home
import HomeDashboard from '@/pages/Home/Dashboard';
// Pages
// import User from "@/pages/Dashboard/Pages/UserProfile.vue";

// import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import Login from '@/pages/Dashboard/Pages/Login';
import Callback from '@/pages/Dashboard/Pages/Callback';
import Register from '@/pages/Dashboard/Pages/Register';
// import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Team members
import TeamMemberList from '@/pages/TeamMembers/TeamMemberList';
import TeamMemberDetails from '@/pages/TeamMembers/TeamMemberDetails';
import TeamProfile from '@/pages/TeamMembers/TeamProfile';

// Team Settings
import TeamSettings from '@/pages/TeamSettings/TeamSettings';

// Team Regions
import TeamRegionsOverview from '@/pages/TeamRegions/TeamRegionsOverview';

// Team Carriers
import TeamCarriersOverview from '@/pages/TeamCarriers/TeamCarriersOverview';

import TeamInviteLayout from '@/pages/Dashboard/Pages/TeamInvite';

import UserProfile from '@/pages/User/UserProfile';
import UserSettings from '@/pages/User/UserSettings';

import AssetDashboard from '@/pages/AssetTracking/AssetDashboard';

// Mobile layouts
import MobileStopOverview from '@/pages-mobile/Stops/StopOverviewMobile';
import MobileWelcomeDashboard from '@/pages-mobile/Dashboard/WelcomeDashboardMobile';
import MobileTripOverview from '@/pages-mobile/Trips/TripOverviewMobile';
import MobileShipmentOverview from '@/pages-mobile/Shipments/ShipmentsOverviewMobile';

// Run Schedules Pages
import RunsOverview from '@/pages/RunSchedules/RunsOverview';
import RunScheduleDetails from '@/pages/RunSchedules/RunScheduleDetails';

import Demo from '@/pages/Reports/Demo';

import Widgets from '@/pages/Dashboard/Widgets';

import CustomerOverview from '@/pages/Customer/CustomerOverview';

import WarehousesOverview from '@/pages/Warehouses/WarehousesOverview';

// Inventory Pages
import InventoryOverview from '@/pages/Inventory/InventoryOverview';
import InventoryDetails from '@/pages/Inventory/InventoryDetails';

// Team member Invoices pages
import TeamMemberInvoicesOverview from '@/pages/TeamMemberInvoices/TeamMemberInvoicesOverview';
import TeamMemberInvoicesDetails from '@/pages/TeamMemberInvoices/TeamMemberInvoicesDetails';

// Customer Invoices pages
import CustomerInvoicesOverview from '@/pages/CustomerInvoices/CustomerInvoicesOverview';
import CustomerInvoicesDetails from '@/pages/CustomerInvoices/CustomerInvoicesDetails';

// Invoice Adjustment Rules
import InvoiceAdjustmentRuleOverview from '@/pages/InvoiceAdjustmentRule/InvoiceAdjustmentRuleOverview';

// Services
import ServicesOverview from '@/pages/Services/ServicesOverview';

import { isMobile, isMobileOnly } from 'mobile-device-detect';

// Asset Managements
import AssetDetails from '@/pages/AssetTracking/AssetDetails';
import AssetGroupDetails from '@/pages/AssetTracking/AssetGroupDetails';

import Marketplace from '@/pages/CarrierMarketplace/Marketplace';

import MaintenanceScheduleOverview from '@/pages/Maintenance/MaintenanceScheduleOverview';
import UpcomingMaintenanceSchedule from '@/pages/Maintenance/UpcomingMaintenanceSchedule';

// Rates
import RatesDashboard from '@/pages/Rates/RatesDashboard';
import RateZonesOverview from '@/pages/TeamSettings/RateZones/RateZonesOverview';

// Dispatch Dashboard
import DispatchDashboard from '@/pages/DispatchDashboard/DispatchDashboard';

// Components pages
// import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
// import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
// import Panels from "@/pages/Dashboard/Components/Panels.vue";
// import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
// import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
// import Icons from "@/pages/Dashboard/Components/Icons.vue";
// import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
// import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
// import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
// import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
// import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
// import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
// import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
// import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Charts
// import Charts from '@/pages/Dashboard/Charts';
// import Widgets from '@/pages/Dashboard/Widgets';

// Dashboard
// import Dashboard from '@/pages/Dashboard/Dashboard';

// Optimise Stops
import BatchStops from '@/pages/BatchStop/OptimiseStops';
import OptimiseStops from '@/pages/OptimiseStops/OptimiseStops';

// Onboarding
import GettingStarted from '@/pages/Onboarding/GettingStarted';

// settings Menu
import GeneralSettings from '@/pages/TeamSettings/GeneralSettings';
import CustomFields from '@/pages/TeamSettings/CustomFields';
import StopCustomFieldsList from '@/pages/TeamSettings/StopCustomFieldsList';
import ShipmentCustomFieldsList from '@/pages/TeamSettings/ShipmentCustomFieldsList';
import AssetCustomFieldsList from '@/pages/TeamSettings/AssetCustomFieldsList';
import SkillList from '@/pages/TeamSettings/SkillList';
import SmsSettings from '@/pages/TeamSettings/SmsSettings';
import Apps from '@/pages/TeamSettings/Apps';
import GeofenceEventsSettings from '@/pages/TeamSettings/GeofenceEventsSettings';
import CapacitySettings from '@/pages/TeamSettings/CapacitySettings';
import QuestionsList from '@/pages/TeamSettings/QuestionsList';
import ImportMappings from '@/pages/TeamSettings/ImportMappings';
import Accounts from '@/pages/TeamSettings/Accounts';
import CustomActionSettings from '@/pages/TeamSettings/CustomActions/CustomActionSettings';
import BrandSettings from '@/pages/TeamSettings/Brands/Brands';

import AppPermissionSettings from '@/pages/TeamSettings/AppPermissionSettings';
import BillingDashboard from '@/pages/TeamSettings/BillingDashboard';

import JobOfferSettingsPage from '@/pages/TeamSettings/JobOffers/JobOfferSettingsPage';
import ServiceAreasOverview from '@/pages/TeamSettings/ServiceAreas/ServiceAreasOverview';
import DriverInstructions from '@/pages/TeamSettings/DriverInstructions';
import ShipmentContents from '@/pages/TeamSettings/ShipmentContents';

import ProcessCharges from '@/pages/ProcessCharges/ProcessChargesList';
import CreateTripPage from '@/pages/Trips/CreateTripPage';

import { handleRequests } from '@/helpers';
import { store } from '@/store/store';

import ProofOfDeliveryTemplateParent from '@/pages/TeamSettings/ProofOfDelivery/ProofOfDeliveryTemplateParent';
import ProofOfDeliveryTemplatePage from '@/pages/TeamSettings/ProofOfDelivery/ProofOfDeliveryTemplatePage';
import ProofOfDeliveryTemplateListPage from '@/pages/TeamSettings/ProofOfDelivery/ProofOfDeliveryTemplateListPage';

import FailedDeliveryConfiguration from '@/pages/TeamSettings/FailedDeliveryConfiguration';

const mapsMenu = {
    path: '/map',
    component: MapOverview,
    name: 'Maps Overview',
    meta: {
        isSecure: true,
        hideFooter: true,
        hideTopbar: true,
        isMap: true,
        title: 'Map'
    },
    children: [
        {
            path: ':date',
            name: 'Maps Overview Selected Date',
            components: {
                default: MapOverview
            },
            meta: {
                isSecure: true,
                hideFooter: true,
                hideTopbar: true,
                isMap: true,
                title: 'Map'
            },
            children: [
                {
                    path: ':group',
                    name: 'Maps Overview Active Panel',
                    components: {
                        default: MapOverview
                    },
                    meta: {
                        isSecure: true,
                        hideFooter: true,
                        hideTopbar: true,
                        isMap: true,
                        title: 'Map'
                    }
                },
                {
                    path: 'user/:userId',
                    name: 'Maps Overview Active User Panel',
                    components: {
                        default: MapOverview
                    },
                    meta: {
                        isSecure: true,
                        hideFooter: true,
                        hideTopbar: true,
                        isMap: true,
                        title: 'Map'
                    }
                }
            ]
        }
    ]
};

const stopsMenu = {
    path: '/stops',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Stops'
    },
    redirect: '/stops/list',
    name: 'Stops',
    children: [
        {
            path: 'list',
            name: 'Stops Overview',
            components: {
                default: !isMobileOnly ? StopsOverview : MobileStopOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Stops'
            },
            children: [
                {
                    path: ':userId',
                    name: 'Stops List Filter',
                    components: {
                        default: !isMobileOnly ? StopsOverview : MobileStopOverview
                    },
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        title: 'Stops'
                    }
                }
            ]
        },
        {
            path: 'search',
            name: 'Stops Search',
            components: {
                default: StopSearch
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Stop Search'
            }
        },
        {
            path: 'calendar',
            name: 'Calendar',
            components: {
                default: StopsScheduler
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Stops Calendar'
            },
            children: [
                {
                    path: ':type',
                    name: 'Stops Calendar',
                    components: {
                        default: StopsScheduler
                    },
                    meta: {
                        hideFooter: true,
                        hideTopbar: true
                    }
                }
            ]
        },
        {
            path: 'details/:stopId',
            name: 'Stop Details',
            components: {
                default: StopDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const tripsMenu = {
    path: '/trips',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Trips'
    },
    redirect: '/trips/list',
    name: 'Trips',
    children: [
        {
            path: 'create',
            name: 'Create Trip',
            components: {
                default: CreateTripPage
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Create Trip'
            }
        },
        {
            path: 'list',
            name: 'Trips Overview',
            components: {
                default: !isMobileOnly ? TripsOverview : MobileTripOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Trips'
            },
            children: [
                {
                    path: ':date',
                    name: 'Trip List Selected Date',
                    components: {
                        default: TripsOverview
                    },
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        title: 'Trips'
                    }
                }
            ]
        },
        {
            path: 'details/:tripId',
            name: 'Trip Details',
            components: {
                default: TripDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Trip Details'
            }
        }
    ]
};

const CustomerMenu = {
    path: '/customers',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Customer List'
    },
    redirect: '/customers/list',
    name: 'Customers',
    children: [
        {
            path: 'list',
            name: 'Customers List',
            components: {
                default: CustomerOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Customer List'
            }
        }
    ]
};

const warehousesMenu = {
    path: '/warehouses',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Warehouses List'
    },
    redirect: '/warehouses/list',
    name: 'Warehouses',
    children: [
        {
            path: 'list',
            name: 'Warehouses List',
            components: {
                default: WarehousesOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Warehouses List'
            }
        }
    ]
};

const inventoryMenu = {
    path: '/inventory',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Inventory List'
    },
    redirect: '/inventory/list',
    name: 'Inventory',
    children: [
        {
            path: 'list',
            name: 'Inventory List',
            components: {
                default: InventoryOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Inventory List'
            }
        },
        {
            path: 'details/:itemId',
            name: 'Inventory Details',
            components: {
                default: InventoryDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const teamMemberInvoicesMenu = {
    path: '/team-member-invoices',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Team Member Invoices List'
    },
    redirect: '/team-member-invoices/list',
    name: 'TeamMemberInvoices',
    children: [
        {
            path: 'list',
            name: 'Team Member Invoices List',
            components: {
                default: TeamMemberInvoicesOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Team Member Invoices List'
            }
        },
        {
            path: 'details/:invoiceId',
            name: 'Team Member Invoice Details',
            components: {
                default: TeamMemberInvoicesDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const customerInvoicesMenu = {
    path: '/customer-invoices',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Customer Invoices List'
    },
    redirect: '/customer-invoices/list',
    name: 'CustomerInvoices',
    children: [
        {
            path: 'list',
            name: 'Customer Invoices List',
            components: {
                default: CustomerInvoicesOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Customer Invoices List'
            }
        },
        {
            path: 'details/:invoiceId',
            name: 'Customer Invoice Details',
            components: {
                default: CustomerInvoicesDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const invoiceAdjustmentRulesMenu = {
    path: '/invoice-adjustments',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Invoice Adjustment Rules List'
    },
    redirect: '/invoice-adjustments/list',
    name: 'In',
    children: [
        {
            path: 'list',
            name: 'Invoice Adjustment Rules List',
            components: {
                default: InvoiceAdjustmentRuleOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Invoice Adjustment Rules List'
            }
        }
    ]
};

const shipmentsMenu = {
    path: '/shipments',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Shipments'
    },
    redirect: '/shipments/list',
    name: 'Shipments',
    children: [
        {
            path: 'list',
            name: 'Shipment List',
            components: {
                default: !isMobileOnly ? ShipmentOverview : MobileShipmentOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'details/:shipmentId',
            name: 'Shipment Details',
            components: {
                default: ShipmentDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: ':shipmentId/dispatch',
            name: 'Dispatch Dashboard',
            components: {
                default: DispatchDashboard
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                isMap: true
            }
        }
    ]
};

const marketPlace = {
    path: '/marketplace',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Marketplace'
    },
    redirect: '/marketplace/apps',
    name: 'Marketplace',
    children: [
        {
            path: 'apps',
            name: 'Marketplace apps',
            components: {
                default: Marketplace
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const processChargesMenu = {
    path: '/processcharges',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Process Charges'
    },
    redirect: '/processcharges/list',
    name: 'processChargesMenu',
    children: [
        {
            path: 'list',
            name: 'Charges',
            components: {
                default: ProcessCharges
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Charges'
            }
        }
    ]
};

const teamMembersMenu = {
    path: '/team',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/team/team-members',
    name: 'Team Member List',
    children: [
        {
            path: 'team-members/:publicUserId',
            name: 'Team Member Profile',
            components: {
                default: TeamMemberDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'profile',
            name: 'Team Profile',
            components: {
                default: TeamProfile
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Team Profile'
            }
        },
        {
            path: 'carriers',
            name: 'Carriers',
            components: {
                default: TeamCarriersOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Carriers'
            }
        },
        {
            path: 'services',
            name: 'Services',
            components: {
                default: ServicesOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Services'
            }
        },
        {
            path: 'settings',
            name: 'Team Settings',
            components: {
                default: TeamSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'run-schedules',
            name: 'Runs Schedules',
            components: {
                default: RunsOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Runs Schedules'
            }
        },
        {
            path: 'team-regions',
            name: 'Team Regions',
            components: {
                default: TeamRegionsOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Team Regions'
            }
        },
        {
            path: ':type',
            name: 'Team Members',
            components: {
                default: TeamMemberList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'run-schedule-details/:runScheduleId',
            name: 'Run Schedule Details',
            components: {
                default: RunScheduleDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Run Schedule Details'
            }
        }
    ]
};

const invitePage = {
    path: '/invite/:invitecode',
    component: TeamInviteLayout,
    name: 'Team Invite'
};

const authPages = {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/callback',
            name: 'Callback',
            component: Callback
        }
    ]
};

const userProfile = {
    path: '/profile',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/profile/view',
    name: 'UserProfile',
    children: [
        {
            path: 'view',
            name: 'User Profile',
            components: {
                default: UserProfile
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'settings',
            name: 'User Settings',
            components: {
                default: UserSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const assetTrackingMenu = {
    path: '/assets',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/assets/overview',
    name: 'AssetDashboard',
    children: [
        {
            path: 'overview',
            name: 'Asset Dashboard',
            components: {
                default: AssetDashboard
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            },
            children: [
                {
                    path: ':type',
                    name: 'Asset Dashboard Type',
                    components: {
                        default: AssetDashboard
                    },
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        title: 'Asset Dashboard'
                    }
                }
            ]
        },
        {
            path: 'details/:assetId',
            name: 'Asset Details',
            components: {
                default: AssetDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'group/details/:assetGroupId',
            name: 'Asset Group Details',
            components: {
                default: AssetGroupDetails
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const maintenanceMenu = {
    path: '/maintenance',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/maintenance/overview',
    name: 'MaintenanceDashboard',
    children: [
        {
            path: 'schedules',
            name: 'Maintenance Schedule Dashboard',
            components: {
                default: MaintenanceScheduleOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'upcoming',
            name: 'Upcoming Maintenance Schedule',
            components: {
                default: UpcomingMaintenanceSchedule
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const locationHistoryReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/location-history-report',
    name: 'LocationHistoryReport',
    children: [
        {
            path: 'location-history-report',
            name: 'Location History Report',
            components: {
                default: LocationHistoryReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const stopReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/stop-report',
    name: 'StopReport',
    children: [
        {
            path: 'stop-report',
            name: 'Stop Report',
            components: {
                default: StopReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const distanceReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/distance-report',
    name: 'DistanceReport',
    children: [
        {
            path: 'distance-report',
            name: 'Distance Report',
            components: {
                default: DistanceReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const ignitionReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/ignition-report',
    name: 'IgnitionReport',
    children: [
        {
            path: 'ignition-report',
            name: 'Ignition Report',
            components: {
                default: IgnitionReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const safetyReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/safety-report',
    name: 'SafetyReport',
    children: [
        {
            path: 'safety-report',
            name: 'Safety Report',
            components: {
                default: SafetyReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const safetySummaryReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/safety-summary-report',
    name: 'SafetySummaryReport',
    children: [
        {
            path: 'safety-summary-report',
            name: 'Safety Summary Report',
            components: {
                default: SafetySummaryReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const speedingReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/speeding-report',
    name: 'SpeedingReport',
    children: [
        {
            path: 'speeding-report',
            name: 'Speeding Report',
            components: {
                default: SpeedingReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const geofenceReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/geofence-report',
    name: 'GeofenceReport',
    children: [
        {
            path: 'geofence-report',
            name: 'Geofence Report',
            components: {
                default: GeofenceReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const geofenceTimesheetReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/geofence-timesheet',
    name: 'GeofenceTimesheet',
    children: [
        {
            path: 'geofence-timesheet',
            name: 'Geofence Timesheet',
            components: {
                default: GeofenceTimesheetReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const driverPaymentSummary = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/driver-payment-summary',
    name: 'DriverPaymentSummary',
    children: [
        {
            path: 'driver-payment-summary',
            name: 'Driver Payment Summary',
            components: {
                default: DriverPaymentSummary
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const stopRatingReport = {
    path: '/reports',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/reports/stop-rating-report',
    name: 'StopRatingReport',
    children: [
        {
            path: 'stop-rating-report',
            name: 'Stop Rating Report',
            components: {
                default: StopRatingReport
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const paymentSuccess = {
    path: '/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/payment-success',
    name: 'PaymentSuccess',
    children: [
        {
            path: 'payment-success',
            name: 'Payment Success',
            components: {
                default: PaymentSuccess
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const pricing = {
    path: '/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/pricing',
    name: 'PricingPlans',
    beforeEnter(to, from, next) {
        if (to.params.token) {
            window.open(`https://locate2u.recurly.com/account/${to.params.token}`, '_blank');
        } else if (to.query.token) {
            window.open(`https://locate2u.recurly.com/account/${to.query.token}`, '_blank');
        } else 
            next();
    },
    children: [
        {
            path: 'pricing',
            name: 'Pricing',
            components: {
                default: Pricing
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const homeDashboard = {
    path: '/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/dashboard',
    name: 'HomeDashboard',
    children: [
        {
            path: 'dashboard',
            name: 'Home Dashboard',
            components: {
                default: !isMobileOnly ? HomeDashboard : MobileWelcomeDashboard
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Dashboard'
            }
        }
    ]
};

const batchStops = {
    path: '/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/optimise',
    name: 'Optimise',
    children: [
        {
            path: 'optimise',
            name: 'Optimise Stops',
            components: {
                default: BatchStops
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                isOptimise: true
            }
        }
    ]
};

const optimiseStops = {
    path: '/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/optimise-stops',
    name: 'New Optimise',
    children: [
        {
            path: 'optimise-stops',
            name: 'New Optimise Stops',
            components: {
                default: OptimiseStops
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                isOptimise: true
            }
        }
    ]
};

const demo = {
    path: '/demo',
    component: Demo,
    name: 'Demo',
    meta: {
        isSecure: true,
        shouldLoginAutomatically: true
    }
};

const onboarding = {
    path: '/support/',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    redirect: '/support/onboarding',
    name: 'Onboarding',
    children: [
        {
            path: 'onboarding',
            name: 'Getting Started',
            components: {
                default: GettingStarted
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                isOptimise: true
            }
        }
    ]
};

const settingsMenu = {
    path: '/settings',
    component: DashboardLayout,
    meta: {
        isSecure: true
    },
    name: 'settings menu',
    children: [
        {
            path: 'general-settings',
            name: 'General Settings',
            components: {
                default: GeneralSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'custom-fields',
            name: 'Custom Fields',
            components: {
                default: CustomFields
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'stop-custom-fields',
            name: 'Stop Custom Fields',
            components: {
                default: StopCustomFieldsList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'shipment-custom-fields',
            name: 'Shipment Custom Fields',
            components: {
                default: ShipmentCustomFieldsList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'asset-custom-fields',
            name: 'Asset Custom Fields',
            components: {
                default: AssetCustomFieldsList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'skills',
            name: 'Skills',
            components: {
                default: SkillList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'sms-settings',
            name: 'SMS Settings',
            components: {
                default: SmsSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'proof-of-delivery-configuration',
            name: 'Proof Of Delivery Configuration',
            component: ProofOfDeliveryTemplateParent,
            redirect: 'proof-of-delivery-configuration/list',
            children: [
                {
                    path: 'list',
                    name: 'Proof Of Delivery Template List',
                    component: ProofOfDeliveryTemplateListPage,
                    meta: {
                        hideFooter: true,
                        hideTopbar: true
                    }
                },
                {
                    path: 'create',
                    name: 'Create Proof Of Delivery Template',
                    component: ProofOfDeliveryTemplatePage,
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        mode: 'create'
                    }
                },
                {
                    path: ':podTemplateId',
                    name: 'Edit Proof Of Delivery Template',
                    component: ProofOfDeliveryTemplatePage,
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        mode: 'edit'
                    }
                }
            ]
        },
        {
            path: 'apps',
            name: 'Apps',
            components: {
                default: Apps
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'geofence-events-settings',
            name: 'Geofence Events Settings',
            components: {
                default: GeofenceEventsSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'capacity-settings',
            name: 'Capacity Settings',
            components: {
                default: CapacitySettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'questions',
            name: 'Questions',
            components: {
                default: QuestionsList
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'import-mappings',
            name: 'Import Mappings',
            components: {
                default: ImportMappings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'accounts',
            name: 'Accounts',
            components: {
                default: Accounts
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'custom-actions',
            name: 'Custom Actions',
            components: {
                default: CustomActionSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'job-offers',
            name: 'Job Offer Settings',
            components: {
                default: JobOfferSettingsPage
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            },
            children: [
                {
                    path: ':teamRegionId',
                    name: 'Job Offer Settings Team Region Level',
                    components: {
                        default: JobOfferSettingsPage
                    },
                    meta: {
                        isSecure: true,
                        hideFooter: true,
                        hideTopbar: true,
                        title: 'Job Offer Settings'
                    }
                }
            ]
        },
        {
            path: 'brands',
            name: 'Brands',
            components: {
                default: BrandSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'app-permission-settings',
            name: 'App Permission Settings',
            components: {
                default: AppPermissionSettings
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'billing-dashboard',
            name: 'Billing Dashboard',
            components: {
                default: BillingDashboard
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        },
        {
            path: 'service-areas',
            name: 'Service Areas',
            components: {
                default: ServiceAreasOverview
            },
            meta: {
                isSecure: true,
                hideFooter: true,
                hideTopbar: true,
                isMap: true,
                title: 'Service Areas'
            }
        },
        {
            path: 'driver-instructions',
            name: 'Driver Instructions',
            components: {
                default: DriverInstructions
            },
            meta: {
                isSecure: true,
                hideFooter: true,
                hideTopbar: true,
                isMap: true,
                title: 'Driver Instructions'
            }
        },
        {
            path: 'shipment-contents',
            name: 'Shipment Contents',
            components: {
                default: ShipmentContents
            },
            meta: {
                isSecure: true,
                hideFooter: true,
                hideTopbar: true,
                isMap: true,
                title: 'Shipment Contents'
            }
        },
        {
            path: 'rate-zones',
            name: 'Rate Zones',
            components: {
                default: RateZonesOverview
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                isMap: true
            }
        },
        {
            path: 'failed-delivery-configuration',
            name: 'Failed Delivery Configuration',
            components: {
                default: FailedDeliveryConfiguration
            },
            meta: {
                hideFooter: true,
                hideTopbar: true
            }
        }
    ]
};

const ratesMenu = {
    path: '/rates',
    component: DashboardLayout,
    meta: {
        isSecure: true,
        title: 'Rates'
    },
    redirect: '/rates/list',
    name: 'RatesDashboard',
    children: [
        {
            path: 'list',
            name: 'Rate',
            components: {
                default: RatesDashboard
            },
            meta: {
                hideFooter: true,
                hideTopbar: true,
                title: 'Rates'
            },
            children: [
                {
                    path: ':type',
                    name: 'Rate Dashboard Type',
                    components: {
                        default: RatesDashboard
                    },
                    meta: {
                        hideFooter: true,
                        hideTopbar: true,
                        title: 'Rate Dashboard'
                    }
                }
            ]
        }
    ]
};

const routes = [
    {
        path: '/',
        // eslint-disable-next-line no-nested-ternary
        redirect: !isMobile ? '/dashboard' : isMobileOnly ? '/dashboard' : '/stops',
        name: 'Home'
    },
    {
        path: '/w',
        component: Widgets
    },
    homeDashboard,
    // mapsMenu,
    stopsMenu,
    tripsMenu,
    CustomerMenu,
    warehousesMenu,
    inventoryMenu,
    teamMemberInvoicesMenu,
    customerInvoicesMenu,
    invoiceAdjustmentRulesMenu,
    shipmentsMenu,
    teamMembersMenu,
    userProfile,
    locationHistoryReport,
    distanceReport,
    stopReport,
    ignitionReport,
    safetyReport,
    safetySummaryReport,
    speedingReport,
    geofenceReport,
    geofenceTimesheetReport,
    batchStops,
    stopRatingReport,
    optimiseStops,
    authPages,
    invitePage,
    demo,
    paymentSuccess,
    pricing,
    assetTrackingMenu,
    onboarding,
    maintenanceMenu,
    {
        path: '/',
        component: DashboardLayout,
        meta: {
            isSecure: true
        },
        children: [mapsMenu]
    },
    settingsMenu,
    marketPlace,
    ratesMenu,
    processChargesMenu,
    driverPaymentSummary
];

const router = new Router({
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior: (to, from, savedPosition) => {
        // scroll to top of page
        const layoutParent = document.querySelector('.layout-parent');

        if (layoutParent) 
            layoutParent.scrollIntoView();

        return { x: 0, y: 0 };
    },
    linkActiveClass: 'nav-item active' // active class for non-exact links like /trips/list/:date
});

router.beforeEach(async (to, from, next) => {
    router.app.$modal.hide();
    if (to.matched.some((record) => record.meta.isSecure)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        await window.auth.isLoggedIn().then(async (isLoggedIn) => {
            if (isLoggedIn) {
                let user = store.getters['user/user'];

                if (user == null) {
                    const api = '/api/users/me/profile';
                    const { data } = await handleRequests(api);
                    user = data;
                }
                const isCustomer = ['Customer', 'Customer Admin'].includes(user.role);
                // force User (but NOT Customer) with empty name to update their profile
                if (!isCustomer && (!user.fullName || user.fullName === ' ') && !to.path.includes('/profile')) {
                    next({ name: 'UserProfile' });
                } else if (isCustomer) {
                    if (
                        to.path.includes('/stops') ||
                        to.path.includes('/shipments') ||
                        to.path.includes('/map') ||
                        to.path.includes('/trips') ||
                        to.path.includes('/reports') ||
                        to.path.includes('/customer-invoices')
                    ) {
                        next();
                    } else {
                        next({
                            path: '/stops'
                        });
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (
                        to.path.includes('/assets') ||
                        to.path.includes('/optimise') ||
                        to.path.includes('/customers')
                    ) {
                        if (user.hasTeam) {
                            if (
                                user.role !== 'Team Owner' &&
                                user.role !== 'Team Manager' &&
                                user.role !== 'Office Staff' &&
                                user.role !== 'Warehouse Manager'
                            ) {
                                next({
                                    path: '/map'
                                });
                            } else {
                                next();
                            }
                        } else {
                            next();
                        }
                    } else if (to.path.includes('/team')) {
                        if (user.hasTeam) {
                            if (user.role === 'Team Owner') {
                                next();
                            } else if (user.role === 'Team Manager') {
                                if (to.path.includes('/settings')) {
                                    next({
                                        path: '/team/team-members'
                                    });
                                } else {
                                    next();
                                }
                            } else {
                                next({
                                    path: '/map'
                                });
                            }
                        } else if (to.name === 'Team Members' || to.name === 'Team Profile') {
                            // Individual users should not have access to team settings or other pages but should have access to team/team-members and team/team-profile
                            next();
                        } else {
                            next({
                                path: '/team/team-members'
                            });
                        }
                    } else if (to.path.includes('/settings')) {
                        if (user.role === 'Team Owner') {
                            next();
                        } else {
                            next({
                                path: '/map'
                            });
                        }
                    } else if (to.path === '/support/onboarding') {
                        // only allow team owners and single users to be able to access the onboarding page
                        if (user.role === 'Team Owner' || user.role === null) {
                            next();
                        } else {
                            next({
                                path: '/dashboard'
                            });
                        }
                    } else {
                        next();
                    }
                }
            } else if (to.matched.some((record) => record.meta.shouldLoginAutomatically)) {
                localStorage.setItem('invitationUrl', to.fullPath);
                window.auth.login();
            } else if (from.fullPath === to.fullPath) {
                // For some reason when we call in some pages the 'this.$router.replace' method, if we logout the rooter after it logs out it calls the beforeEach hook with the same from-to fullpath in the parameters.
                // Not sure of why but we can trap it and ensure that if the user is not loged in we don't navigate there.
                next(false);
            } else {
                window.auth.login();
            }
        });
    } else {
        next();
    }
});

export default router;
