var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _vm.selectedQuestion === null
          ? _c("span", [
              _vm._v("Add Question (" + _vm._s(_vm.selectedGroup) + ")"),
            ])
          : _vm._e(),
        _vm.selectedQuestion !== null
          ? _c("span", [_vm._v("Edit (" + _vm._s(_vm.selectedGroup) + ")")])
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-body questions-form-modal" }, [
      _c(
        "div",
        { staticClass: "stop-form-container" },
        [
          !_vm.isEditMode && _vm.isBookingGroup
            ? _c(
                "div",
                [
                  _c(
                    "form-group",
                    [
                      _c(
                        "md-radio",
                        {
                          attrs: { value: "Standard" },
                          model: {
                            value: _vm.selectedKind,
                            callback: function ($$v) {
                              _vm.selectedKind = $$v
                            },
                            expression: "selectedKind",
                          },
                        },
                        [_vm._v("Standard")]
                      ),
                      _c(
                        "md-radio",
                        {
                          attrs: { value: "Custom" },
                          model: {
                            value: _vm.selectedKind,
                            callback: function ($$v) {
                              _vm.selectedKind = $$v
                            },
                            expression: "selectedKind",
                          },
                        },
                        [_vm._v("Custom")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isEditMode &&
          _vm.isBookingGroup &&
          _vm.selectedKind === "Standard"
            ? [
                !_vm.questionStandardBookingOptions.length
                  ? _c("div", [_vm._v("No Standard questions available.")])
                  : _c(
                      "md-table",
                      {
                        staticClass:
                          "context-menu-support custom-paginated-table",
                      },
                      [
                        _c(
                          "md-table-row",
                          [
                            _c("md-table-head"),
                            _c("md-table-head", [_vm._v("Label")]),
                            _c("md-table-head", [_vm._v("Description")]),
                            _c("md-table-head", [_vm._v("Type")]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.questionStandardBookingOptions,
                          function (q, index) {
                            return _c(
                              "md-table-row",
                              { key: index },
                              [
                                _c(
                                  "md-table-cell",
                                  [
                                    _c("md-checkbox", {
                                      staticClass: "stop-checkbox",
                                      attrs: { value: q },
                                      model: {
                                        value: _vm.selectedStandardQuestions,
                                        callback: function ($$v) {
                                          _vm.selectedStandardQuestions = $$v
                                        },
                                        expression: "selectedStandardQuestions",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("md-table-cell", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(q.label) +
                                      "\n                        "
                                  ),
                                ]),
                                _c("md-table-cell", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(q.description) +
                                      "\n                        "
                                  ),
                                ]),
                                _c("md-table-cell", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(q.type) +
                                      "\n                            "
                                  ),
                                  q.options && q.options.length
                                    ? _c(
                                        "ul",
                                        _vm._l(q.options, function (opt, ndx) {
                                          return _c("li", { key: ndx }, [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(opt) +
                                                "\n                                "
                                            ),
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
              ]
            : [
                _c(
                  "form-wrapper",
                  {
                    staticClass: "form-wrapper",
                    attrs: { validator: _vm.$v.form },
                  },
                  [
                    _vm.isBookingGroup
                      ? _c(
                          "form-group",
                          { attrs: { label: "Name", name: "questionName" } },
                          [
                            _c("md-input", {
                              attrs: { disabled: _vm.isEditMode },
                              model: {
                                value: _vm.form.questionName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "questionName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.questionName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "form-group",
                      { attrs: { label: "Label", name: "label" } },
                      [
                        _c("md-input", {
                          attrs: { disabled: _vm.isBookingStandard },
                          model: {
                            value: _vm.form.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "label", $$v)
                            },
                            expression: "form.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "type" } },
                      [
                        _vm.questionTypes
                          ? _c("vue-select", {
                              attrs: {
                                options: _vm.questionTypes,
                                placeholder: "Type",
                                searchable: _vm.$root.isDesktop,
                                disabled: _vm.isBookingStandard,
                              },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.form.type === "Dropdown"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "md-layout md-medium-size-100 md-xsmall-size-100 md-size-100 options",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-layout-item md-size-100" },
                              [
                                _vm.isTextBoxOptions
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md-layout-item md-size-10 option-toggle-button option-toggle-button-list",
                                          },
                                          [
                                            _c(
                                              "md-button",
                                              {
                                                staticClass:
                                                  "md-primary md-just-icon md-round",
                                                on: {
                                                  click: _vm.toggleOptions,
                                                },
                                              },
                                              [
                                                _c("md-icon", [_vm._v("list")]),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "left",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Use list view to add option"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "label",
                                          { staticClass: "label-text" },
                                          [_vm._v("Available Options:")]
                                        ),
                                        _c(
                                          "form-group",
                                          { attrs: { name: "options" } },
                                          [
                                            _c("md-textarea", {
                                              attrs: { placeholder: "Option" },
                                              on: { change: _vm.addOptions },
                                              model: {
                                                value: _vm.optionValue,
                                                callback: function ($$v) {
                                                  _vm.optionValue = $$v
                                                },
                                                expression: "optionValue",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "form-group",
                                          { attrs: { name: "options" } },
                                          [
                                            _c("md-input", {
                                              attrs: { placeholder: "Option" },
                                              on: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.addOption($event)
                                                },
                                              },
                                              model: {
                                                value: _vm.optionValue,
                                                callback: function ($$v) {
                                                  _vm.optionValue = $$v
                                                },
                                                expression: "optionValue",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            !_vm.isTextBoxOptions
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-0 add-icon",
                                    on: { click: _vm.addOption },
                                  },
                                  [_c("md-icon", [_vm._v("add")])],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    this.form.options.length &&
                    !_vm.isTextBoxOptions &&
                    _vm.form.type === "Dropdown"
                      ? _c(
                          "div",
                          { staticClass: "options-table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-size-10 option-toggle-button",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass:
                                      "md-primary md-just-icon md-round",
                                    on: { click: _vm.toggleOptions },
                                  },
                                  [
                                    _c("md-icon", [_vm._v("launch")]),
                                    _c(
                                      "md-tooltip",
                                      { attrs: { "md-direction": "left" } },
                                      [_vm._v("Use text box field")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("label", [_vm._v("Available Options:")]),
                            _c(
                              "md-table",
                              _vm._l(
                                _vm.form.options,
                                function (option, index) {
                                  return _c(
                                    "md-table-row",
                                    { key: index },
                                    [
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "form-group",
                                            [
                                              _c("md-input", {
                                                model: {
                                                  value: option.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      option,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "option.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "md-table-cell",
                                        [
                                          _c(
                                            "md-button",
                                            {
                                              staticClass:
                                                "md-just-icon md-danger md-simple",
                                              attrs: {
                                                title: "delete option ",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeOption(index)
                                                },
                                              },
                                            },
                                            [_c("md-icon", [_vm._v("close")])],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "md-checkbox",
                          {
                            model: {
                              value: _vm.form.isRequired,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isRequired", $$v)
                              },
                              expression: "form.isRequired",
                            },
                          },
                          [_vm._v("Required")]
                        ),
                      ],
                      1
                    ),
                    ["proofofdelivery", "arrivedatstop"].includes(
                      _vm.selectedGroupNormalized
                    )
                      ? _c(
                          "div",
                          [
                            _c(
                              "md-checkbox",
                              {
                                model: {
                                  value: _vm.form.includeInPdf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "includeInPdf", $$v)
                                  },
                                  expression: "form.includeInPdf",
                                },
                              },
                              [_vm._v("Include in Proof Of Delivery PDF")]
                            ),
                          ],
                          1
                        )
                      : _vm.selectedGroupNormalized === "faileddelivery"
                      ? _c(
                          "div",
                          [
                            _c(
                              "md-checkbox",
                              {
                                model: {
                                  value: _vm.form.includeInPdf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "includeInPdf", $$v)
                                  },
                                  expression: "form.includeInPdf",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Include in Failed Delivery PDF\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    [
                      "Proof of Delivery",
                      "Failed Delivery",
                      "Trip Start",
                      "Trip End",
                    ].includes(_vm.selectedGroup)
                      ? _c(
                          "div",
                          [
                            _c(
                              "form-group",
                              {
                                staticClass: "group-filter",
                                attrs: { label: "Condition" },
                              },
                              [
                                _c(
                                  "md-select",
                                  {
                                    model: {
                                      value: _vm.form.conditions,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "conditions", $$v)
                                      },
                                      expression: "form.conditions",
                                    },
                                  },
                                  _vm._l(
                                    _vm.conditionsOptions,
                                    function (condition, index) {
                                      return _c(
                                        "md-option",
                                        {
                                          key: index,
                                          attrs: { value: condition.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(condition.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.form.conditions == "teamRegions"
                              ? _c(
                                  "div",
                                  { staticClass: "multiple-field-container" },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: _vm.teamRegionsOptions,
                                        multiple: true,
                                        "close-on-select": true,
                                        placeholder: "Pick team regions",
                                        label: "name",
                                        "track-by": "teamRegionId",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function (ref) {
                                              var option = ref.option
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      marginLeft:
                                                        _vm.getIndentation(
                                                          option
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          !option.$isDisabled
                                                            ? option.name
                                                            : option.selectedLabel
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        480993252
                                      ),
                                      model: {
                                        value: _vm.conditionsValue.teamRegions,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.conditionsValue,
                                            "teamRegions",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "conditionsValue.teamRegions",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "md-tooltip",
                                          {
                                            staticClass: "team-region-popup",
                                            attrs: {
                                              "md-direction": "right",
                                              "md-active":
                                                _vm.teamRegionPopUpActive,
                                            },
                                            on: {
                                              "update:mdActive": function (
                                                $event
                                              ) {
                                                _vm.teamRegionPopUpActive =
                                                  $event
                                              },
                                              "update:md-active": function (
                                                $event
                                              ) {
                                                _vm.teamRegionPopUpActive =
                                                  $event
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.teamRegionPopUpMessage
                                              ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm.form.conditions == "shipmentContents"
                              ? _c(
                                  "div",
                                  { staticClass: "multiple-field-container" },
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        options: _vm.shipmentContentsOptions,
                                        multiple: true,
                                        "close-on-select": true,
                                        placeholder: "Pick shipment contentss",
                                        "group-values": "values",
                                        "group-label": "label",
                                        "group-select": true,
                                        "track-by": "name",
                                        label: "name",
                                      },
                                      model: {
                                        value:
                                          _vm.conditionsValue.shipmentContents,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.conditionsValue,
                                            "shipmentContents",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "conditionsValue.shipmentContents",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.form.conditions == "customFields"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "md-layout custom-fields" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              staticClass: "group-filter",
                                              attrs: { label: "Name" },
                                            },
                                            [
                                              _c(
                                                "md-select",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.conditionsValue
                                                        .customFields.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.conditionsValue
                                                          .customFields,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "conditionsValue.customFields.name",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "md-optgroup",
                                                    {
                                                      staticClass:
                                                        "custom-field-option",
                                                      attrs: {
                                                        label:
                                                          "Stop Custom Fields",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.stopCustomFieldsOptions,
                                                      function (
                                                        stopCustomField,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "md-option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value:
                                                                stopCustomField.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  stopCustomField.label
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _c(
                                                    "md-optgroup",
                                                    {
                                                      staticClass:
                                                        "custom-field-option",
                                                      attrs: {
                                                        label:
                                                          "Shipment Custom Fields",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.shipmentCustomFieldsOptions,
                                                      function (
                                                        shipmentCustomField,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "md-option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value:
                                                                shipmentCustomField.name,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  shipmentCustomField.label
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              staticClass: "group-filter",
                                              attrs: { label: "Operator" },
                                            },
                                            [
                                              _c(
                                                "md-select",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.conditionsValue
                                                        .customFields.operator,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.conditionsValue
                                                          .customFields,
                                                        "operator",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "conditionsValue.customFields.operator",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.operatorOptions,
                                                  function (operator, index) {
                                                    return _c(
                                                      "md-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: operator.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              operator.name
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "md-layout-item" },
                                        [
                                          _c(
                                            "form-group",
                                            {
                                              attrs: {
                                                label: "Value",
                                                name: "value",
                                              },
                                            },
                                            [
                                              _c("md-input", {
                                                model: {
                                                  value:
                                                    _vm.conditionsValue
                                                      .customFields.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.conditionsValue
                                                        .customFields,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "conditionsValue.customFields.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isBookingGroup
                      ? _c(
                          "form-group",
                          {
                            attrs: {
                              label: "Description",
                              name: "Description",
                            },
                          },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.form.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "description", $$v)
                                },
                                expression: "form.description",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _vm.selectedQuestion === null
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.addQuestion },
              },
              [_vm._v("\n            Add\n        ")]
            )
          : _vm._e(),
        _vm.selectedQuestion !== null
          ? _c(
              "md-button",
              {
                staticClass: "dialog-button md-primary",
                on: { click: _vm.editQuestion },
              },
              [_vm._v("\n            Save\n        ")]
            )
          : _vm._e(),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }