<template>
    <div class="detail-container">
        <div class="close-button">
            <md-button class="md-default md-just-icon md-round pull-right" @click="closeModal">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <div v-if="!loading && Object.keys(inventoryDetails).length > 0">
            <div class="detail-section text-center">
                <span class="title">{{ inventoryDetails.barcode }}</span>
                <div class="detail-group text-center action-detail-group">
                    <router-link
                        title="View item details"
                        class="md-button md-success md-just-icon md-round"
                        :to="{
                            name: 'Inventory Details',
                            params: { itemId: itemId }
                        }"
                        target="_blank"
                    >
                        <div class="md-ripple">
                            <div class="md-button-content">
                                <md-icon>visibility</md-icon>
                            </div>
                        </div>
                    </router-link>

                    <update-item-button
                        v-if="!isReadOnlyUser"
                        :item="inventoryDetails"
                        @itemUpdated="handleUpdateItem"
                        :is-delivered="inventoryDetails.status === 'Delivered'"
                        :stop-status="
                            inventoryDetails.lines.length > 0 ? inventoryDetails.lines.at(-1).stopStatus : null
                        "
                        :shipment-status="
                            inventoryDetails.lines.length > 0 ? inventoryDetails.lines.at(-1).shipmentStatus : null
                        "
                    />
                    <delete-item-button
                        v-if="!isReadOnlyUser"
                        :item-id="inventoryDetails.itemId"
                        :is-shipment-attached="
                            inventoryDetails.lines &&
                                inventoryDetails.lines.length > 0 &&
                                inventoryDetails.lines.at(-1).shipmentId !== null
                        "
                        :is-stop-attached="inventoryDetails.lines && inventoryDetails.lines.length > 0"
                        :is-order-attached="inventoryDetails.orderId !== null"
                        @itemDeleted="handleDeletedItem"
                    />
                </div>
            </div>
            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Inventory Information</span>
                <div class="detail-group">
                    <label class="label">Type:</label>
                    <span class="value">{{ inventoryDetails.type }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Description:</label>
                    <span class="value">{{ inventoryDetails.description }}</span>
                </div>
                <div class="detail-group" v-if="inventoryDetails.order">
                    <label class="label">Order Number:</label>
                    <span class="value">
                        <router-link
                            :to="{ name: 'Orders', params: { orderId: inventoryDetails.order.orderId } }"
                            target="_blank"
                        >
                            {{ inventoryDetails.order.orderNumber }}
                        </router-link>
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Warehouse:</label>
                    <span class="value">{{ getWarehouseName(inventoryDetails.warehouseId) }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Status:</label>
                    <span class="value">
                        <inventory-status-button
                            :item-status="inventoryDetails.status"
                            :item-id="inventoryDetails.itemId"
                            :inventory-details="inventoryDetails"
                            @statusUpdated="statusUpdated()"
                        />
                    </span>
                </div>
                <div class="detail-group">
                    <label class="label">Current Location:</label>
                    <span class="value">{{ inventoryDetails.currentLocationName }}</span>
                </div>
                <div class="detail-group">
                    <label class="label">Possesed by:</label>
                    <span class="value">{{ getFullNameFromTeamMemberId(inventoryDetails.userId) }}</span>
                </div>

                <div class="detail-group" v-if="inventoryDetails.lines && inventoryDetails.lines.length > 0">
                    <label class="label">Stop:</label>
                    <span class="value">
                        <router-link
                            v-if="!inventoryDetails.lines[inventoryDetails.lines.length - 1].shipmentId"
                            :to="{
                                name: 'Stop Details',
                                params: { stopId: inventoryDetails.lines[inventoryDetails.lines.length - 1].stopId }
                            }"
                            target="_blank"
                        >
                            {{ inventoryDetails.lines[inventoryDetails.lines.length - 1].stopRef }}
                        </router-link>

                        <router-link
                            v-else
                            :to="{
                                name: 'Shipment Details',
                                params: {
                                    shipmentId: inventoryDetails.lines[inventoryDetails.lines.length - 1].shipmentId
                                }
                            }"
                            target="_blank"
                        >
                            {{ inventoryDetails.lines[inventoryDetails.lines.length - 1].shipmentRef }}
                        </router-link>
                    </span>
                </div>
            </div>

            <div class="line-separator" v-if="stopLinesHistory"></div>
            <div class="detail-section" v-if="stopLinesHistory">
                <span class="title">Previous Stops / Shipments</span>

                <div v-for="item in stopLinesHistory" :key="item.stopLineId">
                    <div class="detail-group">
                        <span class="prev-shipment">
                            <router-link
                                v-if="!item.shipmentId"
                                :to="{ name: 'Stop Details', params: { stopId: item.stopId } }"
                                target="_blank"
                            >
                                <span>
                                    {{ item.stopRef }}
                                </span>
                                <span class="time" v-if="item.tripDate">
                                    {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                                <span class="user" v-if="item.publicUserId">
                                    {{ getFullNameFromTeamMemberId(item.publicUserId) }}
                                </span>
                            </router-link>
                            <router-link
                                v-else
                                :to="{ name: 'Shipment Details', params: { shipmentId: item.shipmentId } }"
                                target="_blank"
                            >
                                <span>
                                    {{ item.shipmentRef }}
                                </span>
                                <span class="time" v-if="item.tripDate">
                                    {{ item.tripDate | dateFormat(DATE_TYPES.standardDate) }}
                                </span>
                                <span class="user" v-if="item.publicUserId">
                                    {{ getFullNameFromTeamMemberId(item.publicUserId) }}
                                </span>
                            </router-link>
                        </span>
                    </div>
                </div>
            </div>

            <div class="line-separator" v-if="inventoryDetails.order"></div>
            <div class="detail-section" v-if="inventoryDetails.order">
                <span class="title">Products</span>

                <div v-for="item in inventoryDetails.order.orderLines" :key="item.orderLineId">
                    <div class="detail-group">
                        <div class="prev-shipment">
                            <span>
                                {{ item.productName }}
                            </span>
                            <span v-if="item.productVariantSize">- {{ item.productVariantSize }}</span>
                            <span v-if="item.productVariantColour">- {{ item.productVariantColour }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Status History</span>

                <div v-for="item in inventoryDetails.itemStatusHistory" :key="item.itemStatusHistoryId">
                    <div class="detail-group">
                        <div class="custom-badge-div">
                            <div
                                slot="header"
                                :class="
                                    `custom-badge inventory-status-${item.newStatus.toLowerCase().replace(/\s+/g, '')}`
                                "
                            >
                                {{ $t('inputs.itemStatuses.' + item.newStatus) }}
                            </div>
                        </div>
                        <div slot="headerTime" class="time">
                            {{ item.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </div>
                    </div>
                    <div class="detail-group" v-if="item.notes">
                        <span class="notes">{{ item.notes }}</span>
                    </div>
                </div>
            </div>

            <div class="line-separator"></div>
            <div class="detail-section">
                <span class="title">Movement History</span>

                <div v-for="item in inventoryDetails.itemMovementHistory" :key="item.itemMovementHistoryId">
                    <div class="detail-group">
                        <div class="custom-badge-div">
                            <div
                                slot="header"
                                :class="
                                    `custom-badge inventory-movement-${item.newLocationName
                                        .toLowerCase()
                                        .replace(/\s+/g, '')}`
                                "
                            >
                                {{ $t('inputs.itemLocations.' + item.newLocationName) }}
                            </div>
                        </div>
                        <div slot="headerTime" class="time">
                            {{ item.actionDate | dateTimeFormat(DATE_TYPES.standardDate) }}
                        </div>
                    </div>
                    <div class="detail-group" v-if="item.notes">
                        <span class="notes">{{ item.notes }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="sidebar-loader">
                <fade-loader :loading="loading" color="#333333" />
                <span>LOADING</span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { handleRequests, showErrorMessage } from '@/helpers';
import FadeLoader from 'vue-spinner/src/FadeLoader';
import { GeneralMixin, MapOverviewMixin } from '@/mixins';
import _ from 'lodash';
import { ViewAddressModal } from '..';
import { InventoryStatusButton, UpdateItemButton, DeleteItemButton } from './buttons';

export default {
    name: 'InventorySidebar',
    mixins: [GeneralMixin, MapOverviewMixin],
    components: {
        FadeLoader,
        InventoryStatusButton,
        UpdateItemButton,
        DeleteItemButton
    },
    props: {
        itemId: {
            type: Number,
            default: null
        },
        warehouses: {
            type: Array,
            default: null
        },
        teamMembers: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            stopLinesHistory: null,
            inventoryDetails: {},
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isReadOnlyUser: 'user/isReadOnlyUser'
        })
    },
    watch: {
        async itemId(newValue, oldValue) {
            await this.getInventoryDetails(newValue);
        }
    },
    async mounted() {
        await this.getInventoryDetails(this.itemId);
    },
    methods: {
        async statusUpdated() {
            await this.getInventoryDetails(this.inventoryDetails.itemId);
            this.$emit('itemUpdated', { itemId: this.inventoryDetails.itemId, details: this.inventoryDetails });
        },
        async getInventoryDetails(itemId) {
            try {
                this.loading = true;
                const api = `/api/items/${itemId}`;
                const { data } = await handleRequests(api);
                Object.assign(this.inventoryDetails, data);

                this.inventoryDetails.itemStatusHistory.sort((a, b) => {
                    return moment(a.actionDate).diff(moment(b.actionDate));
                });

                if (this.inventoryDetails.lines && this.inventoryDetails.lines.length > 1) {
                    this.inventoryDetails.lines.sort((a, b) => {
                        if (a.tripDate === null) 
                            return 1;
                        if (b.tripDate === null) 
                            return -1;
                        return moment(a.tripDate).diff(moment(b.tripDate));
                    });

                    this.stopLinesHistory = _.initial(this.inventoryDetails.lines);
                } else 
                    this.stopLinesHistory = null;
            } catch (error) {
                const message = 'Error in getting the inventory details';
                showErrorMessage(this, message, error);
            } finally {
                this.loading = false;
            }
        },
        closeModal() {
            this.$emit('closeModal');
        },
        viewAddress() {
            this.$modal.show(ViewAddressModal, { data: this.inventoryDetails });
        },
        getWarehouseName(id) {
            const fnd = this.warehouses.find((element) => element.warehouseId === id);
            if (fnd === undefined) 
                return '';
            return fnd.name;
        },
        getFullNameFromTeamMemberId(id) {
            const fnd = this.teamMembers.find((element) => element.publicUserId === id);
            if (fnd === undefined) 
                return '';
            return fnd.fullName;
        },
        async handleUpdateItem(item) {
            await this.statusUpdated();
        },
        async handleDeletedItem() {
            this.$emit('itemDeleted');
        }
    }
};
</script>

<style lang="scss" scoped>
$label-width: 125px;

.detail-container {
    position: fixed;
    top: 2vh;
    right: 10px;
    height: 96vh;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    background: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 16px;

    .close-button {
        top: 0;
        right: 0;
        position: absolute;
        /* add z-index for old shipments that have no shipment reference numbers */
        z-index: 999;
    }

    .md-button.md-just-icon,
    ::v-deep .md-button.md-just-icon {
        font-size: 15px;
        height: 30px;
        min-width: 3px;
        width: 30px;
        line-height: 30px;
        margin: 2.5px;
    }

    .detail-section {
        padding-top: 10px;

        .blue-ref a {
            color: #2b93ff !important;
        }

        .title {
            text-transform: uppercase;
            font-weight: 600;
            padding: 2px 16px;
            display: block;
            font-size: 14px;
        }

        ::v-deep .custom-a-blue a {
            color: #2b93ff !important;
        }
    }

    .line-separator {
        height: 1px;
        background-color: #ddd;
        width: calc(100% - 32px);
        margin: 10px auto;
        margin-bottom: 0px;
    }

    .detail-group {
        padding: 2px 16px;
        margin: 5px 0;
        font-size: 0.9em;

        .label {
            display: inline-block;
            width: $label-width;
            margin-right: 4px;
            vertical-align: top;
            font-weight: 400;
        }

        .custom-badge {
            margin: auto;
            max-width: unset;
        }

        .custom-badge-div {
            width: $label-width;
            margin: 0;
            text-align: left;
            float: left;
        }

        .value {
            display: inline-block;
            width: calc(100% - #{$label-width} - 10px);
            vertical-align: top;

            a {
                color: #2b93ff;
            }
        }

        .status-label {
            font-weight: bold;
        }

        .notes {
            padding-left: 24px;
            margin-left: 10px;
            color: #333333;
            font-weight: 400;
            font-size: 0.75rem;
        }

        .prev-shipment {
            margin-left: 0px;
            color: #333333;
            font-weight: 400;
            font-size: 0.75rem;
        }

        .time,
        .user {
            margin-left: 10px;
            color: #333333;
            font-weight: 400;
            font-size: 0.75rem;
        }
    }
}

.sidebar-loader {
    position: absolute;
    top: 40%;
    left: 50%;

    span {
        position: absolute;
        margin-top: 50px;
        width: 110px;
        left: calc((100% - 100px) / 2);
        text-align: center;
        font-weight: 600;
    }
}

.detail-section .address {
    cursor: pointer;
}

.stop-ref-badge {
    background-color: gray !important;
}
</style>
