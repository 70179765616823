var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    [
      _c(
        "md-card-header",
        { staticClass: "md-card-header-icon md-card-header-green" },
        [
          _vm.stopDetails.assignedTo.photoUrl === null
            ? _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("account_box")])],
                1
              )
            : _vm._e(),
          _vm.stopDetails.assignedTo.photoUrl !== null
            ? _c("div", { staticClass: "card-icon profile-image" }, [
                _c("img", {
                  attrs: {
                    src: _vm.stopDetails.assignedTo.photoUrl,
                    alt: "avatar",
                  },
                  on: { error: _vm.$_setDefaultBrokenImage },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _c("md-card-content", [
        _vm.stopDetails.carrierTeamId
          ? _c(
              "div",
              { staticClass: "custom-md-field" },
              [
                _c("div", { staticClass: "custom-label" }, [_vm._v("Carrier")]),
                _vm.isReadOnlyUser
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.stopDetails.carrierTeam.company)),
                    ])
                  : _vm.stopDetails.carrierTeamId != null
                  ? _c("span", [
                      _vm.stopDetails.carrierTeam &&
                      _vm.stopDetails.carrierTeam.logoUrl
                        ? _c("img", {
                            staticClass: "profile-image carrier-image-logo",
                            attrs: {
                              src:
                                _vm.stopDetails.carrierTeam.logoUrl !== null
                                  ? _vm.stopDetails.carrierTeam.logoUrl
                                  : _vm.$root.defaultPhotoUrl,
                              alt: "carrier logo",
                            },
                            on: { error: _vm.$_setDefaultBrokenImage },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.stopDetails.carrierTeam.company) +
                          "\n            "
                      ),
                    ])
                  : _c("reassign-stop-button", {
                      attrs: {
                        type: "text",
                        item: _vm.stopDetails,
                        "team-members": _vm.teamMembers,
                      },
                      on: { assignedUser: _vm.handleAssignedUser },
                    }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "custom-md-field" },
              [
                _c("div", { staticClass: "custom-label" }, [_vm._v("Driver")]),
                _vm.isReadOnlyUser
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.stopDetails.assignedTo.fullName)),
                    ])
                  : _vm.stopDetails.assignedTo.publicUserId
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Team Member Profile",
                            params: {
                              publicUserId:
                                _vm.stopDetails.assignedTo.publicUserId,
                            },
                          },
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.stopDetails.assignedTo.fullName) +
                            "\n            "
                        ),
                      ]
                    )
                  : _c("reassign-stop-button", {
                      attrs: {
                        type: "text",
                        item: _vm.stopDetails,
                        "team-members": _vm.teamMembers,
                      },
                      on: { assignedUser: _vm.handleAssignedUser },
                    }),
                !_vm.stopDetails.rateGroupId &&
                _vm.stopDetails.assignedTo.rateGroupId
                  ? _c("div", [
                      _c("div", { staticClass: "custom-label" }, [
                        _vm._v("Service Package"),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.stopDetails.assignedTo.rateGroupName)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
        _vm.stopDetails.rating
          ? _c("div", { staticClass: "details-box" }, [
              _c("label", [_vm._v("Customer Rating for this Stop")]),
              _c(
                "div",
                { staticClass: "rating" },
                _vm._l(5, function (index) {
                  return _c(
                    "md-icon",
                    {
                      key: index,
                      style: {
                        color:
                          index <= _vm.stopDetails.rating
                            ? "orange"
                            : "rgba(0,0,0,0.54",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    star_rate\n                "
                      ),
                    ]
                  )
                }),
                1
              ),
            ])
          : _vm._e(),
        _vm.stopDetails.assignedTo.phone
          ? _c("div", { staticClass: "details-box" }, [
              _c("label", [_vm._v("Phone")]),
              _c(
                "div",
                { staticClass: "phone-sms" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "tel:" + _vm.stopDetails.assignedTo.phone,
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.stopDetails.assignedTo.phone) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm.stopDetails.assignedTo.phone
                    ? _c("send-sms-button", {
                        attrs: {
                          "public-user-id":
                            _vm.stopDetails.assignedTo.publicUserId,
                          name: _vm.stopDetails.assignedTo.fullName,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.stopDetails.carrierTeamId != null
          ? _c("div", [
              _c("div", { staticClass: "details-box" }, [
                _c("label", [_vm._v("Reference #:")]),
                _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.stopDetails.shipment.assignedCarrierBooking
                          .supportReference
                      ) +
                      "\n                "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "details-box" }, [
                _c("label", [_vm._v("Status")]),
                _c("span", [
                  _c(
                    "div",
                    {
                      staticClass: "custom-badge",
                      class:
                        "status-" +
                        _vm.$_getQuotingStatusBadgeColor(
                          _vm.stopDetails.shipment.quotingStatus
                        ),
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$_getQuotingStatusText(
                              _vm.stopDetails.shipment.quotingStatus
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm.stopDetails.shipment.assignedCarrierBooking.serviceLevel
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Service Type:")]),
                    _c("span", [
                      _c(
                        "div",
                        {
                          staticClass: "custom-badge",
                          class:
                            "status-" +
                            _vm.$_getServiceLevelBadgeColor(
                              _vm.stopDetails.shipment.assignedCarrierBooking
                                .timeWindowEnd
                            ),
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t(
                                  _vm.stopDetails.shipment
                                    .assignedCarrierBooking.serviceLevel
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "details-box" }, [
                _c("label", [_vm._v("Price:")]),
                _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("currency")(
                          _vm.stopDetails.shipment.assignedCarrierBooking
                            .feeAfterTax,
                          _vm.stopDetails.shipment.assignedCarrierBooking
                            .currency
                        )
                      ) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm.stopDetails.type == "Pick Up"
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Pickup ETA:")]),
                    _vm.stopDetails.shipment.assignedCarrierBooking.pickupEta
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.stopDetails.shipment
                                    .assignedCarrierBooking.pickupEta,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("span", { staticClass: "not-available" }, [
                          _vm._v(
                            "\n                    Not available\n                "
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm.stopDetails.type == "Drop"
                ? _c("div", { staticClass: "details-box" }, [
                    _c("label", [_vm._v("Drop ETA:")]),
                    _vm.stopDetails.shipment.assignedCarrierBooking.dropOffEta
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("dateTimeFormat")(
                                  _vm.stopDetails.shipment
                                    .assignedCarrierBooking.dropOffEta,
                                  _vm.DATE_TYPES.standardDate
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _c("span", { staticClass: "not-available" }, [
                          _vm._v(
                            "\n                    Not available\n                "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }