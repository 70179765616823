<template>
    <div class="modal-container" :class="$root.isMobileOnly ? 'modal-dialog-full-width' : ''">
        <div class="modal-header">
            <h4 class="modal-title">Create Run Schedule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.runDetails" class="form-wrapper">
                <div class="md-layout run-item-layout">
                    <div class="md-layout-item md-size-100">
                        <form-group name="runName" label="Name">
                            <md-input type="text" v-model="runDetails.runName" v-focus />
                        </form-group>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer" v-if="!$root.isMobileOnly">
            <md-button class="md-primary dialog-button" @click="validateRunSchedule" :disabled="isLoading">
                Create
            </md-button>
            <md-button class="md-default dialog-button" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { DATE_TYPES } from '@/utils/constants';

export default {
    name: 'CreateRunScheduleModal',
    mixins: [GeneralMixin],
    props: {
        tripId: {
            type: Number,
            default: null
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: true,
            runDetails: {
                runName: null
            }
        };
    },
    validations: {
        runDetails: {
            // eslint-disable-next-line func-names
            runName: {
                required
            }
        }
    },
    async mounted() {
        await this.getTripDetails(this.tripId);
    },
    methods: {
        async getTripDetails(tripId) {
            const api = `/api/trips/${tripId}/details`;

            try {
                const {
                    data: { data }
                } = await handleRequests(api);
                this.isLoading = false;

                const { publicUserId, locationDetails, tripDate, startTime, endTime } = data;

                const runDetails = {
                    name: null,
                    scheduleType: 'Weekly',
                    startTime,
                    endTime,
                    assignToPublicUserId: publicUserId,
                    startAddress: locationDetails.startAddress,
                    startLocation: locationDetails.startLocation,
                    endAddress: locationDetails.endAddress,
                    endLocation: locationDetails.endLocation,
                    tripDate: moment(tripDate).format(DATE_TYPES.internationalDate),
                    schedule: `0 0 * * ${moment(tripDate).day()}`
                };
                Object.assign(this.runDetails, runDetails);
            } catch (e) {
                const message = 'Could not retrieve trip details. Please try again later.';
                showErrorMessage(this, message, e);
                this.resolve(false);
            }
        },
        validateRunSchedule() {
            this.$v.runDetails.$touch();

            if (!this.$v.runDetails.$invalid) {
                this.createRun();
            }
        },
        async createRun() {
            this.$_handleLoaderState(true, 'SAVING...');

            this.runDetails.name = this.runDetails.runName;

            const api = `/api/run-schedules?createFromTripId=${this.tripId}`;
            const payload = {
                method: 'post',
                data: this.runDetails
            };

            try {
                await handleRequests(api, payload);
                this.$notify({
                    message: 'Successfully created a run schedule.',
                    type: 'success'
                });
                this.$v.$reset();
                this.resolve('ok');
            } catch (e) {
                const message = 'Could not create a new run schedule.';
                showErrorMessage(this, message, e);
            }
            this.$_handleLoaderState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 350px;
}
.run-item-layout {
    ::v-deep .md-layout-item {
        padding-left: 0px;
    }
}
</style>
