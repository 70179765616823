<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Routing Setting</h4>
        </div>
        <div class="modal-body">
            <form-group name="distanceCost" label="Distance Cost">
                <md-input type="number" v-model="distanceCost" />
            </form-group>
            <form-group name="timeCost" label="Time Cost">
                <md-input type="number" v-model="timeCost" />
            </form-group>
            <form-group v-if="isDistributeWorkload === false" name="fixedCost" label="Fixed Costs">
                <md-input type="number" v-model="fixedCost" />
            </form-group>
            <form-group
                v-if="isDistributeWorkload === true"
                name="distributeWorkloadCost"
                label="Distribute Workload Cost"
            >
                <md-input type="number" v-model="distributeWorkloadCost" />
            </form-group>
            <form-group
                v-if="startLocationMode === 'manual'"
                name="routeCenteringPenalty"
                label="Route Centering Penalty"
            >
                <md-input type="number" v-model="routeCenteringPenalty" />
            </form-group>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="saveRouringSetting">Save</md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'RoutingSettingsModal',
    props: {
        resolve: {
            type: Function,
            default: () => {}
        },
        startLocationMode: {
            type: String,
            default: null
        },
        isDistributeWorkload: {
            type: Boolean
        }
    },
    data() {
        return {
            distanceCost: null,
            timeCost: null,
            fixedCost: null,
            routeCenteringPenalty: null,
            distributeWorkloadCost: 10
        };
    },
    computed: {
        ...mapGetters({
            getDistanceCost: 'routingSetting/getDistanceCost',
            getTimeCost: 'routingSetting/getTimeCost',
            getFixedCost: 'routingSetting/getFixedCost',
            getRouteCenteringPenalty: 'routingSetting/getRouteCenteringPenalty',
            getDistributeWorkloadCost: 'routingSetting/getDistributeWorkloadCost'
        })
    },
    methods: {
        ...mapMutations({
            saveDistanceCost: 'routingSetting/UPDATE_DISTANCE_COST',
            saveTimeCost: 'routingSetting/UPDATE_TIME_COST',
            saveFixedCost: 'routingSetting/UPDATE_FIXED_COST',
            saveRouteCenteringPenalty: 'routingSetting/UPDATE_ROUTE_CENTERING_PENALTY',
            saveDistributeWorkloadCost: 'routingSetting/UPDATE_DISTRIBUTE_WORKLOAD_COST'
        }),
        saveRouringSetting() {
            const distanceCost = parseFloat(this.distanceCost);
            const timeCost = parseFloat(this.timeCost);
            const fixedCost = parseFloat(this.fixedCost);
            const routeCenteringPenalty = parseFloat(this.routeCenteringPenalty);
            const distributeWorkloadCost = parseFloat(this.distributeWorkloadCost);
            const isChange =
                distanceCost !== this.getDistanceCost ||
                timeCost !== this.getTimeCost ||
                fixedCost !== this.getFixedCost ||
                routeCenteringPenalty !== this.getRouteCenteringPenalty ||
                distributeWorkloadCost !== this.distributeWorkloadCost;

            this.saveDistanceCost(distanceCost);
            this.saveTimeCost(timeCost);
            this.saveFixedCost(fixedCost);
            this.saveRouteCenteringPenalty(routeCenteringPenalty);

            if (this.isDistributeWorkload) {
                this.saveDistributeWorkloadCost(distributeWorkloadCost);
            }

            this.resolve({ isChange });
        }
    },
    mounted() {
        this.distanceCost = this.getDistanceCost;
        this.timeCost = this.getTimeCost;
        this.fixedCost = this.getFixedCost;
        this.routeCenteringPenalty = this.getRouteCenteringPenalty;
        this.distributeWorkloadCost = this.getDistributeWorkloadCost;
    }
};
</script>
<style lang="scss" scoped>
.modal-container {
    max-width: 250px;
}
</style>
