<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">Create Shipment</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div>
            <md-button class="md-success chargeButton-toggle" @click="openCharges()">
                Charges ({{ chargeList.length }})
            </md-button>
            <charge-list-modal
                :rates-list="chargeList"
                :rates-is-loading="ratesIsLoading"
                :rate-list-data="rateListData"
                :currency="currency"
            />
        </div>

        <div class="modal"></div>
        <div class="modal-body shipment-form-modal">
            <form-wrapper :validator="$v.shipmentDetails" class="form-wrapper">
                <tabs
                    :tab-name="['Shipment', 'Pickup Options', 'Drop off Options']"
                    color-button="warning"
                    class="custom-tab-list"
                >
                    <template slot="tab-pane-1">
                        <div class="shipment-form-container">
                            <charging-type-options
                                @selectedOption="handleRateTypeChanged"
                                :selected-charge-type-id="shipmentDetails.rateGroupId"
                            />

                            <team-region-member-controls
                                :team-region-id="shipmentDetails.teamRegionId"
                                :public-user-id="shipmentDetails.assignToPublicUserId"
                                :carrier-team-id="shipmentDetails.carrierTeamId"
                                @onChanged="handleTeamRegionMemberChanged"
                                :strict-team-region-filter="false"
                            />
                            <div>
                                <form-group name="sourceReference" label="Source Reference">
                                    <md-input v-model="shipmentDetails.sourceReference" />
                                </form-group>
                            </div>
                            <div>
                                <brand-filter v-model="shipmentDetails" />
                            </div>
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Pickup"
                                        id="pautoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        gps-coordinate-placeholder="Enter a name for the pickup stop"
                                        v-model="shipmentDetails.pickupStop"
                                        name="pickupStop.address"
                                        @handleCoordinates="handlePickupCoordinates"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="stop-container">
                                <div>
                                    <google-autocomplete
                                        label="Drop off"
                                        id="dautoaddress"
                                        classname="form-control autocomplete-input"
                                        placeholder=""
                                        :should-focus="false"
                                        :disable-gps-coordinates="false"
                                        gps-coordinate-placeholder="Enter a name for the drop stop"
                                        @handleCoordinates="handleDropCoordinates"
                                        name="dropStop.address"
                                        v-model="shipmentDetails.dropStop"
                                        @change="handleShipmentDetailsChanged"
                                    ></google-autocomplete>
                                </div>
                            </div>
                            <div class="contact-details">
                                <span>Contact Details</span>
                                <div class="contact-details">
                                    <customer-autocomplete
                                        :label="shipmentDetails.customerId == null ? 'Name' : 'Customer'"
                                        id="autocustomerShipment"
                                        placeholder=""
                                        :should-focus="false"
                                        v-model="shipmentDetails"
                                        @change="handleCustomerFieldChange"
                                        @changeCustomerRateGroup="handleCustomerChangeRateGroup"
                                    />
                                    <!-- <div>
                <brand-filter v-model="shipmentDetails" :list-of-brands="brands" />
            </div> -->
                                    <form-group
                                        name="name"
                                        label="Contact Name"
                                        v-if="shipmentDetails.customerId != null"
                                    >
                                        <md-input v-model="shipmentDetails.contact.name" />
                                    </form-group>
                                    <form-group name="phone" label="Phone">
                                        <md-input class="shipment-contact" v-model="shipmentDetails.contact.phone" />
                                    </form-group>
                                    <form-group name="email" label="Email">
                                        <md-input class="shipment-contact" v-model="shipmentDetails.contact.email" />
                                    </form-group>
                                </div>
                            </div>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.notes" />
                            </form-group>
                            <div v-if="shipmentCustomFieldList !== null">
                                <custom-field-inputs
                                    v-for="(customField, index) in shipmentCustomFieldList"
                                    :key="index"
                                    :custom-field-definition="customField"
                                    :initial-value="customFieldValues[customField.name]"
                                    @changed="handleCustomFieldChanged"
                                />
                            </div>
                            <div v-if="loadFieldList !== null">
                                <load-inputs
                                    v-for="(load, index) in loadFieldList"
                                    :key="index"
                                    :load-definition="load"
                                    :initial-value="loadValues"
                                    @changed="handleLoadChanged"
                                />
                            </div>
                            <form-group v-if="skillOptions.length" name="skills" label="Skills">
                                <multiselect
                                    v-model="shipmentDetails.skills"
                                    :options="skillOptions"
                                    :multiple="true"
                                    :close-on-select="true"
                                    placeholder="Pick required skills"
                                ></multiselect>
                            </form-group>
                            <div v-if="!isCustomerAdmin">
                                <form-group name="runnumber" label="Run (Optional)">
                                    <md-input type="number" v-model.number="shipmentDetails.runNumber" min="1" />
                                </form-group>
                            </div>
                            <div class="tripdate">
                                <md-checkbox v-model="isSetTripDate" :disabled="tripDateRequired">
                                    Set trip date for this shipment?
                                </md-checkbox>
                            </div>
                            <div class="shipment-datepicker" v-if="isSetTripDate">
                                <div class="stop-container">
                                    <md-datepicker
                                        v-model="shipmentDetails.pickupStop.tripDate"
                                        md-immediately
                                        :md-disabled-dates="disabledDates"
                                        :md-debounce="10"
                                    >
                                        <label>Trip Date</label>
                                    </md-datepicker>
                                    <span
                                        v-if="
                                            isSetTripDate &&
                                                tripDateRequired &&
                                                (shipmentDetails.pickupStop.tripDate == null ||
                                                    shipmentDetails.pickupStop.tripDate == '')
                                        "
                                        class="error"
                                    >
                                        Trip date is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template slot="tab-pane-2">
                        <div class="contact-details">
                            <span>Pickup Details</span>
                            <customer-autocomplete
                                :label="shipmentDetails.pickupStop.customerId == null ? 'Name' : 'Customer'"
                                id="autocustomerShipmentPickup"
                                placeholder=""
                                :should-focus="false"
                                v-model="shipmentDetails.pickupStop"
                                :auto-fill-address="true"
                            />
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="shipmentDetails.pickupStop.customerId != null"
                            >
                                <md-input v-model="shipmentDetails.pickupStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="shipmentDetails.pickupStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="shipmentDetails.pickupStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.pickupStop.notes" />
                            </form-group>
                        </div>
                        <div class="shipment-datepicker" v-if="isSetTripDate">
                            <div class="stop-container">
                                <div class="trip-settings">
                                    <form-group name="time" label="Time" class="time-picker">
                                        <time-picker :time="pickupTime" @selectedTime="getPickupTime" />
                                    </form-group>
                                </div>
                                <div class="trip-settings duration-setting">
                                    <form-group name="pickupStop.durationMinutes" label="Duration (mins)">
                                        <md-input type="number" v-model="shipmentDetails.pickupStop.durationMinutes" />
                                    </form-group>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-3">
                        <div class="contact-details">
                            <span>Drop off Details</span>
                            <customer-autocomplete
                                :label="shipmentDetails.dropStop.customerId == null ? 'Name' : 'Customer'"
                                id="autocustomerShipmentDrop"
                                placeholder=""
                                :should-focus="false"
                                v-model="shipmentDetails.dropStop"
                                :auto-fill-address="true"
                            />
                            <form-group
                                name="name"
                                label="Contact Name"
                                v-if="shipmentDetails.dropStop.customerId != null"
                            >
                                <md-input v-model="shipmentDetails.dropStop.contact.name" />
                            </form-group>
                            <form-group name="phone" label="Phone">
                                <md-input v-model="shipmentDetails.dropStop.contact.phone" />
                            </form-group>
                            <form-group name="email" label="Email">
                                <md-input v-model="shipmentDetails.dropStop.contact.email" />
                            </form-group>
                            <form-group name="notes" label="Notes">
                                <md-textarea v-model="shipmentDetails.dropStop.notes" />
                            </form-group>
                        </div>
                        <div class="shipment-drop-datepicker" v-if="isSetTripDate">
                            <div>
                                <md-datepicker
                                    v-model="shipmentDetails.dropStop.tripDate"
                                    md-immediately
                                    :md-disabled-dates="disabledDates"
                                    :md-debounce="10"
                                >
                                    <label>Trip Date</label>
                                </md-datepicker>
                                <span
                                    v-if="
                                        isSetTripDate &&
                                            tripDateRequired &&
                                            (shipmentDetails.dropStop.tripDate == null ||
                                                shipmentDetails.dropStop.tripDate == '')
                                    "
                                    class="error"
                                >
                                    Trip date is required.
                                </span>
                            </div>
                            <div>
                                <form-group name="time" label="Time" class="time-picker">
                                    <time-picker
                                        :time="dropTime"
                                        :additional-time-options="additionalDropTimeOption"
                                        @selectedTime="getDropTime"
                                    />
                                </form-group>
                            </div>
                            <div>
                                <form-group name="dropStop.durationMinutes" label="Duration (mins)">
                                    <md-input type="number" v-model="shipmentDetails.dropStop.durationMinutes" />
                                </form-group>
                            </div>
                        </div>
                    </template>
                </tabs>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" :disabled="ratesIsLoading" @click="createShipment">
                Create
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, isAValidCoordinate, showErrorMessage } from '@/helpers';
import { requiredIf, numeric, maxLength } from 'vuelidate/lib/validators';
import { STOP_DETAILS_DEFAULTS } from '@/utils/defaults';
import { MapOverviewMixin } from '@/mixins/MapOverviewMixin';
import _ from 'lodash';

import {
    TimePicker,
    GoogleAutocomplete,
    Tabs,
    CustomFieldInputs,
    CustomerAutocomplete,
    LoadInputs,
    TeamRegionMemberControls,
    ChargingTypeOptions,
    ChargeListModal,
    BrandFilter
} from '@/components';
import moment from 'moment';
import { GeneralMixin, RatesEngineMixin } from '@/mixins';
import { DATE_TYPES, TIME_WINDOW_CONSTANTS } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
    name: 'CreateShipmentModal',
    components: {
        TimePicker,
        GoogleAutocomplete,
        Tabs,
        CustomFieldInputs,
        CustomerAutocomplete,
        LoadInputs,
        TeamRegionMemberControls,
        ChargingTypeOptions,
        ChargeListModal,
        BrandFilter
    },
    mixins: [GeneralMixin, MapOverviewMixin, RatesEngineMixin],
    props: {
        shipmentDetails: {
            type: Object,
            default: () => {}
        },
        members: {
            type: Array,
            default: () => []
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/user',
            isCustomerAdmin: 'user/isCustomerAdmin',
            isSingleUser: 'user/isIndividualUser',
            isSingleTeamMember: 'team/isSingleTeamMember',
            isSingleTeamMemberUser: 'user/isSingleTeamMember',
            allWarehouses: 'team/warehouses',
            hasRateRules: 'user/hasRateRules'
        })
    },
    created() {
        // const tempMembers = this.members.map((member) => ({
        //     publicUserId: member.publicUserId,
        //     fullName: `${member.firstName || ''} ${member.lastName || ''}`,
        //     email: member.email
        // }));
        // const defaultOption = {
        //     publicUserId: 0,
        //     fullName: 'Unassigned',
        //     email: null
        // };
        // this.teamMemberOptions = [defaultOption].concat(tempMembers);
    },
    async mounted() {
        if (
            // eslint-disable-next-line eqeqeq
            (this.shipmentDetails.assignToPublicUserId !== null && this.shipmentDetails.assignToPublicUserId != 0) ||
            this.isSingleTeamMemberUser
        ) {
            this.isSetTripDate = true;
            this.tripDateRequired = true;
        }

        this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;
        this.loadFieldList = this.user.vehicleCapacityUnitsConfiguration || [];
        if (this.user.skillOptions) {
            this.skillOptions = this.user.skillOptions.map((x) => x.name);
        }

        if ((this.isSingleUser || this.isSingleTeamMember) && !this.isCustomerAdmin) {
            this.shipmentDetails.assignToPublicUserId = this.user.publicUserId;
        }

        this.initializeCustomFields();
        this.initializeLoads();
    },
    watch: {
        isSetTripDate(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue) {
                this.shipmentDetails.tripDate = moment().format(DATE_TYPES.internationalDate);
                this.shipmentDetails.pickupStop.tripDate = moment().format(DATE_TYPES.internationalDate);
                this.shipmentDetails.dropStop.tripDate = moment().format(DATE_TYPES.internationalDate);
            } else {
                this.shipmentDetails.tripDate = null;
                this.shipmentDetails.pickupStop.appointmentTime = null;
                this.shipmentDetails.pickupStop.tripDate = null;
                this.shipmentDetails.dropStop.appointmentTime = null;
                this.shipmentDetails.dropStop.tripDate = null;
            }
        },
        'shipmentDetails.assignToPublicUserId': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== null && newValue != 0) {
                this.isSetTripDate = true;
                this.tripDateRequired = true;
                this.setDurationMinutes(newValue);
            } else {
                this.tripDateRequired = false;
            }
        },
        'shipmentDetails.pickupStop.tripDate': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            this.shipmentDetails.tripDate = newValue;
            if (newValue) {
                this.shipmentDetails.pickupStop.tripDate = newValue;
                if (oldValue === this.shipmentDetails.dropStop.tripDate) {
                    this.shipmentDetails.dropStop.tripDate = newValue;
                }
            }
        },
        'shipmentDetails.tripDate': function(newValue, oldValue) {
            // eslint-disable-next-line eqeqeq
            if (newValue !== oldValue) {
                this.validateCanGenerateRates();
            }
        }
    },
    methods: {
        initializeCustomFields() {
            if (this.shipmentCustomFieldList && this.shipmentCustomFieldList.length > 0) {
                const custFieldValueList = {};
                this.shipmentCustomFieldList.forEach((item, index) => {
                    if (item.type.toLowerCase() !== 'checkbox') {
                        custFieldValueList[item.name] = null;
                    } else {
                        custFieldValueList[item.name] = false;
                    }
                });
                this.customFieldValues = custFieldValueList;
            }
        },
        initializeLoads() {
            if (this.loadFieldList && this.loadFieldList.length > 0) {
                const loadList = {};
                this.loadFieldList.forEach((item, index) => {
                    loadList[item.type] = null;
                });
                this.loadValues = loadList;
            }
        },
        async createShipment() {
            if (
                (this.isSetTripDate && this.tripDateRequired && this.shipmentDetails.pickupStop.tripDate == null) ||
                this.isPickupTimeValid === false ||
                this.isDropTimeValid === false
            )
                return;

            const { assignToPublicUserId } = this.shipmentDetails;
            this.$v.shipmentDetails.$touch();
            this.isFormTouched = true;

            if (!this.$v.shipmentDetails.$invalid) {
                this.$_handleLoaderState(true);
                // eslint-disable-next-line eqeqeq
                if (assignToPublicUserId == null || assignToPublicUserId == 0)
                    this.shipmentDetails.assignToPublicUserId = null;

                // eslint-disable-next-line radix
                this.shipmentDetails.pickupStop.durationMinutes =
                    parseInt(this.shipmentDetails.pickupStop.durationMinutes, 10) || 15;

                // eslint-disable-next-line radix
                this.shipmentDetails.dropStop.durationMinutes =
                    parseInt(this.shipmentDetails.dropStop.durationMinutes, 10) || 15;

                this.shipmentDetails.customFields = JSON.stringify(this.customFieldValues);
                this.shipmentDetails.load = this.loadValues;
                if (this.shipmentDetails.pickupStop.tripDate) {
                    this.shipmentDetails.tripDate = moment(this.shipmentDetails.pickupStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                    this.shipmentDetails.pickupStop.tripDate = moment(this.shipmentDetails.pickupStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                }

                if (this.shipmentDetails.dropStop.tripDate) {
                    this.shipmentDetails.tripDate = null;
                    this.shipmentDetails.dropStop.tripDate = moment(this.shipmentDetails.dropStop.tripDate).format(
                        DATE_TYPES.internationalDate
                    );
                }

                if (!this.isSetTripDate) {
                    this.shipmentDetails.tripDate = null;
                    this.shipmentDetails.pickupStop.appointmentTime = null;
                    this.shipmentDetails.pickupStop.timeWindowStart = null;
                    this.shipmentDetails.pickupStop.timeWindowEnd = null;
                    this.shipmentDetails.pickupStop.tripDate = null;
                    this.shipmentDetails.dropStop.appointmentTime = null;
                    this.shipmentDetails.dropStop.timeWindowStart = null;
                    this.shipmentDetails.dropStop.timeWindowEnd = null;
                    this.shipmentDetails.dropStop.tripDate = null;
                }
                /* this.shipmentDetails.pickupStop.runNumber = this.shipmentDetails.runNumber;
                this.shipmentDetails.dropStop.runNumber = this.shipmentDetails.runNumber; */

                this.shipmentDetails.chargeLines = this.chargeList;
                this.shipmentDetails.costLines = this.costList;

                const api = '/api/shipments';
                const payload = {
                    method: 'post',
                    data: this.shipmentDetails
                };

                try {
                    const responseData = await handleRequests(api, payload);
                    this.$notify({
                        message: 'Successfully created a shipment.',
                        type: 'success'
                    });

                    const feedbackMessage = responseData?.data;
                    if (feedbackMessage && typeof feedbackMessage === 'string' && feedbackMessage.trim().length !== 0) {
                        this.$notifyWarning(feedbackMessage, 7000);
                    }

                    this.$v.$reset();
                    this.resolve('ok');
                } catch (e) {
                    let message = 'Could not create a new shipment.';
                    if (e && e.data) 
                        [{ message }] = e.data;
                    this.$notify({
                        message,
                        type: 'danger'
                    });
                    this.$_handleLoaderState(false);
                }
            }
        },
        getPickupTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.shipmentDetails.pickupStop.appointmentTime = appointmentTime;
                this.shipmentDetails.pickupStop.timeWindowStart = timeWindowStart;
                this.shipmentDetails.pickupStop.timeWindowEnd = timeWindowEnd;
                this.pickupTime = selectedTime;
                this.pickupTime = this.getStopTime(
                    this.shipmentDetails.pickupStop.timeWindowStart,
                    this.shipmentDetails.pickupStop.timeWindowEnd,
                    this.shipmentDetails.pickupStop.appointmentTime,
                    this.additionalPickupTimeOption
                );
            } else {
                this.shipmentDetails.pickupStop.appointmentTime = null;
                this.shipmentDetails.pickupStop.timeWindowStart = null;
                this.shipmentDetails.pickupStop.timeWindowEnd = null;
                this.pickupTime = null;
            }

            this.isPickupTimeValid = isTimeValid;
        },
        getDropTime(selectedTime, isTimeValid) {
            if (selectedTime != null) {
                const { appointmentTime, timeWindowStart, timeWindowEnd } = selectedTime;
                this.shipmentDetails.dropStop.appointmentTime = appointmentTime;
                this.shipmentDetails.dropStop.timeWindowStart = timeWindowStart;
                this.shipmentDetails.dropStop.timeWindowEnd = timeWindowEnd;
                this.dropTime = this.getStopTime(
                    this.shipmentDetails.dropStop.timeWindowStart,
                    this.shipmentDetails.dropStop.timeWindowEnd,
                    this.shipmentDetails.dropStop.appointmentTime,
                    this.additionalDropTimeOption
                );
            } else {
                this.shipmentDetails.dropStop.appointmentTime = null;
                this.shipmentDetails.dropStop.timeWindowStart = null;
                this.shipmentDetails.dropStop.timeWindowEnd = null;
                this.dropTime = null;
            }

            this.isDropTimeValid = isTimeValid;
        },
        resetShipmentState() {
            Object.assign(this.shipmentDetails, STOP_DETAILS_DEFAULTS());
            this.$v.$reset();
        },
        handleCustomFieldChanged({ name, value }) {
            this.customFieldValues[name] = value;
        },
        handleLoadChanged({ name, value }) {
            this.loadValues[name] = parseFloat(value);
            this.ruleGenerationLoadDetails();
        },
        handleCustomerFieldChange() {
            this.ruleGenerationLoadDetails();
        },
        async handleTeamRegionMemberChanged(val) {
            this.shipmentDetails.teamRegionId = val.teamRegionId;
            this.shipmentDetails.pickupStop.teamRegionId = val.teamRegionId;
            this.shipmentDetails.dropStop.teamRegionId = val.teamRegionId;
            this.shipmentDetails.assignToPublicUserId = val.publicUserId;

            await this.getDefaultWarehouse(val.teamRegionId ?? val.teamMember?.teamRegionId ?? -1);
            this.validateCanGenerateRates();
        },
        handleBrandChange(value) {
            this.shipmentDetails.brandId = value;
        },
        setDurationMinutes(publicUserId) {
            const teamMember = this.members.find((x) => x.publicUserId === publicUserId);
            const defaultStopDurationMinutes =
                teamMember?.defaultStopDurationMinutes ?? this.user.defaultStopDurationMinutes;

            // User user or team default stop duration, if null use value from STOP_DETAILS_DEFAULTS
            this.shipmentDetails.pickupStop.durationMinutes =
                defaultStopDurationMinutes ?? STOP_DETAILS_DEFAULTS().durationMinutes;
            this.shipmentDetails.dropStop.durationMinutes =
                defaultStopDurationMinutes ?? STOP_DETAILS_DEFAULTS().durationMinutes;
        },
        getStopTime(stopTimeWindowStart, stopTimeWindowEnd, stopAppointmentTime, additionalTimeOption) {
            let stopTime = null;
            if (stopTimeWindowStart != null && stopTimeWindowEnd != null) {
                const timeWindowStartStandard = moment(stopTimeWindowStart, DATE_TYPES.militaryTime).format(
                    DATE_TYPES.standardTime
                );
                const timeWindowEndStandard = moment(stopTimeWindowEnd, DATE_TYPES.militaryTime).format(
                    DATE_TYPES.standardTime
                );

                let hasMatch = false;

                TIME_WINDOW_CONSTANTS.some((x) => {
                    if (stopTimeWindowStart === x.timeWindowStart && stopTimeWindowEnd === x.timeWindowEnd) {
                        stopTime = x.name;
                        hasMatch = true;
                    }
                    return stopTimeWindowStart === x.timeWindowStart && stopTimeWindowEnd === x.timeWindowEnd;
                });

                if (!hasMatch) {
                    stopTime = `${timeWindowStartStandard} - ${timeWindowEndStandard}`;
                    additionalTimeOption.push({
                        name: stopTime,
                        timeWindowStart: stopTimeWindowStart,
                        timeWindowEnd: stopTimeWindowEnd
                    });
                }
            } else if (stopAppointmentTime != null) {
                stopTime = stopAppointmentTime;
            } else {
                stopTime = 'None';
            }
            return stopTime;
        },
        handlePickupCoordinates(value) {
            this.usePickupCoordinates = value;
            this.$v.$reset();
        },
        handleDropCoordinates(value) {
            this.useDropCoordinates = value;
            this.$v.$reset();
        },
        async getDefaultWarehouse(teamRegionId) {
            if (!this.user.publicTeamId) 
                return;
            if (!this.allWarehouses.length) 
                return;
            const teamRegionIdToUse = teamRegionId === -1 ? null : teamRegionId;
            const defaultPickup = this.allWarehouses.find(
                (x) => x.isDefaultShipmentPickupStop && x.teamRegionId === teamRegionIdToUse
            );
            this.populateStopWithWarehouse(this.shipmentDetails.pickupStop, defaultPickup);

            const defaultDrop = this.allWarehouses.find(
                (x) => x.isDefaultShipmentDropStop && x.teamRegionId === teamRegionIdToUse
            );
            this.populateStopWithWarehouse(this.shipmentDetails.dropStop, defaultDrop);
        },
        // eslint-disable-next-line func-names
        handleShipmentDetailsChanged: _.debounce(function() {
            if (
                this.shipmentDetails.pickupStop.address !== this.pickupAddress ||
                this.shipmentDetails.dropStop.address !== this.dropStop
            ) {
                this.validateCanGenerateRates(true);

                this.pickupAddress = this.shipmentDetails.pickupStop.address;
                this.dropStop = this.shipmentDetails.dropStop.address;
            }
        }, 500),
        async generateRates(addressChanged, rateGroupChanged) {
            if (!this.hasRateRules) 
                return;

            if (!this.shipmentDetails.rateGroupId) {
                this.chargeList = [];
                this.costList = [];

                this.shipmentDetails.chargeLines = this.chargeList;
                this.shipmentDetails.costLines = this.costList;
                return;
            }

            this.ratesIsLoading = true;

            let response = {
                data: {}
            };

            try {
                const data = this.$_getshipmentDataModel(
                    this.shipmentDetails,
                    this.user,
                    this.loadValues,
                    null,
                    null,
                    true,
                    this.shipmentDetails.assignToPublicUserId !== null &&
                        this.shipmentDetails.assignToPublicUserId !== 0,
                    false,
                    false,
                    rateGroupChanged,
                    addressChanged
                );

                const payload = {
                    method: 'post',
                    data
                };

                response = await handleRequests('/api/rates/generate-rates', payload);

                if (response.data) {
                    this.rateListData = response.data;
                    this.chargeList = response.data.charge ?? [];
                    this.costList = response.data.cost ?? [];
                    this.currency = this.rateListData?.currency;

                    this.shipmentDetails.distance = response.data.distance ?? 0;
                }

                this.ratesIsLoading = false;
            } catch (error) {
                const message = `Error generating rates, if issue persists please contact support`;
                showErrorMessage(this, message, null);
                this.ratesIsLoading = false;
            }
        },
        openCharges() {
            document.querySelector('.charge-wrapper').classList.toggle('side-panel-open');
            document.querySelector('.charge-wrapper').classList.toggle('side-panel');
        },
        handleRateTypeChanged(rateRule) {
            this.shipmentDetails.rateGroupId = rateRule?.rateGroupId ?? null;
            this.validateCanGenerateRates(false, true);
        },
        validateCanGenerateRates(addressChanged, rateGroupChanged) {
            if (
                this.shipmentDetails.pickupStop.address !== null &&
                this.shipmentDetails.dropStop.address !== null &&
                this.ratesIsLoading === false
            ) {
                this.generateRates(addressChanged, rateGroupChanged);
            }
        },
        // eslint-disable-next-line func-names
        ruleGenerationDebounce: _.debounce(function() {
            this.validateCanGenerateRates();
        }, 500),
        // eslint-disable-next-line func-names
        ruleGenerationLoadDetails: _.debounce(function() {
            this.validateCanGenerateRates();
        }, 1000),
        populateStopWithWarehouse(stop, warehouseDetails) {
            if (!warehouseDetails) {
                // clear
                stop.location = {
                    latitude: null,
                    longitude: null
                };
                stop.name = null;
                stop.address = null;
                stop.coordinateAddress = null;
                stop.addressComponents = null;
                stop.contact.name = null;
                stop.contact.phone = null;
                stop.contact.email = null;
                stop.notes = null;
            } else {
                stop.location = {
                    latitude: warehouseDetails.location.latitude,
                    longitude: warehouseDetails.location.longitude
                };
                stop.name = warehouseDetails.name;
                stop.address = warehouseDetails.address;
                stop.coordinateAddress = warehouseDetails.coordinateAddress;
                stop.addressComponents = { ...warehouseDetails.addressComponents };
                stop.contact.name = `${warehouseDetails.firstName || ''} ${warehouseDetails.lastName || ''}`.trim();
                stop.contact.phone = warehouseDetails.phone;
                stop.contact.email = warehouseDetails.email;
                stop.notes = warehouseDetails.defaultStopNotes;
            }
        },
        handleCustomerChangeRateGroup(customerRateGroupId) {
            if (this.shipmentDetails.rateGroupId === null || this.shipmentDetails.rateGroupId === undefined) {
                this.shipmentDetails.rateGroupId = customerRateGroupId;
            }
        }
    },
    data() {
        return {
            disabledDates: (date) => {
                const x = moment(date).format(DATE_TYPES.internationalDate);
                const now = moment().format(DATE_TYPES.internationalDate);
                return !moment(x).isSameOrAfter(now, 'day');
            },
            isSetTripDate: false,
            tripDateRequired: false,
            isPickupTimeValid: true,
            isDropTimeValid: true,
            shipmentCustomFieldList: [],
            customFieldValues: {},
            loadFieldList: [],
            loadValues: {},
            skillOptions: [],
            teamMemberOptions: [],
            pickupTime: 'None',
            dropTime: 'None',
            additionalPickupTimeOption: [],
            additionalDropTimeOption: [],
            isFormTouched: false,
            usePickupCoordinates: false,
            useDropCoordinates: false,
            rateListData: null,
            currency: '',
            chargeList: [],
            ratesIsLoading: false,
            pickupAddress: null,
            dropAddress: null,
            costList: []
        };
    },
    validations: {
        shipmentDetails: {
            pickupStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.usePickupCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.usePickupCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.isSetTripDate;
                        }),
                    numeric
                }
            },
            dropStop: {
                address: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return !this.useDropCoordinates;
                        }),
                    coordinatesValid(data) {
                        if (this.useDropCoordinates) {
                            return isAValidCoordinate(data);
                        }
                        return true;
                    }
                },
                durationMinutes: {
                    required:
                        // eslint-disable-next-line func-names
                        requiredIf(function() {
                            return this.isSetTripDate;
                        }),
                    numeric
                }
            },
            sourceReference: {
                maxLength: maxLength(200)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-container {
    max-width: 600px;

    .modal-body {
        min-height: 400px;
        padding-bottom: 0;

        ::v-deep .md-card-tabs .tab-content {
            max-height: 65vh;
            overflow: auto;
        }
    }

    .stats {
        flex-grow: 8;

        .md-icon {
            color: #ffd900;
        }
    }

    .tip {
        font-size: 12px;
    }
}

.custom-tab-list {
    margin-top: -20px;

    ::v-deep .md-card-header {
        padding: 0;
    }

    ::v-deep .md-card-content {
        padding: 0;

        .md-list {
            margin: 0;

            .md-list-item:first-child {
                margin-left: 0;
            }
        }
    }
}

.shipment-form-container {
    padding-top: 20px;
    max-height: 80vh;
    padding-right: 10px;
}

.trip-settings {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    min-width: 125px;
    max-width: 30%;
}

.duration-setting {
    min-width: 90px;
    max-width: 100px;
}

.contact-details {
    margin-top: 20px;
}

::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}

.shipment-drop-datepicker {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    ::v-deep .md-clear {
        display: none;
    }

    > div {
        margin-right: 20px;
        min-width: 125px;
    }

    > div:first-child {
        width: 35%;
    }

    > div:last-child {
        margin-right: 0;
        min-width: 100px;
        max-width: 100px;
    }

    .error {
        font-size: 12px;
        color: red;
        margin-top: -10px;
        display: block;
    }

    .md-input {
        width: 100%;
    }

    .time-picker:after {
        height: 0;
    }

    .time-window {
        width: 100%;

        > div {
            width: 100%;
        }

        > div:first-child {
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            font-weight: 600;
            text-align: left;
        }

        > div:nth-child(2) {
            ::v-deep .md-radio {
                margin: 0;
                margin-right: 15px;
            }

            text-transform: uppercase;
            text-align: left;
            font-size: 12px;
        }
    }
}

.charge-wrapper {
    padding-left: 25px;
}

.side-panel {
    display: none;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 600px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #000000;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
}

.side-panel-open {
    display: initial;
    width: 500px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 601px;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 0px;
    border-radius: 15px;
    border: 2px solid #ffffff;
    max-width: 500px;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px (0, 0, 0, 0.35);
}

.chargeButton-toggle {
    float: right;
    border: none;
    background-color: #4caf50;
    position: relative;
    place-items: center;
    display: grid;
    color: white !important;
}

.chargeButton-toggle:hover {
    background: #409443;
}

.sp-icon-close {
    display: none !important;
}

.sp-icon-open {
}

.material-symbols-outlined {
    place-items: center;
    display: grid;
    color: #ffff;
}
</style>
