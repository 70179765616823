<template>
    <div class="details-page">
        <div class="md-layout">
            <div class="md-layout-item">
                <div class="custom-toolbar">
                    <div class="custom-toolbar-end" v-if="!$root.isMobileOnly">
                        <div class="header-button-container">
                            <create-run-stop
                                :run-schedule-id="$route.params.runScheduleId"
                                :verify-pending-trip-exists-on-run-schedule="verifyPendingTripExistsOnRunSchedule"
                                :run-schedule-customer-id="runDetails.customerId"
                                @runStopCreated="handleRunStop"
                            />
                        </div>
                        <div class="header-button-container">
                            <md-button title="Edit run schedule details" @click.stop="handleRunScheduleUpdate">
                                <span>Edit</span>
                            </md-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="md-layout md-alignment-top-left content-layout-panel">
            <div
                class="md-layout-item md-small-size-100 md-medium-size-100 md-layout md-large-size-100 md-xlarge-size-100"
            >
                <div class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xlarge-size-100">
                    <md-card>
                        <md-card-header class="md-card-header-icon md-card-header-blue">
                            <div class="card-icon" v-if="runDetails.assignedTo.photoUrl === null">
                                <md-icon>description</md-icon>
                            </div>
                            <div class="card-icon profile-image" v-if="runDetails.assignedTo.photoUrl !== null">
                                <img
                                    :src="runDetails.assignedTo.photoUrl"
                                    @error="$_setDefaultBrokenImage"
                                    alt="avatar"
                                />
                            </div>
                        </md-card-header>

                        <md-card-content>
                            <div class="single-row three-column-box">
                                <div class="custom-md-field details-box">
                                    <div class="custom-label">Name</div>
                                    <a href="#" title="Edit run schedule details" @click.stop="handleRunScheduleUpdate">
                                        {{ runDetails.name }}
                                    </a>
                                </div>
                                <div class="details-box">
                                    <label>Run Number</label>
                                    <span class="value">
                                        {{ runDetails.runNumber }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Schedule</label>
                                    <span class="value">
                                        <span v-if="runDetails.schedule">
                                            {{ cronExpressionExplainer(runDetails) }}
                                            <md-tooltip class="tooltip-width" md-direction="top">
                                                {{ runDetails.humanReadableSchedule }}
                                            </md-tooltip>
                                        </span>
                                        <span v-else-if="runDetails.scheduleType === 'Fortnightly'">
                                            {{ runDetails.scheduleType }} ({{
                                                runDetails.activeFromDate | dateFormat(DATE_TYPES.standardDate)
                                            }})
                                        </span>
                                        <span v-else>
                                            {{ runDetails.scheduleType }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="single-row three-column-box">
                                <div class="custom-md-field details-box">
                                    <div class="custom-label">Driver</div>
                                    <router-link
                                        v-if="runDetails.assignedTo.publicUserId"
                                        :to="{
                                            name: 'Team Member Profile',
                                            params: { publicUserId: runDetails.assignedTo.publicUserId }
                                        }"
                                        target="_blank"
                                    >
                                        {{ runDetails.assignedTo.fullName }}
                                    </router-link>
                                    <a v-else>Unassigned</a>
                                </div>

                                <div class="details-box" v-if="runDetails.customerName">
                                    <label>Customer</label>
                                    <span class="value">
                                        {{ runDetails.customerName }}
                                    </span>
                                </div>
                            </div>
                            <div class="single-row-three-column">
                                <div class="details-box">
                                    <label>Start Time</label>
                                    <span>
                                        {{ convertTimeFormat(runDetails.startTime) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label>Start Location</label>
                                    <span>{{ runDetails.startAddress }}</span>
                                </div>
                                <div class="details-box">
                                    <label v-if="runDetails.startLocationStopDurationMinutes != null">
                                        Start Location Stop Duration (mins)
                                    </label>
                                    <span v-if="runDetails.startLocationStopDurationMinutes != null">
                                        {{ runDetails.startLocationStopDurationMinutes }}
                                    </span>
                                </div>
                            </div>
                            <div class="single-row-three-column">
                                <div class="details-box">
                                    <label v-if="runDetails.endTime != null">End Time</label>
                                    <span v-if="runDetails.endTime != null">
                                        {{ convertTimeFormat(runDetails.endTime) }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label v-if="runDetails.endAddress != null">End Location</label>
                                    <span v-if="runDetails.endAddress != null">
                                        {{ runDetails.endAddress }}
                                    </span>
                                </div>
                                <div class="details-box">
                                    <label v-if="runDetails.endLocationStopDurationMinutes != null">
                                        End Location Stop Duration (mins)
                                    </label>
                                    <span v-if="runDetails.endLocationStopDurationMinutes != null">
                                        {{ runDetails.endLocationStopDurationMinutes }}
                                    </span>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100">
                    <run-schedule-stop-list-table
                        :run-details="runDetails"
                        @deleteRunStop="handleRunStop"
                        @runStopUpdated="handleRunStop"
                        @verifyPendingTripExistsOnRunSchedule="verifyPendingTripExistsOnRunSchedule"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { WEEK_DAYS_CONSTANTS } from '@/utils/constants';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';
import CreateRunStop from '@/pages/RunSchedules/components/CreateRunStop';
import { CreateRunScheduleModal, RunScheduleStopListTable } from './components';

export default {
    name: 'RunScheduleDetails',
    mixins: [GeneralMixin],
    components: {
        RunScheduleStopListTable,
        CreateRunStop
    },
    data() {
        return {
            weekDays: WEEK_DAYS_CONSTANTS,
            runDetails: {
                assignedTo: {
                    photoUrl: null
                }
            },
            teamMembers: []
        };
    },
    async created() {
        this.$_handleLoaderState(true);
        await this.getRunDetails();
        await this.getTeamMembers();
        this.$_handleLoaderState(false);
    },
    methods: {
        async getTeamMembers() {
            this.teamMembers = await this.$store.dispatch('team/FETCH_TEAM_MEMBERS');
        },
        async getRunDetails() {
            try {
                const api = `/api/run-schedules/${this.$route.params.runScheduleId}?includeStops=true`;
                const { data } = await handleRequests(api);
                this.runDetails = data;
            } catch (error) {
                const message = 'Error in loading the run schedule details';
                showErrorMessage(this, message, error);
            }
        },
        async handleRunScheduleUpdate() {
            const runScheduleDetails = this.runDetails;
            runScheduleDetails.runName = runScheduleDetails.name;
            runScheduleDetails.contact = {
                name: runScheduleDetails.customerName
            };
            runScheduleDetails.runStartLocation = {
                address: runScheduleDetails.startAddress,
                location: runScheduleDetails.startLocation,
                name: runScheduleDetails.startAddressName
            };
            runScheduleDetails.runEndLocation = {
                address: runScheduleDetails.endAddress,
                location: runScheduleDetails.endLocation,
                name: runScheduleDetails.endAddressName
            };

            if (!runScheduleDetails.scheduleType) {
                runScheduleDetails.scheduleType = 'Weekly';
            }

            this.$modal
                .show(CreateRunScheduleModal, {
                    members: this.teamMembers,
                    runDetails: Object.assign({}, runScheduleDetails),
                    isUpdate: true
                })
                .then((response) => {
                    if (response && response.toLowerCase() === 'ok') {
                        this.getRunDetails();
                        this.$modal.hide();
                    }
                });
        },
        convertTimeFormat(time) {
            if (time != null) 
                return moment(time, 'LT').format('LT');
            return null;
        },
        cronExpressionExplainer(runDetail) {
            if (runDetail.schedule) {
                const scheduleVal = runDetail.schedule.split(' ');
                const scheduleWeekDays = scheduleVal[4].split(',');
                scheduleWeekDays.forEach((day, index) => {
                    if (day.match(/[a-z]/i)) {
                        scheduleWeekDays[index] = Object.keys(this.weekDays).find(
                            (key) => this.weekDays[key].toLocaleLowerCase() === day.toLocaleLowerCase()
                        );
                    }
                });
                const displayWeekDays = [];
                scheduleWeekDays.sort().forEach((w) => {
                    // eslint-disable-next-line radix
                    displayWeekDays.push(this.weekDays[parseInt(w)]);
                });
                return `${runDetail.scheduleType} (${displayWeekDays.join(', ')})`;
            }
            return '';
        },
        handleRunStop() {
            this.$_handleLoaderState(false);
            this.getRunDetails();
        },
        async verifyPendingTripExistsOnRunSchedule(runScheduleId, onSuccess) {
            this.$_handleLoaderState(true, 'VERIFYING');
            try {
                const api = `/api/run-schedules/${runScheduleId}/verify-trip-exists`;
                const response = await handleRequests(api);

                this.$_handleLoaderState(false);

                const pendingTripExists = response.data;

                if (!pendingTripExists) {
                    await onSuccess();
                    return;
                }

                this.$messageBox
                    .show({
                        title: 'Update run schedule',
                        body:
                            'Existing trips have been detected for this run schedule. All existing trips will be updated.',
                        buttons: ['Ok']
                    })
                    .then(async (response) => {
                        if (response.toLowerCase() === 'ok') {
                            await onSuccess();
                        }
                    });
            } catch (e) {
                this.$_handleLoaderState(false);
                const message = 'Cannot verify trip exists on run schedule';
                showErrorMessage(this, message, e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content-layout-panel {
    > div {
        padding: 0;
    }
}

.details-box {
    display: inline-block;
    vertical-align: top;
    min-width: 150px;
}

.profile-image {
    height: 63px;
    width: 63px;
    padding: 0px !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 0, 0, 0.2) !important;
    img {
        border-radius: 3px;
    }
}

.single-row {
    > div:first-child {
        width: 20%;
    }
    > div:last-child {
        width: 80%;
    }
}

.three-column-box {
    > div:first-child {
        width: 20%;
    }
    > div:nth-child(2) {
        width: 20%;
    }
    > div:last-child {
        width: 20%;
    }
}

.single-row-three-column {
    > div:first-child {
        width: 20%;
    }
    > div:nth-child(2) {
        width: 40%;
    }
    > div:last-child {
        width: 20%;
    }
}
</style>
