<template>
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">{{ isUpdate ? 'Update' : 'Create' }} Invoice Adjustment Rule</h4>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click.stop="$modal.hide">
                <md-icon>clear</md-icon>
            </md-button>
        </div>
        <div class="modal-body">
            <form-wrapper :validator="$v.invoiceAdjustmentRuleDetails" class="form-wrapper">
                <div class="form-container customer-form-container">
                    <div class="contact-details">
                        <form-group attribute="Rule Type" name="invoiceAdjustmentRuleType" label="Rule Type*">
                            <md-input v-model="invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType" type="hidden" />
                            <vue-select
                                :reduce="(r) => r"
                                label="type"
                                :options="invoiceAdjustmentRuleTypeOptions"
                                v-model="invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType"
                                :searchable="$root.isDesktop"
                            ></vue-select>
                        </form-group>
                        <p class="type-message" v-if="typeDescription && typeDescription.trim().length > 0">
                            *{{ typeDescription }}
                        </p>
                        <form-group attribute="Tax Settings" name="taxSettingsId" label="Tax Settings">
                            <tax-settings-options
                                :selected="invoiceAdjustmentRuleDetails.taxSettingsId"
                                :is-update="isUpdate"
                                @optionSelected="handleTaxSettingsChanged"
                            />
                        </form-group>
                        <form-group attribute="Description" name="description" label="Description*">
                            <md-input v-model.trim="invoiceAdjustmentRuleDetails.description" />
                        </form-group>
                        <form-group attribute="Unit Price" name="unitPrice" label="Unit Price*">
                            <md-input type="number" v-model="invoiceAdjustmentRuleDetails.unitPrice" />
                        </form-group>
                        <form-group attribute="Account Code" name="accountCode" label="Account Code">
                            <account-code-options
                                :selected="invoiceAdjustmentRuleDetails.accountCode"
                                :is-update="isUpdate"
                                @optionSelected="handleAccountCodeChanged"
                            />
                        </form-group>
                    </div>
                </div>
            </form-wrapper>
        </div>
        <div class="modal-footer">
            <md-button class="dialog-button md-primary" @click="validateRule">
                {{ isUpdate ? 'Update' : 'Create' }}
            </md-button>
            <md-button class="dialog-button md-default" @click.stop="$modal.hide">Cancel</md-button>
        </div>
    </div>
</template>

<script>
import { handleRequests, showErrorMessage } from '@/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { INVOICE_ADJUSTMENT_RULE_TYPES } from '@/utils/constants';
import TaxSettingsOptions from '../../../components/TaxSettingsOptions';
import AccountCodeOptions from '../../../components/AccountCodeOptions';

export default {
    name: 'CreateInvoiceAdjustmentRuleModal',
    components: {
        TaxSettingsOptions,
        AccountCodeOptions
    },
    mixins: [GeneralMixin],
    data() {
        return {
            invoiceAdjustmentRuleTypeOptions: [...INVOICE_ADJUSTMENT_RULE_TYPES],
            typeDescription: '',
            loadingFinished: false,
        };
    },
    props: {
        invoiceAdjustmentRuleDetails: {
            type: Object,
            default: () => {}
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        resolve: {
            type: Function,
            default: () => {}
        }
    },
    mounted() {
        if (this.invoiceAdjustmentRuleDetails && this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType){
            const invoiceType = this.findInvoiceType(this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType);
            this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType = invoiceType?.type;
            this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleTypeId = invoiceType?.value;
            
            this.setInvoiceAdjustmentTypeDescription(this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleTypeId);
        }
        else{
            this.loadingFinished = true;
        }
    },
    watch: {
        'invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType': {
            immediate: false,
            handler(newValue) {
                if (!this.isUpdate || this.loadingFinished){
                    this.typeDescription = newValue ? newValue.information : '';
                }
            }
        }
    },
    methods: {
        async create() {
            this.$_handleLoaderState(true, 'SAVING...');

            const api = `/api/invoice-adjustment-rules`;
            const payload = {
                method: 'post',
                data: this.getMappedData()
            };

            try {
                const response = await handleRequests(api, payload);
                const invoiceAdjustmentRuleId = response.data;
                if (invoiceAdjustmentRuleId && invoiceAdjustmentRuleId > 0) {
                    this.$notifySuccess('Successfully created invoice adjustment rule.');
                    this.$v.$reset();
                    this.resolve('ok');
                }
            } catch (error) {
                const message = 'Error in creating the invoice adjustment rule';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        async update() {
            this.$_handleLoaderState(true, 'UPDATING...');
            const api = `/api/invoice-adjustment-rules/${this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleId}`;
            const payload = {
                method: 'put',
                data: this.getMappedData()
            };

            try {
                await handleRequests(api, payload);
                this.$notifySuccess('Successfully updated invoice adjustment rule.');
                this.$v.$reset();
                this.resolve('ok');
            } catch (error) {
                const message = 'Error in updating the invoice adjustment rule';
                showErrorMessage(this, message, error);
            }
            this.$_handleLoaderState(false);
        },
        getMappedData() {
            return {
                invoiceAdjustmentRuleType: this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleType?.value
                    ?? this.invoiceAdjustmentRuleDetails.invoiceAdjustmentRuleTypeId,
                taxSettingsId: this.invoiceAdjustmentRuleDetails.taxSettingsId,
                description: this.invoiceAdjustmentRuleDetails.description,
                unitPrice: this.invoiceAdjustmentRuleDetails.unitPrice,
                accountCode: this.invoiceAdjustmentRuleDetails.accountCode
            };
        },
        handleTaxSettingsChanged(value) {
            this.invoiceAdjustmentRuleDetails.taxSettingsId = value;
        },
        handleAccountCodeChanged(value) {
            this.invoiceAdjustmentRuleDetails.accountCode = value;
        },
        validateRule() {
            this.$v.invoiceAdjustmentRuleDetails.$touch();
            if (!this.$v.invoiceAdjustmentRuleDetails.$invalid) {
                if (this.isUpdate) {
                    this.update();
                } else {
                    this.create();
                }
            }
        },
        setInvoiceAdjustmentTypeDescription(val) {
            const typeSelected = this.findInvoiceType(val);
            this.typeDescription = typeSelected ? typeSelected.information : '';
            setTimeout(() => {
                this.loadingFinished = true;
            }, 1000)
        },
        findInvoiceType(value){
            return this.invoiceAdjustmentRuleTypeOptions.find((x) => x.value === value);
        }
    },
    validations: {
        invoiceAdjustmentRuleDetails: {
            description: { required, maxLength: maxLength(100) },
            unitPrice: { required },
            accountCode: {},
            taxSettingsId: {},
            invoiceAdjustmentRuleType: { required }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-menu-content.md-select-menu {
    width: 150px;
}
.modal-container {
    max-width: 600px;
}

.popup {
    position: absolute;
    top: 0;
    left: 100%;
}

.type-message {
    font-size: smaller;
}
</style>
