var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal-header" },
      [
        _c("h4", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(_vm.isUpdate ? "Update" : "Create") + " Customer Profile"
          ),
        ]),
        _c(
          "md-button",
          {
            staticClass: "md-simple md-just-icon md-round modal-default-button",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_c("md-icon", [_vm._v("clear")])],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _c(
          "form-wrapper",
          {
            staticClass: "form-wrapper",
            attrs: { validator: _vm.$v.customerDetails },
          },
          [
            _c(
              "div",
              { staticClass: "form-container customer-form-container" },
              [
                _c(
                  "div",
                  { staticClass: "contact-details" },
                  [
                    !_vm.isSingleUser && !_vm.isSingleTeamMember
                      ? _c(
                          "div",
                          [
                            _vm.showTeamRegion
                              ? _c(
                                  "form-group",
                                  {
                                    attrs: {
                                      name: "teamRegion",
                                      label: "Team Region",
                                    },
                                  },
                                  [
                                    _c("team-region-options", {
                                      attrs: {
                                        selected:
                                          _vm.customerDetails.teamRegionId,
                                        mode: _vm.isUpdate
                                          ? "update"
                                          : "create",
                                        "show-auto": false,
                                      },
                                      on: {
                                        selectedOption:
                                          _vm.handleTeamRegionChanged,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "form-group",
                      { attrs: { name: "firstName", label: "First Name" } },
                      [
                        _c("md-input", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          on: {
                            input: function ($event) {
                              return _vm.setCustomerName(_vm.customerDetails)
                            },
                          },
                          model: {
                            value: _vm.customerDetails.firstName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "firstName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.firstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "lastName", label: "Last Name" } },
                      [
                        _c("md-input", {
                          on: {
                            input: function ($event) {
                              return _vm.setCustomerName(_vm.customerDetails)
                            },
                          },
                          model: {
                            value: _vm.customerDetails.lastName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "lastName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.lastName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "company", label: "Company" } },
                      [
                        _c("md-input", {
                          on: {
                            input: function ($event) {
                              return _vm.setCustomerName(_vm.customerDetails)
                            },
                          },
                          model: {
                            value: _vm.customerDetails.company,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "company",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.company",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "businessRegistrationNumber",
                          label: "ABN",
                        },
                      },
                      [
                        _c("md-input", {
                          model: {
                            value:
                              _vm.customerDetails.businessRegistrationNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "businessRegistrationNumber",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "customerDetails.businessRegistrationNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("brand-filter", {
                          attrs: { "list-of-brands": _vm.brands },
                          model: {
                            value: _vm.customerDetails,
                            callback: function ($$v) {
                              _vm.customerDetails = $$v
                            },
                            expression: "customerDetails",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("charging-type-options", {
                          attrs: {
                            "selected-charge-type-id":
                              _vm.customerDetails.defaultRateGroupId,
                            "label-name": "Default Service",
                          },
                          on: { selectedOption: _vm.rateGroupChanged },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        staticClass: "invoice-line-container",
                        attrs: { name: "invoiceTypes", label: "Invoice Types" },
                      },
                      [
                        _c("md-input", {
                          attrs: { type: "hidden" },
                          model: {
                            value: _vm.customerDetails.invoiceLineTypes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "invoiceLineTypes",
                                $$v
                              )
                            },
                            expression: "customerDetails.invoiceLineTypes",
                          },
                        }),
                        _c("vue-select", {
                          staticClass: "invoice-line-background",
                          attrs: {
                            reduce: function (r) {
                              return r
                            },
                            label: "name",
                            options: _vm.invoiceLineOptions,
                            searchable: _vm.$root.isDesktop,
                            multiple: "",
                          },
                          model: {
                            value: _vm.customerDetails.invoiceLineTypes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "invoiceLineTypes",
                                $$v
                              )
                            },
                            expression: "customerDetails.invoiceLineTypes",
                          },
                        }),
                        _vm.customerDetails.invoiceLineTypes &&
                        _vm.customerDetails.invoiceLineTypes.length > 0
                          ? _c(
                              "div",
                              { staticClass: "invoice-line-icon" },
                              [
                                _vm.shouldShowDownloadIcon
                                  ? _c(
                                      "md-button",
                                      {
                                        staticClass:
                                          "md-primary md-just-icon md-round",
                                        on: {
                                          click:
                                            _vm.downloadInvoiceLineExamples,
                                        },
                                      },
                                      [
                                        _c("md-icon", [_vm._v("download")]),
                                        _c(
                                          "md-tooltip",
                                          {
                                            attrs: { "md-direction": "right" },
                                          },
                                          [_vm._v("Download Examples")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("google-autocomplete", {
                      ref: "address",
                      attrs: {
                        label: "Default Stop Address",
                        id: "autoaddress",
                        classname: "form-control autocomplete-input",
                        placeholder: "",
                        "should-focus": false,
                        "disable-gps-coordinates": false,
                        "gps-coordinate-placeholder":
                          "Enter a name for this stop address",
                      },
                      on: { handleCoordinates: _vm.handleCoordinates },
                      model: {
                        value: _vm.customerDetails,
                        callback: function ($$v) {
                          _vm.customerDetails = $$v
                        },
                        expression: "customerDetails",
                      },
                    }),
                    _c(
                      "form-group",
                      { attrs: { name: "email", label: "Email" } },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.customerDetails.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        attrs: { name: "contactEmail", label: "Contact Email" },
                      },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.customerDetails.contactEmail,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "contactEmail",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.contactEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "md-checkbox",
                          {
                            model: {
                              value:
                                _vm.customerDetails
                                  .enableBookingConfirmationEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerDetails,
                                  "enableBookingConfirmationEmail",
                                  $$v
                                )
                              },
                              expression:
                                "customerDetails.enableBookingConfirmationEmail",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Enable booking confirmation emails\n                        "
                            ),
                          ]
                        ),
                        _c("md-tooltip", [
                          _c("span", [
                            _vm._v(
                              "\n                                When you add a stop/shipment on behalf of this customer they will receive a\n                                confirmation email on their 'Contact Email' if this box is checked.\n                            "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "skillfield-container" },
                      [
                        _vm.accountEmailList.length
                          ? _c("label", { attrs: { for: "skills" } }, [
                              _vm._v("Accounts Email"),
                            ])
                          : _vm._e(),
                        _c("multiselect", {
                          attrs: {
                            options: _vm.accountEmailList,
                            multiple: true,
                            taggable: true,
                            "close-on-select": true,
                            placeholder: "Search or add accounts email",
                          },
                          on: { tag: _vm.addAccountEmail },
                          model: {
                            value: _vm.accountEmailList,
                            callback: function ($$v) {
                              _vm.accountEmailList = $$v
                            },
                            expression: "accountEmailList",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "phone", label: "Phone" } },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.customerDetails.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "defaultStopNotes",
                          label: "Default Stop/Shipment Notes",
                        },
                      },
                      [
                        _c("md-textarea", {
                          model: {
                            value: _vm.customerDetails.defaultStopNotes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "defaultStopNotes",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.defaultStopNotes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      { attrs: { name: "notes", label: "Notes" } },
                      [
                        _c("md-textarea", {
                          model: {
                            value: _vm.customerDetails.notes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "notes",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.notes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        attrs: {
                          name: "defaultStopDurationMinutes",
                          label: "Default stop duration (mins)",
                        },
                      },
                      [
                        _c("md-input", {
                          attrs: { type: "number" },
                          model: {
                            value:
                              _vm.customerDetails.defaultStopDurationMinutes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "defaultStopDurationMinutes",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "customerDetails.defaultStopDurationMinutes",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "form-group",
                      {
                        staticClass: "last-input-field",
                        attrs: { name: "customerName", label: "Customer Name" },
                      },
                      [
                        _c("md-input", {
                          model: {
                            value: _vm.customerDetails.customerName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customerDetails,
                                "customerName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customerDetails.customerName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.allTeamMembers.length
                      ? _c(
                          "form-group",
                          {
                            attrs: {
                              name: "teamMembers",
                              label: "Associated Team members",
                            },
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.allTeamMembers,
                                multiple: true,
                                "close-on-select": true,
                                placeholder: "Associate Team member",
                                label: "fullName",
                                "track-by": "publicUserId",
                              },
                              model: {
                                value: _vm.selectedTeamMembers,
                                callback: function ($$v) {
                                  _vm.selectedTeamMembers = $$v
                                },
                                expression: "selectedTeamMembers",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customerDetails.linkedUserEmail == null &&
                    !_vm.isSingleUser
                      ? _c(
                          "md-checkbox",
                          {
                            model: {
                              value: _vm.customerDetails.createUserAccount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerDetails,
                                  "createUserAccount",
                                  $$v
                                )
                              },
                              expression: "customerDetails.createUserAccount",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Create a user login for this customer?\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.customerDetails.createUserAccount ||
                    _vm.customerDetails.linkedUserEmail != null
                      ? _c(
                          "form-group",
                          {
                            attrs: {
                              name: "customerrole",
                              label: "Customer Role",
                            },
                          },
                          [
                            _c(
                              "md-select",
                              {
                                model: {
                                  value: _vm.customerDetails.role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.customerDetails, "role", $$v)
                                  },
                                  expression: "customerDetails.role",
                                },
                              },
                              _vm._l(
                                _vm.customerRoles,
                                function (customerRole, index) {
                                  return _c(
                                    "md-option",
                                    {
                                      key: index,
                                      attrs: { value: customerRole },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(customerRole) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _vm.customerDetails.createUserAccount
                          ? _c(
                              "md-checkbox",
                              {
                                model: {
                                  value: _vm.setPassword,
                                  callback: function ($$v) {
                                    _vm.setPassword = $$v
                                  },
                                  expression: "setPassword",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Set a password.\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.setPassword
                      ? _c(
                          "form-group",
                          { attrs: { name: "password", label: "Password" } },
                          [
                            _c("md-input", {
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _c("md-icon", { staticClass: "icon-info" }, [
                              _vm._v("info"),
                            ]),
                            _c(
                              "md-tooltip",
                              {
                                staticClass: "tooltip-width",
                                attrs: { "md-direction": "right" },
                              },
                              [
                                _vm._v(
                                  "\n                            Passwords must contains at least 8 characters with at least one non alphanumeric\n                            character, at least one digit ('0'-'9'), at least one lowercase ('a'-'z') and at least\n                            one uppercase ('A'-'Z').\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm.customerDetails.linkedUserEmail != null
                      ? _c("span", { staticClass: "customer-note" }, [
                          _vm._v(
                            "\n                        ** This customer is linked to this user: " +
                              _vm._s(_vm.customerDetails.linkedUserEmail) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-primary",
            on: { click: _vm.validateCustomer },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.isUpdate ? "Update" : "Create") +
                "\n        "
            ),
          ]
        ),
        _c(
          "md-button",
          {
            staticClass: "dialog-button md-default",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$modal.hide($event)
              },
            },
          },
          [_vm._v("Cancel")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }